<mat-toolbar>
    <h1>
        User management
        <button type="button" mat-flat-button color="primary" (click)="expandAll()">{{allExpanded ? 'Collapse' : 'Expand'}} all</button>
    </h1>
    <div class="w-100 text-right">
        <button *ngIf="!isAdmin" type="button" mat-flat-button color="primary" class="ml-4" (click)="newCompany()">New company <mat-icon>add</mat-icon></button>
        <button type="button" mat-flat-button color="primary" class="ml-4" (click)="newUser()">New user <mat-icon>add</mat-icon></button>
    </div>
</mat-toolbar>

<table *ngIf="companies" mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-100 table-row-spacing pb-40">
    <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef>data</th>
        <td mat-cell *matCellDef="let element">

            <div class="mat-cell-content-wrapper ">
                <div class="row">
                    <div class="cell-title col-md-6 d-flex align-items-center text-bold">
                        {{element.name}} <span class="ml-2 text-normal">({{element.users ? element.users.length : 0}})</span>
                        <button *ngIf="(element.id > 0) && element.expanded" mat-icon-button type="button" color="primary" (click)="newCompany(element)" appClickStop><mat-icon>edit</mat-icon></button>
                    </div>
                    <div class="col-md-6 text-right">
                        <span class="text-primary ml-4 cursor-pointer">
                            {{element.expanded ? 'Hide details' : 'Show details'}}
                            <mat-icon class="align-bottom">
                                {{element.expanded ? 'expand_less' : 'expand_more'}}
                            </mat-icon>
                        </span>
                    </div>
                </div>
            </div>
        </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="overflow-hidden mat-cell-expanding-content" [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
                <table class="table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Phone number</th>
                        <th>Email address</th>
                        <th>Last login</th>
                        <th>Last month login</th>
                        <th>Last year login</th>
                        <th>2fa enabled</th>
                        <th>Activated</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let user of element.users">
                        <td>
                            <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="color-mid-grey">more_vert</mat-icon></button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="newUser(user, element.allIndices)">Edit profile</button>
                                <button mat-menu-item (click)="confirmDelete(user)">Delete</button>
                            </mat-menu>
                        </td>
                        <td class="text-bold">
                            <span class="table-user-photo" [style.backgroundImage]="'url('+ user.getPhoto() +')'"></span>
                            {{user.first_name}} {{user.last_name}}
                            <mat-icon *ngIf="user.is_admin" matTooltip="Admin">verified_user</mat-icon>
                        </td>
                        <td>{{user.job_description || '-'}}</td>
                        <td>{{user.phone}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.last_login}}</td>
                        <td>{{user.auth_logs_30_days}}</td>
                        <td>{{user.auth_logs_1_year}}</td>
                        <td>
                            <mat-checkbox [disabled]="true" [(ngModel)]="user.two_fa_enabled" [checked]="user.two_fa_enabled" color="primary"></mat-checkbox>
                        </td>
                        <td>
                            <mat-checkbox [disabled]="true" [(ngModel)]="user.activated" [checked]="user.activated" color="primary"></mat-checkbox>
                        </td>
                    </tr>
                    <tr *ngIf="!element.users || !element.users.length">
                        <td colspan="7" class="text-center text-grey p-2">
                            0 users
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </td>
    </ng-container>


    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        (click)="onRowClick(element)"
        class="example-element-row"
        [class.example-expanded-row]="element.expanded">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
