import {Component, Injector, ViewChild} from '@angular/core';
import {PortfolioCreateComponent} from "../portfolio-create.component";
import { AssetTableColumnVisibilityFilterComponent } from '../../asset-table-column-visibility-filter/asset-table-column-visibility-filter.component';

@Component({
  selector: 'app-page-selection',
  templateUrl: './page-selection.component.html',
  styleUrls: [
    '../portfolio-create.component.scss',
    './page-selection.component.scss'
  ]
})
export class PageSelectionComponent extends PortfolioCreateComponent {

  showUploadBtn = false;
  
  @ViewChild('drawer') drawer: any;
  @ViewChild('stockFilters') stockFilters: AssetTableColumnVisibilityFilterComponent;
  @ViewChild('etfFilters') etfFilters: AssetTableColumnVisibilityFilterComponent;

  type = null;
  drawlerData: any = {};
  selectedFilters = {};

  filters = {};

  constructor(injector: Injector) {
    super(injector);

    this.storage.sideMenuObjectChange.subscribe((data) => {

      this.type = data.type;
      this.drawlerData = data;
      data.drawer && this.drawer && this.drawer.toggle();
    })
  }

  initPage() {
    this.selectedPortfolioType = 1;
  }

  onOpenedChange(event) {
    if(!event) {
      this.filterCompanies();
    }
  }

  filterCompanies() {
    const ids = Object.keys(this.selectedFilters).filter((v) => +this.selectedFilters[v]);
    if(this.drawlerData.modelChanged) {
      this.drawlerData.modelChanged.emit(ids);
    } else {
      // clean the colums that are no longer used
      Object.keys(this.columnVisibility).forEach((v) => {
        if(this.columnVisibility[v] === false && document.getElementsByClassName(v)[0]) {
          (document.getElementsByClassName(v)[0].children[1] as any).click();
        }
      });
    }
  }

  fetchCompaniesIn(filter?, page?, isPageEvent?) {
    this.filters = filter ? filter.filters : {};
    let extraFilters: any = {
      page: page ? page : this.currentPage
    }
    if (filter) {
      if (filter.searchTerm) {
        extraFilters.search_term = filter.searchTerm;
      }
      if (filter.page) {
        extraFilters.page = filter.page;
      }
      // type is the etf/stock type
      if (filter.type) {
        extraFilters.type = filter.type;
        delete filter.type;
      }
      if (filter.limit) {
        extraFilters.limit = filter.limit;
        delete filter.limit;
      }
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp, isPageEvent);
    })
  }

  getFiltersIn(filter?) {
    let extraFilters: any = {}
    if (filter && filter.limit) {
      extraFilters.limit = filter.limit;
      delete filter.limit;
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp);
    })
  }

  handleCheckboxUpdate(id) {
    if(!this.selectedFilters[id]) {
      this.selectedFilters[id] = true;
      return;
    }
    this.selectedFilters[id] = !this.selectedFilters[id];
  }

  get getType() {
    return window['target']
  }
}
