import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {PortfolioModel, VISIBILITY_PRIVATE} from "../../../../../shared/models/portfolio.model";
import {CompanyModel} from "../../../../../shared/models/company.model";
import {PageMeta} from "../../../../../shared/models/page.model";
import {BasePortfolioCreateComponent} from "../../../base-portfolio-create/base-portfolio-create.component";
import {
  AssetTableColumnVisibilityFilterComponent
} from "../../../asset-table-column-visibility-filter/asset-table-column-visibility-filter.component";

@Component({
  selector: 'app-existing-portfolio',
  templateUrl: './existing-portfolio.component.html',
  styleUrls: [
    '../new-portfolio/new-portfolio.component.scss',
    './existing-portfolio.component.scss'
  ],
  encapsulation:ViewEncapsulation.None
})
export class ExistingPortfolioComponent extends BasePortfolioCreateComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() redirectToPortfoliosAfterSave = false;
  @Input() meta: PageMeta = {};
  @Input() portfolio = new PortfolioModel({})
  @Input() selected: CompanyModel[] = [];

  @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() portfolioLoaded: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('stockFilters') stockFilters: AssetTableColumnVisibilityFilterComponent;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    // Set default limit to 50
    if (!this.portfolio.limit) {
      this.portfolio.limit = 50;
    }
    this.assetType = 'stock';
  }

  ngAfterViewInit(): void {
    this.restoreVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Extract filters if portoflio received
    if (changes.portfolio && changes.portfolio.currentValue && changes.portfolio.currentValue.id) {
      if (this.portfolio.params) {
        this.onFilterChange();
      }
    }
    if (changes.selected) {
      const list = changes.selected.currentValue;
      if (list.length) {
        this.checkMaxStartDate(list);
      }
    }
    if (changes.list) {
      this.checkColumnLists(changes.list)
    }
  }

  setParamsInPortfolio() {
    this.filterListDescriptor.map(label => {
      if (this.currentFilters.filters[label] && this.currentFilters.filters[label].length) {
        this.portfolio[label] = this.currentFilters.filters[label];
      }
    })
  }

  onFilterChange() {
    this.setParamsInPortfolio()
    this.getFilters.emit(this.collectFilters());
    this.updateFilterLabels();
  }

  onLimitChange() {
    this.portfolio.checkLimit();
  }

  onSortClick(label) {
    // Reset sorting if not the clicked label is used (only 1 can active)
    const sortingKeys = Object.keys(this.sorting);
    if (sortingKeys[0] !== label) {
      this.sorting = {};
    }

    if (!this.sorting[label]) {
      this.sorting[label] = 'asc';
    } else if (this.sorting[label] === 'asc'){
      this.sorting[label] = 'desc';
    } else {
      delete this.sorting[label]
    }
    this.onFilterChange();
  }

  getDynamicColumns() {
    setTimeout(() => {
      this.stockFilters.updateDynamicColumns();
    }, 500)
  }

  onSaveClick() {
    if (!this.portfolio.visibility) {
      this.portfolio.visibility = VISIBILITY_PRIVATE;
    }
    this.service.saveItem(this.portfolio).subscribe(resp => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      this.service.notificationService.open('Portfolio saved');
      if (this.redirectToPortfoliosAfterSave) {
        this.router.navigate(['/app']);
      }
    })
  }
}
