<mat-form-field [appearance]="(isInTableHeader ? 'standard' : 'outline')" [ngClass]="{'w-100': isFullWidth}">
    <mat-label *ngIf="label">{{label}} <span *ngIf="isMultiple && model && model.length">({{model.length}})</span></mat-label>
    <mat-select
            name="select"
            [(ngModel)]="model"
            [required]="isRequired"
            [multiple]="isMultiple"
            [disabled]="isDisabled || !list.length"
            [placeholder]="labelPrefix + label"
            (openedChange)="searchText = ''"
            (ngModelChange)="onModelChange()"
            panelClass="cim-select"
    >
        <input placeholder="Search" matInput [(ngModel)]="searchText" class="select-input-search">
        <mat-option *ngIf="needAllOption">All {{label}}</mat-option>
        <mat-option *ngFor="let item of list | search: searchText" [hidden]="item.hidden == true" [value]="item.id">{{item.name}}</mat-option>
    </mat-select>
</mat-form-field>
