import {Component, Injector, OnInit, Input, Output} from '@angular/core';
import {PortfolioService} from "../portfolio/portfolio.service";
import {PortfolioModel} from "../../shared/models/portfolio.model";
import {Router} from "@angular/router";
import { Subscription } from 'rxjs';
import {UtilService} from "../../shared/services/util.service";
import {LoaderService} from "../../components/loading-bar/loader.service";
import * as math from "mathjs";
import * as moment from "moment";
import {forEach} from "mathjs";
import { webSocket } from 'rxjs/webSocket';

@Component({
  selector: 'app-general-portfolio',
  templateUrl: './general-portfolio.component.html',
  styleUrls: ['./general-portfolio.component.scss']
})
export class GeneralPortfolioComponent implements OnInit {
  websocket: any = null;
  portfolio = new PortfolioModel({});
  plotData: any = null;
  optimizationData: any = null;
  efficientSeries = [];
  efficientChartLoaded = false;
  service: PortfolioService;
  util: UtilService;
  router: Router;
  loadingService: LoaderService;

  benchmarks: any[] = [];
  portfolios: any[] = [];
  benchmark: any = null;
  selectedBenchmarks: any[] = [];

  selectedBenchmark: any = null;
  chartGlobalStartDate = null;
  chartGlobalEndDate = null;

  subscription: Subscription;
  @Output() optim;
  constructor(
    private injector: Injector
  ) {
    this.router = injector.get(Router);
    this.service = injector.get(PortfolioService)
    this.util = injector.get(UtilService);
    this.loadingService = injector.get(LoaderService);

  }

  ngOnInit(): void {
    this.chartGlobalStartDate = this.portfolio.start_date;
    this.checkPortfolio();
    this.checkBenchmark();
    this.initPage();
    this.subscription = this.service.optim$.subscribe(optim => this.optim = optim);
  }


  checkPortfolio() {
    this.service.portfolio$.subscribe(res => {
      this.portfolio = res;
      if (!this.portfolio.id) {
        // We do not have a selected portfolio, redirect to the overview page
     //    return this.router.navigate(['/app']);
      }
    });
  }

  checkBenchmark() {
    this.service.benchmark$.subscribe((res: any) => {
      if (res && res.id) {
        this.selectedBenchmark = res;
        this.benchmark = res;
      }
    });
  }

  async fetchPerformancePlot(){
           await this.service.fetchPerformancePlot(this.portfolio.id);

  }

  /* Function to compute the return of an index

  The return is todays price divided with yesterdays price and is defined as
    ret_t = (P_t / P_{t-1}) - 1
  at time t, where P_t is the price at time t.

  NOTE: the fx-converstion is included in the price

  */
  calcReturn(priceArray) {
    // The inital value
    const out = [{"date": moment.utc(priceArray[0].date).unix()*1000, "r" : 0}]

    // Iterate over all dates to calculate the return
    for (let i = 1; i < priceArray.length; i++){
      const ret = (priceArray[i].price / priceArray[i-1].price) -1;
      // Check that it is a number
      if (!isNaN(ret)){
        out.push({"date": moment.utc(priceArray[i].date).unix()*1000, "r": ret});
      }
    }
    return out;
  }

  /* Function to compute the geometric cumulative return of the index

  The cumulative (geometric) return is defined as
    cumprod(1 + R) - 1
  where R is the ordered return series
  */
  cumulativeReturn(returnArray){

    // The geometric cumulative return
    const out = [{"date": moment.utc(returnArray[0].date).unix()*1000, "value" : 100}]

    let x = 1;
    // Iterate over all dates to calculate the Geometric return
    for (let i = 1; i < returnArray.length; i++){
      x = x * (1 + returnArray[i].r)
      out.push({"date": moment.utc(returnArray[i].date).unix()*1000, "value": +(x*100).toFixed(2) });
    }
    return out;
  }

  /* Function to compute the rolling annulized rolling volatility

  The volatility is defined as
    std(R)
  where R is the return series in the rolling interval

  NOTE: the result is multipled with sqrt(12) to be annualized for monthly data

  */
  rollingVolatility(returnArray, size = 2){

    // The rolling volatiliy
    const out = []
    const scale = 12;
    const singelValueArray = returnArray.map(i => {return i.r;});
    // Iterate over all dates to calculate the rolling volatility
    for (let i = size; i < returnArray.length; i++){
      // Compute the std over the interval | note the slice index needs incr with 1
      let std = math.std(singelValueArray.slice(i-size, i+1));
      out.push([returnArray[i].date, +((std * +math.sqrt(scale))*100).toFixed(2)]);
    }
    return out;
  }

  /* Calculate the Yearly Performance */
  annualPerformance(returnArray){

    // The current year
    const currenty_year = new Date().getFullYear();
    const out = []
    const scale = 12;

    // Add the years to the elements
    const returnYears = returnArray.map(d => {
      return {"year": new Date(d.date).getFullYear(), "date" : d.date, "r" : d.r};
    });

    // Create a grouping by year
    const dataByYear = returnYears.reduce((acc, value) => {
      // Group initialization
      if (!acc[value.year]) {
        acc[value.year] = [];
      }
      // Grouping
      acc[value.year].push(value);
      return acc;
    }, {});

    // Find the unique keys to iterate over
    const uniqueYears = Object.keys(dataByYear);

    // Iterate over the years
    for (let year = 0; year < uniqueYears.length; year++){

      // Get the values for the year
      const thisYear = uniqueYears[year];
      const data = dataByYear[thisYear];
      let ret = 0 ;
      let vol = 0;
      let sharpe = 0;
      let x = 1;

      // Get a 1D-array for the returns
      const singelValueArray = data.map(i => {return i.r;});
      let count = 0;
      // Compute the cumulative return for the year
      for (let i = 0; i < singelValueArray.length; i++){
        x = x * (1 + singelValueArray[i])
        count += 1;
      }
      // Compute the Volatility for the year
      let volatility = math.std(singelValueArray)

      // In case it is the current year, values should not be annualized
      if (year == currenty_year){
        vol = volatility;
        ret = x - 1;
        sharpe = ret / vol;
        /* Add the data to the output incase there is more than 1 observation */
        if(count > 1){
          out.push({"year" : thisYear, "ret" : ret, "vol" : vol, "sharpe" : sharpe})
        }

      }else{
        // Else the values should be annualizd
        vol = volatility * +math.sqrt(scale);
        ret = +math.pow(x, scale /count) - 1;
        sharpe = ret / vol;
        /* Add the data to the output
          if there is enough data. Must be more than 6 months
        */
        if(count >= 6){
          out.push({"year" : thisYear, "ret" : ret, "vol" : vol, "sharpe" : sharpe})
        }
      }
    }
    return out;
  }

  calculateBenchmarkDrawdown(benchData, portfolioData) {
    const portfolioStart = portfolioData[0][0];
    let benchReturns = [];

    const filteredBenchData = benchData.filter(d => d[0] >= portfolioStart);
    const initalPortfolioValue = this.util.getClosestDate(filteredBenchData, portfolioData) // Get the value at the cloest date
    if (initalPortfolioValue) {
      const benchValue = filteredBenchData[0][1];
      benchReturns = filteredBenchData.map(d => {
        d[1] = +((d[1] / benchValue) * initalPortfolioValue).toFixed(2);
        return d;
      });
    }

    return this.calculateDrawdown({benchmark: benchReturns});
  }

  calculateBenchmark(benchmarkResp) {
    if (!benchmarkResp.benchmarkFx) {
      return false;
    }
    const portfolioReturns = this.plotData.cumulative_returns ? this.plotData.cumulative_returns['Portfolio'] : null;
    const portfolioStart = portfolioReturns[0][0];
    
    const indexReturns = this.calcReturn(benchmarkResp.benchmarkFx.filter(d => moment.utc(d.date).unix()*1000 >= portfolioStart));
    const annualized_return = [];
    const annualized_sharpe = [];
    const annualized_std_deviation = [];

    const performance = this.annualPerformance(indexReturns);

    performance.map(p => {
      const date = moment.utc(`${p.year}-01-01`).unix()*1000;

      annualized_return.push({date, value: p.ret});
      annualized_sharpe.push({date, value: p.sharpe});
      annualized_std_deviation.push({date, value: p.vol});
    });

    const cumulative_returns = this.cumulativeReturn(indexReturns);

    let drawdown = [];
    const benchReturns = cumulative_returns.map(r => [r.date, r.value]);
    if (portfolioReturns) {
      drawdown = this.calculateBenchmarkDrawdown(benchReturns, portfolioReturns);
    }

    this.benchmark = {
      ...benchmarkResp,
      monthly_returns: indexReturns,
      cumulative_returns,
      rolling_std_deviation: this.rollingVolatility(indexReturns),
      drawdown,
      annualized_return,
      annualized_sharpe,
      annualized_std_deviation
    };
  }
  getFundName(ident, full = true) {

    
    let fund = this.portfolio.funds.filter((d) => d.FundShareClassId === ident)
    if(fund.length > 0){ 
      if(full)
        return fund[0].name;
      return fund[0].name.substr(0, 15) + "..";
    }else{
      return ident;
    }
  }
  calculateBenchmarkFromPortfolio(benchmarkResp) {
    const drawdown = this.calculateDrawdown({portfolio: benchmarkResp.cumulative_returns.map(d => [d.date, +(d.value*100).toFixed(2)])});
    this.benchmark = {
      ...benchmarkResp,
      rolling_std_deviation: benchmarkResp.rolling_std_deviation.map(d => [d.date, +(d.value*100).toFixed(2)]),
      drawdown,
      cumulative_returns: benchmarkResp.cumulative_returns.map(d => {
        return {date: d.date, value: +(d.value*100).toFixed(2)};
      })
    };
  }

  calculateDrawdown(cumulative_returns, chartStart?, chartEnd?) {
    let start = chartStart || null;
    let end = chartEnd || null;

    start = this.chartGlobalStartDate ? moment(this.chartGlobalStartDate).unix() * 1000 : start;
    end = this.chartGlobalEndDate ? moment(this.chartGlobalEndDate).unix() * 1000 : end;

    const returnsList: any = this.util.extractPlotData(cumulative_returns, start);
    const returns = returnsList[0].data;

    let filteredReturns = [...returns];
    if (start) {
      filteredReturns = filteredReturns.filter(i => i[0] >= start);
    }
    if (end) {
      filteredReturns = filteredReturns.filter(i => i[0] <= end);
    }
    return this.util.calculateDrawdown(filteredReturns);
  }

   onBenchmarkSelect(benchmark?) {
     this.service.getBenchmark(this.selectedBenchmark.id, this.portfolio.id).subscribe((resp: any) => {
      const benchmarkResp = {
        ...resp,
        ...this.selectedBenchmark
      };

      if(typeof this.selectedBenchmark.id == 'number') {
        // portfolio selected, simple number id
        this.calculateBenchmarkFromPortfolio(benchmarkResp);
      } else {
        this.calculateBenchmark(benchmarkResp);
      }

      console.log("Calculated benchmark: ", this.benchmark);

      this.service.selectBenchmark(this.benchmark);
      this.updatePageWithBenchmark();
      this.openWebsocket();
    })
  }

  
  fetchOptimizationData() {
    this.service.fetchOptimizationData(this.portfolio.id).subscribe((resp: any) => {
      this.optimizationData = resp;

      // Set default values
      if (!this.optimizationData.optim_parameters.assumption_return_type) {
        this.optimizationData.optim_parameters.assumption_return_type = 'forecasted';
      }
      if (!this.optimizationData.optim_parameters.correlation_frequency) {
        this.optimizationData.optim_parameters.correlation_frequency = 'daily';
      }
      if (!this.optimizationData.optim_parameters.correlation_method) {
        this.optimizationData.optim_parameters.correlation_method = 'forecasted';
      }
      if (!this.optimizationData.optim_parameters.assumption_horizon) {
        this.optimizationData.optim_parameters.assumption_horizon = 12;
      }

      if (!this.optimizationData.optim_parameters.return_start_date) {
        this.optimizationData.optim_parameters.return_start_date = this.portfolio.start_date
      }

      this.afterOptimizationDateReceived();
    })
  }

  convertToPercentages(resp) {
    const noPercentage = ['annualized_sharpe'];
    if (!this.service.performancePlotPercentagesConverted) {
      Object.entries(resp).map(([name, chartData]:[string,any]) => {
        if (!noPercentage.includes(name)) {
          Object.entries(chartData).map(([n, data]:[string,any]) => {
            data.map(d => {
              d[1] = +(d[1]*100).toFixed(2)
              return d
            })
          })
        }
      })
      this.service.performancePlotPercentagesConverted = true;
      return resp;
    } else {
      return resp;
    }
  }

  extractHeatMapData(rawData) {
    let correlation :any = {};
    if (rawData) {
      Object.entries(rawData).map(([key,data]:[string,any]) => {
        if (!correlation[data.Var1]) {
          correlation[data.Var1] = [];
        }
        correlation[data.Var1][data.Var2] = +(data.value).toFixed(4)
      })
    }
    return correlation;
  }


    resetScatterData(optim = 'efficient_portfolio_max_sharpe_ratio') {
    const optimisedData = [];
    if (this.portfolio.optim_performance) {
      this.portfolio.optim_performance.map((d:any) => {
        if (d.view_name === 'forecasted_returns') {



          const optimName = this.service.convertEvaluationType(d.Portfolio.split('_').join(' '));

          if(d.Portfolio !== optim) return false;
          // if (d.Portfolio === 'efficient_portfolio_given_mu' || d.Portfolio === 'efficient_portfolio_given_sigma') {
          //   return false;
          // }
          // if (optimName === 'Minimum Variance Portfolio' || optimName === 'Efficient Portfolio Max Return') {
          //   return false;
          // }
          const optimData: any = {
            name: this.getFundName(optimName),
            x: +(d.Std_Deviation*100).toFixed(2),
            y: +(d.Expected_Return*100).toFixed(2)
          };

          if (optimName == 'My Portfolio') {
            optimData.color = "#89CFF0";
          }

          optimisedData.push(optimData);
        }
      })
      this.renameEvaluationTypesInPerformance();
    }

    const optimisedSeries = {
      type: 'scatter',
      name: 'Optim',
      marker: {
        radius: 4,
        symbol: 'circle'
      },
      data: optimisedData,
      dataLabels: {align: 'right', y: +5},
      color: '#0c0c68',

      enableMouseTracking: true,
      zIndex: 20
    }
    this.efficientSeries.push(optimisedSeries)

    setTimeout(() => {
      this.efficientChartLoaded = true
    }, 10);
  }

  extractHeatMapDataWithIndexes(rawData, dataLength) {
    let categories = [];
    const correlation: any[] = [];
    let x = 0;
    let y = 0;

    if (rawData) {
      rawData.map((data: any) => {
        if (x >= dataLength) {
          x = 0;
          y++;
        }
        categories.push(data.Var1);
        correlation.push([x, y, +(data.value).toFixed(2)]);
        x++;
      });
      categories = [...new Set(categories)];
    }
    return {categories, data: correlation};
  }

  setHeatmapColor(value, offset = 0, max) {
    const color = '#424CCD';
    const percent = (Math.abs(value + offset) / Math.abs(max)) * 100;
    return this.util.shadeColor(color, 100 - percent);
  }

  extractHeatMapTableData(rawData, dataLength) {
    let min = 999999999;
    let max = 0;
    let offset = 0;
    for (const data of rawData) {
      if (min > data.value) {
        min = data.value;
      }
      if (max < data.value) {
        max = data.value;
      }
    }
    // move max if min is negative
    if (min < 0) {
      max += Math.abs(min);
    }

    // add 10% as offset
    offset = max * 0.5;

    const matrixColSort = (a, b) => (a.Var1 > b.Var1) ? 1 : ((b.Var1 > a.Var1) ? -1 : 0);
    const result = [];
    let iter = [];
    let count = 0;
    for (const data of rawData) {
      if (count >= dataLength) {
        count = 0;
        iter = iter.sort(matrixColSort);
        result.push(iter);
        iter = [];
      }
      data.value = +(data.value).toFixed(2);
      data.color = this.setHeatmapColor(data.value, offset, max);
      iter.push(data);
      count++;
    }
    if (iter.length) {
      iter = iter.sort(matrixColSort);
      result.push(iter);
    }

    const matrixRowSort = (a, b) => (a[0].Var2 > b[0].Var2) ? 1 : ((b[0].Var2 > a[0].Var2) ? -1 : 0);
    result.sort(matrixRowSort);

    return result;
  }

  openWebsocket() {
    if (!this.websocket) {
      // this.websocket = this.service.openPortfolioWebsocket(this.portfolio.id)
      const subject = webSocket('wss://wss.cimalti.com/websocket/portfolio/' + this.portfolio.id);

      subject.subscribe({
        next: msg => {
          console.log('message received: '+  msg)
          if (msg) {
           this.onWebsocketSuccess(msg)
          } else {
            this.service.notificationService.open("There was an error during calculation.")
          }
          this.service.closeWebsocket();
        },
        error: err => {
          this.websocket = null;
          
          console.log('error: ' ,  err)
          setTimeout(() => {window.location.reload()}, 90000);
          

        },
        complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
      })
    }
  }

  renameEvaluationTypesInPerformance(){
    this.portfolio.optim_performance = this.portfolio.optim_performance.map(p => {
      p.name = this.service.convertEvaluationType(p.Portfolio.split('_').join(' '))
      return p
    })
  }

  extractEfficientFrontierScatterData(optimOnly = false) {
    const scatterData = [];
    const type = 'forecasted_returns';
    const volatilityList = {};
    this.optimizationData.historical_returns.map((d: any) => {
      volatilityList[d.Ticker] = +(d.volatility*100).toFixed(2);
    })

    const isOptimData = (ticker) => {
      return ticker === "Portfolio";
    };

    const createScatterData = (list, returnProp, skip = []) => {
      list.map((d: any) => {
        let color = '#424CCD';
        let ticker = this.getFundName(d.Ticker);


        let company = this.portfolio.security_overviews[ticker] || null;
        let companyName = company ? company.company_name : this.getFundName(ticker);
        let yValue = d[returnProp]

       // if (returnProp != 'forecasted_returns' || ticker == "Portfolio") {
          yValue = yValue * 100
       // }

        if (ticker == "Portfolio") {
          ticker = "Baseline Portfolio";
          companyName = "Baseline Portfolio";
          color = '#40c6ba';
        }


        if (optimOnly) {

            scatterData.push(
              {
                name: companyName,
                company: companyName,
                color: color,
                x: volatilityList[d.Ticker],
                y: +(yValue).toFixed(2),
                dataLabels:{align: "left"}
              }
            );  
        }



        
      });
    };

    createScatterData(this.optimizationData.forecasted_returns, 'forecasted_returns');
    return scatterData;
  }

  initPage() {}
  afterPlotDataReceived() {}
  afterOptimizationDateReceived() {}
  updatePageWithBenchmark() {}
  onWebsocketSuccess(msg) {}
}
