<div *ngIf="loaderService.isLoading | async" class="spinner-container">
    <mat-progress-bar mode="indeterminate" color="primary" value="50" buffer="70"></mat-progress-bar>
    <!--<mat-spinner [diameter]="100"></mat-spinner>-->
</div>

<div *ngIf="loaderService.isLoading | async" class="spinner-container">
<!--     <mat-progress-bar mode="indeterminate" color="primary" value="50" buffer="70"></mat-progress-bar>
 -->    <!--<mat-spinner [diameter]="100"></mat-spinner>-->
</div>

