import {Component, Injector, OnInit} from '@angular/core';
import {PortfolioCreateComponent} from "../../portfolio/portfolio-create/portfolio-create.component";
import {WatchlistService} from "../watchlist.service";
import {PortfolioModel} from "../../../shared/models/portfolio.model";

@Component({
  selector: 'app-watchlist-create',
  templateUrl: './watchlist-create.component.html',
  styleUrls: ['./watchlist-create.component.scss']
})
export class WatchlistCreateComponent extends PortfolioCreateComponent {

  watchlistService: WatchlistService;
  constructor(injector: Injector) {
    super(injector);
    this.watchlistService = injector.get(WatchlistService);
  }

  initPage() {
    this.portfolio.description = '';

    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getWatchlist(id);
    }
  }

  getWatchlist(id) {
    this.watchlistService.getItem(id).subscribe((resp: any) => {
      if (resp.item) {
        this.portfolio = new PortfolioModel(resp.item);
        this.portfolio.securities = resp.item.items;
        this.selected = this.portfolio.securities;
      }
    })
  }

  onSaveWatchlistClick(portfolio) {
    if (!this.selected.length) {
      return this.watchlistService.notificationService.open('Please select some asset');
    }

    let wathclist: any = {
      name: portfolio.name,
      description: portfolio.description,
      items: this.selected.map(s => { return s.id })
    }
    if (this.portfolio.id) {
      wathclist.id = this.portfolio.id;
    }

    this.watchlistService.saveWatchlist(wathclist).subscribe(resp => {
      if (resp.id) {
        this.service.notificationService.open("Watchlist saved");
        this.router.navigate(['/app/watchlist'])
      }
    })
  }

  fetchCompaniesIn(filter?, page?, isPageEvent?) {
    let extraFilters: any = {
      page: page ? page : this.currentPage
    }
    if (filter) {
      if (filter.searchTerm) {
        extraFilters.search_term = filter.searchTerm;
      }
      if (filter.page) {
        extraFilters.page = filter.page;
      }
      // type is the etf/stock type
      if (filter.type) {
        extraFilters.type = filter.type;
        delete filter.type;
      }
      if (filter.limit) {
        extraFilters.limit = filter.limit;
        delete filter.limit;
      }
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp, isPageEvent);
    })
  }

  getFiltersIn(filter?) {
    let extraFilters: any = {}
    if (filter && filter.limit) {
      extraFilters.limit = filter.limit;
      delete filter.limit;
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp);
    })
  }
}
