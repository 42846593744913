<div class="container-fluid h-100 login-form-wrapper">

  <div class="card login-card">
    <div *ngIf="!paramToken" class="d-flex flex-column card-body p-4 align-items-center justify-content-center">
      <form name="form" (ngSubmit)="f.form.valid && onForgotPwdSubmit()" #f="ngForm" class="form-content">
        <h1>Forgot your password?</h1>
        <p>Don't worry. Resetting your password is easy, just tell us the email address you registered with.</p>
        <mat-form-field appearance="outline" class="w-100  text-center">
          <input matInput placeholder="Email" name="email" [(ngModel)]="model.email" #email="ngModel" required email>
          <mat-error *ngIf="f.submitted && email.invalid">
            <span *ngIf="email.errors.required">Email is required</span>
            <span *ngIf="email.errors.email">Email must be a valid email address</span>
          </mat-error>
        </mat-form-field>
        <div class="text-center">
          <button mat-flat-button color="primary" class="btn-wide"><span class="navigation-button">Send</span> </button>
        </div>
      </form>
    </div>

    <div *ngIf="paramToken" class="d-flex flex-column card-body p-4 align-items-center justify-content-center">
      <form *ngIf="!qrCodeSrc" name="form" (ngSubmit)="pr.form.valid && onPasswordResetSubmit()" #pr="ngForm" class="form-content">
      <h1>Reset password</h1>
      <p>You have requested to reset your password.</p>
        <div class="form-group">

          <mat-form-field appearance="outline" class="w-100  text-center">
            <input matInput type="password"
                   [(ngModel)]="pwdResetModel.password"
                   name="password"
                   placeholder="Password"
                   required
                   #password="ngModel"
                   pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                   [ngClass]="{ 'is-invalid': pr.submitted && password.invalid }"
            />
            <mat-error *ngIf="password.invalid && (pr.submitted || password.touched)">
              <span *ngIf="password.errors.required">Password is required</span>
              <span *ngIf="password.errors.pattern">Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</span>
            </mat-error>
          </mat-form-field>


          <mat-form-field appearance="outline" class="w-100  text-center">
            <input matInput type="password"
                   [(ngModel)]="pwdResetModel.password_confirm"
                   name="confirmpassword"
                   placeholder="Confirm Password"
                   required
                   #confirmpassword="ngModel"
                   pattern="{{password.value}}"
                   [ngClass]="{ 'is-invalid': pr.submitted && confirmpassword.invalid }"
            />
            <mat-error *ngIf="confirmpassword.invalid && (pr.submitted || confirmpassword.touched)">
              <span *ngIf="confirmpassword.errors.required">Confirm password is required</span>
              <span *ngIf="confirmpassword.errors.pattern">Password & Confirm Password does not match</span>
            </mat-error>
          </mat-form-field>
        </div>
        <button mat-flat-button color="primary" class="btn-wide">Save</button>
      </form>
      <div *ngIf="qrCodeSrc">
        <img src="{{qrCodeSrc}}" alt="">
      </div>
    </div>

    <a routerLink="/" mat-button color="primary">
      <mat-icon aria-hidden="false" aria-label="Example home icon">keyboard_arrow_left</mat-icon> <span>Back to login</span>
    </a>

  </div>
</div>
