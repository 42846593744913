import {Component, Injector, OnInit} from '@angular/core';
import {WatchlistCreateComponent} from "../watchlist-create/watchlist-create.component";
import {PortfolioModel} from "../../../shared/models/portfolio.model";

@Component({
  selector: 'app-watchlist-view',
  templateUrl: './watchlist-view.component.html',
  styleUrls: ['./watchlist-view.component.scss']
})
export class WatchlistViewComponent extends WatchlistCreateComponent{

  constructor(injector: Injector) {
    super(injector);
  }

  getWatchlist(id) {
    this.watchlistService.getItem(id).subscribe((resp: any) => {
      console.log("get watchlist", resp);
      if (resp.item) {
        this.portfolio = new PortfolioModel(resp.item);
        this.portfolio.securities = resp.item.items;
        this.selected = this.portfolio.securities;
        this.list = resp.item.items;
      }
    })
  }

  fetchCompaniesIn(filter?, page?, isPageEvent?) {
    let extraFilters: any = {
      page: page ? page : this.currentPage
    }
    if (filter) {
      if (filter.searchTerm) {
        extraFilters.search_term = filter.searchTerm;
      }
      if (filter.page) {
        extraFilters.page = filter.page;
      }
      // type is the etf/stock type
      if (filter.type) {
        extraFilters.type = filter.type;
        delete filter.type;
      }
      if (filter.limit) {
        extraFilters.limit = filter.limit;
        delete filter.limit;
      }
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp, isPageEvent);
    })
  }

  getFiltersIn(filter?) {
    let extraFilters: any = {}
    if (filter && filter.limit) {
      extraFilters.limit = filter.limit;
      delete filter.limit;
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp);
    })
  }

}
