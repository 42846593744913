<!-- <div class="mt-2" style="min-height: 24px; display: flex;">
    <mat-slide-toggle color="primary" (change)="onToggle()" class="ml-2 mt-2" style="flex: 1">
        Checked only
    </mat-slide-toggle>
    <button mat-icon-button style="flex: 1"><mat-icon class="color-mid-grey">format_clear</mat-icon> Clear filters</button>
    <div style="flex: 1; display: flex">
        <button class="mr-2" *ngIf="selected.length" mat-button color="primary" style="flex: 1"
            (click)="selectAllClick.emit(false)">Deselect all
        </button>
        <button class="mr-2" mat-button color="primary" (click)="selectAllClick.emit()" style="flex: 1">Select all</button>
    </div>
</div> -->
<p class="mt-2" style="min-height: 24px">
    <mat-slide-toggle color="primary" [checked]="checked" (change)="onToggle()" class="ml-2 mt-2 float-left">
        Checked only
    </mat-slide-toggle>
   
     <button class="float-right ml-2   mat-icon" id="toright-button"mat-button color="primary"  (mousedown)="moveRight()" (mouseup)="clearInterval()">
          <mat-icon inline=true fontSet="fa" fontIcon="fa-chevron-right" aria-label="To the right" style="height: 15px;"></mat-icon>
    </button>
    <button class="float-right ml-2 mat-icon" id="toleft-button"mat-button color="primary" (mousedown)="moveLeft()" (mouseup)="clearInterval()" >
        <mat-icon inline=true fontSet="fa" fontIcon="fa-chevron-left" aria-label="To the left" style="height: 15px;"></mat-icon>
    </button>

     <button class="mr-1 float-right" *ngIf="selected.length" mat-button color="primary"
        (click)="selectAllClick.emit(false)">Deselect all
    </button>
    <button class="mr-1 float-right" mat-button color="primary" (click)="selectAllClick.emit()">Select all</button>


    
</p>    




<div class="mb-2">
    <div class="selected-filter" *ngFor="let s of selectedFilterLabels|keyvalue" [class]="s.key.replaceAll(' ', '_').toLowerCase()">
        <span (click)="openFilter(s)" class="selected-label">{{sortingNiceName(s.key)}}</span>
        <!-- <span *ngFor="let l of s.value;let last = last;"
              [matTooltip]="l.name.length > filter_label_length_limit ? l.name : ''"><span
                class="text-truncate">:{{(l.name.length > filter_label_length_limit) ? ((l.name | slice:0:filter_label_length_limit) + '...') : l.name}}</span> <span
                *ngIf="!last">| </span></span> -->
        <mat-icon (click)="deleteFilterClick.emit(s)">close</mat-icon>
    </div>
    <div class="selected-filter" *ngFor="let d of currentFilters.dynamic|keyvalue">
        <span class="selected-label">{{sortingNiceName(d.key)}}</span>
        <span>: {{d.value}}</span>
        <mat-icon (click)="deleteDynamicFilterClick.emit(d.key)">close</mat-icon>
    </div>
    <!-- <div class="selected-filter" *ngFor="let s of sorting|keyvalue">
        <span class="selected-label">{{sortingNiceName(s.key)}}:</span> {{s.value}}
        <mat-icon (click)="deleteSortClick.emit(s)">close</mat-icon>
    </div> -->
</div>
