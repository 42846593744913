import {Component, Injector, OnInit} from '@angular/core';
import {GeneralPortfolioComponent} from "../../general-portfolio/general-portfolio.component";
import * as moment from "moment";

@Component({
  selector: 'app-portfolio-stress',
  templateUrl: './portfolio-stress.component.html',
  styleUrls: ['./portfolio-stress.component.scss']
})
export class PortfolioStressComponent extends GeneralPortfolioComponent {
  stressLoaded = false;
  selectedStressEvent:any = {
    start: null,
    end: null,
    highlightStart: null,
    highlightEnd: null,
  };
  selectedEvents: any[] = [];
  selectedEvent: any = null;
  searchText = null;

  riskChartSeries = [];
  drawdownChartSeries = [];

  evaluationData: any = {};
  evaluationSelectList: any[] = [];
  evaluationTableData: any = null;
  selectedEvaluationNiceName = null;
  selectedEvaluation: string = 'minimum_variance_portfolio';

  constructor(injector: Injector) {
    super(injector);
  }

  initPage() {
    this.checkSelectedBenchmark();

    const cache = this.service.storage.get(this.service.cacheFactorKey);
    if (cache) {
      if (cache.portfolio === this.portfolio.id) {
        this.selectedEvaluation = cache.evaluation;
      }
    }
    this.fetchEvaluationData();
    
  }
  async fetchEvaluationData(){
    this.evaluationData = await this.service.fetchEvaluationData(this.portfolio.id);
    this.initEvaluationData();
  }
  initEvaluationData() {
    this.selectedEvaluationNiceName = this.service.convertEvaluationType(this.selectedEvaluation.split('_').join(' '));
    if (this.portfolio && !this.evaluationSelectList.length) {
      // Filter out benchmarks
      this.evaluationSelectList = this.evaluationData.evaluation_optim_performance.filter((data: any) => {
        if (data.id.length > 6) {
          return data;
        }
      });
      this.evaluationSelectList = this.service.fixEvaluationTypeNames(this.evaluationSelectList);
    }
    if (!this.evaluationTableData) {
      this.fetchEvaluationTableData();
    }
  }

  fetchEvaluationTableData() {
    this.selectedEvaluationNiceName = this.service.convertEvaluationType(this.selectedEvaluation.split('_').join(' '));
    this.evaluationTableData = null;
    this.service.getEvaluationData(this.portfolio.id, this.selectedEvaluation).subscribe((resp: any) => {
      const cumReturns = [];
      resp.map((data: any) => {
        const value = +(data.value*100).toFixed(2);
        const date = moment(data.date).unix()*1000;
        if (data.variable == 'cumulative_returns') {
          cumReturns.push([date, value])
        }
      })
      this.plotData = {
        cumulative_returns: {
          Portfolio: cumReturns
        }
      };
    });
  }

  onEvaluationTypeChange() {
    this.fetchEvaluationTableData();
  }

  checkSelectedBenchmark() {
    if (this.benchmark) {
      this.selectedBenchmark = this.benchmark;
      this.updatePageWithBenchmark();
    }
  }
  onEventSelect(event) {
      if (this.selectedEvents.includes(event)) {
        this.selectedEvents = this.selectedEvents.filter(p => p !== event);
      } else {
        this.selectedEvents.push(event);
      }
  }

  onStressTestClick() {
    this.stressLoaded = false;
    this.buildStressCharts();

    // select first event and render chart
    if (!this.selectedEvent) {
      this.selectedEvent = this.selectedEvents[0];
    }
    this.onEventCummulativeEventSelect(this.selectedEvent);
  }

  isEventSelected(event) {
    return this.selectedEvents.filter(e => e == event).length > 0;
  }

  buildStressCharts() {
    this.stressLoaded = false;
    this.riskChartSeries = [];
    this.drawdownChartSeries = [];

    let securityTickers = this.portfolio.securities.map(s => s.fs_ticker);
    securityTickers.push('Portfolio');

    const selectedEvent = this.selectedEvent ? this.selectedEvent : this.selectedEvents[0];
    let portfolioDrawDown: any = null;

    // Build scatter chart series (Risk)
    this.portfolio.stress_test_performance.map((performance: any) => {
      if (performance.event == selectedEvent) {
        if (securityTickers.includes(performance.name) || (this.benchmark && this.benchmark.name == performance.name)) {
          let company = this.portfolio.security_overviews[performance.name] || null;
          let companyName = company ? company.company_name : performance.name;

          let drawDownData: any = {
            type: 'column',
            name: companyName,
            data: [{
              name: companyName,
              y: +(performance.max_drawdown*100).toFixed(2)
            }]
          };

          let radius = 4;
          if (companyName == 'Portfolio') {
            radius = 8;
            portfolioDrawDown = drawDownData;
          } else {
            this.drawdownChartSeries.push(drawDownData)
          }
          this.riskChartSeries.push({
            type: 'scatter',
            name: companyName,
            marker: {
              radius: radius,
              symbol: 'circle'
            },
            data: [{
              name: companyName,
              x: +(performance.std_deviation_annualized*100).toFixed(2),
              y: +(performance.return_annualized*100).toFixed(2)
            }]
          })
        }
      }
    })

    // Sort drawdown data (portfolio 1st, rest is decreasing
    this.drawdownChartSeries.sort((a,b) => (a.data[0].y < b.data[0].y) ? 1 : ((b.data[0].y < a.data[0].y) ? -1 : 0))
    this.drawdownChartSeries = [
      portfolioDrawDown,
      ...this.drawdownChartSeries
    ]
    setTimeout(() => this.stressLoaded = true, 10);
  }

  onEventCummulativeEventSelect(event) {
    if (event) {
      const selectedEvent:any = this.portfolio.stress_test_events.filter(p => p.event === event)[0];
      this.selectedStressEvent = {
        ...selectedEvent,
        start: moment(selectedEvent.start_date).subtract(90, 'days').unix()*1000,
        end: moment(selectedEvent.end_date).add(90, 'days').unix()*1000,
        highlightStart: moment(selectedEvent.start_date).unix()*1000,
        highlightEnd: moment(selectedEvent.end_date).unix()*1000,
      }

      this.buildStressCharts()
    }
  }

  updatePageWithBenchmark() {
    if (this.selectedEvent) {
      this.buildStressCharts();
    }
  }

  onBenchmarkSelect(benchmark) {
    if (benchmark && benchmark.id) {
      this.selectedBenchmark = benchmark;
      super.onBenchmarkSelect();
    }
  }


}
