import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as Highcharts from "highcharts/highstock";
import series_label from "highcharts/modules/series-label";
import  Tree from 'highcharts/modules/treemap';
import  Heatmap from 'highcharts/modules/heatmap';
import Boost from 'highcharts/modules/boost';

@Component({
  selector: 'app-heatmap-chart',
  templateUrl: './heatmap-chart.component.html',
  styleUrls: ['./heatmap-chart.component.scss']
})
export class HeatmapChartComponent implements OnInit, OnChanges {
  @Input() dataRaw: any = {};
  @Input() fullHeight = false;
  @Input() extraOptions = {};
  @Input() hideTitle = false;
  @Input() isPrint = false;
  @Input() allowedTickers: any[] = [];

  loaded = false;
  matrixItemLimit = 15;

  chartData: any[] = [];
  series: any[] = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'chart';
  chartOptions: Highcharts.Options = {};

  constructor() { 
    if (typeof Highcharts === 'object') {
      Tree(Highcharts);
      Heatmap(Highcharts);
      Boost(Highcharts);
      series_label(Highcharts);
    }
  }

  ngOnInit(): void {
      this.initData() 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataRaw) {
      this.loaded = false;
      this.dataRaw = changes.dataRaw.currentValue;
      this.initData();
    }
    if (changes.allowedTickers) {
      this.loaded = false;
      this.initData();
    }
  }

  initData() {
    if (this.allowedTickers && this.allowedTickers.length) {
      // Get allowed indexes from tickers (indexes saved from categories order)
      const allowedIndexes = [];
      const chartData = [];

      let index = 0;
      this.allowedTickers.map((ticker: string) => {
        index = 0;
        this.dataRaw.categories.map(cat => {
          if (ticker === cat) {
            allowedIndexes.push(index);
          }
          index++;
        });
      });

      // Filter out allowed indexed securities from chart data
      this.dataRaw.data.map((data: any) => {
        if (allowedIndexes.includes(data[0]) && allowedIndexes.includes(data[1])) {
          chartData.push(data);
        }
      });

      // Reset index from 0
      const dataLength = this.allowedTickers.length;
      let x = 0;
      let y = 0;
      const fixedIndexList = [];
      chartData.map(d => {
        if (x >= dataLength) {
          x = 0;
          y++;
        }
        fixedIndexList.push([x, y, d[2]]);
        x++;
      });
      this.chartData = fixedIndexList;
    } else {
      this.chartData = this.dataRaw.data;
    }
    this.initChart();
  }

  initChart() {
    const getPointCategoryName = (point, dimension) => {
      var series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];
      return axis.categories[point[isY ? 'y' : 'x']];
    }

    // if (!this.chartData) {
    //   console.log("Missing heatmap data", this.dataRaw)
    //   return;
    // }
    this.loaded = false;

    this.series = [{
      type: "treemap",
      data: this.dataRaw,
      borderWidth: 1,
      borderColor: 'white',
      layoutAlgorithm: 'squarified',
      turboThreshold: 0,
      boostThreshold: 1000,
    }];

    this.chartOptions = {
      chart: {
        height: this.isPrint ? '500px' : '630px',
        plotBorderWidth: 1,
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        heatmap: {
          turboThreshold: 0,
          dataLabels: {
            crop: true,
            enabled: true,
            color: '#ffffff',
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              textShadow: false,
              textOutline: 'none'
            }
          }
        },
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      xAxis: {
        startOnTick: false,
        endOnTick: false,
        opposite: true,
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        title: null,
        reversed: true,
      },

      colorAxis: {
        minColor: '#004f99',
        maxColor: '#f3f3f3'
      },
      boost: {
        useGPUTranslations: true
      },
      // tooltip: {
      //   formatter: function () {
      //     return 'x: ' + getPointCategoryName(this.point, 'x') + '<br/>y: ' +
      //       getPointCategoryName(this.point, 'y') + '<br/>z: ' + this.point.value;
      //   }
      //},
      series: this.series
    };

    
    if (this.fullHeight) {
      this.chartOptions.chart.height = '96%';
    }
    if (this.extraOptions) {
      this.chartOptions = {
        ...this.chartOptions,
        ...this.extraOptions
      };
    }
    setTimeout(() => this.loaded = true, 10);
  }

}
