<div class="outer-table-container">
    <div class="body flex">
      <div class='company-names'>
          <div class="header flex-2">
            <div class="text-left pointer">
              <span>Name</span>
            </div>
            <div>
              <button mat-icon-button *ngIf="!hideSorting" (click)="onSortClick('company_name');$event.preventDefault()" class="sorting-button" [class.sort-visible]="!!sorting.company_name"><mat-icon>{{!sorting.company_name ? 'swap_vert' : (sorting.company_name == 'asc' ? 'north' : 'south') }}</mat-icon></button>
            </div>
          </div>
        <div *ngFor="let item of list; index as i;" class="asset-row" [class.grey] = "i%2===0" [class.white] = "i%2===1">
        <div class="width-50">
        </div>
        <div class="asset-col flex-2">
            <div class="d-flex">
                <div class="width-50">
                  <mat-checkbox *ngIf="!hideSelect" color="primary" [checked]="item.selected" (change)="onSelect($event, item)"></mat-checkbox>
                </div>
                <div class="company-name">
                <!-- <span class="underline-on-hover" (click)="onCompanyInfoClick(item)">{{item.company_name}}</span> -->
                  <span class="underline-on-hover" (click)="onCompanyInfoClick(item)">{{item.company_name && item.company_name.length > 26 ? (item.company_name.slice(0, 26) + '...') : item.company_name}} </span>
                </div>
              
              <div class="company-name">
                <app-info-popover *ngIf="item.overview && item.overview.description" [company]="item" [highlight]="currentFilters.searchTerm" [title]="'Description - ' + item.company_name" [content]="item.overview.description" (click)="closeOtherCompanies(item)"></app-info-popover>
                <app-info-popover *ngIf="item.etf_reference_table && item.etf_reference_table.descr" [company]="item" [highlight]="currentFilters.searchTerm" [title]="'Description - ' + item.company_name" [content]="item.etf_reference_table.descr" (click)="closeOtherCompanies(item)"></app-info-popover>
              </div>
         
          </div>
        </div>
        </div>
      </div>
    <div class="big-container table-container fake-table" id="asset-tbl">
    <div class="headers" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let f of quantList" class="header" 
      [class.flex-2]="columnsEnum[f.id] && columnsEnum[f.id].double" cdkDrag>
        <div *ngIf="columnsEnum[f.id]">
          <div *ngIf="!columnsEnum[f.id].headerFilter && !columnsEnum[f.id].menuFilter && !columnsEnum[f.id].headerSelect">{{columnsEnum[f.id].title}}</div>
          <div *ngIf="columnsEnum[f.id].headerFilter" class="header">
            <app-asset-table-header-filter
              [title]="columnsEnum[f.id].title"
              [class.text-right]="columnsEnum[f.id].textAlign"
              [unit]="columnsEnum[f.id].unit"
              [minModel]="columnsEnum[f.id].model$gt"
              [maxModel]="columnsEnum[f.id].model$lt"
              (minModelChanged)="onNumberFilterChange(columnsEnum[f.id].value, '$gt', $event)"
              (maxModelChanged)="onNumberFilterChange(columnsEnum[f.id].value, '$lt', $event)"
              (enterHit)="onFetchCompanies()"
            ></app-asset-table-header-filter>
            <button mat-icon-button *ngIf="!hideSorting && columnsEnum[f.id].sorting" (click)="onSortClick(columnsEnum[f.id].value);$event.preventDefault()" class="sorting-button align-end" [class.sort-visible]="sorting[columnsEnum[f.id].value]">
              <mat-icon>{{!sorting[columnsEnum[f.id].value] ? 'swap_vert' : (sorting[columnsEnum[f.id].value] == 'asc' ? 'north' : 'south')}}</mat-icon>
            </button>
          </div>
          <div *ngIf="columnsEnum[f.id].menuFilter" class="header">
            <span [matMenuTriggerFor]="menu" class="input-filter-header text-right">{{columnsEnum[f.id].title}}</span>

            <mat-menu #menu="matMenu" [overlapTrigger]="false" class="input-menu">
                <div mat-menu-item [disableRipple]="true">
                    <div class="row">
                        <div class="col divider-right">
                            <app-cim-datepicker (click)="$event.stopPropagation();" label="Start date" [model]="columnsEnum[f.id].model$gt" [isFullWidth]="true" (modelChanged)="onNumberFilterChange(columnsEnum[f.id].value, '$gt', $event, true)"></app-cim-datepicker>
                        </div>
                        <div class="col">
                            <app-cim-datepicker (click)="$event.stopPropagation();" label="End date" [model]="columnsEnum[f.id].model$lt" [isFullWidth]="true" (modelChanged)="onNumberFilterChange(columnsEnum[f.id].value, '$lt', $event, true)"></app-cim-datepicker>
                        </div>
                    </div>
                </div>
            </mat-menu>
            <button mat-icon-button *ngIf="!hideSorting" (click)="onSortClick(columnsEnum[f.id].value);$event.preventDefault()" class="sorting-button align-end" [class.sort-visible]="sorting[columnsEnum[f.id].value]"><mat-icon>{{!sorting[columnsEnum[f.id].value] ? 'swap_vert' : (sorting[columnsEnum[f.id].value] == 'asc' ? 'north' : 'south')}}</mat-icon></button>
          </div>
          <div *ngIf="columnsEnum[f.id].headerSelect" class="header" [class.text-right]="columnsEnum[f.id].textRight">
            <app-table-header-select [isInTableHeader]="true" labelPrefix="" [label]="columnsEnum[f.id].title"  [isMultiple]="true" [isFullWidth]="true" [list]="filterLists[columnsEnum[f.id].list]" [model]="getCurrentFilters[columnsEnum[f.id].model]"
              (modelChanged)="onSelectFilterChange(columnsEnum[f.id].value, $event)" (panelClosed)="onFetchCompanies()" [name]="columnsEnum[f.id].value"></app-table-header-select>
            <button mat-icon-button *ngIf="!hideSorting" (click)="onSortClick(columnsEnum[f.id].value);$event.preventDefault()" class="sorting-button" [class.align-end]="columnsEnum[f.id].textRight" [class.sort-visible]="sorting.region_list_name"><mat-icon>{{!sorting[columnsEnum[f.id].value] ? 'swap_vert' : (sorting[columnsEnum[f.id].value] == 'asc' ? 'north' : 'south')}}</mat-icon></button>
          </div>
        </div>
        <div *ngIf="!columnsEnum[f.id]">
          <span *ngIf="!f.default">
            <div *ngIf="!f.filterType" class="flexed text-right">
              <app-asset-table-header-filter
                      [title]="f.shortName"
                      [unit]="f.unit"
                      [minModel]="currentFilters.dynamic[f.id+'$gt']"
                      [maxModel]="currentFilters.dynamic[f.id+'$lt']"
                      (minModelChanged)="onNumberFilterChange(f.id, '$gt', $event)"
                      (maxModelChanged)="onNumberFilterChange(f.id, '$lt', $event)"
                      (enterHit)="onFetchCompanies()"
              >
              </app-asset-table-header-filter>
              <button mat-icon-button *ngIf="!hideSorting" (click)="onSortClick(f.id);$event.preventDefault()" class="sorting-button align-end" [class.sort-visible]="sorting[f.id]"><mat-icon>{{(!sorting[f.id] ? 'swap_vert' : (sorting[f.id] == 'asc') ? 'north' : 'south')}}</mat-icon></button>
            </div>
            <div *ngIf="f.filterType" class="flexed text-right">
              <!-- Credit rating multiselect filter -->
              <app-table-header-select
                      [isInTableHeader]="true"
                      labelPrefix=""
                      [label]="f.name"
                      [isMultiple]="true"
                      [isFullWidth]="true"
                      [hideMarked]="true"
                      [list]="filterLists[f.filterType]"
                      [model]="currentFilters.dynamic[f.id+'$in']"
                      (modelChanged)="onDynamicSelectFilterChange(f.id, $event)"
                      (panelClosed)="onFetchCompanies()"></app-table-header-select>
              <button mat-icon-button *ngIf="!hideSorting" (click)="onSortClick(f.id);$event.preventDefault()" class="sorting-button" [class.sort-visible]="sorting[f.id]"><mat-icon>{{(!sorting[f.id] ? 'swap_vert' : (sorting[f.id] == 'asc') ? 'north' : 'south')}}</mat-icon></button>
            </div>
          </span>
        </div>
      </div>
    </div>
      <div *ngFor="let item of list; index as i;" class="asset-row" [class.grey] = "i%2===0">

        
          <div *ngFor="let f of quantList" class="asset-col" [class.number-col]="f.decimalInfo || f.class === 'date' || f.class === 'code'" [class.text-right]="isRightAligned(item, f.id)" [class.flex-2]="columnsEnum[f.id] && columnsEnum[f.id].double">
            <div *ngIf="columnsEnum[f.id]">
              <div *ngIf="columnsEnum[f.id].colType === 'number'" >
                {{item[columnsEnum[f.id].colValue]|number:'1.0-0'}}
              </div>
              <div *ngIf="columnsEnum[f.id].colType !== 'number'">
                <div *ngIf="columnsEnum[f.id].colValue" [class.text-left]="!columnsEnum[f.id].textRight">
                  {{item[columnsEnum[f.id].colValue]}}
                </div>
              </div>
              <div *ngIf="columnsEnum[f.id].colDeep">
                {{flatten(item)[columnsEnum[f.id].colDeep]}}
              </div>
              <div *ngIf="!columnsEnum[f.id].colDeep && !columnsEnum[f.id].colType" [class.text-left]="!columnsEnum[f.id].textRight">
                <span>
                  {{item[filterProp] ? (item[filterProp][f.id]|optionalNumber: f.decimalInfo) : ''}}<small *ngIf="item[filterProp][f.id] !== null && f.unit && f.unit == '%'">{{f.unit}}</small>
                </span>
              </div>
            </div>
            <div *ngIf="!columnsEnum[f.id]">
              <span>
                {{item[filterProp] ? (item[filterProp][f.id]|optionalNumber: f.decimalInfo) : ''}}<small *ngIf="item[filterProp][f.id] !== null && f.unit && f.unit == '%'">{{f.unit}}</small>
              </span>
            </div>
          </div>
        </div>
      </div>

<!--       <div class="horizontal-nav">
 -->      
      

    </div>

</div>