<mat-drawer-container class="full-container" autosize>
  <mat-drawer #drawer [id]="isPortfolio ? 'portfolio-page' : 'normal-page'" class="big-sidenav" mode="side" [opened]="isOpened">
    <app-nav-list class="d-none d-lg-flex" [portfolio]="portfolio" [isAdmin]="isAdmin" [isPortfolio]="isPortfolio" ></app-nav-list>
  </mat-drawer>

  <div class="example-sidenav-content">
    <!-- <button type="button" mat-button (click)="drawer.toggle()">
      Toggle sidenav
    </button> -->
    <router-outlet #o="outlet" class="main-router-outlet"></router-outlet>
  </div>

</mat-drawer-container>