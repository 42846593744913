<div class="card-container">

<div class="mat-elevation-z8 mt-4">
    <table mat-table [dataSource]="dataSource" matSort  matSortActive="created_at" matSortDirection="desc" class="port-table" [hidden]="!tableLoaded">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
            </mat-checkbox>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox *ngIf="!readOnly" [checked]="element.selected" color="primary" (change)="onSelect($event, element)"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element">
                <span class="cursor-pointer underline-on-hover ml-2" (click)="onStockClick(element)">{{element.name}}</span>
                <app-info-popover *ngIf="element.description" [company]="element" [title]="'Description - ' + element.name" [content]="element.description" (click)="closeOtherInfo(element)"></app-info-popover>
                <mat-icon color="primary" *ngIf="element.shared_with_users_concat" class="cursor-pointer align-middle" [matTooltip]="'Shared with: ' + element.shared_with_users_concat">group</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="shared_by">
            <th mat-header-cell *matHeaderCellDef class="text-center">Shared by</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.user && element.user.name ? element.user.name : ''}} </td>
        </ng-container>
        <ng-container matColumnDef="last_updated_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">Last Updated</th>
            <td mat-cell *matCellDef="let element" class="text-right"> {{(element.last_updated | utcToLocalTime:'short') || '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">Created</th>
            <td mat-cell *matCellDef="let element" class="text-right"> {{(element.created_at | utcToLocalTime:'short') || '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right">Currency</th>
            <td mat-cell *matCellDef="let element" class="text-right"> {{element.currency ? element.currency.name : ''}} </td>
        </ng-container>

        <ng-container matColumnDef="asset_count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"># Assets</th>
            <td mat-cell *matCellDef="let element" class="text-right"> {{element.security_count}} </td>
        </ng-container>

        <ng-container matColumnDef="action" stickyEnd>
            <th mat-header-cell *matHeaderCellDef ></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="color-mid-grey">more_vert</mat-icon></button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="!element.readonly" mat-menu-item [routerLink]="['portfolio', element.id]">Edit Portfolio</button>
                    <button *ngIf="element.readonly" mat-menu-item [routerLink]="['portfolio-view', element.id]">View</button>
                    <button mat-menu-item (click)="onClickCopy(element)">Duplicate portfolio</button>
                    <button *ngIf="!element.readonly" mat-menu-item (click)="deleteItem(element)">Delete</button>
                    <button *ngIf="element.readonly && element.visibility == sharedVisivility" mat-menu-item (click)="unshareItem(element)">Delete share</button>
                </mat-menu></td>
        </ng-container>
        
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header ></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button class="float-right" [matMenuTriggerFor]="menu"><mat-icon class="color-mid-grey">more_vert</mat-icon></button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onRemoveClick(element)" class="border-primary rounded-pill">Delete</button>
                    <button mat-menu-item (click)="onEdit(element)" class="border-primary rounded-pill float-right">Edit</button>
                    <button mat-menu-item (click)="onDuplicate(element)" class="border-primary rounded-pill float-right">Duplicate</button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
<!--     <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons></mat-paginator> -->
</div>
</div>