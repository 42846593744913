<span class="portfolio-start-text" *ngIf="max_start_date && selected.length" [hidden]="hideStartDateText">
  Earliest possible start date: <span class="font-weight-bold">{{max_start_date}}</span>
</span>

<div *ngIf="currentTab < 3" class="search">
  <div class="flex">
    <app-new-portfolio-search [model]="currentFilters.searchTerm" (fetchCompanies)="onFetchCompanies()" (modelChange)="currentFilters.searchTerm = $event" [meta]="meta"> </app-new-portfolio-search>
    <div class="small">
      <button (click)="toggle()" mat-button color="primary" class="px-0 float-right"><mat-icon>filter_list</mat-icon></button>
    </div>
  </div>
</div>
<div class="filters-sidenav-content">
  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChange($event)" [selectedIndex]="0">
    <mat-tab label="Equity Selection" [disabled]="loading">
      <app-new-portfolio-active-sorting
        [selected]="selected"
        [selectedFilterLabels]="selectedFilterLabels"
        [filter_label_length_limit]="filter_label_length_limit"
        [currentFilters]="currentFilters"
        [sorting]="sorting"
        [checked]="checkedOnly"
        [onCheckChange]="onCheckChange"
        (selectAllClick)="onSelectAllCompany($event)"
        (deleteFilterClick)="onDeleteFilterClick($event)"
        (deleteDynamicFilterClick)="onDeleteDynamicFilterClick($event)"
        (deleteSortClick)="onDeleteSortClick($event)"
      ></app-new-portfolio-active-sorting>

      <div class="mat-elevation-z8 mt-4">
        <div class="card mt-4 mb-4">
          <div class="card-body p-0" style="overflow: hidden;">
            <app-asset-table
              *ngIf="assetType == 'stock'"
              [list]="mergedItems"
              [columnVisibility]="columnVisibility"
              [filterLists]="filterLists"
              [sorting]="sorting"
              [currentFilters]="currentFilters"
              [quantList]="dynamicColumns"
              filterProp="quant"
              (itemSelect)="onSelect($event)"
              (fetchCompanies)="onFetchCompanies()"
              (sortClicked)="onSortClick($event)"
            ></app-asset-table>
          </div>
          <app-pagination *ngIf="list.length" [meta]="meta" (pageEvent)="onPageEvent($event)"></app-pagination>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Fund Selection" [disabled]="loading">
      <app-new-portfolio-active-sorting
        [selected]="selected"
        [selectedFilterLabels]="selectedFilterLabels"
        [filter_label_length_limit]="filter_label_length_limit"
        [currentFilters]="currentFilters"
        [sorting]="sorting"
        [checked]="checkedOnly"
        [onCheckChange]="onCheckChange"
        (selectAllClick)="onSelectAllCompany($event)"
        (deleteFilterClick)="onDeleteFilterClick($event)"
        (deleteDynamicFilterClick)="onDeleteDynamicFilterClick($event)"
        (deleteSortClick)="onDeleteSortClick($event)"
      ></app-new-portfolio-active-sorting>

      <div class="mat-elevation-z8 mt-4">
        <div class="card mt-4 mb-4">
          <div class="card-body p-0" style="overflow: hidden;">
            <app-asset-table
              *ngIf="assetType == 'fund'"
              [list]="mergedItems"
              [columnVisibility]="columnVisibility"
              [filterLists]="filterLists"
              [sorting]="sorting"
              [currentFilters]="currentFilters"
              [quantList]="dynamicColumns"
              filterProp="fund_reference_table"
              (itemSelect)="onSelect($event)"
              (fetchCompanies)="onFetchCompanies()"
              (sortClicked)="onSortClick($event)"
            ></app-asset-table>
          </div>
          <app-pagination *ngIf="list.length" [meta]="meta" (pageEvent)="onPageEvent($event)"></app-pagination>
        </div>
      </div>
    </mat-tab>



    <mat-tab label="ETF Selection" [disabled]="true">
      <app-new-portfolio-search [model]="currentFilters.searchTerm" (fetchCompanies)="onFetchCompanies()" (modelChange)="currentFilters.searchTerm = $event" [meta]="meta"> </app-new-portfolio-search>
      <!-- <app-asset-table-column-visibility-filter #etfFilters [columnVisibility]="columnVisibility" assetType="etf" [quantList]="quantList" [dynamicColumns]="dynamicColumns" (columnChanged)="onDynamicColumnChange($event)"></app-asset-table-column-visibility-filter> -->
      <app-new-portfolio-active-sorting
        [selected]="selected"
        [selectedFilterLabels]="selectedFilterLabels"
        [filter_label_length_limit]="filter_label_length_limit"
        [currentFilters]="currentFilters"
        [sorting]="sorting"
        [checked]="checkedOnly"
        (selectAllClick)="onSelectAllCompany($event)"
        (deleteFilterClick)="onDeleteFilterClick($event)"
        (deleteDynamicFilterClick)="onDeleteDynamicFilterClick($event)"
        (deleteSortClick)="onDeleteSortClick($event)"
      ></app-new-portfolio-active-sorting>
      <div class="card mb-4">
        <div class="card-body p-0">
          <app-asset-table
            *ngIf="assetType == 'etf'"
            [list]="list"
            [columnVisibility]="columnVisibility"
            [filterLists]="filterLists"
            [sorting]="sorting"
            [currentFilters]="currentFilters"
            [quantList]="dynamicColumns"
            filterProp="etf_reference_table"
            [widerFirstCol]="true"
            (itemSelect)="onSelect($event)"
            (fetchCompanies)="onFetchCompanies()"
            (sortClicked)="onSortClick($event)"
          ></app-asset-table>
        </div>
        <app-pagination *ngIf="list.length" [meta]="meta" (pageEvent)="onPageEvent($event)"></app-pagination>
      </div>
    </mat-tab>

    <mat-tab label="Overview" [disabled]="loading">
      <div>
          <app-new-portfolio-select-dialog *ngIf="selected.length > 0 && portfolio" [portfolio]="portfolio" [list]="selected"></app-new-portfolio-select-dialog>
<!--        <table class="table m-0">-->
<!--          <thead>-->
<!--          <tr>-->
<!--              <td class="bold-header">Name</td>-->
<!--              <td >Weight</td>-->
<!--              <td class="bold-header">ISIN</td>-->
<!--              <td class="bold-header mw-240 text-right">Market Cap (M$) or AUM</td>-->
<!--              <td class="bold-header mw-240 text-right">Market Cap (M$) at Earliest price data point or AUM at Earliest price data point</td>-->
<!--              <td class="mw-240">Earliest price data point</td>-->
<!--              <td >Asset Class</td>-->
<!--              <td >Currency</td>-->
<!--              <td >Sector</td>-->
<!--              <td >Industry</td>-->
<!--              -->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr *ngFor="let item of selected;index as index" [class.selected-row]="item.markedForDelete" [class.etf-border-top]="selected[index-1] && selected[index-1].isStock() && item.isETF()">-->
<!--              <td class=" font-weight-bold">{{item.company_name}}</td>-->
<!--              <td>-->
<!--                  <mat-form-field style="max-width: 100px" appearance="outline">-->
<!--                      <input matInput [(ngModel)]="item.weight" type="number" min="0" max="100" [disabled]="true"z>-->
<!--                      <span matSuffix>%</span>-->
<!--                  </mat-form-field>-->
<!--              </td>-->

<!--              <td>{{item.isin}}</td>-->
<!--              <td class="text-right">{{item.market_value_usd|number:'1.0-0'}}</td>-->
<!--              <td class="text-right">{{item.market_value_start ? (item.market_value_start|number:'1.0-0') : '-'}}</td>-->
<!--              <td>{{item.p_first_date}}</td>-->
<!--              <td>{{item.security_type_equity}}</td>-->
<!--              <td>{{item.currency ? item.currency.code : item.currency_id}}</td>-->
<!--              <td>{{item.sector ? item.sector.name : item.sector_id}}</td>-->
<!--              <td>{{item.industry ? item.industry.name : item.industry_id}}</td>-->
<!--              -->
<!--          </tr>-->
<!--          </tbody>-->
<!--      </table>-->
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
