import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {StyleManagerService} from './style-manager.service';

@Injectable()
export class ThemeService {
  constructor(
    private http: HttpClient,
    private styleManager: StyleManagerService
  ) {}

  getThemeOptions(): Observable<Array<any>> {
    return this.http.get<Array<any>>('assets/options.json');
  }

  // setTheme(themeToSet) {
  setTheme(themeToSet, isDark) {
    if (isDark) {
      this.styleManager.setStyle(
        'theme',
        `assets/scss/${themeToSet}.theme.css`
      );
    } else {
      this.styleManager.removeStyle('theme');
    }
  }
}
