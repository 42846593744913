<button mat-icon-button class="dialog-close-btn" (click)="onNoClick()"><mat-icon>close</mat-icon></button>
<h1 mat-dialog-title>
    Datawrapper help
</h1>
<div mat-dialog-content>
    <video width="640" height="480" controls>
        <source src="/assets/cim-datawrapper-connect.mp4" type="video/mp4">
        Your browser does not support the video tag.
    </video>
</div>
<div mat-dialog-actions class="justify-content-center">
    <button mat-flat-button color="primary" (click)="onNoClick()" class="border-primary rounded-pill">Close</button>
</div>
