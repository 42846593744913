import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CompanyModel} from "../../shared/models/company.model";

@Component({
  selector: 'app-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoBlockComponent implements OnInit {
  @Input() list: any[] = [];
  @Input() overview: any[] = [];
  @Input() company: CompanyModel = null;
  @Input() employeeNum = '-';
  @Input() isData: any = null;

  constructor() { }

  ngOnInit(): void {
  }

}
