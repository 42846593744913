import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import series_label from 'highcharts/modules/series-label';
import HC_Exporting from 'highcharts/modules/exporting';

export const high_colors = [
  '#424CCD',
  //'#0c0c68',
  // '#C1001F',
  '#40c6ba',
  '#00B4bf',
  '#D0C801',
  '#FF965A',
  '#7886FF',
  '#1C29A1',
  '#A788FF',
  '#559490',
  '#074C48',
  '#CB7CF0',
  '#1B1A3E'];

@Component({
  selector: 'app-general-chart',
  templateUrl: './general-chart.component.html',
  styleUrls: ['./general-chart.component.scss']
})
export class GeneralChartComponent implements OnInit, OnChanges {
  loaded = false;

  @Input() data: any = {};
  @Input() series: any[] = [];
  @Input() fullHeight = false;
  @Input() extraOptions = {};

  chartInstance: Highcharts.Chart;
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'stockChart';
  chartOptions: Highcharts.Options = {};

  constructor(private injector: Injector) {
    if (typeof Highcharts === 'object') {
      HC_Exporting(Highcharts);
      series_label(Highcharts);
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.series) {
      this.loaded = false;
      this.series = changes.series.currentValue;
      this.initChart();
    }
  }

  setChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
  }

  initChart() {}
}
