<h1 mat-dialog-title>File upload</h1>
<div mat-dialog-content>
    <p class="text-center p-0 mb-2">
        <a mat-flat-button color="primary" href="/assets/uploads/company-upload-template.csv">Download the template</a>
    </p>
    <app-file-uploader
            (uploadEvent)="onUploadEvent($event)"
            [isUploading]="isUploading"
            [acceptLabel]="'csv'"
            [accept]="'.csv'"
    ></app-file-uploader>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
</div>
