import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './app.materials';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {LoginComponent} from './pages/login/login.component';
import {HomeComponent} from './pages/home/home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StorageService} from './shared/services/storage.service';
import {AuthService} from './shared/services/auth.service';
import {DataService} from './shared/services/data.service';
import {LoginGuardService} from './shared/services/login-guard.service';
import {NavComponent} from './pages/home/nav/nav.component';
import {ForgotPwdComponent} from './pages/forgot-pwd/forgot-pwd.component';
import {ImageHandlerService} from './shared/services/image-handler.service';
import {UtilService} from './shared/services/util.service';
import {NotificationService} from './shared/services/notification.service';
import {ProfileService} from './shared/services/profile.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {SettingsComponent} from './pages/settings/settings.component';
import {RegistrationComponent} from './pages/registration/registration.component';
import {CountryService} from './shared/services/country.service';
import {HighchartsChartModule} from 'highcharts-angular';
import {StockService} from './shared/services/stock.service';
import {AdminGuardService} from './shared/services/admin-guard.service';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { VolatilityComponent } from './components/sections/volatility/volatility.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import {DescriptionComponent} from './components/sections/description/description.component';
import { DrawdownComponent } from './components/sections/drawdown/drawdown.component';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { AggregatedCompositionComponent } from './components/sections/aggregated-composition/aggregated-composition.component';
import { DatatableComponent } from './components/sections/datatable/datatable.component';
import { ReturnComponent } from './components/sections/return/return.component';
import {LoadingBarComponent} from './components/loading-bar/loading-bar.component';
import {LoaderService} from './components/loading-bar/loader.service';
import { UsersComponent } from './pages/users/users.component';
import { ModelCardComponent } from './components/model-card/model-card.component';
import { ModelCardPurchaseComponent } from './components/model-card-purchase/model-card-purchase.component';
import {UsersService} from './pages/users/users.service';
import { UserDialogComponent } from './pages/users/user-dialog/user-dialog.component';
import { CompanyDialogComponent } from './pages/users/company-dialog/company-dialog.component';
import { AccessComponent } from './components/access/access.component';
import {SysFilterPipe} from './pages/users/sys-filter.pipe';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {ClickStopDirective} from './shared/services/click-stop.directive';
import {SearchPipe} from './shared/services/search.pipe';
import { PopoverComponent } from './components/popover/popover.component';
import { UserGuideComponent } from './pages/user-guide/user-guide.component';
import { SimpleSelectComponent } from './components/simple-select/simple-select.component';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import { IdleDialogComponent } from './components/idle-dialog/idle-dialog.component';
import { ObjectSelectComponent } from './components/object-select/object-select.component';
import { PulsingTooltipComponent } from './components/pulsing-tooltip/pulsing-tooltip.component';
import { OtpResetDialogComponent } from './pages/settings/otp-reset-dialog/otp-reset-dialog.component';
import { CustomHelpComponent } from './pages/user-guide/custom-help/custom-help.component';
import { CustomPortfolioSectionComponent } from './components/sections/custom-portfolio-section/custom-portfolio-section.component';
import { EmailDialogComponent } from './components/email-dialog/email-dialog.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { OverviewComponent } from './pages/overview/overview.component';
import {ThemeService} from './shared/services/theme.service';
import {StyleManagerService} from './shared/services/style-manager.service';
import {EvaluationComponent} from './pages/evaluation/evaluation.component';
import { PortfolioEditComponent } from './pages/portfolio/portfolio-edit/portfolio-edit.component';
import { PortfolioTableComponent } from './pages/portfolio/portfolio-table/portfolio-table.component';
import { PortfolioCreateComponent } from './pages/portfolio/portfolio-create/portfolio-create.component';
import { PerformanceChartComponent } from './pages/overview/performance-chart/performance-chart.component';
import { OptimizationComponent } from './pages/optimization/optimization.component';
import { DatawrapperDialogComponent } from './components/datawrapper-dialog/datawrapper-dialog.component';
import { NewPortfolioComponent } from './pages/portfolio/portfolio-create/components/new-portfolio/new-portfolio.component';
import { ExistingPortfolioComponent } from './pages/portfolio/portfolio-create/components/existing-portfolio/existing-portfolio.component';
import {DatawrapperService} from './shared/services/datawrapper.service';
import {PortfolioService} from './pages/portfolio/portfolio.service';
import {CimDatepickerComponent, CustomHeader} from './components/cim-datepicker/cim-datepicker.component';
import { PortfolioShareDialogComponent } from './pages/portfolio/portfolio-share-dialog/portfolio-share-dialog.component';
import { PortfolioViewComponent } from './pages/portfolio/portfolio-view/portfolio-view.component';
import { DatawrapperHelpDialogComponent } from './pages/settings/datawrapper-help-dialog/datawrapper-help-dialog.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import { GeneralPortfolioComponent } from './pages/general-portfolio/general-portfolio.component';
import { PortfolioSelectorDialogComponent } from './pages/home/portfolio-selector-dialog/portfolio-selector-dialog.component';
import { ManualPortfolioFormComponent } from './pages/portfolio/manual-portfolio-form/manual-portfolio-form.component';
import { EfficientFrontierChartComponent } from './charts/efficient-frontier-chart/efficient-frontier-chart.component';
import { GeneralChartComponent } from './charts/general-chart/general-chart.component';
import { ConstraintSetterComponent } from './components/constraint-setter/constraint-setter.component';
import { ReturnChartsComponent } from './charts/return-charts/return-charts.component';
import { OptimizationSectionsComponent } from './components/optimization-sections/optimization-sections.component';
import { PortfolioStressComponent } from './pages/portfolio/portfolio-stress/portfolio-stress.component';
import { PerformanceCardComponent } from './pages/overview/performance-card/performance-card.component';
import { PerformanceSummaryCardComponent } from './pages/overview/performance-summary-card/performance-summary-card.component';
import { TreeMapChartComponent } from './pages/overview/tree-map-chart/tree-map-chart.component';
import { HeatmapChartComponent } from './pages/overview/heatmap-chart/heatmap-chart.component';
import { StressRiskChartComponent } from './charts/stress-risk-chart/stress-risk-chart.component';
import { StressDrawdownChartComponent } from './charts/stress-drawdown-chart/stress-drawdown-chart.component';
import { CummulativeReturnChartComponent } from './charts/cummulative-return-chart/cummulative-return-chart.component';
import { CimDatatableComponent } from './components/cim-datatable/cim-datatable.component';
import { AdvancedFilterDialogComponent } from './components/advanced-filter-dialog/advanced-filter-dialog.component';
import {CompanyInfoDialogComponent} from './components/company-info-dialog/company-info-dialog.component';
import { AdvancedFilterWithSelectComponent } from './components/advanced-filter-with-select/advanced-filter-with-select.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import {FileUploaderComponent} from './components/file-uploader/file-uploader.component';
import {DndDirective} from './shared/directives/dnd.directive';
import { PortfolioSummaryComponent } from './pages/overview/portfolio-summary/portfolio-summary.component';
import { InfoPopoverComponent } from './components/info-popover/info-popover.component';
import { TableHeaderSelectComponent } from './components/table-header-select/table-header-select.component';
import { AssetTableComponent } from './pages/portfolio/asset-table/asset-table.component';
import { AssetTableColumnVisibilityFilterComponent } from './pages/portfolio/asset-table-column-visibility-filter/asset-table-column-visibility-filter.component';
import { BasePortfolioCreateComponent } from './pages/portfolio/base-portfolio-create/base-portfolio-create.component';
import { ImpliedViewComponent } from './pages/implied-view/implied-view.component';
import { SummaryCardsComponent } from './pages/overview/portfolio-summary/summary-cards/summary-cards.component';
import { InfoBlockComponent } from './components/info-block/info-block.component';
import {OptionalNumberPipe} from './shared/services/optional-number.pipe';
import {DecimalPipe, TitleCasePipe} from '@angular/common';
import { WeightPieChartComponent } from './charts/weight-pie-chart/weight-pie-chart.component';
import { IndustryBarChartComponent } from './charts/industry-bar-chart/industry-bar-chart.component';
import { EvaluationChartsComponent } from './charts/evaluation-charts/evaluation-charts.component';
import { AreaChartComponent } from './charts/area-chart/area-chart.component';
import { WatchlistComponent } from './pages/watchlist/watchlist.component';
import { WatchlistCreateComponent } from './pages/watchlist/watchlist-create/watchlist-create.component';
import {HighlightPipe} from './shared/services/highlight.pipe';
import { AssetTableHeaderFilterComponent } from './pages/portfolio/asset-table-header-filter/asset-table-header-filter.component';
import {ChartPlaceholderComponent} from './components/chart-placeholder/chart-placeholder.component';
import { SankeyChartComponent } from './components/sankey-chart/sankey-chart.component';
import {WatchlistService} from './pages/watchlist/watchlist.service';
import { ChartEditorDialogComponent } from './components/chart-editor-dialog/chart-editor-dialog.component';
import { ItemSelectComponent } from './components/item-select/item-select.component';
import { BenchmarkSelectorComponent } from './components/benchmark-selector/benchmark-selector.component';
import { WatchlistViewComponent } from './pages/watchlist/watchlist-view/watchlist-view.component';
import {ColumnVisibilitySearchPipe} from './shared/services/column-visibility-search.pipe';
import { PageSelectionComponent } from './pages/portfolio/portfolio-create/page-selection/page-selection.component';
import { PageGenerateComponent } from './pages/portfolio/portfolio-create/page-generate/page-generate.component';
import { PageUploadComponent } from './pages/portfolio/portfolio-create/page-upload/page-upload.component';
import { PortfolioListComponent } from './pages/portfolio/portfolio-list/portfolio-list.component';
import { NewPortfolioCartDialogComponent } from './pages/portfolio/portfolio-create/components/new-portfolio-cart-dialog/new-portfolio-cart-dialog.component';
import { SidenavComponent } from './pages/home/nav/sidenav/sidenav.component';
import { NavListComponent } from './pages/home/nav/nav-list/nav-list.component';
import { PrintOverviewComponent } from './pages/print/print-overview/print-overview.component';
import { PrintHeaderComponent } from './pages/print/print-header/print-header.component';
import { EtfInfoDialogComponent } from './components/etf-info-dialog/etf-info-dialog.component';
import {UtcConverterService} from './shared/services/utc-convert.service';
import {UtcToLocalTimePipe} from './shared/services/utc-to-local-time.pipe';
import { ViewsSetterComponent } from './components/views-setter/views-setter.component';
import {ViewsService} from './shared/services/views.service';
import {CleanNamePipe} from './shared/services/clean-name.pipe';
import { OptimizedPortfolioWeightsComponent } from './pages/optimization/optimized-portfolio-weights/optimized-portfolio-weights.component';
import { HeatmapTableComponent } from './pages/overview/heatmap-table/heatmap-table.component';
import { NewPortfolioSearchComponent } from './pages/portfolio/portfolio-create/components/new-portfolio/new-portfolio-search/new-portfolio-search.component';
import { NewPortfolioActiveSortingComponent } from './pages/portfolio/portfolio-create/components/new-portfolio/new-portfolio-active-sorting/new-portfolio-active-sorting.component';
import { AssetTableColumnPresetComponent } from './pages/portfolio/asset-table-column-visibility-filter/asset-table-column-preset/asset-table-column-preset.component';
import {DonutChartComponent} from "./charts/donut-chart/donut-chart.component";
import {StackedBarChartComponent} from "./charts/stacked-bar-chart/stacked-bar-chart.component";
import {
  PortfolioReturnBarChartComponent
} from "./charts/portfolio-return-bar-chart/portfolio-return-bar-chart.component";
import {
  RiskContributionColumnChartComponent
} from "./charts/risk-contribution-column-chart/risk-contribution-column-chart.component";
import {FundInfoDialogComponent} from "./components/fund-info-dialog/fund-info-dialog.component";
import {
  NewPortfolioSelectDialogComponent
} from "./pages/portfolio/portfolio-create/components/new-portfolio-select-dialog/new-portfolio-select-dialog.component";
import {MatDialogRef} from "@angular/material/dialog";

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    LoginComponent,
    ForgotPwdComponent,
    HomeComponent,
    NavComponent,
    SettingsComponent,
    RegistrationComponent,
    DescriptionComponent,
    VolatilityComponent,
    EvaluationComponent,
    LineChartComponent,
    DrawdownComponent,
    FilterSelectComponent,
    AggregatedCompositionComponent,
    DatatableComponent,
    ReturnComponent,
    LoadingBarComponent,
    UsersComponent,
    ModelCardComponent,
    ModelCardPurchaseComponent,
    UserDialogComponent,
    CompanyDialogComponent,
    AccessComponent,
    SysFilterPipe,
    ConfirmDialogComponent,
    ClickStopDirective,
    SearchPipe,
    ColumnVisibilitySearchPipe,
    HighlightPipe,
    OptionalNumberPipe,
    PopoverComponent,
    UserGuideComponent,
    SimpleSelectComponent,
    IdleDialogComponent,
    ObjectSelectComponent,
    PulsingTooltipComponent,
    OtpResetDialogComponent,
    CustomHelpComponent,
    CustomPortfolioSectionComponent,
    EmailDialogComponent,
    PortfolioComponent,
    OverviewComponent,
    PortfolioEditComponent,
    PortfolioTableComponent,
    PortfolioCreateComponent,
    PerformanceChartComponent,
    OptimizationComponent,
    DatawrapperDialogComponent,
    NewPortfolioComponent,
    ExistingPortfolioComponent,
    NewPortfolioCartDialogComponent,
    CimDatepickerComponent,
    PortfolioShareDialogComponent,
    PortfolioViewComponent,
    DatawrapperHelpDialogComponent,
    PaginationComponent,
    GeneralPortfolioComponent,
    PortfolioSelectorDialogComponent,
    ManualPortfolioFormComponent,
    EfficientFrontierChartComponent,
    GeneralChartComponent,
    ConstraintSetterComponent,
    ReturnChartsComponent,
    OptimizationSectionsComponent,
    PortfolioStressComponent,
    PerformanceCardComponent,
    PerformanceSummaryCardComponent,
    TreeMapChartComponent,
    HeatmapChartComponent,
    StressRiskChartComponent,
    StressDrawdownChartComponent,
    CummulativeReturnChartComponent,
    CimDatatableComponent,
    AdvancedFilterDialogComponent,
    CompanyInfoDialogComponent,
    FundInfoDialogComponent,
    AdvancedFilterWithSelectComponent,
    UploadDialogComponent,
    FileUploaderComponent,
    DndDirective,
    PortfolioSummaryComponent,
    InfoPopoverComponent,
    TableHeaderSelectComponent,
    AssetTableComponent,
    AssetTableColumnVisibilityFilterComponent,
    BasePortfolioCreateComponent,
    ImpliedViewComponent,
    SummaryCardsComponent,
    InfoBlockComponent,
    WeightPieChartComponent,
    IndustryBarChartComponent,
    EvaluationChartsComponent,
    WatchlistComponent,
    WatchlistCreateComponent,
    AssetTableHeaderFilterComponent,
    ChartPlaceholderComponent,
    SankeyChartComponent,
    CustomHeader,
    ChartEditorDialogComponent,
    ItemSelectComponent,
    BenchmarkSelectorComponent,
    WatchlistViewComponent,
    PageSelectionComponent,
    PageGenerateComponent,
    PageUploadComponent,
    PortfolioListComponent,
    SidenavComponent,
    NavListComponent,
    PrintOverviewComponent,
    PrintHeaderComponent,
    EtfInfoDialogComponent,
    UtcToLocalTimePipe,
    ViewsSetterComponent,
    CleanNamePipe,
    OptimizedPortfolioWeightsComponent,
    HeatmapTableComponent,
    NewPortfolioSearchComponent,
    NewPortfolioActiveSortingComponent,
    AssetTableColumnPresetComponent,
    AreaChartComponent,
    DonutChartComponent,
    StackedBarChartComponent,
    PortfolioReturnBarChartComponent,
      RiskContributionColumnChartComponent,
      NewPortfolioSelectDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatBadgeModule,
    HighchartsChartModule,
    NgxMatSelectSearchModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    DataService,
    AuthService,
    StorageService,
    LoginGuardService,
    AdminGuardService,
    ProfileService,
    ImageHandlerService,
    UtilService,
    NotificationService,
    CountryService,
    StockService,
    UsersService,
    LoaderService,
    ThemeService,
    StyleManagerService,
    DatawrapperService,
    PortfolioService,
    WatchlistService,
    DecimalPipe,
    TitleCasePipe,
    UtcConverterService,
    UtcToLocalTimePipe,
    ViewsService,
    CleanNamePipe,
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
