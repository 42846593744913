import {Injectable, Injector} from '@angular/core';
import {ApiEndpoints} from '../../ApiEndpoints';
import {map} from 'rxjs/operators';
import {DataService} from './data.service';
import {User} from '../models/user.model';
import {ImageHandlerService} from "./image-handler.service";

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends DataService {

  constructor(
    injector: Injector,
    private imageHandler: ImageHandlerService,
  ) {
    super(injector);
  }

  getItem(id?: number) {
    let endPointParam = '';
    if (id) {
      endPointParam = `/${id}`;
    }

    return this.getRequest(`${ApiEndpoints.me}${endPointParam}`)
      .pipe(map((response: any) => new User(response.item)));
  }

  save(entity: User) {
    if (!entity.photo) {
      delete entity.photo;
    }
    if (!this.imageHandler.isDataUrl(entity.photo)) {
      delete entity.photo;
    }
    if (!entity.password) {
      delete entity.password;
      delete entity.password_confirm;
    }

    // clean up post data
    delete entity.has_custom_portfolio;
    delete entity.indices;
    delete entity.is_admin;
    delete entity.auth_logs_1_year;
    delete entity.auth_logs_30_days;
    delete entity.company;
    delete entity.custom_access;
    delete entity.company_id;

    return this.postRequest(ApiEndpoints.me, entity);
  }

  getTwoFAReset() {
    return this.getRequest(ApiEndpoints.twoFAReset);
  }
  saveTwoFAReset() {
    return this.postRequest(ApiEndpoints.twoFAReset, {});
  }

  purchase(data, type) {
    return this.postRequest(type === 'index' ? ApiEndpoints.purchaseIndex : ApiEndpoints.purchaseCustomAccess, data);
  }
}
