import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleanName',
})
export class CleanNamePipe implements PipeTransform {

  constructor() {
  }

  transform(text: string, args?: any): string {
    return text.replace(/_/g, ' ');
  }
}
