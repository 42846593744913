import {CompanyModel} from "./company.model";

export const VISIBILITY_PRIVATE = 1;
export const VISIBILITY_PUBLIC = 2;
export const VISIBILITY_SHARED = 3;
export class PortfolioModel {

  public id?: number;
  public name?: string;
  public description?: string;
  public start_date?: string;
  public end_date?: string;
  public country?: any;
  public country_id?: number;
  public user?: any;
  public currency?: any;
  public currency_id?: number;
  public visibility?: number;
  public readonly ?: boolean;
  public shared_with_users?: [];
  public market_ids?: [];
  public region_ids?: [];
  public country_ids?: [];
  public sector_ids?: [];
  public industry_ids?: [];
  public security_ids?: any[];
  public securities?: CompanyModel[];
  public funds?: any[];
  public optim_performance?: any[];
  public optim_performance_unconstrained?: any[];
  public optim_performance_cost_of_constrained?: any[];
  public optim_weights?: any[];
  public symbol_performance?: any[];
  public efficient_frontier?: any;
  public currency_hedging?: any[];
  public performance_summary?: any;
  public correlation_matrix?: any;
  public stress_test_events?: any[];
  public stress_test_performance?: any[];
  public evaluation_table?: any[];
  public evaluation_optim_performance?: any[];
  public efficient_frontier_symbols_vol?: any[];
  public risk_contribution?: any[];
  public risk_diversification?: any[];
  public risk_elasticity?: any[];
  public implied_returns?: any[];
  public treemap_plot?: any[];
  public status?: number;
  public status_text?: string;
  public benchmark?: string;
  public type: string;
  public limit?: number;

  public rebalancing_type?: string;
  public last_updated?: string;
  public last_updated_at?: string;
  public cost_of_rebalancing?: number;

  public params?: any;
  public weighting_type?: string;
  public security_overviews?: any;
  public is_tutorial?: number;

  public etfPercent?: number;

  public start_date_fixed = false; // Market cap fetch triggers start date init, prevent with this flag

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.description = data.description;
      this.start_date = data.start_date;
      this.end_date = data.end_date;
      this.country = data.country;
      this.type = Number(data.type).toString();
      this.country_id = data.country_id;
      if (data.country) {
        this.country_id = data.country.id;
      }
      this.currency = data.currency;
      this.currency_id = data.currency_id;
      if (data.currency) {
        this.currency_id = data.currency.id;
      }
      this.benchmark = data.benchmark ? data.benchmark : 'GSPC';
      this.user = data.user;
      this.visibility = data.visibility;
      this.shared_with_users = data.shared_with_users;
      this.readonly = data.readonly;
      if (data.security_ids) {
        this.security_ids = data.security_ids;
      }
      if (data.params) {
        if (data.params.country_ids) {
          this.market_ids = data.params.market_ids || [];
          this.region_ids = data.params.region_ids || [];
          this.country_ids = data.params.country_ids || [];
          this.sector_ids = data.params.sector_ids || [];
          this.industry_ids = data.params.industry_ids || [];
        }
      }
      if (data.securities) {
        this.securities = data.securities.map(s => new CompanyModel(s));
      }
      if (data.funds) {
        data.funds.map(s => {
         s.security_type_equity = 'FUND';
         s.company_name = s.name;
         s.p_first_date = s.FundInceptionDate;

        
        });
        this.securities.push(... data.funds.map(s => new CompanyModel(s)));
      }
      this.optim_performance = data.optim_performance;
      this.optim_performance_unconstrained = data.optim_performance_unconstrained;
      this.optim_performance_cost_of_constrained = data.optim_performance_cost_of_constrained;
      this.optim_weights = data.optim_weights;
      this.symbol_performance = data.symbol_performance;
      this.params = data.params;
      this.efficient_frontier = data.efficient_frontier;
      this.currency_hedging = data.currency_hedging;
      this.performance_summary = data.performance_summary;
      this.correlation_matrix = data.correlation_matrix;
      this.status = data.status;
      this.status_text = data.status_text;
      this.is_tutorial = data.is_tutorial;
      this.cost_of_rebalancing = data.cost_of_rebalancing;
      this.evaluation_table = data.evaluation_table;
      this.evaluation_optim_performance = data.evaluation_optim_performance;
      this.efficient_frontier_symbols_vol = data.efficient_frontier_symbols_vol;
      this.risk_contribution = data.risk_contribution;
      this.risk_diversification = data.risk_diversification;
      this.risk_elasticity = data.risk_elasticity;
      this.implied_returns = data.implied_returns;
      this.stress_test_performance = data.stress_test_performance;
      this.treemap_plot = data.treemap_plot;
      if (data.stress_test_events && data.stress_test_events.length) {
        this.stress_test_events = data.stress_test_events.map(e => {
          let disabled = this.stress_test_performance.filter(s => s.event == e.event).length === 0;

          return {
            ...e,
            id: e.event,
            name: e.event,
            disabled: disabled
          }
        });
      } else {
        this.stress_test_events = [];
      }
      if (data.limit) {
        this.limit = data.limit;
      }
      this.rebalancing_type = data.rebalancing_type;
      this.last_updated_at = data.last_updated_at;
      if (this.last_updated_at) {
        this.last_updated = data.last_updated_at.replace(' ', 'T');
      }
      this.weighting_type = data.weighting_type ? data.weighting_type : 'equal';
      if (data.security_overviews) {
        this.security_overviews = data.security_overviews;
      }
      if (data.funds) {
        this.funds = data.funds;
      }

      this.setETFPercent();
    }
  }

  get userFullName() {
    return this.user && this.user.name ? this.user.name : '';
  }

  isPrivate() {
    return +this.visibility === VISIBILITY_PRIVATE;
  }

  isPublic() {
    return +this.visibility === VISIBILITY_PUBLIC;
  }

  isShared() {
    return +this.visibility === VISIBILITY_SHARED;
  }

  // Portfolio is generated via the form (has the filter params)
  isGenerated() {
    return !!this.params
  }

  checkLimit() {
    if (this.limit > 100) {
      this.limit = 100;
    }
    if (this.limit < 1) {
      this.limit = 1;
    }
  }

  niceWeightType() {
    return this.weighting_type.split('_').join(' ');
  }

  hasETF() {
    let hasETF = false;
    if (this.securities && this.securities.length) {
      this.securities.map(s => {
        if (s.security_type_equity === 'ETF') {
          hasETF = true;
        }
      });
    }
    return hasETF;
  }

  hasOnlyETF() {
    let etfCount = 0;
    if (this.securities && this.securities.length) {
      this.securities.map(s => {
        if (s.security_type_equity === 'ETF') {
          etfCount += 1;
        }
      });
    }
    return (etfCount > 0) && (etfCount === this.securities.length);
  }

  setETFPercent() {
    if (this.hasETF()) {
      let etfWeightTotal = 0;
      if (this.securities && this.securities.length) {
        this.securities.map((company: CompanyModel) => {
          if (company.isETF()) {
            etfWeightTotal += +company.weight;
          }
        });
      }
      etfWeightTotal = Math.round(etfWeightTotal);
      this.etfPercent = !etfWeightTotal ? 20 : etfWeightTotal;
    } else {
      this.etfPercent = 0;
    }
  }
}
