<div class="my-2">
    <div class="filter-container">
        <mat-form-field appearance="outline" class="search-field">
          <input
            matInput
            name="Search"
            ngDefaultControl
            placeholder="Search for names of assets and their description"
            (keyup)="modelChange.emit(model)"
            [(ngModel)]="model"
            (keyup.enter)="onFetchCompanies()"
          >

        </mat-form-field>
        <div class="ml-3 d-inline-block pt-2 result-count" style="font-size: 13px">
            {{meta && meta.total ? meta.total : 0}} results
        </div>
        <button type="button" mat-flat-button color="primary" class="ml-3" (click)="onFetchCompanies()">
            <mat-icon class="navigation-button">search</mat-icon>
            <span class="navigation-button">Search</span>
        </button>
    </div>
</div>
