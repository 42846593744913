import { Component, OnInit } from '@angular/core';
import {PortfolioService} from "../../pages/portfolio/portfolio.service";
import {MatDialogRef} from "@angular/material/dialog";
import {CompanyModel} from "../../shared/models/company.model";

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {
  isUploading = false;

  constructor(
    private service: PortfolioService,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  handleResponse(resp) {
    let companies = [];
    if (resp.success) {
      if (resp.success.items && resp.success.items.length) {
        companies = resp.success.items.map((i: any) => {
          let weight = resp.success.weights[i.isin];
          i.weight = weight ? weight : 0;
          i.selected = true;
          return new CompanyModel(i);
        })
      }
    }
    return companies;
  }

  onUploadEvent(file) {
    if (!file) {
      return this.service.notificationService.open("Error. Missing file.");
    }
    this.isUploading = true;
    const formData = new FormData();
    formData.append('file', file);
    this.service.uploadCSVFile(formData).subscribe((resp: any) => {
      this.service.notificationService.open('File uploaded!');
      this.isUploading = false;

      const companies = this.handleResponse(resp);
      this.dialogRef.close(companies);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
