<mat-toolbar>
    <h1>Stress Testing</h1>
    <div class="ml-auto text-right" style="min-width: 280px">
      <mat-form-field *ngIf="evaluationSelectList && evaluationSelectList.length" style="min-width: 200px;" class="header-select mr-2" appearance="outline" >
        <mat-label >Portfolio</mat-label>
        <mat-select
          name="evaluation-data-select"
          [(ngModel)]="selectedEvaluation"
          (ngModelChange)="onEvaluationTypeChange()"
          panelClass="cim-select"
        >
          <mat-option *ngFor="let item of evaluationSelectList" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>

        <app-benchmark-selector (benchmarkSelect)="onBenchmarkSelect($event)"></app-benchmark-selector>
    </div>
</mat-toolbar>
<div class="pb-3">
    <div class="card mb-4">
        <div class="card-body">
            <div class="filter-container mb-3">
                <span class="filter-title">Choose event</span>
                <mat-form-field appearance="outline" class="search-field" >
                    <input
                      matInput
                      placeholder="Search for events"
                      [(ngModel)]="searchText"
                    >
                </mat-form-field>
            </div>
            <table class="table m-0" >
                <thead>
                <tr>
                    <td class="bold-header">Name</td>
                    <td class="max-500">Description</td>
                    <td >Start date</td>
                    <td >End date</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let i of portfolio.stress_test_events | search:searchText" [hidden]="i.hidden">
                    <td class="font-weight-bold">
                        <mat-checkbox color="primary" [disabled]="i.disabled" (change)="onEventSelect(i.name)">{{i.name}}</mat-checkbox>
                    </td>
                    <td class="max-500"><div >{{i.description||''}}</div></td>
                    <td>{{i.start_date}}</td>
                    <td>{{i.end_date}}</td>
                </tr>
                </tbody>
            </table>
            <button mat-flat-button class="mt-2" color="primary" [disabled]="!selectedEvents.length" (click)="onStressTestClick()">Stress test</button>
        </div>
    </div>

    <div *ngIf="stressLoaded">
        <div class="row">
            <div class="col-md-6">
                <div class="card">
                    <mat-form-field class="stress-event-selector header-select" appearance="outline">
                        <mat-label>Event</mat-label>
                        <mat-select [(ngModel)]="selectedEvent" name="selected-event">
                            <mat-option *ngFor="let e of selectedEvents" [value]="e" (click)="onEventCummulativeEventSelect(e)">{{e}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="card-header">Annualized Risk/Return</div>
                    <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
                    <div class="card-sub-header"><span>Type:</span> Baseline Portfolio</div>
                    <div class="card-body">
                        <app-stress-risk-chart [series]="riskChartSeries"></app-stress-risk-chart>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <mat-form-field class="stress-event-selector header-select" appearance="outline">
                        <mat-label>Event</mat-label>
                        <mat-select [(ngModel)]="selectedEvent" name="selected-event">
                            <mat-option *ngFor="let e of selectedEvents" [value]="e" (click)="onEventCummulativeEventSelect(e)">{{e}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="card-header">Max Drawdown</div>
                    <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
                    <div class="card-sub-header"><span>Type:</span> Baseline Portfolio</div>
                    <div class="card-body">
                        <app-stress-drawdown-chart [series]="drawdownChartSeries"></app-stress-drawdown-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 position-relative">
            <mat-form-field class="stress-event-selector header-select" appearance="outline">
                <mat-label>Event</mat-label>
                <mat-select [(ngModel)]="selectedEvent" name="selected-event">
                    <mat-option *ngFor="let e of selectedEvents" [value]="e" (click)="onEventCummulativeEventSelect(e)">{{e}}</mat-option>
                </mat-select>
            </mat-form-field>
            <app-cummulative-return-chart *ngIf="plotData"
                                          [subHeader]="portfolio.name"
                                          [plotData]="plotData"
                                          [chartStart]="selectedStressEvent.start"
                                          [chartEnd]="selectedStressEvent.end"
                                          [highlightStart]="selectedStressEvent.highlightStart"
                                          [highlightEnd]="selectedStressEvent.highlightEnd"
                                          [benchmark]="benchmark"
                                          [benchmarks]="selectedBenchmarks"></app-cummulative-return-chart>
        </div>
    </div>
</div>
