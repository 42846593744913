import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PortfolioService} from "../../portfolio/portfolio.service";

@Component({
  selector: 'app-portfolio-selector-dialog',
  templateUrl: './portfolio-selector-dialog.component.html',
  styleUrls: ['./portfolio-selector-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortfolioSelectorDialogComponent implements OnInit {

  lists: any = {
    private: [],
    public: [],
    shared_by_me: [],
    shared_with_me: [],
    tutorials: []
  };

  constructor(
    public dialogRef: MatDialogRef<PortfolioSelectorDialogComponent>,
    private service: PortfolioService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (!this.service.portfolioLists) {
      this.service.getItems().subscribe((resp: any) => {
        if (resp.error) {
          this.service.notificationService.open(resp.error)
        }
        resp = this.service.extractPortfolioData(resp);
        this.lists = resp;

        // merge private and shared by me lists
        this.lists.private = [
          ...this.lists.private,
          ...this.lists.shared_by_me
        ]
        this.service.portfolioLists = this.lists;
      })
    } else {
      this.lists = this.service.portfolioLists;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
