import {Injectable} from '@angular/core';

@Injectable()
export class ImageHandlerService {

  regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

  getBase64Image(e) {
    return new Promise( (resolve, reject) => {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
      const pattern = /image-*/;
      const reader = new FileReader();

      if (!file) {
        reject('file missing');
        return;
      }

      if (!file.type.match(pattern)) {
        reject('invalid format');
        return;
      }

      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  isDataUrl(str) {
    return (str && this.regex.test(str));
  }

}
