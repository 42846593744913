<div class="row">
  <div class="col-md-12" *ngIf="showBeta">
    <div style="margin-bottom: 20px">
      <div class="card">
        <div class="card-header pb-0">Correlation and Beta Against Risk Factors</div>
        <div class="card-sub-header"><span>Portfolio:</span> {{subHeader.portfolio}}</div>
        <div class="card-sub-header"><span>Type:</span> {{subHeader.type}}</div>
        <div class="card-body pt-0">
          <highcharts-chart *ngIf="loaded"
                            [Highcharts]="Highcharts"
                            [options]="chart1Options"
                            [constructorType]="chartConstructor"
                            style="width: 100%; height: auto; display: block;"
          ></highcharts-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header pb-0">Risk Contribution and Allocation</div>
      <div class="card-sub-header"><span>Portfolio:</span> {{subHeader.portfolio}}</div>
      <div class="card-sub-header"><span>Type:</span> {{subHeader.type}}</div>
      <div class="card-body pt-0">
        <highcharts-chart *ngIf="loaded"
                          [Highcharts]="Highcharts"
                          [options]="chart2Options"
                          [constructorType]="chartConstructor"
                          style="width: 100%; height: auto; display: block;"
        ></highcharts-chart>
      </div>
    </div>
  </div>
</div>

