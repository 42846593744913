import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-optimization-sections',
  templateUrl: './optimization-sections.component.html',
  styleUrls: ['./optimization-sections.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('100ms ease')),
    ]),
  ]
})
export class OptimizationSectionsComponent implements OnInit {

  @Input() portfolio: any = {};
  @Input() optimData: any = {};
  @Input() filterLists: any = null;
  @Input() correlationMatrixData: any = null;
  @Input() covarianceMatrixData: any = null;
  @Input() correlationMatrixTableData: any = null;
  @Input() covarianceMatrixTableData: any = null;
  @Input() isPrint = false;
  @Input() matrixDataType = 'covariance';

  section_expanded = true;

  correlationExtraOptions = {}

  filter: any = {
    start: null,
    end: null
  }

  matrix: any = {};

  allowedTickers = [];

  constructor() { }

  ngOnInit(): void {
  }

  onDateChange(prop) {
    this.filter[prop] = this.filter[prop].format('YYYY-MM-DD');
  }

  onFilterChange() {
    let companies = this.portfolio.securities;
    if (this.filter.country && this.filter.country.length) {
      companies = companies.filter(c => c.country && this.filter.country.includes(c.country.name));
    }
    if (this.filter.sector && this.filter.sector.length) {
      companies = companies.filter(c => c.sector && this.filter.sector.includes(c.sector.name));
    }
    this.allowedTickers = companies.map(c => c.fs_ticker);
  }
}
