import {Component} from '@angular/core';
import {LoaderService} from "./loader.service";
import {ProgressBarMode, MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent {

  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;
  constructor(public loaderService: LoaderService) { }

}
