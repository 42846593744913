import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProfileService} from '../../../shared/services/profile.service';

@Component({
  selector: 'app-otp-reset-dialog',
  templateUrl: './otp-reset-dialog.component.html',
  styleUrls: ['./otp-reset-dialog.component.scss']
})
export class OtpResetDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OtpResetDialogComponent>,
    private service: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFinish2FAClicked() {
    this.service.saveTwoFAReset().subscribe((response: any) => {
      this.service.notificationService.open('Setup finished.');
      this.dialogRef.close();
    });
  }
}
