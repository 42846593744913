import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from "highcharts/highstock";
import {high_colors} from "../general-chart/general-chart.component";

@Component({
  selector: 'app-evaluation-charts',
  templateUrl: './evaluation-charts.component.html',
  styleUrls: ['./evaluation-charts.component.scss']
})
export class EvaluationChartsComponent implements OnInit {
  loaded = false;
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'chart';

  @Input() riskContributionRaw: any = {};
  @Input() correlationBetaRaw: any = {};
  @Input() diversivicationRaw: any = {};
  @Input() subHeader: any = {};
  @Input() showBeta: boolean = true;

  chart1Options: Highcharts.Options = {};
  chart2Options: Highcharts.Options = {};

  constructor() { }

  ngOnInit(): void {
    this.initChart();
  }

  initChart() {
    this.loaded = false;

    this.chart1Options = {
      chart: {
        height: '300px'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      colors: high_colors,
      legend: {
        enabled: true,
      },
      yAxis: {
        title: {
          text: ''
        },
        minorGridLineWidth: 0,
        tickInterval: 0.1
      },
      xAxis: {
        categories: this.correlationBetaRaw.categories,
        gridLineWidth: 0,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          }
        }
      },
      tooltip: {
        valueDecimals: 2,
      },
      series: this.correlationBetaRaw.series
    };

    // Risk
    this.chart2Options = {
      chart: {
        height: '300px'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      colors: high_colors,
      legend: {
        enabled: true,
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          format: '{value}%'
        },
        minorGridLineWidth: 0,
        gridLineColor: '#ffff'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          }
        }
      },
      xAxis: {
        categories: this.riskContributionRaw.categories,
        minorGridLineWidth: 0,
        gridLineWidth: 0

      },
      tooltip: {
        valueSuffix: '%',
        valueDecimals: 2,
      },
      series: this.riskContributionRaw.series
    };


    setTimeout(() => this.loaded = true, 10);
  }
}
