import {Component, EventEmitter, Input, OnInit, Output,  OnChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, MatSortable} from "@angular/material/sort";
import {PortfolioService} from "../../pages/portfolio/portfolio.service";

export enum ColumnType {
  Simple = 1,
  Percentage,
  Number2Decimal,
  Input,
  CompanyInfo
}

@Component({
  selector: 'app-cim-datatable',
  templateUrl: './cim-datatable.component.html',
  styleUrls: ['./cim-datatable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CimDatatableComponent implements OnInit {

  @Input() columns: any[] = [];
  @Input() data: any[] = [];
  @Input() footer: any = null;
  @Input() inputDisabled = false;
  @Input() needPaginator = false;

  @Input() sortProp: string = null;
  @Input() sortDirection: string = '';
  @Input() sortDisabled: boolean = false;
  @Input() disableRightPadding: boolean = false;

  @Output() inputChange: EventEmitter<any> = new EventEmitter<any>();

  columnLabels: string[] = [];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private service: PortfolioService
  ) { }

  ngOnInit(): void {
    this.initTable()
    if (this.sortProp) {
      this.sort.sort(({ id: this.sortProp, start: this.sortDirection}) as MatSortable)
    }
    
  }

  ngOnChanges(){
    this.initTable()
   }
  initTable() {

    this.columnLabels = this.columns.map(c => c.id);
    this.dataSource = new MatTableDataSource(this.data);
    if (this.needPaginator) {
      this.dataSource.paginator = this.paginator;
    }

    this.dataSource.sort = this.sort;
    
  }

  onCompanyInfoClick(company) {
    this.service.openCompanyInfoDialog(company.Ticker)
  }

  onInputFocus(entity, prop) {
    if (entity[prop] === 0) {
      entity[prop] = null;
    }
  }


  onInputChange() {

    this.inputChange.emit()
  }

}
