<button mat-icon-button class="dialog-close-btn" (click)="onNoClick()"><mat-icon>close</mat-icon></button>
<h1 mat-dialog-title *ngIf="companyName">
  ETF Tear Sheet - {{companyName}}
  <div class="float-right">
    <button mat-button color="primary" (click)="onToggleAll()">Expand all</button>
    <button mat-button color="primary" (click)="onToggleAll(false)">Minimize all</button>
  </div>
</h1>
<div mat-dialog-content>
  <app-line-chart [series]="closeAdjData" [extraOptions]="extraOptions"></app-line-chart>
  <div>
    <h3 class="summary-header cursor-pointer" (click)="introExpanded = !introExpanded">
      Description
      <span class="text-primary float-right ">{{introExpanded ? 'Hide content' : 'Show content'}}<mat-icon class="align-bottom">{{introExpanded ? 'expand_less' : 'expand_more'}}</mat-icon></span>
    </h3>
    <div [@detailExpand]="introExpanded ? 'expanded' : 'collapsed'" class="overflow-hidden">
      <p class="overview-text mt-3">{{overview['descr']}}</p>
      <app-info-block [list]="introSection" [overview]="overview" [company]="data.company"></app-info-block>
    </div>
  </div>
  <div *ngFor="let section of sections" >
    <h3 class="summary-header mt-3 cursor-pointer" (click)="section.expanded = !section.expanded">
      {{section.name}}
      <span class="text-primary float-right ">{{section.expanded ? 'Hide content' : 'Show content'}}<mat-icon class="align-bottom">{{section.expanded ? 'expand_less' : 'expand_more'}}</mat-icon></span>
    </h3>
    <div [@detailExpand]="section.expanded ? 'expanded' : 'collapsed'" class="overflow-hidden">
      <app-info-block [list]="section.list" [overview]="overview"></app-info-block>
    </div>
  </div>
  <div class="mt-4">
    <button mat-flat-button class="float-right" color="primary" (click)="onNoClick()">Close</button>
  </div>
</div>
