<div class="heatmap-table-container">
  <table class="heatmap-table">
    <tr class="position-sticky sticky-col-top">
      <td class="sticky-corner"></td>
      <td class="text-center rotate" *ngFor="let colData of tableData">
        <div>{{colData[0].Var2}}</div>
      </td>
    </tr>
    <tr *ngFor="let colData of tableData">
      <td class="text-right sticky-left min-width-120">{{colData[0].Var2}}</td>
      <td class="text-center text-white hover-style number-col" [style.background-color]="data.color" *ngFor="let data of colData" [matTooltip]="
    'x: ' + data.Var1 +
    '\ny: ' + data.Var2"
          matTooltipClass="heatmap-tip">{{data.value}}</td>
    </tr>
  </table>
</div>
