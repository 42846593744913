import {Component, OnInit, ViewChild} from '@angular/core';
import {WatchlistService} from "./watchlist.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {Router} from "@angular/router";

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: [
    '../portfolio/portfolio-table/portfolio-table.component.scss',
    './watchlist.component.scss'
  ]
})
export class WatchlistComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  list: any[] = [];
  columns: any = [
    'name',
    'action'
  ];
  dataSource: MatTableDataSource<any>;
  tableLoaded = false

  constructor(
    private service: WatchlistService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchWatchlists();
  }

  fetchWatchlists() {
    this.service.getItems().subscribe((resp: any) => {
      if (resp.error) {
        this.service.notificationService.open(resp.error)
      }
      this.list = resp;
      this.initDatatable();

    })
  }

  initDatatable() {
    if (this.list && this.list.length) {
      this.tableLoaded = false;
      this.dataSource = new MatTableDataSource<any>(this.list);
      this.dataSource.sort = this.sort;
      this.tableLoaded = true
    }
  }

  doDelete(item) {
    this.service.deleteItem(item).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      this.service.notificationService.open('Watchlist deleted')
      this.list = this.list.filter(i => i.id !== item.id);
      this.dataSource.data = this.list;
    })
  }

  deleteItem(item) {
    this.service.confirmAction().subscribe(confirm => {
      if (confirm) {
        this.doDelete(item);
      }
    })
  }

  closeOtherInfo(element) {
    this.list = this.list.map((c: any) => {
      if (c.id !== element.id) {
        c.isOpen = false;
      }
      return c;
    });
  }

  onWatchClick(item) {
    console.log("Watch clicked", item)
    this.router.navigate(['/app/watchlist-view', item.id]);

  }
}
