<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">Annualized Volatility (63d rolling)</div>
            <div class="card-sub-header"><span>Portfolio:</span> {{subHeader}}</div>
            <div class="card-sub-header"><span>Type:</span> Baseline Portfolio</div>
            <div class="card-body">
                <!-- Daily Return -->
                <app-line-chart *ngIf="series[0] && series[0].series" [enableDatawrapper]="!isPrint" [series]="series[0].series" [chartTitle]="series[0].title" [extraOptions]="volatilitsOptions" [loaded]="loaded" [isPrint]="isPrint" [startDate]="startDate" [endDate]="endDate"></app-line-chart>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">Drawdown</div>
            <div class="card-sub-header"><span>Portfolio:</span> {{subHeader}}</div>
            <div class="card-sub-header"><span>Type:</span> Baseline Portfolio</div>
            <div class="card-body">
                <!-- Drawdown -->
                <app-line-chart *ngIf="series[1] && series[1].series" [enableDatawrapper]="!isPrint" [series]="series[1].series" [chartTitle]="series[1].title" [extraOptions]="drawDownExtraOptions" [loaded]="loaded" [isPrint]="isPrint" [startDate]="startDate" [endDate]="endDate" (afterSetExtremes)="onDrawdownDateChange($event)"></app-line-chart>
            </div>
        </div>
    </div>
</div>


