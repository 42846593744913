<button *ngIf="enableDatawrapper" class="datawrapper-btn" mat-button color="primary" (click)="onDatawrapperClick()">Publish</button>

<app-chart-placeholder [loaded]="loaded"></app-chart-placeholder>

<highcharts-chart *ngIf="loaded"
        [Highcharts]="Highcharts"
        [options]="chartOptions"
        [constructorType]="chartConstructor"
        (chartInstance)="setChartInstance($event)"
        style="width: 100%; height: auto; display: block;"
></highcharts-chart>
