import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PortfolioModel} from "../../../shared/models/portfolio.model";
import {PortfolioService} from "../portfolio.service";

@Component({
  selector: 'app-portfolio-view',
  templateUrl: './portfolio-view.component.html',
  styleUrls: ['./portfolio-view.component.scss']
})
export class PortfolioViewComponent implements OnInit {
  portfolio = new PortfolioModel({})

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: PortfolioService
  ) { }

  ngOnInit(): void {
    this.initPage()
  }

  initPage() {
    const id = +this.route.snapshot.paramMap.get('id');
    if (!id) {
      this.service.notificationService.open('Missing portfolio ID');
      return this.router.navigate(['/app']);
    }
    this.viewPortfolio(id);
  }

  viewPortfolio(id?) {
    this.service.viewItem(id).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      if (resp) {
        this.portfolio = new PortfolioModel(resp);
        this.service.selectPortfolio(this.portfolio);
      }
    })
  }
}
