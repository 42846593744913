<h1 mat-dialog-title>
{{title}}
</h1>

<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<div mat-dialog-actions align="end">
    <button *ngIf="!isAlert" mat-flat-button color="primary" (click)="onConfirm()">Yes</button>
    <button mat-button (click)="onDismiss()">{{isAlert ? 'Close' : 'No'}}</button>
</div>
