import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-asset-table-header-filter',
  templateUrl: './asset-table-header-filter.component.html',
  styleUrls: ['../../../components/filter-select/filter-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssetTableHeaderFilterComponent implements OnInit {

  @Input() title = '';
  @Input() unit = null;
  @Input() minModel: any;
  @Input() maxModel: any;

  @Output() enterHit: EventEmitter<any> = new EventEmitter<any>();
  @Output() minModelChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() maxModelChanged: EventEmitter<any> = new EventEmitter<any>();

  keepData = false;

  constructor() { }

  ngOnInit(): void {
  }

  onEnterHit() {
    this.enterHit.emit()
  }

  minModelChange() {
    this.minModelChanged.emit(this.minModel)
  }

  maxModelChange() {
    this.maxModelChanged.emit(this.maxModel)
  }

  onOKClick() {
    this.keepData = true;
    this.enterHit.emit();
  }

  onPanelCloseOrCancel() {
    this.deselectAll();
  }

  onPanelClosed() {
    if (!this.keepData) {
      this.deselectAll();
    }
    this.keepData = false;
  }

  private deselectAll() {
    this.minModel = null;
    this.maxModel = null;
    this.minModelChanged.emit(this.minModel)
    this.maxModelChanged.emit(this.maxModel)
  }
}
