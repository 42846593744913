import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import HC_Exporting from "highcharts/modules/exporting";
import * as Highcharts from "highcharts/highstock";
import series_label from "highcharts/modules/series-label";
import  More from 'highcharts/highcharts-more';
import  Tree from 'highcharts/modules/treemap';
import  Heatmap from 'highcharts/modules/heatmap';
import {high_colors} from "../../../charts/general-chart/general-chart.component";

@Component({
  selector: 'app-tree-map-chart',
  templateUrl: './tree-map-chart.component.html',
  styleUrls: ['./tree-map-chart.component.scss']
})
export class TreeMapChartComponent implements OnInit, OnChanges {
  @Input() dataRaw: any[] = [];
  @Input() fullHeight = false;
  @Input() secondary = false;
  @Input() isPrint = false;
  @Input() hasETF = false;
  @Input() extraOptions = {};
  @Input() subHeader: string = '';

  loaded = false;
  series: any[] = [];

  selectedData: any = null;
  filteredData: any = null;
  selectedColoring: any = 'individual';

  countries = [];
  sectors = [];
  industries = [];
  types = [];

  parents = [];

  colors: any[] = high_colors;

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'chart';
  chartOptions: Highcharts.Options = {};

  constructor() { 
    if (typeof Highcharts === 'object') {
      HC_Exporting(Highcharts);
      More(Highcharts);
      Tree(Highcharts);
      Heatmap(Highcharts);
      
      series_label(Highcharts);
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataRaw) {
      this.loaded = false;
      this.dataRaw = changes.dataRaw.currentValue;
      if (this.dataRaw.length) {
        if (this.secondary) {
          this.selectedData = this.dataRaw[1];
        } else {
          this.selectedData = this.dataRaw[0];
        }
        this.initChart();
      }
    }
  }

  initLists() {
    this.countries = [];
    this.sectors = [];
    this.industries = [];
    this.types = [];
    this.filteredData.data.map(d =>{
      this.countries.push(d.country);
      this.sectors.push(d.sector);
      this.industries.push(d.industry);
      this.types.push(d.type);
    });
    this.countries = [...new Set(this.countries)];
    this.sectors = [...new Set(this.sectors)];
    this.industries = [...new Set(this.industries)];
    this.types = [...new Set(this.types)];
  }

  separateStockFromETF() {
    this.filteredData = JSON.parse(JSON.stringify(this.selectedData));
    if (
      (this.selectedColoring == 'country') ||
      (this.selectedColoring == 'industry') ||
      (this.selectedColoring == 'sector')
    ) {
      // stocks only
      this.filteredData.data = this.filteredData.data.filter(d => d.type === 'SHARE');
    } else if (this.selectedColoring == 'type')  {
      // etf only
      this.filteredData.data = this.filteredData.data.filter(d => d.type !== 'SHARE');
    }
  }

  setColors() {
    this.separateStockFromETF();
    this.initLists();

    if (this.selectedColoring == 'country') {
      this.initParents(this.countries);
    } else if (this.selectedColoring == 'industry') {
      this.initParents(this.industries);
    } else if (this.selectedColoring == 'sector') {
      this.initParents(this.sectors);
    } else if (this.selectedColoring == 'type') {
      this.initParents(this.types);
    }

    this.filteredData.data = this.filteredData.data.map(d => {
      d.parent = d[this.selectedColoring];
      return d;
    });
  }

  initParents(list) {
    let index = 0;
    this.parents = list.map(i => {
      let parent = {
        id: i,
        name: i,
        color: this.colors[index]
      };
      index++;
      return parent;
    })
  }

  initChart() {
    if (!this.selectedData || !this.selectedData.data) {
      console.log("Missing treemap data", this.selectedData)
      return;
    }
    this.setColors();
    this.loaded = false;

    this.series = [{
      type: "treemap",
      layoutAlgorithm: 'squarified',
      allowDrillToNode: true,
      animationLimit: 1000,
      dataLabels: {
        enabled: false
      },
      levels: [{
        level: 1,
        dataLabels: {
          enabled: true
        },
        borderWidth: 3,
        levelIsConstant: false
      }, {
        level: 1,
        dataLabels: {
          style: {
            fontSize: '14px'
          }
        }
      }],
      data: [
        ...this.parents,
        ...this.filteredData.data
      ]
    }];
    this.chartOptions = {
      chart: {
        height: '630px'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        formatter: function () {
          let point: any = this.point;
          let children = point.node && point.node.children.length ? point.node.children.length : null;
          if (children) {
            return point.node.id + ': ' + point.node.children.length; // level 1 tooltip
          } else {
            return point.company + ': ' + point.realValue + '%'; // level 2 tooltip
          }
        }
      },
      legend: {
        enabled: true,
      },
      series: this.series,
      plotOptions: {
        treemap: {
          dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'middle',
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              textShadow: false,
              textOutline: 'none'
            }
          },
          levels: [
            {
              level: 0,
              dataLabels: {
                enabled: true,
                align: 'center',
                verticalAlign: 'middle',
                style: {
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textShadow: false,
                  textOutline: 'none'
                }
              }
            },{
            level: 1,
            dataLabels: {
              enabled: true,
              align: 'center',
              verticalAlign: 'middle',
              style: {
                fontSize: '16px',
                fontWeight: 'bold',
                textShadow: false,
                textOutline: 'none'
              }
            }
          }],
          opacity: 1
        }
      }
    };

    if (this.fullHeight) {
      this.chartOptions.chart.height = '96%';
    }
    if (this.extraOptions) {
      this.chartOptions = {
        ...this.chartOptions,
        ...this.extraOptions
      };
    }
    setTimeout(() => this.loaded = true, 10);
  }
}
