import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageMeta} from "../../../../../../shared/models/page.model";

@Component({
  selector: 'app-new-portfolio-search',
  templateUrl: './new-portfolio-search.component.html'
})
export class NewPortfolioSearchComponent implements OnInit {

  @Input() model: any;

  @Output() modelChange = new EventEmitter<any>();

  @Output() fetchCompanies: EventEmitter<any> = new EventEmitter<any>();

  @Input() meta: PageMeta;

  constructor() {
  }

  onFetchCompanies() {
    this.fetchCompanies.emit();
  }

  ngOnInit(): void {
  }

}
