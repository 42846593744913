import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProfileService} from "../../../shared/services/profile.service";
import {PortfolioModel} from "../../../shared/models/portfolio.model";
import {UsersService} from "../../users/users.service";
import {AuthService} from "../../../shared/services/auth.service";
import {PortfolioService} from "../portfolio.service";

@Component({
  selector: 'app-portfolio-share-dialog',
  templateUrl: './portfolio-share-dialog.component.html',
  styleUrls: ['./portfolio-share-dialog.component.scss']
})
export class PortfolioShareDialogComponent implements OnInit {
  currentUser: any = null;
  portfolio: PortfolioModel;
  selected: any[] = [];
  users: any[] = [];

  filterUser: any;

  constructor(
    public dialogRef: MatDialogRef<PortfolioShareDialogComponent>,
    private service: PortfolioService,
    private userService: UsersService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.portfolio) {
      this.portfolio = new PortfolioModel(this.data.portfolio);
    }
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.auth.getCurrentUser().subscribe((resp) => {
      if (resp) {
        this.currentUser = resp;
        this.fetchUsers();
      }
    })
  }

  fetchUsers() {
    this.service.getShareTargets(this.portfolio.id).subscribe((resp: any) => {
      if (resp.length) {
        let selectedIds = [];
        if (this.portfolio.shared_with_users) {
          selectedIds = this.portfolio.shared_with_users.map((item: any) => item.id);
        }
        this.users = resp;
        this.users = this.users.filter(item => !selectedIds.includes(item.id));
      }
    })
  }

  onUserSelect(user) {
    this.filterUser = null;
    this.selected.push(user);
    this.users = this.users.filter(u => u.id !== user.id);
  }

  onUserRemove(user) {
    if (!this.portfolio.isShared()) {
      this.selected = this.selected.filter(u => u.id !== user.id);
      this.users.push(user);
    } else {
      this.service.unshareItem(this.portfolio.id, [user.id])
        .subscribe(resp => {
          this.service.notificationService.open('Share removed');
          this.portfolio = new PortfolioModel(resp);
          // this.selected = this.portfolio.shared_with_users;
        });
    }
  }

  onNoClick(): void {
    this.dialogRef.close({portfolio: this.portfolio});
  }

  onShareClicked() {
    const userIDs = this.selected.map(u => u.id);
    if (userIDs.length) {
      this.service.shareItem(this.portfolio.id, userIDs).subscribe((resp: any) => {
        if (resp.error) {
          return this.service.notificationService.open(resp.error);
        }
        this.service.notificationService.open('Portfolio shared');
        this.portfolio = new PortfolioModel(resp);
        this.dialogRef.close({shared: true, portfolio: resp});
      });
    }
  }
}
