import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {return value; }
    if (!args) {return value.map(i => {i.hidden = false; return i}); }

    return value.map((item) => {
      const text = args.toLowerCase();
      const matchFound = item.name ? item.name.toLowerCase().includes(text) : item.toLowerCase().includes(text);
      item.hidden = !matchFound;
      return item;
    });
  }

}
