import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})

export class HighlightPipe implements PipeTransform {

  highlightText(text, searchText) {
    searchText = searchText.trim().toLowerCase();
    const startIndex = text.toLowerCase().indexOf(searchText.trim().toLowerCase());
    if (startIndex !== -1) {
      const matchingString = text.substr(startIndex, searchText.length);
      return text.replaceAll(matchingString, "<mark>" + matchingString + "</mark>");
    }
    return text;
  }

  transform(value: string, args: string): any {
    if (args && value) {
      value = String(value);

      let searched = args.toLowerCase();

      if (searched.includes(',')) {
        let searchList = searched.split(',');
        searchList.map(s => {
          value = this.highlightText(value, s);
        })
      } else {
        value = this.highlightText(value, args);
      }
    }
    return value;
  }
}
