import {AfterContentInit, AfterViewInit, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PortfolioService} from "../../pages/portfolio/portfolio.service";
import * as moment from "moment";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ColumnType} from "../cim-datatable/cim-datatable.component";

@Component({
  selector: 'app-fund-info-dialog',
  templateUrl: './fund-info-dialog.component.html',
  styleUrls: ['./fund-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('100ms ease')),
    ]),
  ]
})
export class FundInfoDialogComponent implements OnInit {
  closeAdjData: any = [];

  introExpanded = true;
  sankeyExpanded = true;
  valuationExpanded = true;
  incomeExpanded = true;
  balanceExpanded = true;
  employeesNum = '-';

  incomeStatementColumns: any[] = [];
  incomeStatementData: any[] = [];
  incomeDataLoaded = false;

  balanceColumns: any[] = [];
  balanceData: any[] = [];

  introSection = [
  ];

  sections: any[] = [
  ];

  companyTicker = null;
  companyName = '';
  overview: any = {};
  isData: any = null;
  resp: any = null;
  styles: any = {
    1: 'Large Value',
    2: 'Large Blend',
    3: 'Large Growth',
    4: 'Mid Value',
    5: 'Mid Blend',
    6: 'Mid Growth',
    7: 'Small Value',
    8: 'Small Blend',
    9: 'Small Growth',
  }
  feeLevel: any = {
    'low': 'Low',
    'belowAvg': 'Below Average',
    'avg': 'Average',
    'aboveAvg': 'Above Average',
    'high': 'High',
  }

  extraOptions = {
    tooltip: {
      xDateFormat: '%Y-%m-%d',
      valueSuffix: ''
    },
    rangeSelector: {
      buttons: [{
        type: 'month',
        count: 1,
        text: '1m',
        title: 'View 1 month'
      },
        {
          type: 'month',
          count: 3,
          text: '3m',
          title: 'View 3 months'
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
          title: 'View 6 months'
        },
        {
          type: 'ytd',
          text: 'YTD',
          title: 'View year to date'
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
          title: 'View 1 year'
        },
        {
          type: 'year',
          count: 3,
          text: '3y',
          title: 'View 3 years'
        },
        {
          type: 'year',
          count: 5,
          text: '5y',
          title: 'View 5 years'
        },
        {
          type: 'year',
          count: 10,
          text: '10y',
          title: 'View 10 years'
        },
        {
          type: 'all',
          text: 'All',
          title: 'View all'
        }]
    },
  };

  constructor(
    public dialogRef: MatDialogRef<FundInfoDialogComponent>,
    private service: PortfolioService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.dialogRef.afterOpened().subscribe(() => {
      this.initDialog();
    });
  }

  initDialog() {
    if (this.data.company) {
  //    const currency = this.data.company.currency ? this.data.company.currency.code : 'USD';
   //   this.extraOptions.tooltip.valueSuffix = ` ${currency}`;
      this.fetchAdjChart();
     // this.fetchIs();
    }
  }

  fetchAdjChart() {
    this.service.getFundCloseAdj(this.data.company.id).subscribe((resp: any) => {
        this.companyName = resp.fund_reference.name;
       this.resp = resp.fund_reference;
             const currency = this.resp ? this.resp.BaseCurrency: '';
         this.extraOptions.tooltip.valueSuffix = ` ${currency}`;
      if (resp.items) {
        const closeAdj = [];
        resp.items.map((r: any) => {
          closeAdj.push([moment(`${r.date} 12:00:00`).unix() * 1000, r.close_adj]);
        });
        this.closeAdjData = [{
          name: this.companyName,
          data: closeAdj,
          tooltip: {
            valueDecimals: 2
          }
        }];
        this.incomeDataLoaded = true;
      }
    })
  }

  fetchIs() {
    this.service.getFundIs(this.data.company.security_id).subscribe((resp: any) => {
      if (resp.success) {
        this.isData = resp.success;
        let incomeData = {};
        let balanceData = {};
        let firstYear = null;
        let epsFormatted = `EPS (${this.extraOptions.tooltip.valueSuffix.trim()})`
        let dpsFormatted = `DPS (${this.extraOptions.tooltip.valueSuffix.trim()})`
        resp.success.map((e: any, index: number) => {
          let date = new Date(e.date);
          let year = date.getFullYear().toString();
          if (index === 0) {
            firstYear = year;
            this.employeesNum = e.ff_emp_num;
          }
          this.incomeStatementColumns.push({
            id: year,
            label: year,
            type: ColumnType.Number2Decimal
          });
          this.balanceColumns.push({
            id: year,
            label: year,
            type: ColumnType.Number2Decimal
          });

          incomeData[year] = {
            'Revenue': e.ff_sales,
            'Gross Income': e.ff_gross_inc,
            'EBITDA': e.ff_ebitda_oper,
            'EBIT': e.ff_ebit_oper,
            'Pretax profit': e.ff_ptx_inc,
            'Net income': e.ff_net_income,
            [epsFormatted]: e.ff_eps,
            [dpsFormatted]: e.ff_dps,
          };

          balanceData[year] = {
            'Total Assets': e.ff_assets,
            'Total Liabilities': e.ff_liabs,
            'Total Equity': e.ff_eq_tot,
          };
        })

        this.incomeStatementData = Object.keys(incomeData[firstYear]).map((msek) => ({
          msek,
          ...Object.keys(incomeData).reduce((acc, year) => {
            acc[year] = incomeData[year][msek];
            return acc;
          }, {}),
        }));

        this.balanceData = Object.keys(balanceData[firstYear]).map((msek) => ({
          msek,
          ...Object.keys(balanceData).reduce((acc, year) => {
            acc[year] = balanceData[year][msek];
            return acc;
          }, {}),
        }));

        this.incomeDataLoaded = true;
      }
    })
  }

  initIncomeEmptyData() {
    this.incomeStatementColumns.map((e: any) => {

    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onToggleAll(expand = true) {
    this.introExpanded = expand;
    this.valuationExpanded = expand;
    this.sankeyExpanded = expand;
    this.sections = this.sections.map(s => {
      s.expanded = expand;
      return s;
    });
  }
}
