import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'sysfilter',
  pure: false
})
export class SysFilterPipe implements PipeTransform {
  transform(items: any[]): any {
    if (!items) {
      return items;
    }
    return items.filter(item => item.id > 0);
  }
}
