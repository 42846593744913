import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { ViewModel } from '../models/view.model';
import { ApiEndpoints } from '../../ApiEndpoints';

@Injectable({
  providedIn: 'root'
})
export class ViewsService extends DataService {

  constructor(injector: Injector) {
    super(injector)
  }

  create(entity: ViewModel) {
    if (entity.id) {
      return this.putRequest(ApiEndpoints.portfolioViews, entity);
    } else {
      return this.postRequest(ApiEndpoints.portfolioViews, entity);
    }
  }

  delete(entity: ViewModel) {
    return this.deleteRequest(ApiEndpoints.portfolioViews, entity.id);
  }
}
