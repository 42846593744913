import {Component, Inject, Injector, Input, OnInit, Optional} from '@angular/core';
import {CompanyModel} from '../../../../../shared/models/company.model';
import {PortfolioService} from '../../../portfolio.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NewPortfolioCartDialogComponent} from "../new-portfolio-cart-dialog/new-portfolio-cart-dialog.component";

@Component({
  selector: 'app-new-portfolio-select-dialog',
  templateUrl: './new-portfolio-select-dialog.component.html',
  styleUrls: ['./new-portfolio-select-dialog.component.scss']
})
export class NewPortfolioSelectDialogComponent implements OnInit {

  @Input() list: any[] = [];
  selected: any[] = [];
  weightError = false;
  hasETF = false;
  data: any = null;
  dialogRef: MatDialogRef<NewPortfolioCartDialogComponent> = null;
  @Input() portfolio: any = null;
  @Input() isStep2: boolean = false;

  constructor(
      private service: PortfolioService,
      private injector: Injector
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.data = this.injector.get(MAT_DIALOG_DATA, null);
  }

  onNoClick(): void {
    this.selected = [];
    this.dialogRef.close();
  }

  ngOnInit(): void {
    console.log(this.data)
    if (this.data && this.data.list) {
      this.list = [...this.data.list];
      this.checkETF();
    }
    if (this.data && this.data.portfolio) {
      this.portfolio = this.data.portfolio;
    }
  }

  onSaveClick() {
    this.selected = [];
    this.dialogRef.close({list: this.list});
  }

  onRemoveClick() {
    this.list = this.list.filter(i => !i.markedForDelete);
    this.selected = [];
    this.recalculateWeight();
    this.checkETF();
  }

  onSelect(value, item) {
    this.selected = this.selected.includes(item) ? this.selected.filter(i => i !== item) : [ ...this.selected, item ];
    item.markedForDelete = value.checked;
  }

  recalculateMarketCapWeight() {
    let freeWeight = 100;

    if (this.hasETF) {
      freeWeight = 100 - this.portfolio.etfPercent;
      const selectedETFLength = this.list.filter(c => c.isETF()).length;
      this.list = this.list.map((company: CompanyModel) => {
        if (!company.isStock()) {
          company.weight = +(this.portfolio.etfPercent / selectedETFLength).toFixed(2);
        }
        return company;
      });
    }
    let totalMarketCap = 0;
    this.list.map((company: CompanyModel) => {
      if (company.isStock()) {
        totalMarketCap += +company.market_value_usd;
      }
    });
    this.list = this.list.map((company: CompanyModel) => {
      if (company.isStock()) {
        company.weight = +(+company.market_value_usd/totalMarketCap*freeWeight).toFixed(2);
      }
      return company;
    });
  }

  recalculateWeight() {
    if (this.portfolio.weighting_type === 'market_cap') { // market cap
      this.recalculateMarketCapWeight();
      return;
    }

    // recalculate equal weights
    let selectedStockLength = this.list.filter(c => c.isStock()).length + this.list.filter(c => c.isFund()).length;;
    let freeStockWeight = 100 - this.portfolio.etfPercent;

    let selectedETFLength = this.list.filter(c => c.isETF()).length;
    let freeETFWeight = this.portfolio.etfPercent;

    if (selectedStockLength || selectedETFLength) {
      this.list.map((company: CompanyModel) => {
        if (company.weightManuallyAdjusted) {
          if (company.isStock() || company.isFund()) {
            selectedStockLength -= 1;
            freeStockWeight -= company.weight;
          } else {
            selectedETFLength -= 1;
            freeETFWeight -= company.weight;
          }
        }
      });
      this.list = this.list.map((company: CompanyModel) => {
        if (!company.weightManuallyAdjusted) {
          if (company.isStock() || company.isFund()) {
            company.weight = +(freeStockWeight / selectedStockLength).toFixed(2);
          } else {
            company.weight = +(freeETFWeight / selectedETFLength).toFixed(2);
          }
        }
        return company;
      });
      this.weightError = freeStockWeight < 0 || freeETFWeight < 0;
    }

  }

  onWeightTypeChange() {
    if (this.portfolio.weighting_type === 'equal') { // equal
      this.list = this.list.map((company: CompanyModel) => {
        company.weightManuallyAdjusted = false;
        return company;
      });
      this.recalculateWeight();
    } else if (this.portfolio.weighting_type === 'market_cap') { // market cap
      this.recalculateMarketCapWeight()
    }
  }

  onWeightChange(company) {
    this.portfolio.weighting_type = 'manual';
    company.weightManuallyAdjusted = company.weight;
    this.recalculateWeight();
  }

  checkETF() {
    this.hasETF = this.service.hasETF(this.list);
  }
}
