import {Component, EventEmitter, Injector, Output, ViewChild} from '@angular/core';
import {
  PortfolioModel,
  VISIBILITY_PRIVATE,
  VISIBILITY_PUBLIC,
  VISIBILITY_SHARED
} from "../../../shared/models/portfolio.model";
import {PortfolioShareDialogComponent} from "../portfolio-share-dialog/portfolio-share-dialog.component";
import {PortfolioCreateComponent} from "../portfolio-create/portfolio-create.component";
import {AuthService} from "../../../shared/services/auth.service";
import {User} from "../../../shared/models/user.model";
import {
  AssetTableColumnVisibilityFilterComponent
} from '../asset-table-column-visibility-filter/asset-table-column-visibility-filter.component';
import * as moment from "moment/moment";
import {MatSnackBarRef, TextOnlySnackBar} from "@angular/material/snack-bar";
import {CompanyModel} from "../../../shared/models/company.model";

@Component({
  selector: 'app-portfolio-edit',
  templateUrl: './portfolio-edit.component.html'
})



export class PortfolioEditComponent extends PortfolioCreateComponent {
  portfolio: PortfolioModel;
  isPublic = false;
  publicToggle = false;
  isTutorial = false;
  tutorialToggle = false;

  currentUser: User = null;

  type = null;
  drawlerData: any = {};
  selectedFilters = {};

  filters = {};
  @ViewChild('drawer') drawer: any;
  @ViewChild('stockFilters') stockFilters: AssetTableColumnVisibilityFilterComponent;
  @Output() startDateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() startingDate: EventEmitter<any> = new EventEmitter<any>();


  constructor(injector: Injector, private auth: AuthService) {
    super(injector);
    this.storage.sideMenuObjectChange.subscribe((data) => {
      this.type = data.type;
      this.drawlerData = data;
      data.drawer && this.drawer && this.drawer.toggle();
    })
  }

  initPage() {

    this.auth.getCurrentUser().subscribe((resp) => {
      if (resp) {
        this.currentUser = new User(resp);
      }
    })

    const id = +this.route.snapshot.paramMap.get('id');
    this.getPortfolio(id);
  }

  getPortfolio(id?) {
    this.service.getItem(id).subscribe((resp: any) => {

      if (resp.item) {
        this.portfolio = new PortfolioModel(resp.item);
        this.onPortfolioLoaded(this.portfolio);

        if (!this.portfolio.isGenerated()) {
          this.selected = this.portfolio.securities;
          console.log(this.selected);
          this.sortSelectedList();
        }
        if (this.portfolio.currency_hedging.length) {
          this.extractCurrencyHedging();
        } else {
          this.updateSelectedCurrencyList();
        }
      }
    })
  }

  onPortfolioLoaded(portfolio) {
    this.portfolio = portfolio;
    this.isPublic = this.portfolio.isPublic();
    this.isTutorial = portfolio.is_tutorial == 1;
  }

  togglePublic() {
    this.isPublic = !this.isPublic;
    this.publicToggle = this.isPublic;
    this.portfolio.visibility = this.isPublic ? VISIBILITY_PUBLIC : VISIBILITY_PRIVATE;

    this.simpleSavePortfolio(`Portfolio is now ${this.isPublic ? 'public' : 'private'}`)
  }

  onTutorialToggle() {
    this.isTutorial = !this.isTutorial;
    this.tutorialToggle = this.isTutorial;
    this.portfolio.is_tutorial = this.isTutorial ? 1 : 0;

    if (this.isTutorial) {
      this.isPublic = true;
      this.publicToggle = true;
    } else {
      this.isPublic = false;
      this.publicToggle = false;
    }
    this.portfolio.visibility = this.isPublic ? VISIBILITY_PUBLIC : VISIBILITY_PRIVATE;

    this.simpleSavePortfolio(`Portfolio is now ${this.isTutorial ? 'tutorial' : 'private'}`)
  }

  simpleSavePortfolio(successMsg = 'Saved') {
    const hedging = this.portfolio.currency_hedging;
    delete this.portfolio.currency_hedging;
    this.service.saveItem(this.portfolio).subscribe(resp => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      this.portfolio.currency_hedging = hedging;
      this.service.notificationService.open(successMsg);
    })
  }


  unShare() {
    this.service.unshareItem(this.portfolio.id).subscribe((resp: any) => {
      this.togglePublic();
    })
  }

  onPublicToggle() {
    // Unshare before changing visibility
    if (this.portfolio.visibility === VISIBILITY_SHARED) {
      this.service.confirmAction().subscribe(confirm => {
        if (confirm) {
          this.unShare();
        } else {
          this.publicToggle = false;
        }
      })
    } else {
      this.togglePublic();
    }
  }

  onShareClick() {
    if (this.portfolio.isPublic()) {
      return false;
    }
    const dialogRef = this.dialog.open(PortfolioShareDialogComponent, {
      data: { portfolio: this.portfolio },
      maxWidth: '600px',
      autoFocus: false,
      width: '100%'
    });
    dialogRef.afterClosed().subscribe( (resp: any) => {
      if (resp) {
        this.portfolio = new PortfolioModel(resp.portfolio);
      }
    });
  }

  onUnshareClick() {
    this.service.unshareItem(this.portfolio.id).subscribe((resp: any) => {
      this.portfolio.visibility = VISIBILITY_PRIVATE;
    });
  }

  onMaxStartDateChange(date) {
    if (date) {
      this.max_start_date = date;
      if (!this.portfolio.start_date_fixed && !this.portfolio.start_date) {
        this.portfolio.start_date = date;
      }
      if (moment(date) > moment(this.portfolio.start_date)) {
        this.portfolio.start_date = date
      }
    }
  }
  onClickCart() {
    this.onPortfolioCartClick();
  }

  fetchCompaniesIn(filter?, page?, isPageEvent?) {
    let extraFilters: any = {
      page: page ? page : this.currentPage
    }
    if (filter) {
      if (filter.searchTerm) {
        extraFilters.search_term = filter.searchTerm;
      }
      if (filter.page) {
        extraFilters.page = filter.page;
      }
      // type is the etf/stock type
      if (filter.type) {
        extraFilters.type = filter.type;
        delete filter.type;
      }
      if (filter.limit) {
        extraFilters.limit = filter.limit;
        delete filter.limit;
      }
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp, isPageEvent);
    })
  }

  getFiltersIn(filter?) {
    let extraFilters: any = {}
    if (filter && filter.limit) {
      extraFilters.limit = filter.limit;
      delete filter.limit;
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp);
    })
  }
   handleCheckboxUpdate(id) {
    if(!this.selectedFilters[id]) {
      this.selectedFilters[id] = true;
      return;
    }
    this.selectedFilters[id] = !this.selectedFilters[id];
  }

  filterCompanies() {
    const ids = Object.keys(this.selectedFilters).filter((v) => +this.selectedFilters[v]);
    if(this.drawlerData.modelChanged) {
      this.drawlerData.modelChanged.emit(ids);
    } else {
      // clean the colums that are no longer used
      Object.keys(this.columnVisibility).forEach((v) => {
        if(this.columnVisibility[v] === false && document.getElementsByClassName(v)[0]) {
          (document.getElementsByClassName(v)[0].children[1] as any).click();
        }
      });
    }
  }

  onOpenedChange(event) {
    if(!event) {
      this.filterCompanies();
    }
  }


  onCompanySelect(selectInfo): MatSnackBarRef<TextOnlySnackBar> {
      const selectedIndex = this.isCompanyInSelected(selectInfo.company);
      if (selectInfo.isSelected) {
        // selected, add to list
        if (selectedIndex > -1) {
          return this.service.notificationService.open('Company already selected with security id: ' + selectInfo.company.security_id);
        }
        if (this.selected.length < 100) {
          selectInfo.company.selected = true;
          if (selectInfo.company.isStock()) {
            // Add stock to start of array
            this.selected.unshift(selectInfo.company);
          } else {
            // Add !stock to end of array
            this.selected.push(selectInfo.company);
          }
        } else {
          return this.service.notificationService.open(`You can select only ${this.selectLimit} assets.`);
        }
      } else {
        // deselected
        if (selectedIndex > -1) {
          this.selected.splice(selectedIndex, 1);
          selectInfo.company.selected = false;
          let maxDate = '1800-01-01';
          this.selected.map((l: CompanyModel) => {
            if (moment(l.p_first_date) > moment(maxDate)) {
              maxDate = l.p_first_date
            }

          })
          this.portfolio.start_date = maxDate;
        }
      }

      this.checkETF();
      if ((this.portfolio.weighting_type === 'equal') && this.hasETF) {
        this.setAllAssetEqual()
      } else {
        this.recalculateWeight()
      }
      this.updateSelectedCurrencyList();
      this.sortSelectedList();
  }
}
