import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompanyModel} from "../../../../../../shared/models/company.model";
import {UtilService} from "../../../../../../shared/services/util.service";

@Component({
  selector: 'app-new-portfolio-active-sorting',
  templateUrl: './new-portfolio-active-sorting.component.html',
  styleUrls: ['./new-portfolio-active-sorting.component.scss']
})
export class NewPortfolioActiveSortingComponent implements OnInit {

  @Input() selected: CompanyModel[];
  @Input() selectedFilterLabels: any;
  @Input() filter_label_length_limit: number;
  @Input() currentFilters: any;
  @Input() sorting: any;
  @Input() checked: boolean = false;

  @Input() onCheckChange = new EventEmitter<any>();

  @Output() deleteSortClick = new EventEmitter<any>();
  @Output() deleteDynamicFilterClick = new EventEmitter<any>();
  @Output() deleteFilterClick = new EventEmitter<any>();
  @Output() selectAllClick = new EventEmitter<any>();


  constructor(private util: UtilService) {
  }

  ngOnInit(): void {
  }

  sortingNiceName(key) {
    return this.util.sortingNiceName(key);
  }

  onToggle() {
    this.onCheckChange.emit({});
  }

  openFilter(s) {
    (document.getElementsByClassName(s.value[0].label_raw)[0].children[0] as any).click();
  }
  
  interval = null;
  moveRight() {
  
      this.interval = setInterval(function () {
        document.getElementById('asset-tbl').scrollLeft += 30;
      },100);
     
  }

  moveLeft() {
    this.interval = setInterval(function () {
     document.getElementById('asset-tbl').scrollLeft -= 30;

      },100);
  }
  clearInterval(){
    clearInterval(this.interval);
    this.interval = null;
  }

}
