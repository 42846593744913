<mat-toolbar>
    <h1 *ngIf="portfolio">Watchlist View - {{portfolio.name}}</h1>
</mat-toolbar>
<div class="card">
    <div class="card-body">
        <app-new-portfolio
                [list]="list"
                [selected]="selected"
                [filterLists]="filterLists"
                [meta]="meta"
                [hideStartDateText]="true"
                [priorityCurrencies]="priorityCurrencies"
                (nextClick)="onNextClick()"
                (getFilters)="getFiltersIn($event)"
                (fetchCompanies)="fetchCompaniesIn($event, 1)"
                (itemSelect)="onCompanySelect($event)"
                (selectAll)="onSelectAll($event)"
                (pageEvent)="onPageEvent($event)"
                (cartClick)="onPortfolioCartClick()"
                (startDateChange)="onMaxStartDateChange($event)"
                (fileUploaded)="onFileUploaded($event)"
        ></app-new-portfolio>
    </div>
</div>
