<table mat-table [dataSource]="dataSource" matSort [matSortDisabled]='sortDisabled' class="w-100 mat-small-table table-with-input table-hover">

    <ng-container *ngFor="let col of columns" matColumnDef="{{col.id}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="inputDisabled && col.canHide == true" [class.text-right]="col.type == 2 || col.type == 3 || col.textRight" >
            <span [class.percent-header]="col.type == 2" [class.bold]="col.bold">{{ col.label}}</span>
        </th>
        <td mat-cell *matCellDef="let element" [ngSwitch]="col.type" [hidden]="inputDisabled && col.canHide == true" [class.text-right]="col.type == 2 || col.type == 3 || col.textRight" [class.pr-4]="(col.type == 2 || col.type == 3 || col.textRight) && !disableRightPadding">
            <div *ngSwitchCase="1">{{element[col.id]}}</div>
            <div *ngSwitchCase="2">
                <span class="number-col" [class.bold]="col.bold">{{ element[col.id] !== null && element[col.id] !== 0 ? ((element[col.id]*100).toFixed(2)|number:'1.2-2') + '%' : '-'}}</span>
            </div>
            <div *ngSwitchCase="3">
                <span class="number-col" [class.bold]="col.bold">{{(element[col.id])|number:'1.0-2'}}<span *ngIf="col.suffix">{{col.suffix}}</span></span>
            </div>
            <div *ngSwitchCase="4">
                <mat-form-field class="max-100 table-input-suffix" appearance="outline">
                  <input matInput type="number" [(ngModel)]="element[col.id]" step="0.1" [disabled]="inputDisabled" (focus)="onInputFocus(element, col.id)" (ngModelChange)="onInputChange()">
                  <span matSuffix *ngIf="col.suffix">{{col.suffix}}</span>
                </mat-form-field>
            </div>
            <div *ngSwitchCase="5">
                <span class="underline-on-hover ml-2 text-truncate" (click)="onCompanyInfoClick(element)">{{element[col.id]}}</span>
            </div>
        </td>
        <ng-container *ngIf="footer">
            <td mat-footer-cell [class.text-right]="col.type == 2 || col.type == 3" [class.pr-4]="(col.type == 2 || col.type == 3) && !disableRightPadding" *matFooterCellDef> <span class="bold" [class.number-col] = "col.type == 2 || col.type ==3" >{{ footer[col.id]}}</span> </td>
        </ng-container>

    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnLabels"></tr>
    <tr mat-row *matRowDef="let row; columns: columnLabels;"></tr>
    <ng-container *ngIf="footer">
        <tr mat-footer-row *matFooterRowDef="columnLabels"></tr>
    </ng-container>>

</table>
<mat-paginator [hidden]="!needPaginator" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
