import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private service: MatSnackBar) { }

  open(message: string, closeLabel: string = 'X') {
    return this.service.open(message, closeLabel,  {
      duration: 3000,
      panelClass: 'cim-toastr',
      verticalPosition: 'top'
    });
  }

  invalidForm() {
    return this.open('Please fill the required fields!');
  }
}
