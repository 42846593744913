<div *ngIf="isLoaded"  class="card-container">

  <a class="header-back" [routerLink]="['/app']">
    <mat-icon inline=true fontSet="fa" fontIcon="fa-arrow-left" aria-label="back"></mat-icon>
    <span>My portfolios</span>
  </a>
 
  <a id="opt" >
    <mat-icon fontSet="fa" fontIcon="fa-cogs"  aria-label="save">> </mat-icon>
  </a>

  <mat-toolbar>
    <h1>Risk analysis</h1>
  </mat-toolbar>


<div class="pb-3">
<div class="card mb-3">
      <div class="col-md-12 flex">
        <div class="col-md-6">
            <mat-card>
                <div class="card h-100" style="height: 300px !important;">
                    <div class="card-header">Expected Risk/Return</div>
                    <div class="card-body">
                        <div class="info-section">
                            <div class="info-line">
                                <span class="info-title">Start date</span>
                                <span class="info-value">{{ portfolio.start_date }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">End date</span>
                                <span class="info-value">{{ portfolio.end_date }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Number of Assets</span>
                                <span class="info-value">{{ portfolio.securities ?  portfolio.securities.length : "-"}}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Expected return</span>
                                <span class="info-value"
                                      style="text-transform: capitalize">{{portfolio.performance_summary['Annualized Return']['Portfolio'] *100| number:'1.0-2' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Expected volatility</span>
                                <span class="info-value">{{ portfolio.performance_summary['Annualized Std Dev']['Portfolio'] *100| number:'1.0-2'  }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">Expected sharpe</span>
                                <span class="info-value">{{ portfolio.performance_summary['Annualized Sharpe (Rf=0%)']['Portfolio']|number:'1.2-2'}}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">VAR 95</span>
                                <span class="info-value"
                                      style="text-transform: capitalize">{{ portfolio.performance_summary['VaR']['Portfolio'] * 100|number:'1.2-2' }}</span>
                            </div>
                            <div class="info-line">
                                <span class="info-title">VAR 99</span>
                                <span class="info-value">{{ portfolio.performance_summary['VaR']['Portfolio'] * 100|number:'1.2-2' }}</span>
                            </div>
                            
                            </div>
                      </div>
             

            
            </div>
          </mat-card>
      </div>

    </div>
</div>



<div class="pb-3">
<div class="card mb-3">
  <div class="card-header">Risk/Return contribution</div>
  <div class="card-body">
      <mat-card>
          <mat-card-content>
            <section id="summary-risk-contribution-chart">
              <div>
                  <app-risk-contribution-column-chart 
                                                      [riskContributionRaw]="riskContributionData"
                                                      [subHeader]="{portfolio: portfolio.name}">
                  </app-risk-contribution-column-chart>
              </div>
            </section>
          </mat-card-content>
        </mat-card>
  </div>
</div>

<div class="card mb-3">
  <div class="card-header">Covariance matrix</div>
  <div class="card-body">
      <mat-card>
          <mat-card-content>
            <section id="risk-covarianceMatrixTableData">
              <app-heatmap-table *ngIf="covarianceMatrixTableData" [dataRaw]="covarianceMatrixTableData" [allowedTickers]="allowedTickers"></app-heatmap-table>
            </section>
          </mat-card-content>
        </mat-card>
  </div>
</div>


<div class="card mb-3">
  <div class="card-header">Correlation matrix</div>
  <div class="card-body">
      <mat-card>
          <mat-card-content>
            <section id="summary-marginal-risk-contribution-chart">
              <app-heatmap-table *ngIf="correlationMatrixTableData" [dataRaw]="correlationMatrixTableData" [allowedTickers]="allowedTickers"></app-heatmap-table>
            </section>
          </mat-card-content>
        </mat-card>
  </div>
</div>

<div class="card mb-3">
  <div class="card-header">Hot spots</div>
  <div class="card-body">
      <mat-card>
          <mat-card-content>
            <section id="summary-marginal-risk-contribution-chart">
              <app-heatmap-chart *ngIf="weightPieData" [dataRaw]="weightPieData"></app-heatmap-chart>
            </section>
          </mat-card-content>
        </mat-card>
  </div>
</div>

<div class="card mb-3">
  <div class="card-header">Best hedge</div>
  <div class="card-body">
      <mat-card>
          <mat-card-content>
            <section id="summary-marginal-risk-contribution-chart">
              <app-heatmap-table [dataRaw]="correlationMatrixData"></app-heatmap-table>
            </section>
          </mat-card-content>
        </mat-card>
  </div>
</div>

<div class="card mb-3">
  <div class="card-header">Drawdown</div>
  <div class="card-body">
      <mat-card>
          <mat-card-content>
            <section id="risk-drawdown">
             <app-line-chart  [series]="comboData" [extraOptions]="comboExtraOptions"></app-line-chart>
            </section>
          </mat-card-content>
        </mat-card>
  </div>
</div>

<div class="card mb-3">
  <div class="card-header">Volatility</div>
  <div class="card-body">
      <mat-card>
          <mat-card-content>
            <section id="risk-drawdown">
             <app-line-chart  [series]="volatilityData.series" [extraOptions]="drawDownExtraOptions"></app-line-chart>
            </section>
          </mat-card-content>
        </mat-card>
  </div>
</div>
<!-- 
<div class="row mb-3">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">Annualized Volatility (63d rolling)</div>
      <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
      <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
      <div class="card-body">
        <app-line-chart [enableDatawrapper]="true" [series]="volatilityData.series" [extraOptions]="drawDownExtraOptions"></app-line-chart>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">Drawdown & Performance</div>
      <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
      <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
      <div class="card-body">
        <app-line-chart *ngIf="comboData" [chartStart]="comboDates.start" [chartEnd]="comboDates.end" [enableDatawrapper]="true" [series]="comboData" [extraOptions]="comboExtraOptions" (afterSetExtremes)="onComboChartDateChange($event)"></app-line-chart>
      </div>
    </div>
  </div>
</div> -->
<!-- 

<div class="row mb-3">
  <div class="col-md-6">
    <app-weight-pie-chart *ngIf="pieDataList" [lists]="pieDataList" [subHeader]="{portfolio: portfolio.name, type: selectedEvaluationNiceName}"></app-weight-pie-chart>
  </div>

  <div class="col-md-6">
      <div class="card">
        <div class="row">
          <div class="col-md-6">
            <div class="card-header pb-0">Diversification Effect</div>
            <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
            <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
          </div>
          <div class="col-md-6" *ngIf="!isPrint">
            <div class="float-right pt-3 pr-3">
              <mat-form-field appearance="outline" class="header-select ">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="selectedDiversification" (ngModelChange)="initDiversificationChart()">
                  <mat-option [value]="'volatilty'">Volatilty</mat-option>
                  <mat-option [value]="'return'">Return</mat-option>
                  <mat-option [value]="'sharpe'">Sharpe</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <app-line-chart *ngIf="loaded" [series]="riskDiversificationData.series" [extraOptions]="chart3Options" [chartConstructor]="'chart'"></app-line-chart>
        </div>
      </div>
  </div>
</div>
<div class="mb-3">
  <app-evaluation-charts *ngIf="loaded" [riskContributionRaw]="riskContributionData" [correlationBetaRaw]="correlationBetaData" [subHeader]="{portfolio: portfolio.name, type: selectedEvaluationNiceName}"></app-evaluation-charts>
</div>

  <div class="card">
    <div class="card-header pb-0">Marginal Risk Contribution</div>
    <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
    <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
    <div class="card-body pt-0">
      <mat-form-field appearance="outline" class="header-select" style="position: absolute; top: 16px; right: 30px;">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="riskContributionType" (ngModelChange)="initRiskContributionCategoryChart()">
          <mat-option [value]="'sector_fs'">Sector</mat-option>
          <mat-option [value]="'country_hq_name'">Country</mat-option>
        </mat-select>
      </mat-form-field>
      <app-line-chart *ngIf="loaded" [series]="riskElasticityData" [extraOptions]="riskElasticityOptions" [chartConstructor]="'chart'"></app-line-chart>
    </div>
  </div>
</div>

</div> -->