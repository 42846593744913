<div class="pagination-container">
      <button mat-stroked-button (click)="onNextClick()" [disabled]="!afters.length">Load more</button>

    <p class="pagination-info">Showing {{meta.to}} / {{meta.total}}
<!--      <br> <mat-progress-bar mode="determinate" value="{{ (meta.to / meta.total) * 100 }}"></mat-progress-bar>-->
    </p>

<!--  <button mat-icon-button (click)="onFirstClick()" [disabled]="!befores.length"><mat-icon>first_page</mat-icon></button>-->
<!--  <button mat-icon-button (click)="onPrevClick()" [disabled]="!befores.length"><mat-icon>navigate_before</mat-icon></button>-->
<!--    &lt;!&ndash;<div class="pag-pages">-->
<!--        <span *ngIf="meta.current_page > pages">...</span>-->
<!--        <span *ngFor="let p of befores" (click)="onPageEvent(p)">{{p}}</span>-->
<!--        <span class="current-page">{{meta.current_page}}</span>-->
<!--        <span *ngFor="let p of afters" (click)="onPageEvent(p)">{{p}}</span>-->
<!--        <span *ngIf="meta.last_page - pages > meta.current_page">...</span>-->
<!--    </div>&ndash;&gt;-->
<!--    <button mat-icon-button (click)="onNextClick()" [disabled]="!afters.length"><mat-icon>navigate_next</mat-icon></button>-->
<!--    <button mat-icon-button (click)="onLastClick()" [disabled]="!afters.length"><mat-icon>last_page</mat-icon></button>-->
</div>

