<mat-form-field [appearance]="(isInTableHeader ? 'standard' : 'outline')" [ngClass]="{'w-100': isFullWidth}">
    <mat-label *ngIf="label && !isInTableHeader">{{label}} <small *ngIf="unit">({{unit}})</small> <span *ngIf="isMultiple && model && model.length">({{model.length}})</span></mat-label>
    <mat-select
            [(ngModel)]="model"
            [required]="isRequired"
            [multiple]="isMultiple"
            [disabled]="isDisabled"
            [placeholder]="labelPrefix + label"
            (openedChange)="searchText = ''"
            (ngModelChange)="onModelChange()"
            panelClass="cim-select"
    >
        <mat-select-trigger *ngIf="isInTableHeader">
            {{label}} <span *ngIf="model?.length > 0">({{model.length}})</span>
        </mat-select-trigger>
        <input placeholder="Search" matInput [(ngModel)]="searchText" class="select-input-search">
        <div class="selection-container">
            <button type="button" mat-button color="primary" (click)="selectAll()">Select all</button>
            <button type="button" mat-button color="primary" (click)="deselectAll()">Deselect all</button>
        </div>
        <mat-option *ngFor="let item of list | search: searchText" [hidden]="item.hidden == true || (hideMarked && item.marked == true)" [value]="item.id">{{item.name}}</mat-option>
    </mat-select>
</mat-form-field>
