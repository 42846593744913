<button mat-icon-button class="dialog-close-btn" (click)="onNoClick()"><mat-icon>close</mat-icon></button>
<h1 mat-dialog-title>
    Portfolio select
</h1>
<div mat-dialog-content>
    <app-portfolio-table *ngIf="lists.private.length" title="My Portfolios" [visibleShareUser]="false" [list]="lists.private" [readOnly]="true" (portfolioSelected)="onNoClick()"></app-portfolio-table>
    <app-portfolio-table *ngIf="lists.shared_by_me.length" title="Shared portfolios" [visibleShareUser]="false" [list]="lists.shared_by_me" [readOnly]="true" (portfolioSelected)="onNoClick()"></app-portfolio-table>
    <app-portfolio-table *ngIf="lists.public.length" title="Public portfolios" [visibleShareUser]="false" [list]="lists.public" [readOnly]="true" (portfolioSelected)="onNoClick()"></app-portfolio-table>
    <app-portfolio-table *ngIf="lists.shared_with_me.length" title="Portfolios shared with me" [visibleShareUser]="false" [readOnly]="true" (portfolioSelected)="onNoClick()"></app-portfolio-table>
</div>
