export class PageMeta {
  public current_page?: number;
  public last_page?: number;
  public total?: number;
  public from?: number;
  public to?: number;

  constructor(data?: any) {
    if (data) {
      if (data.meta) {
        this.current_page = data.meta.current_page;
        this.last_page = data.meta.last_page;
        this.total = data.meta.total;
        this.from = data.meta.from;
        this.to = data.meta.to;
      }
      if (data.current_page) {
        this.current_page = data.current_page;
      }
      if (data.last_page) {
        this.last_page = data.last_page;
      }
      if (data.total) {
        this.total = data.total;
      }
      if (data.from) {
        this.from = data.from;
      }
      if (data.to) {
        this.to = data.to;
      }
    }
  }
}
