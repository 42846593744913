export class Filters {
  static readonly stockFilters = [
    {
      title: 'Reference data',
      items: [
        {
          id: 'fs_ticker',
          name: 'Ticker',
          shortName: 'Ticker',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: true,
          class: 'code'
        },
        {
          id: 'isin',
          name: 'ISIN',
          shortName: 'ISIN',
          unit: '',
          decimalInfo: null,
          visible: true,
          default: true,
          class: 'code'
        },
        
        {
          id: 'region',
          name: 'Region',
          shortName: 'Region',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: true,
        },
        {
          id: 'country',
          name: 'Country',
          shortName: 'Country',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: true,
        },
        {
          id: 'sector',
          name: 'Sector',
          shortName: 'Sector',
          unit: '',
          decimalInfo: null,
          visible: true,
          default: true,
        },
        {
          id: 'industry',
          name: 'Industry',
          shortName: 'Industry',
          unit: '',
          decimalInfo: null,
          visible: true,
          default: true,
        },
        {
          id: 'currency',
          name: 'Currency',
          shortName: 'Currency',
          unit: '',
          decimalInfo: null,
          visible: true,
          default: true,
        },
        {
          id: 'exchange_code_fs',
          name: 'Exchange',
          shortName: 'Exchange',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: true,
        },
        {
          name: 'Latest Annual Report',
          shortName: 'Fiscal year',
          id: 'report_date',
          unit: '',
          decimalInfo: null,
          visible: false,
          class: 'date'

        },{
          name: 'Number of Employees',
          shortName: 'Employees',
          id: 'ff_emp_num',
          unit: '',
          decimalInfo: '1.0-0',
          visible: false,
          class: 'int'
        },
        {
          name: 'Number of Shares Outstanding',
          shortName: 'Shares',
          id: 'ff_com_shs_out',
          unit: 'M',
          decimalInfo: '1.0-0',
          visible: false,
          class: 'int'
        },
        {
          id: 'startDate',
          name: 'Earliest Price Data Point',
          shortName: 'Hist. Date',
          unit: '',
          decimalInfo: null,
          visible: true,
          default: true,
          class: 'date'
        }
      ]
    },
    {
      title: 'Valuation/Ratios',
      items: [
        {
          id: 'marketCap',
          name: 'Market Capitalization (MCap)',
          shortName: 'MCap',
          unit: 'USD',
          decimalInfo: '1.0-0',
          visible: false,
          default: true,
          class: "number"
        },
        {
          name: 'Enterprise Value (EV)',
          shortName: 'EV',
          id: 'ff_entrpr_val',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          lass: "number"
        },
        {
          name: 'Price to Earnings ratio (P/E))',
          shortName: 'P/E',
          id: 'ff_pe',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          lass: "number"
        },
        {
          id: 'ff_psales',
          shortName: 'P/S',
          name: 'Price to Sales ratio (P/S)',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          lass: "number"
        },
        {
          id: 'ff_pbk',
          shortName: 'P/B',
          name: 'Price to Book ratio (P/B)',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          lass: "number"
        },
        {
          id: 'ff_pfcf',
          name: 'Price to Free Cash Flow (P/FCF)',
          shortName: 'P/FCF',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },
        {
          id: 'ff_roe',
          name: 'Return on Average Total Equity (ROE)',
          shortName: 'ROE',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },
        {
          id: 'ff_roic',
          name: 'Return on Average Invested Capital (ROIC)',
          shortName: 'ROIC',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },
        {
          id: 'ff_roce',
          name: 'Return on Common Equity (ROCE)',
          shortName: 'ROCE',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },
        {
          id: 'ff_rotc',
          name: 'Return on Average Total Capital (ROTC)',
          shortName: 'ROTC',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },
        {
          name: 'Enterprise Value to Sales (EV/S)',
          shortName: 'EV/S',
          id: 'ff_entrpr_val_sales',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },
        {
          name: 'Enterprise Value to EBITDA (EV/EBITDA)',
          shortName: 'EV/EBITDA',
          id: 'ff_entrpr_val_ebitda_oper',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },
        {
          name: 'Enterprise value to EBIT (EV/EBIT)',
          shortName: 'EV/EBIT',
          id: 'ff_entrpr_val_ebit_oper',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },
        {
          name: 'Debt/EBITDA',
          shortName: 'Debt/EBITDA',
          id: 'ff_debt_ebitda_oper',
          unit: '',
          decimalInfo: '1.1-1',  
          visible: false,
          class: "number"
        },
        {
          name: 'Altman Z-Score',
          shortName: 'A. Z-Score',
          id: 'ff_zscore',
          unit: '',
          decimalInfo: '1.1-1',
          visible: false,
          class: "number"
        },{
          name: 'Piotroski F-Score',
          shortName: 'P. F-Score',
          id: 'ff_fscore',
          unit: '',
          decimalInfo: null,
          visible: false,
          class: "number"
        }
      ]
    },
    {
      title: 'Fundamentals',
      items: [
        {
          name: 'Revenue',
          shortName: 'Revenue',
          id: 'ff_sales',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Gross Income',
          shortName: 'Gross Inc.',
          id: 'ff_gross_inc',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          id: 'ebitda_usd',
          name: 'EBITDA',
          shortName: 'EBITDA',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Operating Income (EBIT)',
          shortName: 'EBIT',
          id: 'ff_ebit_oper',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          id: 'ff_ptx_inc',
          name: 'Pretax Income',
          shortName: 'Pretax',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Total Net Income',
          shortName: 'Net Inc.',
          id: 'ff_consol_net_inc',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Net Income Attributable to Shareholders',
          shortName: 'Net Inc. to SH',
          id: 'ff_net_income',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Earnings Per Share (EPS)',
          shortName: 'EPS',
          id: 'ff_eps',
          unit: '$',
          decimalInfo: '1.2-2',
          visible: false,
          class: "number"
        },
        {
          name: 'Dividend Per Share (DPS)',
          shortName: 'DPS',
          id: 'ff_dps',
          unit: '$',
          decimalInfo: '1.2-2',
          visible: false,
          class: "number"
        },
        {
          name: 'Net Operating Cash Flow (NOCF)',
          shortName: 'NOCF',
          id: 'ff_oper_cf',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Capital Expenditures',
          shortName: 'Capex',
          id: 'ff_capex',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Total Assets',
          shortName: 'Assets',
          id: 'ff_assets',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Liabilities',
          shortName: 'Liabilities',
          id: 'ff_liabs',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Total Equity',
          shortName: 'Equity',
          id: 'ff_eq_tot',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Gross Debt',
          shortName: 'Gross debt',
          id: 'ff_debt',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        },
        {
          name: 'Net Debt',
          shortName: 'Net Debt',
          id: 'ff_net_debt',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          class: "number"
        }

      ]
    },
    {
      title: 'Margins',
      items: [
        {
          name: 'Gross Income Margin',
          shortName: 'Gross inc.',
          id: 'ff_gross_mgn',
          unit: '%',
          decimalInfo: '1.1-1',
          visible: false,
          class: 'percent'
        },
        {
          name: 'EBITDA Margin',
          shortName: 'EBITDA',
          id: 'ff_ebitda_oper_mgn',
          unit: '%',
          decimalInfo: '1.1-1',
          visible: false,
          class: 'percent'
        },
        {
          name: 'EBIT Margin',
          shortName: 'EBIT',
          id: 'ff_ebit_oper_mgn',
          unit: '%',
          decimalInfo: '1.1-1',
          visible: false,
          class: 'percent'
        },
        {
          name: 'Net Income Margin',
          shortName: 'Net inc.',
          id: 'ff_net_mgn',
          unit: '%',
          decimalInfo: '1.1-1',
          visible: false,
          class: 'percent'
        },
        {
          name: 'Dividend Yield',
          shortName: 'Div. Yld.',
          id: 'ff_div_yld',
          unit: '%',
          decimalInfo: '1.1-1',
          visible: false,
          class: 'percent'
        }
      ]
    }

  ];
  static readonly stockFiltersIndexes = {
   
  };

  static readonly etfFilters = [
    
    {
      title: 'Reference data',
      items: [
        {
          id: 'isin',
          name: 'ISIN',
          shortName: 'ISIN',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: true,
          class: 'code'
        },
        {
          id: 'name',
          name: 'Name',
          shortName: 'Name',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: true,
          class: 'code'
        },
        ]
    }

  ];
  static readonly etfFiltersIndexes = {
   
  };

  static readonly fundFilters = [

    {
      title: 'Reference data',
      items: [
        {
          id: 'isin',
          name: 'ISIN',
          shortName: 'ISIN',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: false,
          class: 'code'
        },
        {
          id: 'BaseCurrency',
          name: 'Base Currency',
          shortName: 'Currency',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: false
        },
        {
          id: 'BrandingCompanyName',
          name: 'Fund Company Name',
          shortName: 'Company',
          dounble: true,
          visible: false,
          default: false
        },
        {
          id: 'DomicileName',
          name: 'Fund Domicile',
          shortName: 'Domicile',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: false
        },
        {
          id: 'GlobalCategoryName',
          name: 'Global Category',
          shortName: 'Global Category',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: false
        },
        {
          id: 'LocalCategoryName',
          name: 'Local Category',
          shortName: 'Local Category',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: false
        },
        {
          id: 'EquityStyleBox',
          name: 'Equity StyleBox',
          shortName: 'StyleBox',
          visible: false,
          default: false
        },
        {
          id: 'FundTNAVDayEnd',
          name: 'Assets under Management',
          shortName: 'AUM',
          unit: 'M$',
          decimalInfo: '1.0-0',
          visible: false,
          default: false
        },
        {
          id: 'MorningStarRating',
          name: 'Morningstar Rating',
          shortName: 'Rating',
          visible: false,
          default: false
        },
        {
          id: 'FeeLevel',
          name: 'Fee Level',
          shortName: 'Fee Level',
          visible: false,
          default: false
        },
        {
          id: 'UCITS',
          name: 'UCITS',
          shortName: 'UCITS',
          unit: '',
          visible: false,
          default: false
        },
        {
          id: 'LegalStructure',
          name: 'Legal Structure',
          shortName: 'Legal Structure',
          unit: '',
          decimalInfo: null,
          double: true,
          visible: false,
          default: false
        },
        {
          id: 'FundOfFunds',
          name: 'Fund of Funds',
          shortName: 'Fund of Funds',
          unit: '',
          visible: false,
          default: false
        },
        {
          id: 'FundInceptionDate',
          name: 'Fund Inception Date',
          shortName: 'Inception Date',
          unit: '',
          decimalInfo: null,
          visible: false,
          default: false,
          class: 'date'
        }
      ]
    },
    {
      title: 'Performance Valuation',
      items: [
        {
          id: 'AverageMarketCapital',
          name: 'Average Market Capital',
          shortName: 'Mcap',
          unit: 'M$',
          decimalInfo: '1.0-0',
          textRight: true,
          visible: false,
          class: 'int'
        },
        {
          id: 'PERatio',
          name: 'Price/Earnings Ratio',
          shortName: 'P/E',
          unit: '',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        {
          id: 'PBRatio',
          name: 'Price/Book Ratio',
          shortName: 'P/B',
          unit: '',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        {
          id: 'ReturnM1',
          name: 'Return 1 month',
          shortName: '1m',
          unit: '%',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        {
          id: 'ReturnM3',
          name: 'Return 3 months',
          shortName: '3m',
          unit: '%',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        {
          id: 'ReturnM0',
          name: 'Return Year to Date',
          shortName: 'YTD',
          unit: '%',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        {
          id: 'ReturnM12',
          name: 'Return 1 Year',
          shortName: '1Yr',
          unit: '%',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        {
          id: 'ReturnM36UA',
          name: 'Return 3 Years',
          shortName: '3Yr',
          unit: '%',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        {
          id: 'ReturnM60UA',
          name: 'Return 5 Years',
          shortName: '5Yr',
          unit: '%',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        {
          id: 'ReturnM120UA',
          name: 'Return 10 Years',
          shortName: '10Yr%',
          unit: '%',
          decimalInfo: '1.2-2',
          textRight: true,
          visible: false,
          lass: 'number'
        },
        ]
    },

// EquityStyleBox,
// Rating,
// StarRating,
// StarRatingM255,
// BaseCurrency,
// ClosePrice,
// FundId,
// FundInceptionDate,
// ,
// FundOfFunds,
// FundShareClassId,
// FundTNAVDayEnd,
// FundTNAVDayEndDate,
// GlobalCategoryId,
// GlobalCategoryName,
// LegalStructureName,
// PBRatio,
// PERatio,
// UCITS,
// BrandingCompanyName,
// DomicileName,
// FeeLevel,
// ,
// NAVReturnM6,
// NAVReturnM60,
// 52WeekHigh,
// 52WeekLow,
// AverageMarketCapital

  ];
  static readonly fundFiltersIndexes = {
   
  };
}
