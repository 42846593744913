<button mat-icon-button class="dialog-close-btn" (click)="onNoClick()">
    <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title *ngIf="companyName">
    Company Tear Sheet - {{ companyName || companyTicker }}
</h1>
<div mat-dialog-content>
    <app-line-chart [series]="closeAdjData" [extraOptions]="extraOptions"></app-line-chart>

    <div>
        <h3 class="summary-header">Description</h3>
        <div class="overflow-hidden" *ngIf="isData">
            <p class="overview-text mt-3">{{ overview['description'] }}</p>
            <app-info-block [list]="introSection"
                            [overview]="overview"
                            [company]="data.company"
                            [employeeNum]="employeesNum"
                            [isData]="isData"
            ></app-info-block>
        </div>
    </div>

    <div>
        <h3 class="summary-header">Valuation/Ratios</h3>
        <div class="overflow-hidden" *ngIf="isData">
            <div class="col-md-12 flex">
                <div class="col-md-6">
                    <div class="info-section">
                        <div class="info-line">
                            <span class="info-title">Market Capitalization</span>
                            <span class="info-value">
                                {{ isData[0].currency}} {{ isData[0].ff_mcap | number: '1.0-0' }}M
                            </span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Net Debt</span>
                            <span class="info-value">
                                {{ isData[0].currency}} {{ isData[0].ff_net_debt | number: '1.0-0' }}M
                            </span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Price to Earnings ratio (P/E)</span>
                            <span class="info-value">{{ isData[0].ff_pe | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Price to Sales ratio (P/S)</span>
                            <span class="info-value">{{ isData[0].ff_psales | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Price to Book ratio (P/B)</span>
                            <span class="info-value">{{ isData[0].ff_pbk | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Price to Free Cash Flow (P/FCF)</span>
                            <span class="info-value">{{ isData[0].ff_pfcf | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Debt/EBITDA</span>
                            <span class="info-value">{{ isData[0].ff_debt_ebitda_oper | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Altman Z-Score</span>
                            <span class="info-value">{{ isData[0].ff_zscore | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Piotroski F-Score</span>
                            <span class="info-value">{{ isData[0].ff_fscore | number: '1.1-1' }}</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="info-section">
                        <div class="info-line">
                            <span class="info-title">Enterprise Value</span>
                            <span class="info-value">
                                {{ isData[0].currency}} {{ isData[0].ff_entrpr_val | number: '1.0-0' }}M
                            </span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Capital Expenditure</span>
                            <span class="info-value">
                                {{ isData[0].currency}} {{ isData[0].ff_capex | number: '1.0-0' }}M
                            </span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Return on Average Total Equity (ROE)</span>
                            <span class="info-value">{{ isData[0].ff_roe | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Return Average Invested Capital (ROIC)</span>
                            <span class="info-value">{{ isData[0].ff_roic | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Return on Common Equity (ROCE)</span>
                            <span class="info-value">{{ isData[0].ff_roce | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Return on Average Total Capital (ROTC)</span>
                            <span class="info-value">{{ isData[0].ff_rotc | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Enterprise Value to Sales (EV/S)</span>
                            <span class="info-value">{{ isData[0].ff_entrpr_val_sales | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Enterprise Value to EBITDA (EV/EBITDA)</span>
                            <span class="info-value">{{ isData[0].ff_entrpr_val_ebitda_oper | number: '1.1-1' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Enterprise Value to EBIT (EV/EBIT)</span>
                            <span class="info-value">{{ isData[0].ff_entrpr_val_ebit_oper | number: '1.1-1' }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div>
        <h3 class="summary-header cursor-pointer">Income Statement</h3>
        <div class="overflow-hidden">
            <app-cim-datatable *ngIf="incomeDataLoaded"
                               [columns]="incomeStatementColumns"
                               [data]="incomeStatementData"
                               [sortDisabled]="true"
                               [disableRightPadding]="true"
                               ></app-cim-datatable>
        </div>
    </div>

    <div class="mt-2">
        <h3 class="summary-header">Balance Sheet</h3>
        <div class="overflow-hidden">
            <app-cim-datatable *ngIf="incomeDataLoaded"
                               [columns]="balanceColumns"
                               [data]="balanceData"
                               [sortDisabled]="true"
                               [disableRightPadding]="true"
            ></app-cim-datatable>
        </div>
    </div>

    <!--  <h3 class="summary-header mt-3 cursor-pointer" *ngIf="data.company && data.company.rbics" (click)="sankeyExpanded = !sankeyExpanded">-->
    <!--    RBICS Revenue Distribution-->
    <!--    <span class="text-primary float-right ">{{sankeyExpanded ? 'Hide content' : 'Show content'}}<mat-icon class="align-bottom">{{sankeyExpanded ? 'expand_less' : 'expand_more'}}</mat-icon></span>-->
    <!--  </h3>-->
    <!--  <div *ngIf="data.company && data.company.rbics && closeAdjData && closeAdjData.length" [@detailExpand]="sankeyExpanded ? 'expanded' : 'collapsed'" class="overflow-hidden">-->
    <!--    <app-sankey-chart [rbics]="data.company.rbics" [companyName]="companyName"></app-sankey-chart>-->
    <!--  </div>-->

    <div *ngFor="let section of sections">
        <h3 class="summary-header mt-3 cursor-pointer" (click)="section.expanded = !section.expanded">
            {{ section.name }}
            <span class="text-primary float-right ">{{ section.expanded ? 'Hide content' : 'Show content' }}
                <mat-icon class="align-bottom">{{ section.expanded ? 'expand_less' : 'expand_more' }}</mat-icon></span>
        </h3>
        <div [@detailExpand]="section.expanded ? 'expanded' : 'collapsed'" class="overflow-hidden">
            <app-info-block [list]="section.list" [overview]="overview"></app-info-block>
        </div>
    </div>
    <div class="mt-4">
        <button mat-flat-button class="float-right" color="primary" (click)="onNoClick()"><span class="navigation-button">Close</span></button>
    </div>
</div>
