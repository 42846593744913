import {EventEmitter, Injectable} from '@angular/core';
import { AppComponent } from 'src/app/app.component';

class LocalStorage implements Storage {
  [name: string]: any;
  readonly length: number;
  clear(): void {}
  getItem(key: string): string | null {return undefined;}
  key(index: number): string | null {return undefined;}
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

@Injectable()
export class StorageService {
  public visibilityStorageKey = 'CimColumnVisibility';
  public columnPresetStorageKey = 'CimColumnPreset';
  public favoriteFiltersStorageKey = 'CimFavoriteFilters';

  private storage: Storage;

  public filterSelected: EventEmitter<any> = new EventEmitter<any>();
  public sideMenuObjectChange: EventEmitter<any> = new EventEmitter<any>();

  public visabilityChange: EventEmitter<any> = new EventEmitter<any>();

  public updateDynamicColumns: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.storage = new LocalStorage();

    AppComponent.isBrowser.subscribe(isBrowser => {
      if (isBrowser) {
        this.storage = localStorage;
      }
    });
  }

  get(key: string) {
    const item = this.storage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  set(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
    return this;
  }

  remove(key: string) {
    this.storage.removeItem(key);
    return this;
  }

  removeAll() {
    this.storage.clear();
    return this;
  }
}
