



<mat-toolbar>

    <h1>
        Edit Portfolio
        <div class="d-inline-block" *ngIf="portfolio && portfolio.id">
            <span class="small text-muted" *ngIf="portfolio.isPrivate()">(Private)</span>
            <span class="small text-muted" *ngIf="portfolio.isPublic() && !portfolio.is_tutorial">(Public)</span>
            <span class="small text-muted" *ngIf="portfolio.isShared()">(Shared)</span>
            <span class="small text-muted" *ngIf="portfolio.is_tutorial">(Tutorial)</span>
        </div>
    </h1>
    <div class="ml-auto d-inline-block">
        <button [matMenuTriggerFor]="shareMenu" mat-flat-button color="primary"><span class="navigation-button">Share</span></button>
        <mat-menu #shareMenu="matMenu" [overlapTrigger]="false" class="filter-menu">
            <div mat-menu-item *ngIf="portfolio" [disabled]="portfolio && portfolio.isPublic()" (click)="onShareClick()">
                <span *ngIf="portfolio.isShared()">Share settings</span>
                <span *ngIf="!portfolio.isShared()">Share to users</span>
            </div>
            <div *ngIf="(currentUser && currentUser.canSharePublicly()) && !portfolio.is_tutorial" mat-menu-item (click)="onPublicToggle()">{{portfolio.isPublic() ? 'Unshare from Public' : 'Share to Public'}}</div>
            <div mat-menu-item (click)="onTutorialToggle()">{{portfolio.is_tutorial ? 'Unshare from Tutorial' : 'Share to Tutorial'}}</div>
            <div mat-menu-item *ngIf="portfolio && portfolio.isShared()" (click)="onUnshareClick()">Unshare</div>
        </mat-menu>
        <button [disabled]="!selected.length" mat-flat-button class="ml-4" color="primary" (click)="onClickCart()">
           <mat-icon class="navigation-button">work</mat-icon> <span class="navigation-button"><span class="font-weight-bold navigation-button">{{selected.length}}</span> portfolio {{selected.length > 1 ? 'assets' : 'asset'}} selected </span>
       </button>
        <button  mat-flat-button type="button" class="ml-4" mat-stroked-button color="primary" (click)="onHistoryBackClick()">
            <span class="navigation-button">Discard changes</span></button>
        <button mat-flat-button type="button" class="ml-4" color="primary" (click)="onSavePortfolioClick(portfolio)"><span class="navigation-button">Save portfolio</span></button>
    </div>
</mat-toolbar>

<div class="pb-3">
    <div *ngIf="!portfolio.isGenerated()">
        <mat-card>
          <mat-card-content>
        <section id="edit-portfolio">
            <div class="card mb-4">
                <div class="card-body">
                    <app-manual-portfolio-form
                            *ngIf="portfolio && portfolio.id"
                            [portfolio]="portfolio"
                            [maxStartDate]="max_start_date"
                            [filterLists]="filterLists"
                            [selectedCurrencies]="selectedCurrencies"
                            (portfolioSave)="onSavePortfolioClick($event)"
                            (weightChange)="onWeightTypeChange()"
                            (startDateSet)="onStartDateSet()"
                    ></app-manual-portfolio-form>
                </div>
            </div>
            
        </section>
    </mat-card-content></mat-card>
    <mat-card>
          <mat-card-content>

        <section id="asset-selection">
            <mat-drawer-container class="sidenav-container" autosize>
                <mat-drawer (openedChange)="onOpenedChange($event)" #drawer mode="side" position="end" autoFocus="false" mode="over" class="sidenav">
                        <div [hidden]="!(type === 'filters') ">
                            <app-asset-table-column-visibility-filter #stockFilters [columnVisibility]="columnVisibility" assetType="stock" [quantList]="quantList" [dynamicColumns]="dynamicColumns" (columnChanged)="onDynamicColumnChange($event)"></app-asset-table-column-visibility-filter>
                        </div>
                        <div [hidden]="!(type !== 'filters' && drawlerData) || true" class="p-48 pr-0">
                            <mat-form-field class="example-full-width">
                                <mat-label>Search</mat-label>
                                <input matInput placeholder="Search for..." [(ngModel)]="drawlerData.searchText">
                            </mat-form-field>
                            <div class="selection-container">
                                <button type="button" mat-button color="primary" (click)="drawlerData.selectAll()">Select all</button>
                                <button type="button" mat-button color="primary" (click)="drawlerData.deselectAll()">Deselect all</button>
                            </div>

                            <mat-label>Choose an option</mat-label>
                            <ul>
                                <li 
                                    *ngFor="let item of drawlerData.list | search: drawlerData.searchText"
                                    [hidden]="item.hidden == true || (drawlerData.hideMarked && item.marked == true)">
                                    <mat-checkbox
                                        (change)="handleCheckboxUpdate(item.id)"
                                        [checked]="filters[drawlerData.name] ? filters[drawlerData.name].includes(item.id.toString()) : false"
                                    >
                                        {{item.name}}
                                    </mat-checkbox>
                                </li>
                            </ul>
                            <div class="selection-container bottom-buttons">
                                <button type="button" mat-button color="primary" (click)="filterCompanies();drawer.toggle();">OK</button>
                                <button type="button" mat-button color="primary" (click)="drawlerData.onPanelCloseOrCancel();drawer.toggle();">Cancel</button>
                            </div>
                        </div>
                </mat-drawer>
            <div class="card">
                <div class="card-body">
                    <app-new-portfolio
                            [list]="list"
                            [selected]="selected"
                            [filterLists]="filterLists"
                            [meta]="meta"
                            [portfolio]="portfolio"
                            (getFilters)="getFiltersIn($event)"
                            (fetchCompanies)="fetchCompaniesIn($event)"
                            (itemSelect)="onCompanySelect($event)"
                            (selectAll)="onSelectAll($event)"
                            (pageEvent)="onPageEvent($event)"
                            (cartClick)="onPortfolioCartClick()"
                            (startDateChange)="onMaxStartDateChange($event)"
                            (fileUploaded)="onFileUploaded($event)"
                            (selectedSortingChanged)="changeSelectedSorting($event)"
                    ></app-new-portfolio>
                </div>
            </div>
            </mat-drawer-container>
        </section>
    </mat-card-content></mat-card>
    </div>

<!--     <div class="card" *ngIf="portfolio.isGenerated()">
        <div class="card-body">
            <app-existing-portfolio
                    [list]="list"
                    [meta]="meta"
                    [filterLists]="filterLists"
                    [redirectToPortfoliosAfterSave]="true"
                    [portfolio]="portfolio"
                    (pageEvent)="onPageEvent($event)"
                    (getFilters)="getFiltersIn($event)"
                    (portfolioLoaded)="onPortfolioLoaded($event)"></app-existing-portfolio>
        </div>
    </div> -->
</div>
