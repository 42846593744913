import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {AuthService} from '../shared/services/auth.service';
import {LoaderService} from '../components/loading-bar/loader.service';
import {NotificationService} from '../shared/services/notification.service';

@Injectable({providedIn: 'root'}) export class HttpConfigInterceptor implements HttpInterceptor {
  static readonly SERVER_ERROR = {
    TOKEN: 'Token has expired.',
    USER: 'Invalid user.',
    INVALID_TOKEN: 'Invalid token',
    NO_TOKEN: 'No token found.'
  };



  private requests: HttpRequest<any>[] = []; // Store pending requests

  constructor(private auth: AuthService, private loaderService: LoaderService, private notificationService: NotificationService) {

  }

  private removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1); // remove request from array
    }

    // Show loading bar if there is still a request loading
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    this.requests.push(request);
    this.loaderService.isLoading.next(true);

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.removeRequest(request);
          if (event.body && event.body.error) {
            if (event.body.error !== HttpConfigInterceptor.SERVER_ERROR.INVALID_TOKEN && event.body.error !== HttpConfigInterceptor.SERVER_ERROR.NO_TOKEN) {
              this.notificationService.open(event.body.error);
            }
            if (
              (event.body.error === HttpConfigInterceptor.SERVER_ERROR.TOKEN) ||
              (event.body.error === HttpConfigInterceptor.SERVER_ERROR.USER)
            ) {
              this.auth.clearStoredUserData();
            }
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.removeRequest(request);
        this.auth.notificationService.open(`Error ${error.status}. Something went wrong`);
        return throwError(error);
      }));
  }
}
