<div class="pb-3">
  <mat-horizontal-stepper class="content-stepper portfolio-container" linear #stepper [selectedIndex]="selectedStep" (selectionChange)="stepChange($event)">
    <mat-step class="text-center">
      <mat-toolbar>
        <h1>Create portfolio</h1>
      </mat-toolbar>
      <div class="portfolio-step-1">

        <h3>How would you like to get started?</h3>
        <div class="portfolio-btn-wrapper" (click)="openSelectedType(1)">
          <mat-icon class="button-icon">edit</mat-icon>
          <span class="button-title">Asset Selection</span>
          <ul>
            <li>Search and select securities representing the assets you want to use for portfolio construction.</li>
            <li>Refine your desired selection by using the intuitive and comprehensive asset data filter menu.</li>
            <li>Range-bound filtering per asset data point allows you create an universe of assets representing your investment idea.</li>
            <li>Your final selection takes you seamlessly to the portfolio construction module where you generate your portfolio.</li>
          </ul>
        </div>
        <div class="portfolio-btn-wrapper" (click)="openSelectedType(2)">
          <mat-icon class="button-icon">description</mat-icon>
          <span class="button-title">Exposure Selection</span>
          <ul>
            <li>Define the number of securities you want to include in your exposure.</li>
            <li>Create an exposure based on a specific theme, sector, attribute or mix.</li>
            <li>Use the filters to define the intrinsic characteristics of your assets for the exposure.</li>
            <li>Apply your logic as to how you want to sort the selected filters and the system will create the exposure which you can simulate in the portfolio construction module.</li>
          </ul>
        </div>
        <div class="portfolio-btn-wrapper">
          <div (click)="onUploadClick()">
            <mat-icon class="button-icon">upload</mat-icon>
            <span class="button-title">Import a Portfolio</span>
            <p>Great for constructing a portfolio:</p>
            <ul>
              <li>based on an Excel file which I've exported from my trading or asset management platform</li>
            </ul>
          </div>
          <p class="text-center mt-5">
            <a mat-raised-button color="inverse" href="/assets/uploads/company-upload-template.csv">Download the template</a>
          </p>
        </div>
      </div>

    </mat-step>
    <mat-step>
      <mat-toolbar>
        <h1>Create portfolio</h1>
        <div class="toolbar-buttons">
          <button type="button" mat-stroked-button color="primary" matStepperPrevious>Back</button>
          <button type="button" class="float-right ml-3" mat-stroked-button color="primary" (click)="onNextClick()">Next</button>
        </div>
      </mat-toolbar>
      <div class="card" style="overflow: visible !important;">
        <div class="card-body">
          <app-new-portfolio
            *ngIf="selectedPortfolioType === 1"
            [list]="list"
            [selected]="selected"
            [filterLists]="filterLists"
            [meta]="meta"
            [priorityCurrencies]="priorityCurrencies"
            (nextClick)="onNextClick()"
            (getFilters)="getFiltersIn($event)"
            (fetchCompanies)="fetchCompaniesIn($event, 1)"
            (itemSelect)="onCompanySelect($event)"
            (selectAll)="onSelectAll($event)"
            (pageEvent)="onPageEvent($event)"
            (cartClick)="onPortfolioCartClick()"
            (startDateChange)="onMaxStartDateChange($event)"
            (fileUploaded)="onFileUploaded($event)"
          ></app-new-portfolio>
          <app-existing-portfolio
            *ngIf="selectedPortfolioType === 2"
            [list]="list"
            [meta]="meta"
            [filterLists]="filterLists"
            [portfolio]="portfolio"
            [selected]="selected"
            (nextClick)="onNextClick()"
            (getFilters)="fetchCompaniesIn($event)"
            (pageEvent)="onPageEvent($event)"
            (fetchCompanies)="fetchCompaniesIn($event, 1)"
            (startDateChange)="onMaxStartDateChange($event)"
          ></app-existing-portfolio>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <mat-toolbar>
        <h1>Create portfolio</h1>
        <div class="toolbar-buttons">
          <button mat-stroked-button type="button" color="primary" matStepperPrevious>Back</button>
          <button mat-flat-button type="button" class="float-right ml-3" color="primary" (click)="onSavePortfolioClick(portfolio)">Save portfolio</button>
        </div>
      </mat-toolbar>
      <div class="card" style="overflow: visible !important;">
        <div class="card-body" style="overflow: visible !important;">
          <h4>Portfolio</h4>
          <div class="mb-4">
            <app-manual-portfolio-form
              *ngIf="selectedStep == 2"
              [portfolio]="portfolio"
              [maxStartDate]="max_start_date"
              [filterLists]="filterLists"
              [selectedCurrencies]="selectedCurrencies"
              (portfolioSave)="onSavePortfolioClick($event)"
              (weightChange)="onWeightTypeChange()"
              (startDateSet)="onStartDateSet()"
            ></app-manual-portfolio-form>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <p *ngIf="weightError" class="text-center p-2 text-danger">Weight is over 100%!</p>
              <table class="table m-0 table-with-input">
                <thead>
                <tr>
                  <td class="bold-header">Name</td>
                  <td class="bold-header">ISIN</td>
                  <td class="bold-header text-right">Market Cap (M$)</td>
                  <td class="bold-header text-right">Market Cap (M$) at Start date</td>
                  <td >Start date</td>
                  <td >Asset Class</td>
                  <td >Currency</td>
                  <td >Sector</td>
                  <td >Weight</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of selected;index as index" [class.etf-border-top]="list[index-1] && list[index-1].isStock() && item.isETF()">
                  <td class="font-weight-bold">
                    <span class="underline-on-hover ml-2 text-truncate" (click)="onCompanyInfoClick(item)">{{item.company_name}}</span>
                  </td>
                  <td>{{item.isin}}</td>
                  <td >{{item.market_value_usd|number:'1.0-0'}} {{item.fund_reference_table.AverageMarketCapital |number:'1.0-0'}}</td>
                  <td>{{item.market_value_start ? (item.market_value_start|number:'1.0-0') : '-'}}</td>
                  <td>{{item.p_first_date}}</td>
                  <td>{{item.security_type_equity}}</td>
                  <td>{{item.currency ? item.currency.code : item.currency_id}}</td>
                  <td>{{item.sector ? item.sector.name : item.sector_id}}{{item.fund_reference_table.GlobalCategoryName}}</td>
                  <td>
                    <mat-form-field class="max-100" appearance="outline">
                      <input matInput [(ngModel)]="item.weight" type="number" min="0" max="100" [disabled]="portfolio.weighting_type !== 'manual'" (ngModelChange)="onWeightChange(item)">
                      <span matSuffix>%</span>
                    </mat-form-field>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

