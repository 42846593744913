import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserGuideComponent implements OnInit {
  SectionID = {
    Intro: 1,
    FAQ: 2 ,
    Custom: 3
  };
  currentSection = this.SectionID.Intro;

  constructor() { }

  ngOnInit(): void {
  }

  displaySection(section) {
    this.currentSection = section;
  }
}
