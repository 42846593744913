import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent {

  @Input() model: any;
  @Input() label: string;
  @Input() labelPrefix = 'All ';
  @Input() list: any[] = [];
  @Input() isRequired = false;
  @Input() isMultiple = false;
  @Input() isDisabled = false;
  @Input() isFullWidth = false;
  @Input() needAllOption = true;
  @Input() isInTableHeader = false;
  @Output() modelChanged: EventEmitter<any>;
  searchText = '';

  constructor() {
    this.modelChanged = new EventEmitter<any>();
  }

  onModelChange() {
    this.modelChanged.emit(this.model);
  }
}
