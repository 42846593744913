import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-object-select',
  templateUrl: './object-select.component.html',
  styleUrls: ['../filter-select/filter-select.component.scss']
})
export class ObjectSelectComponent {

  @Input() model: any;
  @Input() label: string;
  @Input() unit: string;
  @Input() labelPrefix = '';
  @Input() list: any[] = [];
  @Input() isRequired = false;
  @Input() isMultiple = false;
  @Input() hideMarked = false;
  @Input() isDisabled = false;
  @Input() isFullWidth = false;
  @Input() isInTableHeader = false;
  @Input() hasNullFilter = false;
  @Output() modelChanged: EventEmitter<any>;
  @Output() panelClosed: EventEmitter<any>;
  @Output() nullFilter: EventEmitter<any>;
  searchText = '';

  constructor() {
    this.modelChanged = new EventEmitter<any>();
    this.panelClosed = new EventEmitter<any>();
    this.nullFilter = new EventEmitter<any>();
  }

  keepData = false;

  onModelChange() {
    this.modelChanged.emit(this.model);
  }

  onOKClick() {
    this.keepData = true;
    this.panelClosed.emit();
  }

  onPanelCloseOrCancel() {
    this.deselectAll();
  }

  onPanelClosed() {
    if (!this.keepData) {
      this.deselectAll();
    }
  }

  selectAll() {
    this.model = [];
    if (this.hideMarked) {
      this.list.map((i: any) => {
        if (i.marked === false && i.id !== 'NULL') {
          this.model.push(i.id);
        }
      });
    } else {
      this.list.map((i: any) => {
        if (i.id !== 'NULL') {
          this.model.push(i.id);
        }
      });
    }
    this.onModelChange();
  }

  deselectAll() {
    this.model = null;
    this.onModelChange();
  }

  nullOnly() {
    this.model = [];
    this.nullFilter.emit(this.model);
  }
}
