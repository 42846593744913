<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header pb-0">
                <h1>Risk Contribution and Allocation</h1>
<!--                 <mat-form-field appearance="outline" class="header-select" style="float: right">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="chartType" (ngModelChange)="changeDataRC()">
                        <mat-option [value]="'risk'">Volatility</mat-option>
                        <mat-option [value]="'return'">Return</mat-option>
                   </mat-select>
                </mat-form-field> -->
            </div>
            <div class="card-body pt-0">
                <highcharts-chart *ngIf="loaded"
                                  [Highcharts]="Highcharts"
                                  [options]="chartOptions"
                                  style="width: 100%; height: auto; display: block;"
                ></highcharts-chart>
            </div>
        </div>
    </div>
</div>

