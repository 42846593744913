<h1 mat-dialog-title>
    You Have Been Idle!
</h1>
<div mat-dialog-content>
    <div class="mb-2">
        <mat-progress-bar mode="determinate" [value]="seconds*10"></mat-progress-bar>
    </div>
    You will time out in {{seconds}} seconds!
</div>
<div mat-dialog-actions class="justify-content-center">
    <button mat-flat-button color="primary" type="button" (click)="logout()" class="border-primary rounded-pill">Logout</button>

    <button mat-flat-button color="primary" type="button" (click)="stay()" class="border-primary rounded-pill">Stay</button>
</div>
