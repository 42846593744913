<div class="container-fluid h-100 login-form-wrapper">
  <div class="logo-container">
    <div class="logo"><img src="../../../assets/images/Logo-Main.svg" alt="Cimalti"></div>
  </div>
  <div class="card login-card">
    <div class="card-body">
      <form name="form" (ngSubmit)="loginForm.form.valid && onLoginFormSubmit()" #loginForm="ngForm" class="form-content">
        <div *ngIf="!qrCodeSrc && !showOtpField" class="form-content w-100">
          <h1>Login to Client Portal</h1>

          <mat-form-field appearance="outline" class="w-100 text-center">
            <input matInput placeholder="Email" name="email" [(ngModel)]="guest.email" #email="ngModel" required email>
            <mat-error *ngIf="loginForm.submitted && email.invalid">Email must be a valid email address</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100 text-center">
            <input matInput [type]="hidePwd ? 'password' : 'text'" placeholder="Password" [(ngModel)]="guest.password" name="password" #password="ngModel" required>
            <button type="button" mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd">
              <mat-icon>{{hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="loginForm.submitted && password.invalid">Password is required</mat-error>
          </mat-form-field>

          <button mat-flat-button color="primary" class="btn-wide rounded-pill"><span class="navigation-button">Login</span></button>
          <a mat-button routerLink="password-reset" class="forgot-pwd-link text-400" color="primary">Forgot your password?</a>
          <span class="version-text">{{version}}</span>
        </div>

        <div *ngIf="qrCodeSrc" class="form-content w-100 text-center">
          <h3>Setup One Time Password</h3>
          <p >Scan the QR code with <b>Google Authenticator</b> app.</p>
          <img src="{{qrCodeSrc}}" alt="" class="mb-5">
          <div>
            <button mat-flat-button color="primary" class="btn-wide rounded-pill" (click)="onOtpSetupFinished()">Finish setup</button>
          </div>
        </div>

        <div *ngIf="showOtpField" class="form-content w-100 text-center">
          <h1>Verify it’s you</h1>
          <p class="mb-5">Please enter your authentication code in the field below.</p>
          <mat-form-field appearance="outline" class="w-100 text-center">
            <input matInput placeholder="Code" name="otp" [(ngModel)]="guest.otp" #otp="ngModel">
            <mat-error *ngIf="otp.dirty && otp.invalid">One time password is required</mat-error>
          </mat-form-field>
          <div class="d-block text-center">
            <p><button mat-flat-button color="primary" class="btn-wide rounded-pill">Submit</button></p>
            <p><button type="button" (click)="onReset2FAClick()" mat-button color="primary" class="">Send code via email</button></p>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>
