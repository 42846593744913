import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {PortfolioService, portfolioTypes} from "../portfolio.service";
import {PortfolioModel, VISIBILITY_SHARED} from "../../../shared/models/portfolio.model";
import {Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import * as moment from 'moment';

@Component({
  selector: 'app-portfolio-table',
  templateUrl: './portfolio-table.component.html',
  styleUrls: ['./portfolio-table.component.scss']
})
export class PortfolioTableComponent implements OnInit, OnChanges {

  @Input() title = 'Portfolio';
  @Input() list: any[] = [];
  @Input() readOnly = false;
  @Input() visibleShareUser = true;
  @Input() needPagination = false;
  @Output() forkPortfolio: EventEmitter<any> = new EventEmitter<any>();
  @Output() unsharePortfolio: EventEmitter<any> = new EventEmitter<any>();
  @Output() portfolioSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;

  tableLoaded = false
  sharedVisivility = VISIBILITY_SHARED;
  portfolioTypes = portfolioTypes;
  selected = [];

  columns: any = [
    // 'checkbox',
    'name',
    'currency',
    'asset_count',
    'created_at',
    'last_updated_at',
    'actions'
  ];
  selection = new SelectionModel<any>(true, []);

  constructor(
    private service: PortfolioService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // if (this.visibleShareUser) {
    //   this.columns.push('shared_by')
    // }
    // if (!this.readOnly) {
    //   this.columns.push('action')
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list) {
      this.initDatatable();
    }
  }

  initDatatable() {
    this.tableLoaded = false;
    this.dataSource = new MatTableDataSource<any>(this.list);
    this.dataSource.sort = this.sort;
    this.tableLoaded = true

    if (this.needPagination) {
      this.dataSource.paginator = this.paginator;
    }
  }

  onSelect(value, item) {
    this.selected = this.selected.includes(item) ? this.selected.filter(i => i !== item) : [ ...this.selected, item ];
    item.selected = value.checked;
  }

  handleBulkDelete(idList) {
    this.service.bulkDelete({ids: idList}).subscribe(resp => {
      if (resp.success) {
        this.service.notificationService.open('Portfolios deleted')
        idList.map(id => {
          this.list = this.list.filter(i => i.id !== id);
        })
        this.dataSource.data = this.list;
      }
    })
  }

  onBulkDeleteClick() {
    const idList = this.selected.map(s => s.id);
    if (idList.length) {
      this.service.confirmAction('Do you want to delete ' + idList.length + ' portfolio?').subscribe(conf => {
        if (conf == true) {
          this.handleBulkDelete(idList);
        }
      })
    }
  }

  onStockClick(portfolio) {
    if (portfolio.status !== 3) {
      return this.service.notificationService.open("Portfolio is not ready yet");
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/app/portfolio-view', portfolio.id]);
      if (this.readOnly) {
        // portfolio selected in the nav bar dialog, reload the page
        setTimeout(() => {
          window.location.reload();
        }, 200)
      }
    });
  }

  closeOtherInfo(element) {
    this.list = this.list.map((c: any) => {
      if (c.id !== element.id) {
        c.isOpen = false;
      }
      return c;
    });
  }

  onSelectAll(value) {
    this.list = this.list.map(i => {i.selected = value.checked; return i})
    this.selected = value.checked ? [...this.list] : []
  }

  onClickCopy(item) {
    this.forkPortfolio.emit(item);
  }

  doDelete(item) {
    this.service.deleteItem(item).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      this.service.notificationService.open('Portfolio deleted')
      this.list = this.list.includes(item) ? this.list.filter(i => i !== item) : this.list;
      this.dataSource.data = this.list;
    })
  }

  deleteItem(item) {
    this.service.confirmAction().subscribe(confirm => {
      if (confirm) {
        this.doDelete(item);
      }
    })
  }

  unshareItem(item) {
    this.unsharePortfolio.emit(item);
  }

  openPortfolio(item) {
    this.service.viewItem(item.id).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      if (resp) {
        this.service.selectPortfolio(new PortfolioModel(resp));
        this.router.navigate(['/app/overview']);
        this.portfolioSelected.emit();
      }
    })
  }

  getTypeName(id) {
    if (+id > 0) {
      const type = this.portfolioTypes.filter(t => t.id == id);
      return type.length ? type[0].name : '';
    } else {
      return ''
    }
  }

  onRemoveClick(item) {
    this.doDelete(item);
  }

  onEdit(item) {
    this.onStockClick(item);
  }

  onDuplicate(item) {
    this.forkPortfolio.emit(item);
  }
}
