import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {NotificationService} from '../../shared/services/notification.service';

@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class ForgotPwdComponent implements OnInit {
  model = {
    email: ''
  };

  pwdResetModel = {
    password: '',
    password_confirm: ''
  };

  paramToken = '';

  qrCodeSrc: null;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.paramToken = '';
    this.route.params.subscribe((params: Params) => {
      if (params.token) {
        this.paramToken = params.token;
      }
    });
  }

  onPasswordResetSubmit() {
    if (this.paramToken && this.pwdResetModel.password && this.pwdResetModel.password_confirm &&
      this.pwdResetModel.password === this.pwdResetModel.password_confirm
    ) {
      this.authService.sendResetPassword(this.pwdResetModel, this.paramToken).subscribe((resp: any) => {
        if (resp.success) {
          this.qrCodeSrc = resp.success.qrCodeSrc;
          this.notificationService.open('Password changed. Please login.');
          // this.router.navigate(['/']).then(() => {
          // });
        } else {
          this.notificationService.open(resp.error);
        }
      });
    }
  }

  onForgotPwdSubmit() {
    if (this.model.email) {
      this.authService.sendForgotPassword(this.model).subscribe((resp: any) => {
        if (resp.success) {
          this.notificationService.open(`Reset email sent to ${this.model.email}`);
        }
      });
    }
  }
}
