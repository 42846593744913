<div class="card h-100">
    <div class="card-header" *ngIf="!isSummary">
      <div class="flex">
        <h1 class="flex-1">Constraints</h1>
        <div class="flex flex-1">
          <div class="flex-1 text-right">Allow short positions?</div>
          <div class="flex-none">
            <mat-checkbox disabled [checked]="shorts" color="primary">Yes</mat-checkbox>
          </div>
          <div class="flex-none">
            <mat-checkbox disabled [checked]="!shorts" color="primary">No</mat-checkbox>
          </div>
        </div>
      </div>

      <button type="button" class="float-right" mat-button color="warn" (click)="deleteBulk()" [disabled]="!selectedCons.length">Delete selected</button>
      <button type="button" class="float-right" mat-button color="primary" (click)="editBulk()" [disabled]="!selectedCons.length">Edit selected</button>
    </div>
    <div class="card-body">
        <table class="table" *ngIf="constraints.length">
            <thead>
            <tr>
                <td *ngIf="!isSummary"></td>
                <td>Type</td>
                <td>Data</td>
                <td class="text-right number-col">Min value</td>
                <td class="text-right number-col">Max value</td>
                <td *ngIf="!isSummary"></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let c of constraints" [hidden]="c.id == activeCons.id">
                <td *ngIf="!isSummary"><mat-checkbox [checked]="c.selected" color="primary" (change)="onSelect(c)"></mat-checkbox></td>
                <td>{{c.type.name}}</td>
                <td>
                    <span *ngFor="let d of c.items" class="m-0 p-0 d-block">{{d.name}}</span>
                </td>
                <td class="text-right pr-4 number-col">{{c.min}}%</td>
                <td class="text-right pr-4 number-col">{{c.max}}%</td>
                <td *ngIf="!isSummary" class="text-right pr-4">
                  <mat-icon class="cursor-pointer text-primary" (click)="editCon(c)">edit</mat-icon>
                  <mat-icon class="cursor-pointer text-danger" (click)="deleteCon(c)">delete</mat-icon>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="row pt-4" *ngIf="activeCons.type">
            <div class="col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="activeCons.type" placeholder="Type" (ngModelChange)="onTypeChange()">
                        <mat-option *ngFor="let i of types" [value]="i.id">{{i.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <app-filter-select [label]="activeCons.type ? activeCons.type.name : 'Data'" [isFullWidth]="true" [needAllOption]="false" [isMultiple]="true" [list]="list" [model]="activeCons.items" (modelChanged)="activeCons.items = $event"></app-filter-select>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Min value</mat-label>
                    <input matInput [(ngModel)]="activeCons.min" type="number">
                    <span matSuffix>%</span>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Max value</mat-label>
                    <input matInput [(ngModel)]="activeCons.max" type="number">
                    <span matSuffix>%</span>
                </mat-form-field>
            </div>

          <div class="col-md-3">
            <mat-slide-toggle color="primary" [checked]="activeCons.saveEach" (change)="onSaveEachChange()">Save each</mat-slide-toggle>
          </div>
            <div class="col-md-9 text-right mb-4">
                <button mat-button (click)="saveCon()" color="primary"><mat-icon>save</mat-icon> Save</button>
                <button mat-button (click)="discardCon()"><mat-icon>close</mat-icon> Close</button>
            </div>
        </div>

      <div class="row" *ngIf="bulkEdit.enabled">
        <div class="col-md-2">
          <p class="pt-2">{{selectedCons.length}} constraint edit</p>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Min value</mat-label>
            <input matInput [(ngModel)]="bulkEdit.min" type="number">
            <span matSuffix>%</span>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Max value</mat-label>
            <input matInput [(ngModel)]="bulkEdit.max" type="number">
            <span matSuffix>%</span>
          </mat-form-field>
        </div>
        <div class="col-md-3 text-right">
          <button mat-button (click)="saveBulk()" color="primary"><mat-icon>save</mat-icon> Save</button>
          <button mat-button (click)="discardBulk()"><mat-icon>close</mat-icon> Close</button>
        </div>
      </div>

        <div class="text-center" *ngIf="!activeCons.id && !isSummary">
            <button mat-flat-button color="primary" (click)="onAddConstraint()"><span class="navigation-button">+ Add constraint</span></button>
        </div>
    </div>
</div>
