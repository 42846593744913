<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header pb-0"><h1>{{title}}</h1></div>
            <div class="card-body pt-5">
                <highcharts-chart
                        [Highcharts]="Highcharts"
                        [options]="chartOptions"
                        style="width: 100%; height: auto; display: block;"
                >
                </highcharts-chart>
            </div>
        </div>
    </div>
</div>
