import {Injectable} from "@angular/core";
import {DataService} from "../../shared/services/data.service";
import {ApiEndpoints} from "../../ApiEndpoints";

@Injectable({
  providedIn: 'root'
})
export class WatchlistService extends DataService {
  getItems() {
    return this.getRequest(ApiEndpoints.watchlists)
  }

  getItem(id?) {
    return this.getRequest(`${ApiEndpoints.watchlist}/${id}`);
  }

  saveWatchlist(data) {
    if (data.id) {
      return this.putRequest(ApiEndpoints.watchlist, data);
    } else {
      return this.postRequest(ApiEndpoints.watchlist, data);
    }
  }

  deleteItem(data) {
    return this.deleteRequest(ApiEndpoints.watchlist, data.id);
  }
}
