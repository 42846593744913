import {Component, Input, OnInit} from '@angular/core';
import {PortfolioModel} from '../../../shared/models/portfolio.model';

@Component({
  selector: 'app-print-header',
  templateUrl: './print-header.component.html',
  styleUrls: ['./print-header.component.scss']
})
export class PrintHeaderComponent implements OnInit {
  @Input() portfolio = new PortfolioModel({});
  constructor() { }

  ngOnInit(): void {
  }

}
