import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../shared/services/notification.service';
import {NgForm} from '@angular/forms';
import { version } from "../../../version"

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginForm') loginForm: NgForm;
  version = '';
  hidePwd = true;

  guest = {
    email: '',
    password: '',
    otp: null
  };

  qrCodeSrc = null;
  showOtpField = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.version = version.number;
    this.auth.checkLogin();
  }

  onLoginFormSubmit() {
    if (this.guest.email && this.guest.password) {
      // for login we dont need the otp field
      if (this.guest.otp === null) {
        delete this.guest.otp;
      }
      this.auth.login(this.guest).subscribe((resp: any) => {
        if (resp.success) {
          if (this.guest.otp === 'fallback') {
            this.auth.notificationService.open('Successful 2FA reset, please check your email account for further information.');
            this.guest.otp = '';
          }
          if (resp.success.qrCodeSrc) {
            this.qrCodeSrc = resp.success.qrCodeSrc;
          }

          if (resp.success.otp === false) {
            this.qrCodeSrc = null;
            this.guest.otp = '';
            this.showOtpField = true;
            if (resp.success.message) {
              this.notificationService.open(resp.success.message);
            }
          }

          if (resp.success.token) {
            this.auth.saveToken(resp.success.token);
          }
        }

        if (resp.error) {
          this.notificationService.open(resp.error);
        }
      });
    }
  }

  onOtpSetupFinished() {
    this.guest.otp = 'finish-setup';
    this.onLoginFormSubmit();
  }

  onReset2FAClick() {
    this.guest.otp = 'fallback';
    this.onLoginFormSubmit();
  }
}
