import {throwError as observableThrowError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {StorageService} from './storage.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {NotificationService} from './notification.service';
import {UtilService} from './util.service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CompanyInfoDialogComponent} from "../../components/company-info-dialog/company-info-dialog.component";

@Injectable()
export class DataService {

  protected token = '';

  protected serverUrl = '';

  authCompleted = false;
  httpOptions = {};
  keyToken = 'cimalgo-k';
  keyDate = 'cimalgo-d';

  private apiURL = environment.apiURL;
  wsURL: string = environment.wsURL || '';

  protected http: HttpClient;
  router: Router;
  storage: StorageService;
  util: UtilService;
  notificationService: NotificationService;

  dialog: MatDialog;
  constructor(
    private injector: Injector
  ) {
    this.http = injector.get(HttpClient);
    this.router = injector.get(Router);
    this.storage = injector.get(StorageService);
    this.notificationService = injector.get(NotificationService);
    this.dialog = injector.get(MatDialog);
    this.util = injector.get(UtilService);
    if (!this.token) {
      this.restoreToken();
    } else {
      this.setHttpHeader();
    }
  }

  restoreToken() {
    const token = this.storage.get(this.keyToken);
    if (token) {
      this.token = token;
      this.setHttpHeader();
    } else {
      if (this.authCompleted) {
        this.notificationService.open('Unauthorized');
        this.router.navigate(['/']);
      }
    }
  }

  setHttpHeader() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
    };
  }

  resetHttpHeader() {
    this.httpOptions = {};
  }

  private buildURL(endpoint, params, extra) {
    const paramQuery = this.util.stringifyParams(params, true);
    const extraQuery = extra ? this.util.stringifyParams(extra, true, true) : null;
    let url = `${this.apiURL}/${endpoint}`;

    if (paramQuery.length > 0) {
      url += `?${paramQuery}`;
    }
    if (extraQuery) {
      const prefix = paramQuery ? '&' : '?';
      url += `${prefix}${extraQuery}`;
    }
    return url;
  }

  public postRequest(endpoint: string, data: any, params = {}, extra = {}, isUpload = false) {
    const url = this.buildURL(endpoint, params, extra);
    let options = Object.assign({}, this.httpOptions);
    if (isUpload) {
      options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.token,
        })
      };
    }

    return this.handleFinishedRequest(
      this.http
        .post(url, data, options)
    );
  }

  public putRequest(endpoint: string, data: any) {
    return this.handleFinishedRequest(
      this.http
        .post(`${this.apiURL}/${endpoint}/${data.id}`, data, this.httpOptions)
    );
  }

  public getRequest(endpoint: string, params = {}, extra = {}) {
    const url = this.buildURL(endpoint, params, extra);
    return this.http.get(url, this.httpOptions);
  }

  protected deleteRequest(endpoint: string, id) {
    return this.http.delete(`${this.apiURL}/${endpoint}/${id}`, this.httpOptions);
  }

  protected handleFinishedRequest(observable: any) {
    return observable.pipe(catchError(
      (response: HttpErrorResponse) => {
        let errorMsg = 'Something went wrong!';
        // Unauthorized
        if (response.status === 401) {
          errorMsg = response.error;
          this.router.navigate(['']);
        }
        return observableThrowError(response.error.message ? response.error.message : errorMsg);
      }
    ));
  }

  public confirmAction(message = 'Are you sure?', title = 'Confirm action', isAlert = false) {

    const dialogData = new ConfirmDialogModel(title, message, isAlert);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    return dialogRef.afterClosed();
  }
}
