<mat-dialog-content>
  <mat-form-field class="w-100 px-3">
    <input placeholder="Name" matInput [(ngModel)]="name" (click)="$event.stopPropagation()">
  </mat-form-field>

  <hr>

  <mat-form-field class="w-100 px-3">
    <input placeholder="Search" matInput [(ngModel)]="searchPanel" (click)="$event.stopPropagation()">
  </mat-form-field>
  <mat-accordion class="app-nav-accordion" [multi]="true">
    <mat-expansion-panel *ngFor="let panel of accordionData|panelsearch:searchPanel; first as isFirst" [expanded]="isFirst" [disabled]="panel.hidden">
      <mat-expansion-panel-header (click)="$event.stopPropagation()" [hidden]="panel.hidden">
        <mat-panel-title>{{panel.title}}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <div mat-menu-item *ngFor="let f of panel.items" [disableRipple]="true" [hidden]="f.hidden">
          <mat-checkbox [checked]="columnPreset[f.id] == true" [(ngModel)]="columnPreset[f.id]" (click)="$event.stopPropagation();" color="primary">{{f.name}}</mat-checkbox>
        </div>
      </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button (click)="onSaveClick()">Save</button>
</mat-dialog-actions>
