<div class="page-header">
    <h1 class="page-title">User guide</h1>
</div>

<div class="row">
  <div class="col-6 divider-right">
    <app-cim-datepicker label="Start date" [isFullWidth]="true" ></app-cim-datepicker>
  </div>
  <div class="col-6">
    <app-cim-datepicker label="End date" [isFullWidth]="true" ></app-cim-datepicker>
  </div>
</div>
