import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class LoginGuardService implements CanActivate {
  constructor(private auth: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.getCurrentUser().pipe(
      map(e => {
        if (e) {
          return true;
        }
      }),
      catchError(val => {
        this.auth.logout();
        
        return of(`Error: ${val}`);
      })
    );
  }
}
