import {Injectable, Injector} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../models/user.model';
import {ApiEndpoints} from '../../ApiEndpoints';
import {DataService} from './data.service';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {IdleDialogComponent} from '../../components/idle-dialog/idle-dialog.component';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends DataService {

  protected currentUser: User;
  private dateLimit: number = 60 * 60 * 24 * 1000; // 1 day in milisec

  dialogRef = null;
  idleState = 'Not started.';
  idle: any = {
    watch: () => {},
    stop: () => {},
  };
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    injector: Injector,
    private keepalive: Keepalive
) {
    super(injector);
    if(!isPlatformServer) {
      this.initIdleService();
    }
  }

  initIdleService() {
    // sets an idle timeout of 3600 seconds (1hour), for testing purposes.
    this.idle.setIdle(3600);
    // sets a timeout period of 10 seconds. after 15 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.resetIdle();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.dialogRef.close({logout: true});
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      this.showIdleDialog();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }

  login(guest) {
    return this.postRequest(ApiEndpoints.login, guest);
  }

  extractUserFromResponse(resp): User {
    let _user: User;
    if (resp instanceof User) {
      _user = resp;
    } else if (resp instanceof Observable) {
      resp.subscribe((user: any) => {
        if (user && user.id) {
          _user = user;
        }
      });
    } else if (resp && resp.value && resp.value instanceof User) {
      _user = resp.value;
    }
    return _user;
  }

  checkLogin() {
    this.getCurrentUser().subscribe( (resp: any) => {
      const user = this.extractUserFromResponse(resp);
      if (user && user.id) {
        return this.router.navigate(['/app']);
      } else {
        return this.router.navigate(['/']);
      }
    });
  }

  logout(needReload = false) {
    this.idle.stop();
    return this.postRequest(ApiEndpoints.logout, {}).subscribe((resp: any) => {
      this.clearStoredUserData();
      // if (needReload) {
      //   window.location.reload();
      // }
    });
  }

  clearStoredUserData() {
    this.currentUser = null;
    this.token = '';
    this.storage.remove(this.keyToken);
    this.storage.remove(this.keyDate);
    this.resetHttpHeader();
    this.router.navigate(['/']);
  }

  sendForgotPassword(guest) {
    return this.postRequest(ApiEndpoints.forgotPassword, guest);
  }

  sendResetPassword(passwordsObj, token) {
    if (!passwordsObj || !token) {
      return EMPTY;
    }
    return this.postRequest(`${ApiEndpoints.resetPassword}/${token}`, passwordsObj);
  }

  saveToken(token) {
    this.token = token;
    this.storage.set(this.keyToken, token);
    this.storage.set(this.keyDate, new Date().getTime());
    this.setHttpHeader();
    this.checkLogin();
  }

  getCurrentUser(): any {
    this.token = this.token ? this.token : this.storage.get(this.keyToken);
    if (!this.token) {
      this.logout();
      return of(null);
    }

    // Check last login date
    const lastLogin = this.storage.get(this.keyDate);
    const today = new Date().getTime();
    if (lastLogin && (today - lastLogin > this.dateLimit)) {
      return of<User>();
    }

    if (this.currentUser && this.currentUser.id) {
      this.authDone();
      return of(this.currentUser);
    } else {
      this.currentUser = new User();
      return this.getRequest(ApiEndpoints.me).pipe(
        map( (resp: any) => {
          if (resp.error) {
            this.notificationService.open(resp.error);
            return this.logout(true);
          }
          if (resp.item) {
            this.currentUser = Object.assign(this.currentUser, resp.item);
          }
          this.authDone();
          return of(this.currentUser);
        })
      );
    }
  }

  authDone() {
    this.storage.set(this.keyDate, new Date().getTime());
    this.authCompleted = true;
    this.resetIdle();
  }

  isCurrentUserSuperAdmin() {
    return this.currentUser && this.currentUser.isSuperAdmin();
  }

  isCurrentUserAdminOrSuperAdmin() {
    return this.currentUser && (this.currentUser.isSuperAdmin() || this.currentUser.isAdmin());
  }

  showIdleDialog() {
    this.dialogRef = this.dialog.open(IdleDialogComponent, {
      data: { idleState: this.idleState },
      maxWidth: '340px',
      autoFocus: false,
      width: '100%',
      disableClose: true
    });
    this.dialogRef.afterClosed().subscribe( (resp: any) => {
      if (resp.logout) {
        this.logout(true);
      } else {
        this.resetIdle();
      }
    });
  }
  resetIdle() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
