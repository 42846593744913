<button mat-icon-button class="dialog-close-btn" (click)="onNoClick()"><mat-icon>close</mat-icon></button>
<h1 mat-dialog-title>
    {{company.id ? 'Edit' : 'Create new'}} company
</h1>
<div mat-dialog-content>

    <div class="row pt-1">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label class="cim-input-label">Name</mat-label>
                <input matInput placeholder="Name *" name="name" [(ngModel)]="company.name" required>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-slide-toggle color="primary" [(ngModel)]="company.two_fa_enabled" class="pt-2">
                2-Factor Authentication
            </mat-slide-toggle>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-between">
    <div>
        <button *ngIf="company.id && !isAdmin" mat-stroked-button color="primary" (click)="confirmDeleteCompany()" class="border-primary rounded-pill">Delete company</button>
        <button mat-flat-button color="primary" (click)="onSaveClick()" class="border-primary rounded-pill">Save</button>
    </div>
</div>
