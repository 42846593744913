import {Component, Input, OnInit, OnChanges} from '@angular/core';
import * as Highcharts from "highcharts/highstock";
import {high_colors} from "../general-chart/general-chart.component";

@Component({
    selector: 'app-area-chart',
    templateUrl: './area-chart.component.html',
    styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit {
    @Input() optimizationData: any;
    @Input() minVolatility: any;
    @Input() maxVolatility: any;

    loaded = false;
    Highcharts: typeof Highcharts = Highcharts;
    chartConstructor = 'chart';
    chartOptions: Highcharts.Options = {};
    chartData: any[] = [];

    ngOnInit(): void {
        this.initChart()
    }
    ngOnChanges(): void {
        this.initChart()
    }

    initChart() {
        this.loaded = false;
        this.chartData = [];
        this.optimizationData.map((d: any) => {
         
            this.chartData.push({
                name: d.company_name,
                data: d.id_w,
                type: 'area'
            });
        })

        this.chartOptions = {
            chart: {
                height: '500px',
                type: 'area'
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            colors: high_colors,
            tooltip: {
                valueSuffix: '%',
                shared: true
            },
            plotOptions: {
                area: {
                    pointStart: this.minVolatility,
                    pointInterval: 1,
                    relativeXValue: true,
                    stacking: 'percent',
                    marker: {
                        enabled: false
                    }
                }
            },
            yAxis: {
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: 'Allocation'
                }
            },
            xAxis: {
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: 'Standard Deviation'
                }
            },
            series: this.chartData
        };
        setTimeout(() => this.loaded = true, 10);
    }
}
