<div class="card">
    <div class="card-header pb-0">Exposure / Holding</div>
    <div class="card-sub-header"><span>Portfolio:</span> {{subHeader.portfolio}}</div>
    <div class="card-sub-header"><span>Type:</span> {{subHeader.type}}</div>
    <div class="card-body pt-0">
      <mat-form-field appearance="outline" class="header-select" style="position: absolute; top: 16px; right: 30px;" *ngIf="!isPrint">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="selectedType" (ngModelChange)="onTypeChange()">
          <mat-option *ngFor="let i of types" [value]="i">{{i}}</mat-option>
        </mat-select>
      </mat-form-field>
        <highcharts-chart *ngIf="loaded"
                          [Highcharts]="Highcharts"
                          [options]="chartOptions"
                          [constructorType]="chartConstructor"
                          style="width: 100%; height: auto; display: block;"
        ></highcharts-chart>
    </div>
</div>

