import { Component, OnInit } from '@angular/core';
import {PortfolioService} from "./portfolio.service";

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  selectedTab = 0;

  lists: any = {
    private: [],
    public: [],
    shared_by_me: [],
    shared_with_me: [],
    tutorials: []
  };

  constructor(
    private service: PortfolioService
  ) { }

  ngOnInit(): void {
    this.fetchPortfolios();
    this.listenFinished();
  }

  listenFinished() {
    this.service.isPortfolioFinished().subscribe((resp: any) => {
      if (resp && resp.id) {
        this.service.notificationService.open("Reloading portfolios");
        this.fetchPortfolios();
      }
    })
  }

  fetchPortfolios() {
    this.service.portfolioFinished.next({});
    this.lists = {
      private: [],
      public: [],
      shared_by_me: [],
      shared_with_me: [],
      tutorials: []
    };
    this.service.getItems().subscribe((resp: any) => {
      if (resp.error) {
        this.service.notificationService.open(resp.error)
      }
      resp = this.service.extractPortfolioData(resp);
      this.lists = resp;

      if (this.lists.shared_by_me && this.lists.shared_by_me.length) {
        this.lists.shared_by_me = this.lists.shared_by_me.map((p: any) => {
          const sharedUsers: string[] = [];
          p.shared_with_users.map((u: any) => {
            sharedUsers.push(u.name);
          });
          p.shared_with_users_concat = sharedUsers.join(', ');
          return p;
        });
      }

      // merge private and shared by me lists
      this.lists.private = [
        ...(this.lists.private ?? []),
        ...(this.lists.shared_by_me ?? [])
      ]
      this.service.portfolioLists = this.lists;
    })
  }

  forkItem(item) {
    this.service.forkItem(item.id).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      this.service.notificationService.open('Portfolio copied to your private list.')
      window.location.reload();
    })
  }

  unshareItem(item) {
    this.service.leaveShareItem(item.id)
      .subscribe(resp => {
        this.service.notificationService.open('Share removed');
        this.lists.shared_with_me = this.lists.shared_with_me.filter(p => p.id !== item.id);
      });
  }
}
