import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chart-placeholder',
  templateUrl: './chart-placeholder.component.html',
  styleUrls: ['./chart-placeholder.component.scss']
})
export class ChartPlaceholderComponent implements OnInit {

  @Input() loaded = false;

  constructor() { }

  ngOnInit(): void {
  }

}
