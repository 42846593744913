<div class="card h-100">
  <div class="card-body">
    <table class="table" *ngIf="views.length">
      <thead>
      <tr>
        <td>Type</td>
        <td>View</td>
        <td>Type Of View</td>
        <td>Take</td>
        <td>View 2</td>
        <td class="text-right">Value</td>
        <td class="text-right">Confidence</td>
        <td *ngIf="!isSummary"></td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let c of views" [hidden]="c.id == activeView.id">
        <td>{{c.type.name}}</td>
        <td>
          <span *ngFor="let d of c.items" class="m-0 p-0 d-block">{{d.name}}</span>
        </td>
        <td class="text-capitalize">{{c.view_type | cleanName}}</td>
        <td class="text-capitalize">{{c.take}}</td>
        <td>
          <span *ngFor="let d of c.items2" class="m-0 p-0 d-block">{{d.name}}</span>
        </td>
        <td class="text-right pr-4 number-col">{{c.value}}%</td>
        <td class="text-right pr-4 number-col">{{c['confidence']}}%</td>
        <td *ngIf="!isSummary">
          <mat-icon class="cursor-pointer text-primary" (click)="editView(c)">edit</mat-icon>
          <mat-icon class="cursor-pointer text-danger" (click)="deleteView(c)">delete</mat-icon>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="row pt-4" *ngIf="activeView.type">
      <div class="col-md-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Type</mat-label>
          <mat-select [(ngModel)]="activeView.type" placeholder="Type" (ngModelChange)="onTypeChange()">
            <mat-option *ngFor="let i of types" [value]="i.id">{{i.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <app-filter-select [label]="'View'" [isFullWidth]="true" [needAllOption]="false" [isMultiple]="true" [list]="list" [model]="activeView.items" (modelChanged)="activeView.items = $event"></app-filter-select>
      </div>
      <div class="col-md-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>View Type</mat-label>
          <mat-select [(ngModel)]="activeView.view_type" placeholder="View Type" (ngModelChange)="onViewTypeChange()">
            <mat-option *ngFor="let i of viewTypes" [value]="i.id">{{i.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Take</mat-label>
          <mat-select [(ngModel)]="activeView.take" placeholder="Take" [disabled]="activeView.view_type !== 'relative'">
            <mat-option *ngFor="let i of takes" [value]="i.id">{{i.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <app-filter-select [label]="'View 2'" [isFullWidth]="true" [needAllOption]="false" [isMultiple]="true" [isDisabled]="activeView.view_type !== 'relative'" [list]="list" [model]="activeView.items2" (modelChanged)="activeView.items2 = $event"></app-filter-select>
      </div>

      <div class="col-md-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Value</mat-label>
          <input matInput [(ngModel)]="activeView.value" type="number">
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Confidence</mat-label>
          <input matInput [(ngModel)]="activeView['confidence']" type="number">
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div class="col-md-8 text-right mb-4" *ngIf="!isSummary">
        <button mat-button (click)="saveView()" color="primary"><mat-icon>save</mat-icon> Save</button>
        <button mat-button (click)="discardView()"><mat-icon>close</mat-icon> Close</button>
      </div>
    </div>

    <div class="text-center" *ngIf="!activeView.type && !isSummary">
      <button mat-flat-button color="primary" (click)="onAddView()"><span class="navigation-button">+ Add view</span></button>
    </div>
  </div>
</div>
