import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UsersService} from "../users.service";
import {ImageHandlerService} from "../../../shared/services/image-handler.service";
import { ADMIN_GROUP_ID, END_USER_GROUP_ID, SUPERADMIN_GROUP_ID, User } from "../../../shared/models/user.model";

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  user: User;
  isSuperAdmin = false;

  // Hide new company button from normal admin
  isAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private service: UsersService,
    private imageHandler: ImageHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.user) {
      this.user = Object.assign(new User(), this.data.user);
      this.user.company_id = this.user.company ? this.user.company.id : null;
    } else {
      this.user = new User({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        job_description: '',
        group_id: END_USER_GROUP_ID,
        is_admin: false,
        activated: false,
        two_fa_enabled: false,
        public_share: false,
      });
    }
    this.isSuperAdmin = this.user.isSuperAdmin();
    this.isAdmin = this.data.isAdmin;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    if (this.user.isValidCompany() || this.isSuperAdmin) {
      if (!this.isSuperAdmin) {
        if (this.user.is_admin) {
          this.user.group_id = ADMIN_GROUP_ID;
        } else {
          this.user.group_id = END_USER_GROUP_ID;
        }
      } else {
        this.user.group_id = SUPERADMIN_GROUP_ID;
      }
      this.service.saveUser(this.user).subscribe((resp: any) => {
        if (resp.error) {
          setTimeout(() => window.location.reload(), 1000);
          return this.service.notificationService.open(resp.error);
        }
        this.service.notificationService.open('Saved');
        return this.dialogRef.close(resp);
      });
    } else {
      return this.service.notificationService.open('Please fill every required fields.');
    }
  }

  handleInputChange(e) {
    this.imageHandler.getBase64Image(e).then( (res: string) => {
      if (res) {
        this.user.photo = res;
      }
    });
  }
}
