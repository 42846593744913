import {Injectable, Injector} from '@angular/core';
import {DataService} from './data.service';
import {ApiEndpoints} from '../../ApiEndpoints';
import {KeyValuePair} from '../models/key-value.model';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CountryService extends DataService {

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  getItems() {
    return this.getRequest(ApiEndpoints.countries)
      .pipe(map((response: any) => response.map(item => new KeyValuePair(item))));
  }

  getCities(countryId) {
    return this.getRequest(ApiEndpoints.cities.replace('{id}', countryId))
      .pipe(map((response: any) => response.map(item => new KeyValuePair(item))));
  }
}
