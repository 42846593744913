<mat-form-field *ngIf="list.length" [ngClass]="{'w-100': isFullWidth}" appearance="outline" class="header-select">
    <mat-label *ngIf="label">{{label}} <span *ngIf="isMultiple && model && model.length">({{model.length}})</span></mat-label>
    <mat-select
            name="simple-select"
            [(ngModel)]="model"
            [required]="isRequired"
            [multiple]="isMultiple"
            [disabled]="isDisabled"
            [placeholder]="label"
            (ngModelChange)="onModelChange()"
            panelClass="cim-select"
    >
        <mat-option *ngFor="let item of list" [value]="item">{{item}}</mat-option>
    </mat-select>
</mat-form-field>
