import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-portfolio-summary',
  templateUrl: './portfolio-summary.component.html',
  styleUrls: ['./portfolio-summary.component.scss']
})
export class PortfolioSummaryComponent implements OnInit {
  @Input() summaryRaw: any;
  @Input() performanceData: any;
  @Input() benchmark: any = null;
  @Input() etfOnly = false;

  portfolioPerformance: any = null;

  constructor() { }

  ngOnInit(): void {
    this.initPortfolioPerformance();
  }

  initPortfolioPerformance() {
    if (this.performanceData) {
      this.performanceData.map(d => {
        if (d.Ticker == "Portfolio") {
          this.portfolioPerformance = d;
        }
      })
    }
  }
}
