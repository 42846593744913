<div class="print-load-container" *ngIf="isPrinting">
  <app-spinner [isLoading]="isPrinting"></app-spinner>
  <p>Printing...</p>
</div>
<div class="ml-auto text-right" *ngIf="!isPrinting">
  <button type="button" *ngIf="portfolio" mat-flat-button color="primary" class="mr-3" (click)="onPrintClick()">Print</button>
  <app-benchmark-selector (benchmarkSelect)="onBenchmarkSelect($event)"></app-benchmark-selector>
</div>
<div #page1>
  <app-print-header [portfolio]="portfolio"></app-print-header>
  <div class="pb-3">
    <div class="row mb-2">
      <div class="col-md-8">
        <div class="card h-100">
          <div class="card-header">Description</div>
          <div class="card-body">
            <p class="overview-text mb-0" style="font-size: 14px">{{portfolio.description}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card h-100">
          <div class="card-header">Portfolio Characteristics</div>
          <div class="card-body">
            <div class="info-section">
              <div class="info-line">
                <span class="info-title">Start date</span>
                <span class="info-value">{{portfolio.start_date}}</span>
              </div>
              <div class="info-line">
                <span class="info-title">Rebalancing</span>
                <span class="info-value" style="text-transform: capitalize">{{portfolio.rebalancing_type || 'No rebalancing'}}</span>
              </div>
              <div class="info-line">
                <span class="info-title">Cost of Rebalancing</span>
                <span class="info-value">{{portfolio.cost_of_rebalancing}} bps</span>
              </div>
              <div class="info-line">
                <span class="info-title">Currency</span>
                <span class="info-value">{{portfolio.currency.code}}</span>
              </div>
              <div class="info-line">
                <span class="info-title">Weighting</span>
                <span class="info-value" style="text-transform: capitalize">{{portfolio.niceWeightType()}}</span>
              </div>
              <div class="info-line">
                <span class="info-title">Last updated at</span>
                <span class="info-value">{{(portfolio.last_updated| utcToLocalTime:'short') || '-'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!benchmarkCalculating">
      <div class="mb-2"><app-portfolio-summary [summaryRaw]="portfolio.performance_summary" [performanceData]="portfolio.symbol_performance" [benchmark]="selectedBenchmark"></app-portfolio-summary></div>
      <div class="mb-2"><app-performance-card [performanceData]="portfolio.symbol_performance" [benchmark]="selectedBenchmark" [needFooter]="false"></app-performance-card></div>
    </div>
  </div>
</div>


<div #page2>
  <app-print-header [portfolio]="portfolio"></app-print-header>

  <div class="row mb-4">
    <div class="col-md-6">
      <app-cummulative-return-chart [subHeader]="portfolio.name" [plotData]="plotData" [enableDatawrapper]="false" [benchmark]="benchmark" [isPrint]="true"></app-cummulative-return-chart>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">Annual Performance</div>
        <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
        <div class="card-sub-header"><span>Type:</span> Baseline Portfolio</div>
        <div class="card-body ">
          <mat-form-field appearance="outline" class="header-select hide-on-print" style="position: absolute;top: 16px;right: 30px;">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="annualChartType" (ngModelChange)="initAnnualSharpeChart()">
              <mat-option [value]="'annualized_sharpe'">Sharpe Ratio</mat-option>
              <mat-option [value]="'annualized_return'">Return</mat-option>
              <mat-option [value]="'annualized_std_deviation'">Standard Deviation</mat-option>
            </mat-select>
          </mat-form-field>
          <app-line-chart [series]="sharpeChartData" [extraOptions]="annualChartOptions" [isPrint]="true"></app-line-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-2">
    <app-return-charts *ngIf="drawdownData && volatilityData" [volatilityData]="volatilityData" [drawdownData]="drawdownData" [plotData]="plotData" [benchmark]="benchmark" [subHeader]="portfolio.name"></app-return-charts>
  </div>
</div>

<div #page3>
  <app-print-header [portfolio]="portfolio"></app-print-header>
  <div class="row">
    <div class="col-md-6"><app-tree-map-chart *ngIf="treeMapData" [dataRaw]="treeMapData" [subHeader]="portfolio.name"  [isPrint]="true"></app-tree-map-chart></div>
    <div class="col-md-6"><app-tree-map-chart *ngIf="treeMapData" [dataRaw]="treeMapData" [secondary]="true" [subHeader]="portfolio.name" [isPrint]="true"></app-tree-map-chart></div>
  </div>
</div>

<div #page4>
  <app-print-header [portfolio]="portfolio"></app-print-header>
  <app-optimization-sections *ngIf="optimizationData"
                             [isPrint]="true"
                             [portfolio]="portfolio"
                             [optimData]="optimizationData"
                             [correlationMatrixData]="correlationMatrixData"
                             [covarianceMatrixData]="covarianceMatrixData"
  ></app-optimization-sections>
  <app-optimization-sections *ngIf="optimizationData"
                             [isPrint]="true"
                             [portfolio]="portfolio"
                             [optimData]="optimizationData"
                             [correlationMatrixData]="correlationMatrixData"
                             [covarianceMatrixData]="covarianceMatrixData"
                             [matrixDataType]="'correlation'"
  ></app-optimization-sections>
</div>
<div #page5>
  <app-print-header [portfolio]="portfolio"></app-print-header>
  <div class="card">
    <div class="card-header">
      Views
    </div>
    <div class="card-body">
      <app-cim-datatable
        *ngIf="forecastedReturnsTableData.length"
        [columns]="forecastedReturnsTableColumns"
        [data]="forecastedReturnsTableData"
        [footer]="footerData"
        [needPaginator]="false"
      ></app-cim-datatable>
    </div>
  </div>
</div>

<div #page6>
  <app-print-header [portfolio]="portfolio"></app-print-header>

  <div class="card">
    <div class="card-header">Portfolio Performance</div>
    <div class="card-body">
      <table class="table m-0">
        <thead>
        <tr>
          <td >Portfolio</td>
          <td >Expected Return</td>
          <td >Expected Volatility</td>
          <td >Sharpe Ratio</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of optimPerformance">
          <td>{{item.name}}</td>
          <td>{{(item.Expected_Return*100)|number:'1.0-2'}}%</td>
          <td>{{(item.Std_Deviation*100)|number:'1.0-2'}}%</td>
          <td>{{(item.Sharpe_Ratio)|number:'1.0-2'}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-header">
      Optimized Portfolio Weights
    </div>
    <div class="card-body">
      <app-cim-datatable *ngIf="filteredOptimWeights.length" [columns]="optimizedPortfolioWeightsColumns" [data]="filteredOptimWeights" [needPaginator]="false"></app-cim-datatable>
    </div>
  </div>
</div>

<div #page7>
  <app-print-header [portfolio]="portfolio"></app-print-header>
  <div class="card mb-3">
    <div class="row">
      <div class="col">
        <div class="card-header pb-0">Efficient Frontier</div>
        <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
      </div>
    </div>
    <div class="card-body">
      <app-efficient-frontier-chart *ngIf="efficientChartLoaded" [series]="efficientSeries" [isPrint]="true"></app-efficient-frontier-chart>
    </div>
  </div>

  <div class="card ">
    <div class="card-header">Comparative Risk/Return Analysis</div>
    <div class="card-body">
      <app-cim-datatable *ngIf="evaluationTableData" [columns]="evaluationTableColumns" [data]="evaluationTableData" [needPaginator]="false"></app-cim-datatable>
    </div>
  </div>
</div>

<div #page8>
  <app-print-header [portfolio]="portfolio"></app-print-header>

  <div class="row mb-3">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">Annualized Volatility (63d rolling)</div>
        <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
        <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
        <div class="card-body">
          <!-- Daily Return -->
          <app-line-chart *ngIf="volatilityData && volatilityData.series" [series]="volatilityData.series" [extraOptions]="drawDownExtraOptions" [isPrint]="true"></app-line-chart>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">Drawdown & Performance</div>
        <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
        <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
        <div class="card-body">
          <!-- Combo -->
          <app-line-chart *ngIf="comboData" [series]="comboData" [extraOptions]="comboExtraOptions" [isPrint]="true"></app-line-chart>
        </div>
      </div>
    </div>
  </div>


  <div class="row mb-3">
    <div class="col-md-6"><app-weight-pie-chart *ngIf="weightPieData.length" [lists]="{weights: weightPieData}" [subHeader]="{portfolio: portfolio.name, type: selectedEvaluationNiceName}" [isPrint]="true"></app-weight-pie-chart></div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header pb-0">Diversification Effect</div>
        <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
        <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
        <div class="card-body pt-0">
          <app-line-chart *ngIf="riskDiversificationData && riskDiversificationData.series" [series]="riskDiversificationData.series" [extraOptions]="chart3Options" [chartConstructor]="'chart'" [isPrint]="true"></app-line-chart>
        </div>
      </div>
    </div>
  </div>
</div>

<div #page9>
  <app-print-header [portfolio]="portfolio"></app-print-header>
  <div class="mb-3">
    <app-evaluation-charts *ngIf="riskContributionData && correlationBetaData" [riskContributionRaw]="riskContributionData" [correlationBetaRaw]="correlationBetaData" [subHeader]="{portfolio: portfolio.name, type: selectedEvaluationNiceName}"></app-evaluation-charts>
  </div>

  <div class="mb-3">
    <div class="card">
      <div class="card-header pb-0">Marginal Risk Contribution</div>
      <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
      <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
      <div class="card-body pt-0">
        <app-line-chart *ngIf="riskElasticityData" [series]="riskElasticityData" [extraOptions]="riskElasticityOptions" [chartConstructor]="'chart'"></app-line-chart>
      </div>
    </div>
</div>
