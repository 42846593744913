import {
  AfterViewInit,
  Component,
  EventEmitter, Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MY_FORMATS} from "../../../../../shared/services/util.service";
import {PageMeta} from "../../../../../shared/models/page.model";
import {CompanyModel} from "../../../../../shared/models/company.model";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {BasePortfolioCreateComponent} from "../../../base-portfolio-create/base-portfolio-create.component";

@Component({
  selector: 'app-new-portfolio',
  templateUrl: './new-portfolio.component.html',
  styleUrls: ['new-portfolio.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: DateAdapter, useClass: MomentDateAdapter,deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class NewPortfolioComponent extends BasePortfolioCreateComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() meta: PageMeta = {};
  @Input() selected: CompanyModel[] = [];
  @Input() priorityCurrencies = [];
  @Input() hideStartDateText = false;
  @Input() drawer = null;
  @Input() portfolio = null;

  @Output() itemSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() cartClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();

  onCheckChange: EventEmitter<any> = new EventEmitter<any>();

  checkedOnly = false;
  currentTab = 0;
  previousTab;
  shouldResetTab = false;

  constructor(injector: Injector) {
    super(injector);
    this.storage.filterSelected.subscribe((data) => {
      this.dynamicColumns = data;
    });

    this.onCheckChange.subscribe(() => {
      this.checkedOnly = !this.checkedOnly;
    });
  }

  ngOnDestroy() {
    // blowing for unknown reason
    // this.onCheckChange.unsubscribe();
    // this.storage.filterSelected.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.restoreVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      const list = changes.selected.currentValue;

      if (list.length) {
        this.checkMaxStartDate(list);
      }
    }
    if (changes.list) {
      this.checkColumnLists(changes.list);
    }
  }

  toggle() {

    this.storage.sideMenuObjectChange.emit({
      type: 'filters',
      assetType: this.assetType,
      drawer: true
    })
    
  }

  onSelect(data) {
    this.itemSelect.emit(data);
  }

  onSelectAllCompany(selected = true) {
    this.selectAll.emit({
      selected,
      type: this.assetType
    });
  }

  onClickCart() {
    this.cartClick.emit();
  }

  onTabChange(event) {
    this.previousTab = this.currentTab;
    this.currentTab = event.index;
    this.resetFilters();
    switch (this.currentTab) {
      case 0:
        this.shouldResetTab = this.previousTab !== 0;
        this.assetType = 'stock';
        if (this.shouldResetTab) {
          this.resetTab();
        }
        break;
      case 1:
        this.shouldResetTab = this.previousTab !== 1;
        this.assetType = 'fund';
        if (this.shouldResetTab) {
          this.resetTab();
        }
        break;
      case 2:
        this.shouldResetTab = this.previousTab !== 2;
        this.assetType = 'etf';
        if (this.shouldResetTab) {
          this.resetTab();
        }
        break;
      case 3:
        this.onOverview();
        break;
      default:
        this.assetType = 'stock';
    }
    this.storage.sideMenuObjectChange.emit({
      type: 'filters',
      assetType: this.assetType,
      drawer: false
    })
  }

  resetTab() {
    this.loading = true;
    this.meta = {};
    this.list = [];
    this.resetFilters();
    this.restoreVisibility();
    this.onFetchCompanies();
  }

  

  // Fetch market values at start date
  onOverview(openCartAfterReceived = true) {
    const securities = this.selected.map(s => s.security_id);
    if (securities.length && this.portfolio && this.portfolio.start_date) {
      this.service.getMarketValuesAtStart({
        date: this.portfolio.start_date,
        securities: securities
      }).subscribe((resp: any) => {
        if (resp && resp.success) {
          this.portfolio.start_date_fixed = true;
          const mcapList = resp.success;
          this.selected = this.selected.map((company: CompanyModel) => {
            if (mcapList[company.security_id]) {
              company.market_value_start = `${mcapList[company.security_id].mcap/1000}`; // Fix mcp value
            }
            return company
          });

          if (openCartAfterReceived) {
            console.log('HIT ME');
          }
        }
      })
    }
  }

  getDynamicColumns() {

  }

  get mergedItems() {
    if (this.checkedOnly) {
      let type = '';
      if (this.assetType === 'stock') {
        type = 'SHARE';
      } else {
        type = 'FUND';
      }
      this.selected.forEach((element) => {
        element.selected = true;
      })
      return this.selected.filter((asset) => asset.security_type_equity === type);
    }
    return this.list;
    // let idArray = this.selected.map((s) => s.id);
    // return [
    //   ...this.selected,
    //   ...this.list.filter((i) => !idArray.includes(i.id))
    // ];
  }
}
