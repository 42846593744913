<mat-toolbar>
    <h1>Watchlist Create</h1>
    <button [disabled]="!selected.length" class="ml-auto" mat-flat-button color="primary" (click)="onPortfolioCartClick()">
        <mat-icon class="mr-2">work</mat-icon> <span><span class="font-weight-bold">{{selected.length}}</span> watchlist {{selected.length > 1 ? 'items' : 'item'}} selected</span>
    </button>
    <button mat-flat-button type="button" class="ml-2" color="primary" (click)="onSaveWatchlistClick(portfolio)">Save watchlist</button>
</mat-toolbar>
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name of watchlist</mat-label>
                    <input matInput placeholder="Name of watchlist" name="name" [(ngModel)]="portfolio.name" [required]="true">
                </mat-form-field>
            </div>
        </div>

        <app-new-portfolio
                [list]="list"
                [selected]="selected"
                [filterLists]="filterLists"
                [meta]="meta"
                [hideStartDateText]="true"
                [priorityCurrencies]="priorityCurrencies"
                (nextClick)="onNextClick()"
                (getFilters)="getFiltersIn($event)"
                (fetchCompanies)="fetchCompaniesIn($event, 1)"
                (itemSelect)="onCompanySelect($event)"
                (selectAll)="onSelectAll($event)"
                (pageEvent)="onPageEvent($event)"
                (cartClick)="onPortfolioCartClick()"
                (startDateChange)="onMaxStartDateChange($event)"
                (fileUploaded)="onFileUploaded($event)"
        ></app-new-portfolio>
    </div>
</div>
