<mat-toolbar class="pl-3">
  <h1>My profile</h1>
</mat-toolbar>

<div class="row pb-3">
  <div class="col-md-8">
    <div class="card">
      <div class="card-header">Profile Settings</div>
      <div class="card-body">
        <div class="row">
          <mat-form-field class="col-6" appearance="outline">
            <mat-label>First name</mat-label>
            <input matInput placeholder="First name" type="text" name="first-name" id="itemFirstName" [(ngModel)]="item.first_name" cdkFocusInitial required>
          </mat-form-field>
          <mat-form-field class="col-6" appearance="outline">
            <mat-label>Last name</mat-label>
            <input matInput placeholder="Last name" type="text" name="last-name" id="itemLastName" [(ngModel)]="item.last_name" required>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-6" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" type="email" name="email" id="email" [(ngModel)]="item.email" required>
          </mat-form-field>
          <mat-form-field class="col-6" appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput placeholder="Phone" type="text" name="phone" id="phone" [(ngModel)]="item.phone">
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col" appearance="outline">
            <mat-label>Job role</mat-label>
            <input matInput placeholder="Job role" name="job" [(ngModel)]="item.job_description">
          </mat-form-field>
        </div>
          <div class="row">
              <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Password</mat-label>
                  <input matInput placeholder="Password" type="password" name="product-name" id="password" autocomplete="off" [(ngModel)]="item.password">
              </mat-form-field>
              <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Password confirm</mat-label>
                  <input matInput placeholder="Password confirm" type="password" name="product-name" id="password_confirm" [(ngModel)]="item.password_confirm">
              </mat-form-field>
          </div>
<!--        <div class="row mb-4">-->
<!--          <div class="col-md-4">-->
<!--            <div class="d-flex justify-content-start align-items-center pb-5">-->
<!--              <mat-slide-toggle color="primary" [(ngModel)]="item.two_fa_enabled" class="pt-1">-->
<!--                2-Factor Authentication-->
<!--              </mat-slide-toggle>-->
<!--            </div>-->
<!--          </div>-->
<!--          <mat-form-field *ngIf="item.two_fa_enabled" appearance="outline" class="col-md-3">-->
<!--            <mat-label>2FA type</mat-label>-->
<!--            <mat-select [(ngModel)]="item.two_fa_type" placeholder="2FA type" panelClass="cim-select">-->
<!--              <mat-option *ngFor="let t of otpTypes" [value]="t">{{t}}</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
<!--          <div class="col-md-2" *ngIf="item.two_fa_enabled && item.two_fa_type == 'app'" >-->
<!--            <button mat-stroked-button color="primary" class="border-primary rounded-pill ml-2" (click)="onReset2FAClicked()" >Reset 2FA</button>-->
<!--          </div>-->
<!--        </div>-->
        <button mat-flat-button color="primary" (click)="onSaveClick()" >
            <span class="navigation-button">Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
