import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
  name: 'optionalNumber'
})
export class OptionalNumberPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: any, args?: any): any {
    if (!value) {return value; }
    let digitsInfo = '1.0-2';
    if (args) {
      digitsInfo = args;
    }

    if (typeof value == 'number') {
      return this.decimalPipe.transform(value, digitsInfo);
    } else {
      return value;
    }
  }

}
