import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appClickStop]'
})
export class ClickStopDirective {
  @HostListener('click', ['$event'])

  public onClick(event: any): void {
    event.stopPropagation();
  }
}
