<mat-nav-list class="nav-list">
  <div class='top' *ngIf="isPortfolio && portfolio.id">
    <div class="logo-container">
      <a class="navbar-brand mr-2" (click)="onClosePortfolio()"><img src="../../../../../assets/images/Logo-Main.svg" alt="Cimalti"></a>
    </div>
    <a mat-list-item [routerLink]="['/app/portfolio/'+portfolio.id]" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
          <span class="icon-container" routerLinkActive="active">
              <mat-icon  inline=true fontSet="fa" fontIcon="fa-1" aria-label="Edit portfolio"></mat-icon>
          </span>
          <span>Edit portfolio</span>
        </button>
        <span class="icon-right" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
            <mat-icon inline=true svgIcon="assets" aria-label="Edit portfolio"></mat-icon>
        </span>
    </a>
    <div class="under-container">
      <a (click)="scroll('edit-portfolio')" [class.active]="active === 'edit-portfolio'">Edit</a>
      <a (click)="scroll('asset-selection')" [class.active]="active === 'asset-selection'">Select Assets</a>
    <!--   <div class="active">Screener</div>
      <div>Select assets</div> -->
    </div>
    <a mat-list-item [routerLink]="['/app/portfolio-view/'+portfolio.id]" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
          <span class="icon-container">
            <mat-icon inline=true fontSet="fa" fontIcon="fa-2" aria-label="Backtest"></mat-icon>
          </span>
          <span>Backtest</span>
        </button>
        <span class="icon-right" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
          <mat-icon inline=true svgIcon="backtest" aria-label="Backtest"></mat-icon>
        </span>
    </a>
    <div class="under-container">
        <a (click)="scroll('settings')" [class.active]="active === 'settings'">Settings</a>
        <a (click)="scroll('description-card')" [class.active]="active === 'description-card'">Description</a>
        <a (click)="scroll('cumulative_returns')" [class.active]="active === 'cumulative_returns'">Cumulative Return</a>
        <a (click)="scroll('annual_performance')" [class.active]="active === 'annual_performance'">Annual Performance</a>
        <a (click)="scroll('weights_performance')" [class.active]="active === 'weights_performance'">Weights/Performance</a>
        <a (click)="scroll('portfolio_constituents')" [class.active]="active === 'portfolio_constituents'">Portfolio Constituents</a>
        <a (click)="scroll('summary-risk-contribution-chart')" [class.active]="active === 'summary-risk-contribution-chart'">Risk Contribution</a>
        <a (click)="scroll('summary-marginal-risk-contribution-chart')" [class.active]="active === 'summary-marginal-risk-contribution-chart'">Marginal Risk</a>
    </div>
    <a class="third-link" mat-list-item [routerLink]="['/app/portfolio-view/'+portfolio.id+'/optimization']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
          <span class="icon-container">
            <mat-icon inline=true fontSet="fa" fontIcon="fa-3" aria-label="Optimization"></mat-icon>
          </span>
          <span>Optimization</span>
        </button>
        <span class="icon-right" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
          <mat-icon inline=true svgIcon="optimization" aria-label="Optimization"></mat-icon>
        </span>
    </a>
    <div class="under-container big-margin">
      <a (click)="scroll('settings-section-optimized')" [class.active]="active === 'settings-section-optimized'">Settings</a>
      <a (click)="scroll('covariance-section-optimized')" [class.active]="active === 'covariance-section-optimized'">Covariance</a>
      <a (click)="scroll('implied-views-section-optimized')" [class.active]="active === 'implied-views-section-optimized'">Implied views</a>
      <a (click)="scroll('my-views-section-optimized')" [class.active]="active === 'my-views-section-optimized'">My views</a>
      <a (click)="scroll('historical-returns-section-optimized')" [class.active]="active === 'historical-returns-section-optimized'">Historical returns</a>
      <a (click)="scroll('constraints-section-optimized')" [class.active]="active === 'constraints-section-optimized'">Constraints</a>
      <a (click)="scroll('results-section-optimized')" [class.active]="active === 'results-section-optimized'">Results</a>
      <a (click)="scroll('summary-section-optimized')" [class.active]="active === 'summary-section-optimized'">Summary</a>
    </div>
    <a mat-list-item [routerLink]="['/app/portfolio-view/'+portfolio.id+'/factor-analysis']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
          <span class="icon-container">
            <mat-icon inline=true fontSet="fa" fontIcon="fa-4" aria-label="Risk analysis"></mat-icon>
          </span>
          <span>Risk analysis</span>
        </button>
        <span class="icon-right" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
          <mat-icon inline=true svgIcon="risk_analisys" aria-label="Risk analysis"></mat-icon>
        </span>
    </a>
  </div>
  <div class='top' *ngIf="isPortfolio && !portfolio.id">
    <div class="logo-container" >
      <a class="navbar-brand mr-1"  (click)="onClosePortfolio()"><img src="../../../../../assets/images/Logo-Main.svg" alt="Cimalti"></a>
    </div>
    <a mat-list-item [routerLink]="['/app/portfolio-create/select']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
          <span class="icon-container">
              <mat-icon inline=true fontSet="fa" fontIcon="fa-1" aria-label="Assets selection"></mat-icon>
          </span>
          <span>Assets selection</span>
        </button>
        <span class="icon-right">
            <mat-icon inline=true svgIcon="assets" aria-label="Assets selection"></mat-icon>
        </span>
    </a>
    <div class="under-container">
      <div class="active">Screener</div>
      <div>Select assets</div>
    </div>
    <a mat-list-item [routerLink]="['/app/portfolio-view/'+portfolio.id]" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
          <span class="icon-container">
            <mat-icon inline=true fontSet="fa" fontIcon="fa-2" aria-label="Backtest"></mat-icon>
          </span>
          <span>Backtest</span>
        </button>
        <span class="icon-right">
          <mat-icon inline=true svgIcon="backtest" aria-label="Backtest"></mat-icon>
        </span>
    </a>
    <div class="under-container">
        <a (click)="scroll('settings')" [class.active]="active === 'settings'">Settings</a>
        <a (click)="scroll('description-card')" [class.active]="active === 'description-card'">Description</a>
        <a (click)="scroll('cumulative_returns')" [class.active]="active === 'cumulative_returns'">Cumulative Return</a>
        <a (click)="scroll('annual_performance')" [class.active]="active === 'annual_performance'">Annual Performance</a>
        <a (click)="scroll('weights_performance')" [class.active]="active === 'weights_performance'">Weights/Performance</a>
        <a (click)="scroll('portfolio_constituents')" [class.active]="active === 'portfolio_constituents'">Portfolio Constituents</a>
        <a (click)="scroll('summary-risk-contribution-chart')" [class.active]="active === 'summary-risk-contribution-chart'">Risk Contribution</a>
        <a (click)="scroll('summary-marginal-risk-contribution-chart')" [class.active]="active === 'summary-marginal-risk-contribution-chart'">Marginal Risk</a>
    </div>
    <a class="third-link" mat-list-item [routerLink]="['/app/portfolio-view/'+portfolio.id+'/optimization']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
          <span class="icon-container">
            <mat-icon inline=true fontSet="fa" fontIcon="fa-3" aria-label="Optimization"></mat-icon>
          </span>
          <span>Optimization</span>
        </button>
        <span class="icon-right">
          <mat-icon inline=true svgIcon="optimization" aria-label="Optimization"></mat-icon>
        </span>
    </a>
    <div class="under-container big-margin">
      <a (click)="scroll('settings-section-optimized')" [class.active]="active === 'settings-section-optimized'">Settings</a>
      <a (click)="scroll('covariance-section-optimized')" [class.active]="active === 'covariance-section-optimized'">Covariance</a>
      <a (click)="scroll('implied-views-section-optimized')" [class.active]="active === 'implied-views-section-optimized'">Implied views</a>
      <a (click)="scroll('my-views-section-optimized')" [class.active]="active === 'my-views-section-optimized'">My views</a>
      <a (click)="scroll('historical-returns-section-optimized')" [class.active]="active === 'historical-returns-section-optimized'">Historical returns</a>
      <a (click)="scroll('constraints-section-optimized')" [class.active]="active === 'constraints-section-optimized'">Constraints</a>
      <a (click)="scroll('results-section-optimized')" [class.active]="active === 'results-section-optimized'">Results</a>
      <a (click)="scroll('summary-section-optimized')" [class.active]="active === 'summary-section-optimized'">Summary</a>
    </div>
    <a mat-list-item [routerLink]="['/app/portfolio-view/'+portfolio.id+'/factor-analysis']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
          <span class="icon-container">
            <mat-icon inline=true fontSet="fa" fontIcon="fa-4" aria-label="Risk analysis"></mat-icon>
          </span>
          <span>Risk analysis</span>
        </button>
        <span class="icon-right">
          <mat-icon inline=true svgIcon="risk_analisys" aria-label="Risk analysis"></mat-icon>
        </span>
    </a>
    <div class="under-container big-margin">
      <a (click)="scroll('settings-section-optimized')" [class.active]="active === 'settings-section-optimized'">Risk-return</a>
      <a (click)="scroll('covariance-section-optimized')" [class.active]="active === 'covariance-section-optimized'">Contribution</a>
      <a (click)="scroll('implied-views-section-optimized')" [class.active]="active === 'implied-views-section-optimized'">Covariance</a>
      <a (click)="scroll('my-views-section-optimized')" [class.active]="active === 'my-views-section-optimized'">Corelation</a>
      <a (click)="scroll('historical-returns-section-optimized')" [class.active]="active === 'historical-returns-section-optimized'">Historical returns</a>
      <a (click)="scroll('constraints-section-optimized')" [class.active]="active === 'constraints-section-optimized'">Hot spots</a>
      <a (click)="scroll('results-section-optimized')" [class.active]="active === 'results-section-optimized'">Best hedge</a>
      <a (click)="scroll('summary-section-optimized')" [class.active]="active === 'summary-section-optimized'">Drawdown</a>
      <a (click)="scroll('summary-section-optimized')" [class.active]="active === 'summary-section-optimized'">Volatility</a>
    </div>
  </div>
  <div class='top' *ngIf="!isPortfolio">
    <div class="logo-container" >
      <a class="navbar-brand mr-1" (click)="onClosePortfolio()"><img src="../../../../../assets/images/logo-white.svg" alt="Cimalti"></a>
    </div>
    <a mat-list-item  [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
            <mat-icon class="white-icon" inline=true fontSet="fa" fontIcon="fa-chart-simple" aria-label="My portfolios"></mat-icon>
            <span class="navigation-button">My portfolios</span>
        </button>
    </a>
<!--     <a disabled mat-list-item [routerLink]="['/app/watchlist']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
            <mat-icon inline=true fontSet="fa" fontIcon="fa-book-open" aria-label="Watchlists"></mat-icon>
            <span>Watchlists</span>
        </button>
    </a>
    <a disabled mat-list-item [routerLink]="['/app/tutorial_portfolios']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" class="colorful">
        <button mat-icon-button>
            <mat-icon inline=true fontSet="fa" fontIcon="fa-chart-column" aria-label="Tutorial portfolios"></mat-icon>
            <span>Tutorial portfolios</span>
        </button>
    </a>
    <a disabled mat-list-item [routerLink]="['/app/guide']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" class="colorful">
        <button mat-icon-button>
            <mat-icon inline=true fontSet="fa" fontIcon="fa-circle-info" aria-label="FAQ"></mat-icon>
            <span>FAQ</span>
        </button>
    </a>
    <a disabled mat-list-item [routerLink]="['/app/tutorial']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" class="colorful">
        <button mat-icon-button>
            <mat-icon inline=true fontSet="fa" fontIcon="fa-play" aria-label="Tutorial"></mat-icon>
            <span>Tutorial</span>
        </button>
    </a> -->
  </div>
  <div class='bottom' *ngIf="!isPortfolio"> 
<!--    <a disabled mat-list-item [routerLink]="['/app/support']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">-->
<!--        <button mat-icon-button>-->
<!--            <mat-icon inline=true fontSet="fa" fontIcon="fa-circle-question" aria-label="Support"></mat-icon>-->
<!--            <span>Support</span>-->
<!--        </button>-->
<!--    </a>-->
    <a mat-list-item [routerLink]="['/app/settings']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <button mat-icon-button>
            <mat-icon class="white-icon" inline=true fontSet="fa" fontIcon="fa-cog" aria-label="Settings"></mat-icon>
            <span class="navigation-button">Settings</span>
        </button>
    </a>
      <a mat-list-item *ngIf="isAdmin" [routerLink]="['/app/users']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
          <button mat-icon-button>
              <mat-icon class="white-icon" inline=true fontSet="fa" fontIcon="fa-user-tie" aria-label="Admin"></mat-icon>
              <span class="navigation-button">Admin</span>
          </button>
      </a>
    <a mat-list-item (click)="onLogout()">
        <button mat-icon-button>
            <mat-icon class="white-icon" inline=true fontSet="fa" fontIcon="fa-right-from-bracket" aria-label="Sign out"></mat-icon>
            <span class="navigation-button">Sign out</span>
        </button>
    </a>
  </div>
</mat-nav-list>
