import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['../filter-select/filter-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleSelectComponent {

  @Input() model: any;
  @Input() label: string;
  @Input() list: any[] = [];
  @Input() isRequired = false;
  @Input() isMultiple = false;
  @Input() isDisabled = false;
  @Input() isFullWidth = false;
  @Output() modelChanged: EventEmitter<any>;

  constructor() {
    this.modelChanged = new EventEmitter<any>();
  }

  onModelChange() {
    this.modelChanged.emit(this.model);
  }
}
