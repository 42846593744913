import {Component, Input} from '@angular/core';
import {PopoverComponent} from "../popover/popover.component";

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['../popover/popover.component.scss']
})
export class InfoPopoverComponent extends PopoverComponent {

  @Input() title = '';
  @Input() content = '';
  @Input() isMAR = false;
  @Input() isCAGR = false;
  @Input() highlight = '';

  @Input() portfolioCreateContent = null;
}
