import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as moment from "moment";

@Component({
  selector: 'app-return-charts',
  templateUrl: './return-charts.component.html',
  styleUrls: ['./return-charts.component.scss']
})
export class ReturnChartsComponent implements OnInit, OnChanges{
  @Input() plotData: any = {};
  @Input() volatilityData: any = {};
  @Input() drawdownData: any[] = null;
  @Input() benchmark: any = null;
  @Input() benchmarks: any[] = [];
  @Input() subHeader: string = '';
  @Input() isPrint = false;
  @Input() startDate = null;
  @Input() endDate = null;
  @Output() afterDrawdownSetExtremes: EventEmitter<any> = new EventEmitter<any>();

  loaded = false;
  series = [];

  drawDownExtraOptions = {
    yAxis: {
      opposite: false,
      minorTickInterval: 0.01,
      gridLineColor: '#ffff',
      labels: {
        format: '{value}%',
      },
      title: {
        text: 'Drawdown',
        style: {
          textTransform: 'capitalize'
        }
      },
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}%</b>',
        },
        dataGrouping: {
          enabled: false
        }
      }
    }
  }
  volatilitsOptions = {
    ...this.drawDownExtraOptions,
    yAxis: {
      opposite: false,
      minorTickInterval: 0.01,
      labels: {
        format: '{value}%',
      },
      title: {
        text: 'Volatility',
        style: {
          textTransform: 'capitalize'
        }
      },
    },
  }

  constructor() { }

  ngOnInit(): void {
    this.initPlot();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.drawdownData) {
      this.loaded = false;
      this.initPlot()
    }
    if (changes.benchmarks) {
      this.loaded = false;
      this.initPlot()
    }
    if (changes.benchmark) {
      this.loaded = false;
      this.initPlot()
    }
  }

  initPlot() {
    this.series = [];

    const resp = this.plotData

    // const drawdownData = resp.drawdown ? this.extractPlotData(resp.drawdown) : [];
    const drawdownData = this.drawdownData ?
      [{
        name: 'Portfolio',
        data: this.drawdownData,
        type: 'line'
      }] :
      [];

    this.series.push({
      title: '',
      series: JSON.parse(JSON.stringify(this.volatilityData.series))
    })
    this.series.push({
      title: '',
      series: drawdownData
    })

    if (this.benchmark) {
      const drawdownStart = drawdownData[0].data[0][0];
      const volatilityStart = this.volatilityData.series[0].data[0][0];
      this.series[0].series.push(this.extractBenchmarkData(this.benchmark.rolling_std_deviation, this.benchmark.name, volatilityStart));
      this.series[1].series.push(this.extractBenchmarkData(this.benchmark.drawdown, this.benchmark.name, drawdownStart));
    }
    setTimeout(() => {
      this.loaded = true;
    }, 100)
  }

  extractBenchmarkData(list, name, start) {
    // convert string dates to timestamps and value tuples
    const convertedList = [];
    list.map((data: any[]) => {
      const date = data[0];
      if (date >= start) {
        convertedList.push([date, data[1]]);
      }
    })
    return {
      name: name,
      data: convertedList,
      type: 'line'
    };
  }

  extractPlotData(list) {
    let result = [];
    Object.entries(list).map(([name, data]:[string,any]) => {
      let series = {
        name: name,
        data: data.map(d => {
          if (d[1]) {
            d[1] = +(d[1].toFixed(2));
          }
          return d
        }),
        type: 'line'
      }
      result.push(series)
    })
    return result;
  }

  onDrawdownDateChange(event) {
    this.afterDrawdownSetExtremes.emit(event);
  }
}
