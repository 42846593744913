import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PortfolioService} from "../../pages/portfolio/portfolio.service";
import * as moment from "moment";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-etf-info-dialog',
  templateUrl: './etf-info-dialog.component.html',
  styleUrls: ['../company-info-dialog/company-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('100ms ease')),
    ]),
  ]
})
export class EtfInfoDialogComponent implements OnInit {
  closeAdjData: any = [];

  introExpanded = true;
  sankeyExpanded = true;

  introSection = [
    /*{
      id: 'address',
      title: 'Address',
      unit: '',
      type: 'string',
    },
    {
      id: 'web_site',
      title: 'URL',
      unit: '',
      type: 'url',
    },
    {
      id: 'pct_above_52w_low',
      title: '% above 52w low',
      unit: '%',
      type: 'percent',
    },
    {
      id: 'pct_below_52w_high',
      title: '% below 52w high',
      unit: '%',
      type: 'percent',
    },
    {
      id: 'peg_ratio',
      title: '52w position',
      unit: '%',
      type: 'percent',
    },*/
  ];

  sections: any[] = [
    {
      name: 'Key Data',
      list: [
        {
          id: 'aum',
          title: 'AUM',
          unit: 'M$',
          type: 'string'
        },{
          id: 'asset_class',
          title: 'Asset Class',
          unit: '',
          type: 'string'
        },{
          id: 'brand_name',
          title: 'Brand Name',
          unit: '',
          type: 'string'
        },{
          id: 'broad_geo_desc',
          title: 'Broad Geo Description',
          unit: '',
          type: 'string'
        },{
          id: 'category_desc',
          title: 'Category Description',
          unit: '',
          type: 'string'
        },{
          id: 'econ_development_desc',
          title: 'Econ Development Description',
          unit: '',
          type: 'string'
        },{
          id: 'focus_desc',
          title: 'Focus Description',
          unit: '',
          type: 'string'
        },{
          id: 'niche_desc',
          title: 'Niche Description',
          unit: '',
          type: 'string'
        },{
          id: 'segment_desc',
          title: 'Segment Description',
          unit: '',
          type: 'string'
        },{
          id: 'specific_geo_desc',
          title: 'Specific Geo Description',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'name',
          title: 'Name',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'isin',
          title: 'Isin',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'iso_country',
          title: 'Iso country',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'currency',
          title: 'Currency',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'active_desc',
          title: 'Active desc',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'nav_date',
          title: 'Nav date',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'last_update',
          title: 'Last update',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'selection_criteria_desc',
          title: 'Selection criteria desc',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'strategy_desc',
          title: 'Strategy desc',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'weighting_scheme_desc',
          title: 'Weighting scheme desc',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'legal_structure_desc',
          title: 'Legal structure desc',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'actively_managed_flag',
          title: 'Actively managed flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'currency_hedged_flag',
          title: 'Currency hedged flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'inverse_flag',
          title: 'Inverse flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'is_leveraged_flag',
          title: 'Is leveraged flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'distribute_k1_flag',
          title: 'Distribute k1 flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'user_contr_transparent_flag',
          title: 'User contr transparent flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'holdings_transparent_flag',
          title: 'Holdings transparent flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'ucits_compliant_flag',
          title: 'Ucits compliant flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'holds_derivatives_flag',
          title: 'Holds derivatives flag',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'launch_date',
          title: 'Launch date',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'hedged_from_currency',
          title: 'Hedged from currency',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'hedged_into_currency',
          title: 'Hedged into currency',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'leverage_type',
          title: 'Leverage type',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'leverage_factor',
          title: 'Leverage factor',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'expense_ratio',
          title: 'Expense ratio',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'potential_expense_ratio',
          title: 'Potential expense ratio',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'potential_cost_inc_date',
          title: 'Potential cost inc date',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'contract_advisory_fee',
          title: 'Contract advisory fee',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'index_currency',
          title: 'Index currency',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'index_homepage',
          title: 'Index homepage',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'fund_homepage',
          title: 'Fund homepage',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'dividend_date',
          title: 'Dividend date',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'dividend_treatment',
          title: 'Dividend treatment',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'distribution_treatment',
          title: 'Distribution treatment',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'max_lt_capital_gains',
          title: 'Max lt capital gains',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'max_st_capital_gains',
          title: 'Max st capital gains',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'backing_desc',
          title: 'Backing desc',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'synthetic_type',
          title: 'Synthetic type',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'creation_unit_size',
          title: 'Creation unit size',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'creation_unit_cost',
          title: 'Creation unit cost',
          unit: '',
          type: 'string'
        }
        ,{
          id: 'index_name',
          title: 'Index name',
          unit: '',
          type: 'string'
        }
      ],
      expanded: false
    }
  ];

  companyTicker = null;
  companyName = '';
  overview: any = {};

  extraOptions = {
    tooltip: {
      xDateFormat: '%Y-%m-%d',
      valueSuffix: ' USD'
    }
  };

  constructor(
    public dialogRef: MatDialogRef<EtfInfoDialogComponent>,
    private service: PortfolioService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.dialogRef.afterOpened().subscribe(() => {
      this.initDialog();
    });
  }

  initDialog() {
    if (this.data.company) {
      const currency = this.data.company.currency ? this.data.company.currency.code : 'USD';
      this.extraOptions.tooltip.valueSuffix = ` ${currency}`;
      this.companyName = this.data.company.company_name;
      this.overview = this.data.company.etf_reference_table;
      this.fetchAdjChart();
    }
    if (this.data.company.fs_ticker) {
      this.companyTicker = this.data.company.fs_ticker.split('-')[0];
    } else {
      this.service.notificationService.open('Missing company ticker');
    }
  }

  fetchAdjChart() {
    this.service.getCloseAdj(this.data.company.id).subscribe((resp: any) => {
      if (resp.etf_reference) {
        this.overview = resp.etf_reference;
      }
      if (resp.items) {
        const closeAdj = [];
        resp.items.map((r: any) => {
          closeAdj.push([moment(`${r.date} 12:00:00`).unix() * 1000, r.close_adj]);
        });
        this.closeAdjData = [{
          name: this.companyName,
          data: closeAdj,
          tooltip: {
            valueDecimals: 2
          }
        }];
      }
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onToggleAll(expand = true) {
    this.introExpanded = expand;
    this.sankeyExpanded = expand;
    this.sections = this.sections.map(s => {
      s.expanded = expand;
      return s;
    });
  }
}
