import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {

  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(@Inject(PLATFORM_ID) private platformId: any, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));

    this.matIconRegistry.addSvgIcon(
      "optimization",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/optimization.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "backtest",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/backtest.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "risk_analisys",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/risk_analisys.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "assets",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/icons/assets.svg")
    );
  }
}
