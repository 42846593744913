<button mat-icon-button class="dialog-close-btn" (click)="onNoClick()">
    <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title *ngIf="companyName">
    Fund Tear Sheet - {{ companyName || companyTicker }}
</h1>
<div mat-dialog-content>
    <app-line-chart [series]="closeAdjData" [extraOptions]="extraOptions"></app-line-chart>

    <div>
        <h3 class="summary-header">Description</h3>
        <div class="overflow-hidden" *ngIf="incomeDataLoaded">
            <p class="overview-text mt-3">{{ overview['description'] }}</p>
            <div class="info-section">

                <div *ngIf="resp" class="col-md-12 flex">
                    <div class="col-md-6">
                        <div class="info-line">
                            <span class="info-title">ISIN</span>
                            <span class="info-value">{{resp.isin ?? '-'}}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Managing Company</span>
                            <span class="info-value industry-sector">{{ resp.BrandingCompanyName ?? '-' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Domicile</span>
                            <span class="info-value industry-sector">{{resp.DomicileName ?? '-'}}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Price Currency</span>
                            <span class="info-value">{{ resp.BaseCurrency }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Morningstar Category</span>
                            <span class="info-value">{{ resp.GlobalCategoryName }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Morningstar Style</span>
                            <span class="info-value">{{ styles[resp.EquityStyleBox] }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Morningstar Rating</span>
                            <span class="info-value">{{ resp.MorningStarRating ?? '-' }}/5</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Fund Type</span>
                            <span class="info-value">{{ resp.LegalStructure }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Launch Date</span>
                            <span class="info-value">{{ resp.FundInceptionDate }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Fee Level</span>
                            <span class="info-value">{{ feeLevel[resp.FeeLevel] }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">UCITS</span>
                            <span class="info-value">{{ resp.UCITS == 0 ? 'No' : 'Yes' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Fund of Funds</span>
                            <span class="info-value">{{ resp.FundOfFunds == 0 ? 'No' : 'Yes' }}</span>
                        </div>

                    </div>

                    <div class="col-md-6">
                        <div class="info-line">
                            <span class="info-title">AUM</span>
                            <span class="info-value">{{ resp.FundTNAVDayEnd | number: '1.0-0'}}M$</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Price date</span>
                            <span class="info-value">{{closeAdjData[0].data.slice(-1)[0][0] | date: 'MMM d, y' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">Last Price</span>
                            <span class="info-value">{{closeAdjData[0].data.slice(-1)[0][1] | number: '1.2-2' }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">52 Week High</span>
                            <span class="info-value">{{ resp.BaseCurrency }} {{ resp['52WeekHigh'] }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">52 Week Low</span>
                            <span class="info-value">{{ resp.BaseCurrency }} {{ resp['52WeekLow'] }}</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">1 Month return</span>
                            <span class="info-value">{{ resp.ReturnM1 }}%</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">3 Months return</span>
                            <span class="info-value">{{ resp.ReturnM3 }}%</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">YTD return</span>
                            <span class="info-value">{{ resp.ReturnM0 }}%</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">1 Year return</span>
                            <span class="info-value">{{ resp.ReturnM12 }}%</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">3 Years return</span>
                            <span class="info-value">{{ resp.ReturnM36UA }}%</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">5 Years return</span>
                            <span class="info-value">{{ resp.ReturnM60UA }}%</span>
                        </div>
                        <div class="info-line">
                            <span class="info-title">10 Years return</span>
                            <span class="info-value">{{ resp.ReturnM120UA }}%</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

<!--    <div>-->
<!--        <h3 class="summary-header">Valuation/Ratios</h3>-->
<!--        <div class="overflow-hidden" *ngIf="isData">-->
<!--            <div class="col-md-12 flex">-->
<!--                <div class="col-md-6">-->
<!--                    <div class="info-section">-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Market Capitalization</span>-->
<!--                            <span class="info-value">-->
<!--                                {{ isData[0].currency}} {{ isData[0].ff_mcap | number: '1.0-0' }}M-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Net Debt</span>-->
<!--                            <span class="info-value">-->
<!--                                {{ isData[0].currency}} {{ isData[0].ff_net_debt | number: '1.0-0' }}M-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Price to Earnings ratio (P/E)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_pe | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Price to Sales ratio (P/S)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_psales | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Price to Book ratio (P/B)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_pbk | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Price to Free Cash Flow (P/FCF)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_pfcf | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Debt/EBITDA</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_debt_ebitda_oper | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Altman Z-Score</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_zscore | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Piotroski F-Score</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_fscore | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="col-md-6">-->
<!--                    <div class="info-section">-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Enterprise Value</span>-->
<!--                            <span class="info-value">-->
<!--                                {{ isData[0].currency}} {{ isData[0].ff_entrpr_val | number: '1.0-0' }}M-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Capital Expenditure</span>-->
<!--                            <span class="info-value">-->
<!--                                {{ isData[0].currency}} {{ isData[0].ff_capex | number: '1.0-0' }}M-->
<!--                            </span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Return on Average Total Equity (ROE)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_roe | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Return Average Invested Capital (ROIC)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_roic | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Return on Common Equity (ROCE)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_roce | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Return on Average Total Capital (ROTC)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_rotc | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Enterprise Value to Sales (EV/S)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_entrpr_val_sales | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Enterprise Value to EBITDA (EV/EBITDA)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_entrpr_val_ebitda_oper | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                        <div class="info-line">-->
<!--                            <span class="info-title">Enterprise Value to EBIT (EV/EBIT)</span>-->
<!--                            <span class="info-value">{{ isData[0].ff_entrpr_val_ebit_oper | number: '1.1-1' }}</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div>-->
<!--        <h3 class="summary-header cursor-pointer">Income Statement</h3>-->
<!--        <div class="overflow-hidden">-->
<!--            <app-cim-datatable *ngIf="incomeDataLoaded"-->
<!--                               [columns]="incomeStatementColumns"-->
<!--                               [data]="incomeStatementData"-->
<!--                               [sortDisabled]="true"-->
<!--                               [disableRightPadding]="true"-->
<!--                               ></app-cim-datatable>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="mt-2">-->
<!--        <h3 class="summary-header">Balance Sheet</h3>-->
<!--        <div class="overflow-hidden">-->
<!--            <app-cim-datatable *ngIf="incomeDataLoaded"-->
<!--                               [columns]="balanceColumns"-->
<!--                               [data]="balanceData"-->
<!--                               [sortDisabled]="true"-->
<!--                               [disableRightPadding]="true"-->
<!--            ></app-cim-datatable>-->
<!--        </div>-->
<!--    </div>-->

    <!--  <h3 class="summary-header mt-3 cursor-pointer" *ngIf="data.company && data.company.rbics" (click)="sankeyExpanded = !sankeyExpanded">-->
    <!--    RBICS Revenue Distribution-->
    <!--    <span class="text-primary float-right ">{{sankeyExpanded ? 'Hide content' : 'Show content'}}<mat-icon class="align-bottom">{{sankeyExpanded ? 'expand_less' : 'expand_more'}}</mat-icon></span>-->
    <!--  </h3>-->
    <!--  <div *ngIf="data.company && data.company.rbics && closeAdjData && closeAdjData.length" [@detailExpand]="sankeyExpanded ? 'expanded' : 'collapsed'" class="overflow-hidden">-->
    <!--    <app-sankey-chart [rbics]="data.company.rbics" [companyName]="companyName"></app-sankey-chart>-->
    <!--  </div>-->

<!--    <div *ngFor="let section of sections">-->
<!--        <h3 class="summary-header mt-3 cursor-pointer" (click)="section.expanded = !section.expanded">-->
<!--            {{ section.name }}-->
<!--            <span class="text-primary float-right ">{{ section.expanded ? 'Hide content' : 'Show content' }}-->
<!--                <mat-icon class="align-bottom">{{ section.expanded ? 'expand_less' : 'expand_more' }}</mat-icon></span>-->
<!--        </h3>-->
<!--        <div [@detailExpand]="section.expanded ? 'expanded' : 'collapsed'" class="overflow-hidden">-->
<!--            <app-info-block [list]="section.list" [overview]="overview"></app-info-block>-->
<!--        </div>-->
<!--    </div>-->
    <div class="mt-4">
        <button mat-flat-button class="float-right" color='primary' (click)="onNoClick()"><span class="navigation-button" >Close</span></button>
    </div>
</div>
