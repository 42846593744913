import {Component, Injector, OnInit} from '@angular/core';
import {PortfolioCreateComponent} from "../portfolio-create.component";

@Component({
  selector: 'app-page-generate',
  templateUrl: './page-generate.component.html',
  styleUrls: [
    '../portfolio-create.component.scss',
    './page-generate.component.scss']
})
export class PageGenerateComponent extends PortfolioCreateComponent {

  constructor(injector: Injector) {
    super(injector);
  }

  initPage() {
    this.selectedPortfolioType = 2;
  }

  fetchCompaniesIn(filter?, page?, isPageEvent?) {
    let extraFilters: any = {
      page: page ? page : this.currentPage
    }
    if (filter) {
      if (filter.searchTerm) {
        extraFilters.search_term = filter.searchTerm;
      }
      if (filter.page) {
        extraFilters.page = filter.page;
      }
      // type is the etf/stock type
      if (filter.type) {
        extraFilters.type = filter.type;
        delete filter.type;
      }
      if (filter.limit) {
        extraFilters.limit = filter.limit;
        delete filter.limit;
      }
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp, isPageEvent);
    })
  }

}
