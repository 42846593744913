import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../users.service';

@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent implements OnInit {

  company: any = {
    name: '',
    two_fa_enabled: false
  };

  isAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<CompanyDialogComponent>,
    private service: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.company) {
      this.company = Object.assign({}, this.data.company);
    }
    this.isAdmin = this.data.isAdmin;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    if (this.company.name) {
      this.service.saveCompany(this.company).subscribe((resp: any) => {
        if (resp.error) {
          return this.service.notificationService.open(resp.error);
        }
        return this.dialogRef.close(resp);
      });
    } else {
      return this.service.notificationService.open('Please fill every required fields.');
    }
  }
  deleteCompany() {
    this.service.deleteCompany(this.company).subscribe(resp => {
      this.service.notificationService.open('Company deleted');
      this.dialogRef.close({deleted: true});
    });
  }
  confirmDeleteCompany() {
    this.service.confirmAction().subscribe(confirm => {
      if (confirm) {
        this.deleteCompany();
      }
    });
  }
}
