<div class="fixed-header-button-container">
    <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="onOptimizeClick(true, true)">Save Inputs</button>
</div>
<mat-toolbar style="position: relative">
  <h1>Portfolio Views</h1>
  <div class="d-inline-block ml-4 pt-4" *ngIf="dataLoaded">
    <div class="row">
      <div class="col divider-right">
          <app-cim-datepicker class="header-select" label="Start date" [minDate]="portfolio.start_date" [model]="optimizationData.optim_parameters.return_start_date" (modelChanged)="optimizationData.optim_parameters.return_start_date = $event;"></app-cim-datepicker>
      </div>
      <div class="col">
          <app-cim-datepicker class="header-select" label="End date" [minDate]="portfolio.start_date" [model]="optimizationData.optim_parameters.return_end_date" (modelChanged)="optimizationData.optim_parameters.return_end_date = $event;"></app-cim-datepicker>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Risk Free Rate</mat-label>
          <input matInput [(ngModel)]="optimizationData.optim_parameters.risk_free_rate" type="number" step="0.01">
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div class="col">
          <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="saveInputsAndTriggerHistoricalCalc()">Update Views</button>
      </div>
    </div>
  </div>
</mat-toolbar>
<div class="pb-3" *ngIf="dataLoaded">
  <h2 class="section-title">1. Set Your Views</h2>
  <app-views-setter
    [portfolio]="portfolio"
    [sectors]="constraintLists.sectors"
    [assets]="constraintLists.assets"
    [countries]="constraintLists.countries"
    [views]="optimizationData.views"
  ></app-views-setter>

  <h2 class="section-title">2. Define the Covariance</h2>
  <app-optimization-sections *ngIf="optimizationData && filterLists"
                               [portfolio]="portfolio"
                               [optimData]="optimizationData"
                               [correlationMatrixData]="correlationMatrixData"
                               [covarianceMatrixData]="covarianceMatrixData"
                               [correlationMatrixTableData]="correlationMatrixTableData"
                               [covarianceMatrixTableData]="covarianceMatrixTableData"
                               [filterLists]="filterLists"
    ></app-optimization-sections>

  <h2 class="section-title">3. Update view</h2>
  <div class="card">
        <div class="card-header">
            Views
            <app-simple-select class="ml-2" *ngIf="filterLists.countryList.length" [list]="filterLists.countryList" label="Country" [isMultiple]="true" [model]="filter.country" (modelChanged)="filter.country = $event; onFilterChange()"></app-simple-select>
            <app-simple-select class="ml-2" *ngIf="filterLists.sectorList.length" [list]="filterLists.sectorList" label="Sector" [isMultiple]="true" [model]="filter.sector" (modelChanged)="filter.sector = $event; onFilterChange()"></app-simple-select>
            <app-simple-select class="ml-2" *ngIf="filterLists.typeList.length && filterLists.typeList.length > 1" [list]="filterLists.typeList" label="Type" [isMultiple]="true" [model]="filter.type" (modelChanged)="filter.type = $event; onFilterChange()"></app-simple-select>
        </div>
        <div class="card-body">
            <app-chart-placeholder *ngIf="!forecastedReturnsTableData.length" [loaded]="forecastedReturnsTableData.length > 0"></app-chart-placeholder>
            <app-cim-datatable
                    *ngIf="viewTablesLoaded && forecastedReturnsTableData.length"
                    [columns]="forecastedReturnsTableColumns"
                    [data]="filteredForecastedReturnsTableData"
                    [footer]="footerData"
                    (inputChange)="onForecastedReturnInputChange()"></app-cim-datatable>
        </div>
    </div>
</div>
