import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {PortfolioService} from "../../portfolio/portfolio.service";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-performance-card',
  templateUrl: './performance-card.component.html',
  styleUrls: ['./performance-card.component.scss']
})
export class PerformanceCardComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input() performanceData: any[] = [];
  @Input() benchmark: any = null;
  @Input() needFooter = true;


  displayedColumns: string[] = [];
  defaultColumns = ['company', 'isin', 'sector', 'industry', 'Weight', 'CAGR', 'Volatility', 'Max Drawdown', 'Sharpe'];
  benchmarkRelatedColumns = ['TrackingError', 'Beta', 'Alpha'];

  dataSource = new MatTableDataSource<any>();

  filter: any = {};
  portfolioHasETF = false;
  typeList: any[] = [];
  filteredData: any[] = [];

  ngOnInit(): void {
    this.initComponent();
  }

  constructor(
    private service: PortfolioService,
    private dialog: MatDialog
  ) { }

  extractSortableData() {
    this.performanceData = this.performanceData.map((d: any) => {
      return {
        ...d,
        company: d.reference_table ? d.reference_table.company_name : '',
        isin: d.reference_table ? d.reference_table.isin : '',
        sector: d.reference_table ? d.reference_table.sector.name : '',
        industry: d.reference_table ? d.reference_table.industry.name : '',
      }
    })
  }

  initComponent() {
    this.typeList = this.service.typeList;
    this.displayedColumns = this.benchmark ? [...this.defaultColumns, ...this.benchmarkRelatedColumns] : this.defaultColumns;
    this.extractSortableData();
    this.performanceData = this.performanceData.filter(p => p.Ticker != 'Portfolio');

    this.performanceData.map(d => {
      if (d.reference_table && d.reference_table.security_type_equity) {
        if (d.reference_table.security_type_equity === "ETF") {
          this.portfolioHasETF = true;
        }
      }
    });
    this.filteredData = [...this.performanceData];

    this.dataSource = new MatTableDataSource(this.filteredData);
    if (this.needFooter) {
      this.dataSource.paginator = this.paginator;
    }
    this.sort.sort(({ id: 'Weight', start: 'desc'}) as MatSortable);
    this.dataSource.sort = this.sort;
  }

  onFilterChange() {
    if (this.filter.type && this.filter.type.length) {
      this.filteredData = [...this.performanceData].filter(d => this.filter.type.includes(d.reference_table.security_type_equity));
    } else {
      this.filteredData = [...this.performanceData];
    }
    this.dataSource = new MatTableDataSource(this.filteredData);
    if (this.needFooter) {
      this.dataSource.paginator = this.paginator;
    }
  }

  onCompanyInfoClick(company) {
    this.service.openCompanyInfoDialog(company.Ticker);
  }
}
