import { Component, OnInit } from '@angular/core';
import {User} from '../../shared/models/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileService} from '../../shared/services/profile.service';
import {ImageHandlerService} from '../../shared/services/image-handler.service';
import {MatDialog} from '@angular/material/dialog';
import {OtpResetDialogComponent} from './otp-reset-dialog/otp-reset-dialog.component';
import {DatawrapperHelpDialogComponent} from "./datawrapper-help-dialog/datawrapper-help-dialog.component";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public id: number = null;
  public item: User = new User();
  public image: any = null;
  public qrCodeSrc = null;

  otpTypes = ['app', 'email'];

  constructor(
    private service: ProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private imageHandler: ImageHandlerService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchItem();
  }

  fetchItem() {
    return this.service.getItem().subscribe(result => {
      this.item = result;
    });
  }

  onSaveClick() {
    if (!this.item.isValid()) {
      this.service.notificationService.invalidForm();
      return;
    }
    const photoCache = this.item.photo ? this.item.photo : null;

    this.service.save(this.item).subscribe(response => {
      if (response.error) {
        return this.service.notificationService.open(response.error)
      }
      this.service.notificationService.open('Saved!');
      this.item.photo = photoCache;
    });
  }

  open2faResetDialog(qrCode) {
    const dialogRef = this.dialog.open(OtpResetDialogComponent, {
      data: { qrCode },
      maxWidth: '400px',
      autoFocus: false,
      width: '100%',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe( (resp: any) => {
      this.onSaveClick();
    });
  }

  onDatawrapperHelpClick() {
    this.dialog.open(DatawrapperHelpDialogComponent, {
      maxWidth: '800px',
      disableClose: true
    });
  }

  onReset2FAClicked() {
    this.service.getTwoFAReset().subscribe((response: any) => {
      if (response.success) {
        this.open2faResetDialog(response.success.qrCodeSrc);
      } else {
        this.service.notificationService.open(response.error ? response.error : 'Something went wrong.');
      }
    });
  }

  handleInputChange(e) {
    this.imageHandler.getBase64Image(e).then( (res: string) => {
      if (res) {
        this.item.photo = res;
      }
    });
  }
}
