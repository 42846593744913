import { Injectable, Injector } from '@angular/core';
import { DataService } from "./data.service";
import { ConstraintModel } from "../models/constraint.model";
import { ApiEndpoints } from "../../ApiEndpoints";

@Injectable({
  providedIn: 'root'
})
export class ConstraintService extends DataService {

  constructor(injector: Injector) {
    super(injector)
  }

  create(entity: ConstraintModel) {
    if (entity.id) {
      return this.putRequest(ApiEndpoints.portfolioConstraint, entity);
    } else {
      return this.postRequest(ApiEndpoints.portfolioConstraint, entity);
    }
  }

  delete(entity: ConstraintModel) {
    return this.deleteRequest(ApiEndpoints.portfolioConstraint, entity.id);
  }
}
