import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LineChartComponent} from "../../components/line-chart/line-chart.component";
import * as moment from "moment";
import {high_colors} from "../general-chart/general-chart.component";
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-cummulative-return-chart',
  templateUrl: './cummulative-return-chart.component.html',
  styleUrls: ['../../components/line-chart/line-chart.component.scss']
})
export class CummulativeReturnChartComponent extends LineChartComponent implements OnInit, OnChanges {
  isHighcharts = typeof Highcharts === 'object';
  @Input() plotData: any = {};
  @Input() subHeader: string = '';
  @Input() chartStart: any = null;
  @Input() chartEnd: any = null;
  @Input() highlightStart: any = null;
  @Input() highlightEnd: any = null;
  @Input() isPrint = false;

  @Input() startDate = null;
  @Input() endDate = null;

  workingPlotData: any = {};
  portfolioStart: any = null;

  series = [];

  seriesUpdateFunc = undefined;
  seriesUpdateDelay = 250; // mili seconds

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.startDate || changes.endDate) {
      this.updateDates();
    }
    if (changes.chartStart || changes.chartEnd) {
      this.loaded = false;
      if (this.chartStart && this.chartEnd) {
        this.initPlot();
      }
    }
    if (changes.plotData) {
      this.loaded = false;
      this.initPlot()
    }
    if (changes.benchmarks) {
      this.loaded = false;
      this.initPlot()
    }
    if (changes.benchmark) {
      this.loaded = false;
      this.initPlot()
    }
  }

  ngOnInit(): void {
    
    this.initPlot();
  }

  initPlot() {
    if (!this.plotData) {
      return false;
    }
    this.workingPlotData = JSON.parse(JSON.stringify(this.plotData)); // Do not mess with original data
    this.series = this.workingPlotData.cumulative_returns
      ? this.extractPlotData(this.workingPlotData.cumulative_returns, this.chartStart, true)
      : [];

    if (this.series.length) {
      // this.series[0].data = this.series[0].data.map((e) => {
      //   return [e[0], e[1] - 100];
      // });
      const portfolio = this.series[0];
      if (portfolio.data && !this.chartStart) {
        this.chartStart = portfolio.data[0][0];
        this.chartEnd = portfolio.data[portfolio.data.length - 1][0];
      }
    }
    let benchCumRet: any = null;
    if (this.benchmark) {
      benchCumRet = this.extractBenchmarkData(this.benchmark.cumulative_returns, this.benchmark.name_short, this.chartStart);
    }

    if (benchCumRet) {
      this.series.push(benchCumRet)
    }
    this.series[0].data.map(item => item[1] -= 100);
    this.series[0].type = 'area';
  //  this.series[0].xAxis = 1;
    this.series[0].fillColor  = {
              linearGradient : [0, 0, 0, 300],
              stops : [
                [0, Highcharts.getOptions().colors[0]],
                [1, 'rgba(2,0,0,0)']
              ]
            };
    this.initChart();
  }

  initChart() {
    const updateSeries = (start, end) => {
      this.chartStart = start;
      this.chartEnd = end;
      this.initPlot();
    };

    let highlight = null;

    if (this.highlightStart && this.highlightEnd) {
      highlight = {
        color: '#eeeeee',
        from: this.highlightStart,
        to: this.highlightEnd
      }
    }

    this.chartOptions = {
      chart: {
        height: this.isPrint ? '500px' : '700px',
        type: 'area',
        zoomType: 'x',
        resetZoomButton: {
            position: {
                // align: 'right', // by default
                // verticalAlign: 'top', // by default
                x: 0,
                y: -30
            }
        }

    //    styledMode: true
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      navigator: {
         enabled: false,
         maskInside: false,
        // height: 50
      },

      rangeSelector: {
        buttons: [{
          type: 'month',
          count: 1,
          text: '1m',
          title: 'View 1 month'
        },
          {
          type: 'month',
          count: 3,
          text: '3m',
          title: 'View 3 months'
        },
          {
          type: 'month',
          count: 6,
          text: '6m',
          title: 'View 6 months'
        },
          {
          type: 'ytd',
          text: 'YTD',
          title: 'View year to date'
        },
          {
          type: 'year',
          count: 1,
          text: '1y',
          title: 'View 1 year'
        },
          {
            type: 'year',
            count: 3,
            text: '3y',
            title: 'View 3 years'
          },
          {
            type: 'year',
            count: 5,
            text: '5y',
            title: 'View 5 years'
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
            title: 'View 10 years'
          },
          {
          type: 'all',
          text: 'All',
          title: 'View all'
        }]
      },
      colors: high_colors,
      scrollbar: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: '',
          style: {
            textTransform: 'capitalize'
          }
        },
        // gridLineWidth: 1,
        // minorGridLineWidth: 1,
        min: this.chartStart,
        max: this.chartEnd,
        plotBands: highlight ? [highlight] : null,
        events: {
          afterSetExtremes(e) {
            updateSeries(e.min, e.max);
          }
        },
        crosshair: {
          snap:false,
          color:'#c18b94',
          label: {
            formatter: function(e) {
              return moment(e).format('YYYY-MM-DD')
            },
            enabled: false
          }
        },
      },
      yAxis: [
        {
          opposite: false,
          //gridLineWidth: 1,
          minorGridLineWidth: 0,
          // labels: {
          //   formatter: function () {
          //         const label = this.axis.defaultLabelFormatter.call(this);


          //         return (label-100) + "%";
          //     }
          //},
          title: {
            text: 'Return',
            style: {
              textTransform: 'capitalize',
              fontFamily: 'os400'
            },
          },
          labels: {
            format: '{text}%'
          }

        },
        {
          opposite: true,
          linkedTo: 0,
          minorGridLineWidth: 0,
          title: {
            text: 'Return',
            style: {
              textTransform: 'capitalize',
              fontFamily: 'os400'
            },
          },
          labels: {
            format: '{text}%'
          }

        }
      ],
      legend: {
        enabled: true,
      },

      tooltip: {
        formatter: function () {
            return this.points.reduce(function (s, point) {
              return s+  '<span style="color:' + point.color + '">'+ point.series.name + ' </span>: <b>' +
                    (point.y).toFixed(2) + '%</b><br/>';
           //     return s + '<br/>' + point.series.name + ': ' +  (point.y-100).toFixed(2) + '%';
            }, '<b>' + moment(this.x).format('YYYY-MM-DD') + '</b><br/>');
          },
        shared: true
        //   pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
              
        // //split: false,
        // valueSuffix: '%',
        // valueDecimals: 2,
      },
      series: this.series,
      plotOptions: {  
        // area: {
        //         fillColor: {
        //             linearGradient: {
        //                 x1: 0,
        //                 y1: 0,
        //                 x2: 0,
        //                 y2: 1
        //             },
        //             stops: [
        //                 [0, Highcharts.getOptions().colors[0]],
        //                 [1, '#0000']
        //             ]
        //         },
        //         marker: {
        //             radius: 2
        //         },
        //         lineWidth: 1,
        //         states: {
        //             hover: {
        //                 lineWidth: 1
        //             }
        //         },
        //         threshold: null
        //     },

        column: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          }
        },
        series: {
          lineWidth: 2,
          dataGrouping: {
            enabled: false
          },
          label: {
            enabled: false,
          }
        }
      },
    };
    if (this.fullHeight) {
      this.chartOptions.chart.height = '96%';
    }
    if (this.extraOptions) {
      this.chartOptions = {
        ...this.chartOptions,
        ...this.extraOptions
      };
    }
    if(typeof Highcharts === 'object') {
      setTimeout(() => this.loaded = true, 20);
    }
  }

  extractPlotData(list, start?, saveStart = false) {
    if (saveStart) {
      this.portfolioStart = list['Portfolio'][0][0]; // First item, first column is the start date
    }
    return this.util.extractPlotData(list, start);
  }

  extractBenchmarkData(list, name, start?) {
    
    // convert string dates to timestamps and value tuples
    let convertedList = list.map((data: any) => {
      return [data.date, (data.value)];
    });
    if (start) {
      // Skip benchmark values that older then portfolio start
      convertedList = convertedList.filter(d => d[0] >= start);
      const initalPortfolioValue = this.util.getClosestDate(convertedList, this.series[0].data) // Get the value at the cloest date
      if (initalPortfolioValue) {
        const benchValue = convertedList[0][1];
        convertedList = convertedList.map(d => {
          d[1] = ((d[1] / benchValue)-1)*100;
          return d;
        });
      }
    }

    return {
      name: name ?? 'Benchmark',
      data: convertedList,
      type: 'line'

    };
  }

  stopSeriesUpdate() {
    if (typeof this.seriesUpdateFunc !== 'undefined') {
      clearTimeout(this.seriesUpdateFunc);
      this.seriesUpdateFunc = undefined;
    }
  }
}
