import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {PageMeta} from "../../shared/models/page.model";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() meta: any;
  @Output() pageEvent: EventEmitter<any>;

  items: number[] = [];
  befores: number[] = [];
  afters: number[] = [];

  pages = 5;

  constructor() {
    this.pageEvent = new EventEmitter<any>();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void {
    if (changes.meta) {
      this.meta = new PageMeta(changes.meta.currentValue);
      this.initComponent();
    }
  }

  initComponent() {
    this.befores = [];
    this.afters = [];

    // get before pages
    let page = 0;
    const current = this.meta.current_page;
    const last = this.meta.last_page;
    for (let i = this.pages; i > 0; i--) {
      page = current - i;
      if (page > 0) {
        this.befores.push(page);
      }
    }
    // get after pages
    for (let i = 1; i <= this.pages; i++) {
      page = current + i;
      if (page <= last) {
        this.afters.push(page);
      }
    }
  }

  onPageEvent(page) {
    this.pageEvent.emit(page);
  }

  onNextClick() {
    let index = this.meta.current_page + 1;
    if (index > this.meta.last_page) {
      index = this.meta.last_page
    } else {
      this.onPageEvent(index);
    }
  }
  onPrevClick() {
    let index = this.meta.current_page - 1;
    if (index < 1) {
      index = 1;
    } else {
      this.onPageEvent(index);
    }
  }

  onLastClick() {
    this.onPageEvent(this.meta.last_page);
  }

  onFirstClick() {
    this.onPageEvent(1);
  }
}
