import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-chart-editor-dialog',
  templateUrl: './chart-editor-dialog.component.html',
  styleUrls: ['./chart-editor-dialog.component.scss']
})
export class ChartEditorDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ChartEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
