import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-summary-cards',
  templateUrl: './summary-cards.component.html',
  styleUrls: ['./summary-cards.component.scss']
})
export class SummaryCardsComponent implements OnInit {
  @Input() summaryRaw: any;
  @Input() benchmark: any;
  @Input() portfolioPerformance: any = null;
  @Input() title: string = null;
  @Input() property: string = 'Portfolio';
  @Input() etfOnly = false;

  summaryRawLabels = [
    'Annualized Return',
    'Annualized Std Dev',
    'Max Drawdown'    //'Worst Drawdown'
  ];

  summaryLabels = [
    'Annualized Return',
    'Annualized Volatility',
    'Maximum Drawdown'
  ];

  constructor() { }

  ngOnInit(): void {
  }
}
