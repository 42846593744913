import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {ApiEndpoints} from '../../ApiEndpoints';
import {KeyValuePair} from '../models/key-value.model';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StockService extends DataService {

  getItems() {
    return this.getRequest(ApiEndpoints.stocks)
  }

  getCharts(symbol) {
    return this.getRequest(ApiEndpoints.charts.replace('{id}', symbol))
  }

  getStock(symbol) {
    return this.getRequest(ApiEndpoints.stock.replace('{id}', symbol));
  }

  getChartsByType(symbol, type) {
    return this.getRequest(ApiEndpoints.charts.replace('{id}', symbol), type)
  }

  getDiscoverFilters() {
    return this.getRequest(ApiEndpoints.discoveryFilters)
  }
  postDiscover(filters) {
    return this.postRequest(ApiEndpoints.discovery, filters)
  }
  postPlayground(filters) {
    return this.postRequest(ApiEndpoints.playground, filters)
  }
}
