import {Component, ElementRef, Injector, ViewChild, ViewEncapsulation} from '@angular/core';
import {OverviewComponent} from "../../overview/overview.component";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {ColumnType} from "../../../components/cim-datatable/cim-datatable.component";
import * as moment from "moment";
import {high_colors} from "../../../charts/general-chart/general-chart.component";

@Component({
  selector: 'app-print-overview',
  templateUrl: './print-overview.component.html',
  styleUrls: ['./print-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrintOverviewComponent extends OverviewComponent{

  @ViewChild('page1') page1!: ElementRef;
  @ViewChild('page2') page2!: ElementRef;
  @ViewChild('page3') page3!: ElementRef;
  @ViewChild('page4') page4!: ElementRef;
  @ViewChild('page5') page5!: ElementRef;
  @ViewChild('page6') page6!: ElementRef;
  @ViewChild('page7') page7!: ElementRef;
  @ViewChild('page8') page8!: ElementRef;
  @ViewChild('page9') page9!: ElementRef;

  forecastedReturnsTableData: any[] = [];
  historicalReturnTableData: any[] = [];
  marketEquilibriumTableData: any[] = [];
  impliedReturnData = [];

  companyReturns: any = {};
  viewReturns: any = {}

  forecastedReturnsTableColumns: any[] = [
    {id: 'company_name', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'Ticker', label: 'Ticker', type: ColumnType.Simple, inputDisabled: true, canHide: true},
    {id: 'Weight', label: 'Weight', type: ColumnType.Percentage},
    {id: 'weight_marketcap', label: 'Marketcap Weight', type: ColumnType.Number2Decimal, suffix: '%'},
    {id: 'volatility', label: 'Volatility', type: ColumnType.Number2Decimal, suffix: '%'},
    {id: 'historical_returns', label: 'Historical Returns', type: ColumnType.Number2Decimal, suffix: '%'},
    {id: 'implied_returns', label: 'Implied Return', type: ColumnType.Number2Decimal, suffix: '%'},
    {id: 'market_equilibrium', label: 'Market Equilibrium Return', type: ColumnType.Number2Decimal, suffix: '%'},
    {id: 'forecasted_returns', label: 'Forecasted Return', type: ColumnType.Number2Decimal, suffix: '%'},
  ];

  optimizedPortfolioWeightsColumns = [
    {id: 'company_name', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'Ticker', label: 'Ticker', type: ColumnType.Simple},
    {id: 'minimum_variance_portfolio', label: 'Minimum Variance Portfolio', type: ColumnType.Percentage},
    {id: 'efficient_portfolio_max_mu', label: 'Efficient Portfolio Max Return', type: ColumnType.Percentage},
    {id: 'efficient_portfolio_max_sharpe_ratio', label: 'Efficient Portfolio Max Sharpe', type: ColumnType.Percentage},
    {id: 'efficient_portfolio_given_mu', label: 'Efficient Portfolio Target Return', type: ColumnType.Percentage},
    {id: 'efficient_portfolio_given_sigma', label: 'Efficient Portfolio Target Volatility', type: ColumnType.Percentage},
  ];

  drawDownExtraOptions = {
    yAxis: {
      opposite: false,
      minorTickInterval: 0.01,
      labels: {
        format: '{value}%',
      },
      title: {
        text: 'Volatility',
        style: {
          textTransform: 'capitalize'
        }
      },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        },
        lineWidth: 2,
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}%</b>',
        },
      }
    }
  }

  comboExtraOptions = {
    yAxis: [
      {
        minorTickInterval: 0.01,
        labels: {
          format: '{value}%',
        },
        title: {
          text: 'Return',
          style: {
            textTransform: 'capitalize'
          }
        },
        opposite: false
      },
      {
        minorTickInterval: 0.01,
        labels: {
          format: '{value}%',
          x: -2
        },
        title: {
          text: 'Drawdown',
          x: 0,
          style: {
            textTransform: 'capitalize'
          }
        },
        opposite: true
      },
    ]
  }

  footerData:any = {
    company_name: 'Total expected return',
    Ticker: '',
    Weight: '',
    weight_marketcap: '',
    volatility: '',
    historical_returns: 'foot',
    implied_returns: 'foot',
    market_equilibrium: 'foot',
    forecasted_returns: 'foot',
  };
  correlationMatrixData:any = null;
  covarianceMatrixData:any = null;
  portfolioMarketEquilibriumReturn = 0;

  optimPerformance: any[] = [];
  optimWeights: any[] = [];
  filteredOptimWeights: any[] = [];
  efficientSeries = [];
  efficientChartLoaded = false;

  selectedEvaluation: string = 'minimum_variance_portfolio';
  selectedEvaluationNiceName = null;
  riskElasticityData: any = null;
  riskElasticityOptions = {};
  evaluationTableColumns = [
    {id: 'metric', label: 'Metric', type: ColumnType.Simple},
  ];
  evaluationTableData: any = null;
  riskContributionData: any = null;
  riskDiversificationData: any = null;
  correlationBetaData: any = null;
  weightPieData: any[] = [];
  chart3Options: Highcharts.Options = {};
  comboData: any = [];
  evaluationData: any = {}
  riskContributionType = 'sector_fs';
  selectedRiskFactor: any = [
    "MSCI WORLD",
    "MSCI WORLD MOMENTUM",
    "MSCI WORLD QUALITY",
    "MSCI USA ALL CAP",
    "MSCI AC EUROPE",
    "MSCI WORLD MINIMUM VOLATILITY (USD)",
    "MSCI EM (EMERGING MARKETS)"
  ];
  skipList = [
    "Portfolio_Baseline",
    "Portfolio_Analytics"
  ]

  fileWidth = 200;
  isPrinting = true;
  constructor(injector: Injector) {
    super(injector);
  }

  initPage() {
    if (this.portfolio && this.portfolio.id) {
      if (!this.plotData) {
        this.fetchPerformancePlot();
      }

      if (!this.treeMapData) {
        this.extractTreeMapData();
      }
      if (!this.optimizationData) {
        this.fetchOptimizationData();
      }
      if (!this.evaluationTableData) {
        this.fetchEvaluation();
      }
      this.checkSelectedBenchmark();
    }
  }

  startAutoPrint() {
    setTimeout(() => { this.onPrintClick(); }, 2000);
  }

  async fetchEvaluation() {
    this.evaluationData = await this.service.fetchEvaluationData(this.portfolio.id);
    
    this.fetchEvaluationTableData();
  }

  fetchEvaluationTableData() {
    this.selectedEvaluationNiceName = this.service.convertEvaluationType(this.selectedEvaluation.split('_').join(' '));

    this.weightPieData = [];
    this.riskContributionData = null;
    this.correlationBetaData = null;
    this.riskDiversificationData = null;
    this.evaluationTableColumns = [
      {id: 'metric', label: 'Metric', type: ColumnType.Simple},
    ];
    this.evaluationTableData = null;

    this.service.getEvaluationData(this.portfolio.id, this.selectedEvaluation).subscribe((resp: any) => {
      let cumReturns = [];
      let drawdowns = [];
      let volatility = [];

      resp.map((data: any) => {
        let value = +(data.value*100).toFixed(2);
        let date = moment(data.date).unix()*1000;

        if (data.variable == 'drawdown') {
          drawdowns.push([date, value])
        }
        if (data.variable == 'cumulative_returns') {
          cumReturns.push([date, value])
        }
        if (data.variable == 'rolling_std_deviation') {
          if (value > 0) { // skip 0 from start
            volatility.push([date, value])
          }
        }
      })
      this.comboData = [
        {
          name: 'Return',
          data: cumReturns,
          type: 'area',
        },{
          name: 'Drawdown',
          data: drawdowns,
          type: 'line',
          yAxis: 1
        }];
      this.volatilityData = {
        title: '',
        series: [{
          name: "Portfolio",
          type: 'line',
          data: volatility
        }]
      };
      this.initRiskCharts();
      this.startAutoPrint();
    })
  }

  afterOptimizationDateReceived() {
    this.renameEvaluationTypesInPerformance();

    this.correlationMatrixData = this.extractHeatMapData(this.optimizationData.correlation_matrix.items);
    this.optimizationData.covariance_matrix.items = this.optimizationData.covariance_matrix.items.map((i: any) => {
      if (!i.converted) {
        i.value = i.value * 100;
        i.converted = true;
      }
      return i;
    })
    this.covarianceMatrixData = this.extractHeatMapData(this.optimizationData.covariance_matrix.items);
    this.initOptimTables();
    this.initEfficientChart();
    this.initForecastTable();
    this.drawdownData = this.calculateDrawdown(this.plotData.cumulative_returns);
  }

  resetScatterData() {
    const optimisedData = [];

    if (this.portfolio.optim_performance) {
      this.portfolio.optim_performance.map((d:any) => {
        if (d.view_name === this.optimizationData.optim_parameters.return_type) {
          optimisedData.push({
            name: this.service.convertEvaluationType(d.Portfolio.split('_').join(' ')),
            x: +(d.Std_Deviation*100).toFixed(2),
            y: +(d.Expected_Return*100).toFixed(2)
          });
        }
      })
      this.renameEvaluationTypesInPerformance();
    }

    const optimisedSeries = {
      type: 'scatter',
      name: 'Optim',
      marker: {
        radius: 4,
        symbol: 'circle'
      },
      data: optimisedData
    }

    this.efficientSeries.push(optimisedSeries)

    setTimeout(() => {
      this.efficientChartLoaded = true
    }, 10);
  }

  initEfficientChart() {
    this.efficientSeries = [];
    const lineData = [];
    let scatterData = [];

    if (this.portfolio.efficient_frontier) {
      if (this.portfolio.efficient_frontier.optim_efficient_frontier) {
        let index = 0;
        this.portfolio.efficient_frontier.optim_efficient_frontier.map((d: any) => {
          if (d.view_name === this.optimizationData.optim_parameters.return_type) {
            lineData.push({
              x: +(d.sd_ret * 100).toFixed(2),
              y: +(d.exp_ret * 100).toFixed(2),
              return_index: index
            })
            index++;
          }
        })
      }

      scatterData = this.extractEfficientFrontierScatterData();
    }

    const lineSeries = {
      type: 'line',
      name: 'Optim Efficient Frontier',
      data: lineData
    }

    const scatterSeries = {
      type: 'scatter',
      name: 'Stocks',
      marker: {
        radius: 4,
        symbol: 'circle'
      },
      data: scatterData
    }

    this.efficientSeries.push(lineSeries)
    this.efficientSeries.push(scatterSeries)

    this.resetScatterData();
  }

  collectWeights() {
    let weights = [];
    // load the proper weights
    if (this.selectedEvaluation == 'baseline_portfolio') { // original portfolio
      this.portfolio.symbol_performance.map((d: any) => {
        if (d.Ticker != 'Portfolio') {
          weights.push({
            Ticker: d.Ticker,
            weight: +(d.Weight*100).toFixed(2)
          })
        }
      })
    } else { // Optimized weight
      this.portfolio.optim_weights.map((d: any) => {
        if (d.view_name == 'historical_returns' && d[this.selectedEvaluation]) {
          weights.push({
            Ticker: d.Ticker,
            weight: +(d[this.selectedEvaluation]*100).toFixed(2)
          })
        }
      })
    }

    return weights;
  }

  initRiskCharts() {
    const weights = this.collectWeights();
    const securityCount = weights.length;
    let industries = [];

    weights.map((data: any) => {
      if (data.weight > 0) {
        let company = this.portfolio.security_overviews[data.Ticker];
        if (!company) {
          return ;
        }

        if (!industries[company.industry.name]) {
          industries[company.industry.name] = 0;
        }
        industries[company.industry.name] += 1;

        this.weightPieData.push({
          name: company.company_name,
          y: data.weight
        })
      }
    })

    // Risk contribution
    let riskCategories = [];
    let ws = [];
    let risks = [];
    this.evaluationData.risk_contribution.map((data: any) => {
      // Only add selected evaluatino data
      if (data.Portfolio == this.selectedEvaluation) {
        let company = this.portfolio.security_overviews[data.Ticker];
        let companyName = (company && company.id) ? company.company_name : data.Ticker;
        if (companyName) {
          riskCategories.push(companyName);
          ws.push(+(data.weight * 100).toFixed(2));
          risks.push(+(data.risk_contribution * 100).toFixed(2));
        }
      }
    })
    riskCategories = [...new Set(riskCategories)];

    this.riskContributionData = {
      categories: riskCategories,
      series: [
        {
          name: 'Weight',
          type: "column",
          data: ws
        },
        {
          name: 'Risk',
          type: "column",
          data: risks
        }
      ]
    }

    // Risk diversification
    const categories = [];
    const chartData = [];
    this.evaluationData.diversification_effect.map((data: any) => {
      if (data.portfolio_name == this.selectedEvaluation && data.type == 'volatility') {
        categories.push(data.variable);
        chartData.push(+(data.value * 100).toFixed(2));
      }
    });
    this.riskDiversificationData = {
      categories,
      series: [
        {
          name: '',
          type: "column",
          data: chartData,
        }
      ]
    };

    this.setRiskFactorRelatedContents()

    this.chart3Options = {
      chart: {
        height: '500px'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      colors: high_colors,
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          format: '{value}%'
        },
        minorGridLineWidth: 0,
      },
      tooltip: {
        valueSuffix: '%',
        valueDecimals: 2,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          }
        }
      },
      xAxis: {
        categories: this.riskDiversificationData.categories,
        gridLineWidth: 0
      },
      series: this.riskDiversificationData.series
    };
  }
  setRiskFactorRelatedContents() {
    this.evaluationTableColumns = [
      {id: 'metric', label: 'Metric', type: ColumnType.Simple},
    ];
    this.evaluationTableData = null;
    // Evaluation table
    let columns = new Set([]);
    let tableData: any = {};
    const needPercentage = [
      'Annualized Return',
      'Annualized Std Dev',
      'VaR'
    ]

    //Correlation and beta agains risk factors chart
    let correlationCategories = new Set([]);
    let correlations = [];
    let betas = [];
    this.evaluationData.evaluation_table.map((data: any) => {
      // Skip portfolio data
      if (data.portfolio == 'Portfolio' || data.portfolio == 'Portfolio_Analytics' || data.portfolio == 'Baseline Portfolio') {
        return false;
      }

      if (data.reference_portfolio != this.selectedEvaluation) {
        return false;
      }

      let label = data.portfolio;

      if (
        this.skipList.includes(label) ||
        this.selectedRiskFactor.includes(label) ||
        this.selectedEvaluation == data.portfolio
      ) {
        if (label == this.selectedEvaluation) {
          label = this.selectedEvaluationNiceName;
        }

        if (data.portfolio == 'Portfolio_Baseline') {
          return false;
        }

        if (data.portfolio != this.selectedEvaluation) {
          if (data.metric === 'Correlation Portfolio') {
            correlations.push(+data.value.toFixed(2))
            correlationCategories.add(label);
          }
          if (data.metric === 'Beta Portfolio') {
            betas.push(+data.value.toFixed(2))
          }
        }

        columns.add(label);

        if (!tableData[data.metric]) {
          let metricLbl = data.metric;
          if (metricLbl == 'Annualized Std Dev') {
            metricLbl = 'Annualized Volatility';
          }
          tableData[data.metric] = {
            metric: metricLbl
          }
        }
        tableData[data.metric][label] = needPercentage.includes(data.metric) ?
          `${(data.value*100).toFixed(2)}%` :
          data.value.toFixed(2);
      }
    })

    const moveSecondToFirst = (list) => {
      const temp = list[0];
      list[0] = list[1]
      list[1] = temp;
      return list;
    }


    let datatableColumns = [];
    columns.forEach(c => {
      let label = c;
      if (c == 'Portfolio_Baseline') {
        label = 'Baseline Portfolio';
      }
      datatableColumns.push({ id: c, label: label, type: ColumnType.Simple })
    })

    // Correlation chart column ordering fix
    let correlactionCategoriesArray = [...correlationCategories];

    this.evaluationTableColumns = [
      ...this.evaluationTableColumns,
      ...datatableColumns
    ];

    this.evaluationTableData = Object.values(tableData);
    this.correlationBetaData = {
      categories: correlactionCategoriesArray,
      series: [
        {
          name: 'Correlation Portfolio',
          type: "column",
          data: correlations
        },
        {
          name: 'Beta Portfolio',
          type: "column",
          data: betas
        }
      ]
    }
    this.initRiskContributionCategoryChart();
  }

  initRiskContributionCategoryChart() {
    this.riskElasticityData = null;
    let elasticityData = [];
    this.evaluationData.risk_elasticity.map((data: any) => {
      if (data.Portfolio == this.selectedEvaluation) {
        if (data.category == this.riskContributionType && data.risk_inc > 0) {
          elasticityData[data.category_name] = +(data.risk_inc).toFixed(2)
        }
      }
    })
    this.riskElasticityData = [
      {
        name: '',
        type: "column",
        data: Object.values(elasticityData),
      }
    ];
    this.riskElasticityOptions = {
      chart: {
        height: '300px'
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: Object.keys(elasticityData),
        gridLineWidth: 0,
      },
      yAxis: {
        title: {
          text: ''
        },
        minorGridLineWidth: 0
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          }
        },
        series: {
          lineWidth: 2,
          dataGrouping: {
            enabled: false
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y}%</b>',
          },
        }
      },
    }

  }

  extendListWithCompanyData(list = [], name, returnName) {
    const result = [];
    list.map((d: any) => {
      d.return = +(d[returnName] * 100).toFixed(2);

      if (d.Ticker === 'Portfolio') {
        this.viewReturns[name] = d.return;
      } else {
        d.company_name = this.portfolio.security_overviews[d.Ticker] ? this.portfolio.security_overviews[d.Ticker].company_name : d.Ticker;
        result.push(d);
        if (!this.companyReturns[d.Ticker]) {
          this.companyReturns[d.Ticker] = {}
        }
        this.companyReturns[d.Ticker][name] = d.return;
      }
    });
    return result;
  }

  initOptimTables() {
    this.optimPerformance = this.portfolio.optim_performance.filter(d => d.view_name === this.optimizationData.optim_parameters.return_type);
    this.optimWeights = this.portfolio.optim_weights.filter(d => d.view_name === this.optimizationData.optim_parameters.return_type);
    this.filteredOptimWeights = [...this.optimWeights];
  }

  initForecastTable() {
    this.companyReturns = {}

    this.historicalReturnTableData = this.extendListWithCompanyData(this.optimizationData.historical_returns, 'historical', 'historical_returns');
    this.marketEquilibriumTableData = this.extendListWithCompanyData(this.optimizationData.market_equilibrium, 'market', 'implied_ret');
    this.forecastedReturnsTableData = this.extendListWithCompanyData(this.optimizationData.forecasted_returns, 'forecast', 'forecasted_returns');
    this.impliedReturnData = this.extendListWithCompanyData(this.optimizationData.implied_returns, 'implied', 'implied_ret');

    this.extendListWithCompanyData(this.optimizationData.historical_returns, 'volatility', 'volatility');
    this.extendListWithCompanyData(this.optimizationData.market_equilibrium, 'weight_marketcap', 'weight_marketcap');

    this.forecastedReturnsTableData = this.forecastedReturnsTableData.map((d: any) => {
      if (!d.converted) {
        d.forecasted_returns = +(d.forecasted_returns * 100).toFixed(2) // multiple with 100 to get percenteges in input and table
        d.historical_returns = this.companyReturns[d.Ticker]['historical'];
        d.volatility = this.companyReturns[d.Ticker]['volatility'];
        d.weight_marketcap = this.companyReturns[d.Ticker]['weight_marketcap'];
        d.market_equilibrium = this.companyReturns[d.Ticker]['market'];
        d.implied_returns = this.companyReturns[d.Ticker]['implied'];
        d.sector = this.portfolio.security_overviews[d.Ticker].sector.name || '';
        d.country = this.portfolio.security_overviews[d.Ticker].country.name || '';
        d.type = this.portfolio.security_overviews[d.Ticker].security_type_equity || '';
        d.converted = true;
      }
      return d;
    });

    this.optimizationData.market_equilibrium.map(d => {
      if (d.Ticker == 'Market Equilibrium') {
        this.portfolioMarketEquilibriumReturn = +(d.implied_ret * 100).toFixed(2);
      }
    });
    this.onForecastedReturnInputChange();
    this.beforeViewTablesLoaded();
  }

  beforeViewTablesLoaded() {
    this.footerData.historical_returns = `${this.viewReturns.historical || 0}%`;
    this.footerData.implied_returns = `${this.viewReturns.implied || 0}%`;
    this.footerData.market_equilibrium = `${this.viewReturns.market || 0}%`;
    this.footerData.forecasted_returns = `${this.viewReturns.forecast || 0}%`;
  }

  onForecastedReturnInputChange() {
    let portfolioReturn = 0;
    this.forecastedReturnsTableData.map(d => {
      portfolioReturn += d.Weight * d.forecasted_returns
    })
    this.viewReturns.forecast = +(portfolioReturn.toFixed(2));
  }

  onPrintClick() {
    this.generatePDF();
  }

  public generatePDF(): void {
    this.isPrinting = true;
    const PDF = new jsPDF('p', 'mm', 'a4');
    PDF.setFontSize(9);

    const maxPages = 9;
    const images: any = {};

    // Page 1
    html2canvas(this.page1.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[1] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });

    // Page 2
    html2canvas(this.page2.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[2] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });

    // Page 3
    html2canvas(this.page3.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[3] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });

    // Page 4
    html2canvas(this.page4.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[4] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });
    // Page 5
    html2canvas(this.page5.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[5] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });

    // Page 6
    html2canvas(this.page6.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[6] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });

    // Page 7
    html2canvas(this.page7.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[7] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });

    // Page 8
    html2canvas(this.page8.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[8] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });

    // Page 9
    html2canvas(this.page9.nativeElement, { scale: 3,  logging: false }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/jpeg');
      const generatedImageHeight = (canvas.height * this.fileWidth) / canvas.width;
      images[9] = {
        image: imageGeneratedFromTemplate,
        height: generatedImageHeight
      };
      if (Object.keys(images).length === maxPages) {
        createPDF();
      }
    });

    const footerText = 'This report is generated using Cimalti Analytics. All data within is integrated from MSCI, S&P and FactSet and powered by Cimalti’s FinTech. Cimalti (www.cimalti.com) is a global asset allocation and risk management platform provider. Cimalti offers technological analytics tools, powered by high-quality data, for all levels of professional investors to make better investment decisions.';
    const footer = PDF.splitTextToSize(footerText, 180);
    const createPDF = () => {
      for (let i = 1; i <= maxPages; i++) {
        if (i !== 1) {
          PDF.addPage('a4', 'p');
        }
        PDF.addImage(images[i].image, 'PNG', 5, 5, this.fileWidth, images[i].height);
        PDF.html(this['page' + i].nativeElement.innerHTML);
        PDF.text(footer, 5, 280);
        PDF.text(i + '/' + maxPages, 200, 290);
      }
      PDF.save('cimalti-' + this.portfolio.name + '.pdf');
      this.isPrinting = false;
    };
  }
}
