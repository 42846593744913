<h3 class="summary-header" *ngIf="title">{{title}}</h3>
<div class="row mb-3">
    <div class="col text-center" *ngFor="let label of summaryRawLabels; index as index">
        <div class="card ">
            <div class="card-body p-3 summary-card">
                <div class="figure">
                  {{summaryRaw[label] && summaryRaw[label][property] ? (summaryRaw[label][property]*100|number:'1.0-2') : '0'}}<span class="figure-unit">%</span>
                </div>
                <div class="figure-label">
                    {{summaryLabels[index]}}
                </div>
            </div>
        </div>
    </div>
    <div class="col text-center" [hidden]="etfOnly">
        <div class="card ">
            <div class="card-body p-3 summary-card">
              <div class="figure">{{summaryRaw['ESG Score'] ? (summaryRaw['ESG Score'][property]|number:'1.0-2') : '0'}}<span class="figure-unit">/10</span></div>
                <div class="figure-label">ESG Score</div>
            </div>
        </div>
    </div>
    <div class="col text-center">
        <div class="card ">
            <div class="card-body p-3 summary-card">
                <div class="figure">{{portfolioPerformance.Sharpe|number:'1.0-2'}}</div>
                <div class="figure-label">Sharpe Ratio</div>
            </div>
        </div>
    </div>
    <div class="col text-center" [hidden]="!benchmark">
        <div class="card ">
            <div class="card-body p-3 summary-card">
              <div class="figure">{{portfolioPerformance.TrackingError*100|number:'1.0-2'}}<span class="figure-unit">%</span></div>
                <div class="figure-label">Tracking Error</div>
            </div>
        </div>
    </div>
    <div class="col text-center" [hidden]="!benchmark">
        <div class="card ">
            <div class="card-body p-3 summary-card">
                <div class="figure">{{portfolioPerformance.Beta|number:'1.0-2'}}</div>
                <div class="figure-label">Beta</div>
            </div>
        </div>
    </div>
    <div class="col text-center" [hidden]="!benchmark">
        <div class="card ">
            <div class="card-body p-3 summary-card">
              <div class="figure">{{portfolioPerformance.Alpha*100|number:'1.0-2'}}<span class="figure-unit">%</span></div>
                <div class="figure-label">Alpha</div>
            </div>
        </div>
    </div>
</div>

<div class="row mb-3">
  <div class="col text-center">
    <div class="card ">
      <div class="card-body p-3 summary-card">
        <div class="figure">{{summaryRaw['Market Value'] ? (summaryRaw['Market Value'][property]|number:'1.0-0') : '0'}} <span class="figure-unit">M USD</span></div>
        <div class="figure-label">Market Value</div>
      </div>
    </div>
  </div>
  <div class="col text-center">
    <div class="card ">
      <div class="card-body p-3 summary-card">
        <div class="figure">{{summaryRaw['Enterprise Value'] ? (summaryRaw['Enterprise Value'][property]|number:'1.0-0') : '0'}} <span class="figure-unit">M USD</span></div>
        <div class="figure-label">Enterprise Value</div>
      </div>
    </div>
  </div>
  <div class="col text-center">
    <div class="card ">
      <div class="card-body p-3 summary-card">
        <div class="figure">{{summaryRaw['Dividend Yield'] ? (summaryRaw['Dividend Yield'][property]|number:'1.0-2') : '0'}}<span class="figure-unit">%</span></div>
        <div class="figure-label">Dividend Yield</div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-3 text-center">
    <div class="card ">
      <div class="card-body p-3 summary-card">
        <div class="figure">{{summaryRaw['EBITDA'] ? (summaryRaw['EBITDA'][property]|number:'1.0-2') : '0'}} <span class="figure-unit">M</span></div>
        <div class="figure-label">EBITDA</div>
      </div>
    </div>
  </div>
  <div class="col-md-3 text-center">
    <div class="card ">
      <div class="card-body p-3 summary-card">
        <div class="figure">{{summaryRaw['P/E'] ? (summaryRaw['P/E'][property]|number:'1.0-2') : '0'}}</div>
        <div class="figure-label">P/E</div>
      </div>
    </div>
  </div>
  <div class="col-md-3 text-center">
    <div class="card ">
      <div class="card-body p-3 summary-card">
        <div class="figure">{{summaryRaw['P/B'] ? (summaryRaw['P/B'][property]|number:'1.0-2') : '0'}}</div>
        <div class="figure-label">P/B</div>
      </div>
    </div>
  </div>
  <div class="col-md-3 text-center">
    <div class="card ">
      <div class="card-body p-3 summary-card">
        <div class="figure">{{summaryRaw['P/S'] ? (summaryRaw['P/S'][property]|number:'1.0-2') : '0'}}</div>
        <div class="figure-label">P/S</div>
      </div>
    </div>
  </div>
</div>
