
<div class="card">
    <div class="card-header"><h1>Cumulative Return</h1></div>
    <div class="card-body">
<!--        <button *ngIf="enableDatawrapper" class="datawrapper-btn" mat-button color="primary" (click)="onDatawrapperClick('Cumulative Return', subHeader)">Publish</button>-->
<!--        <button *ngIf="enableDatawrapper" class="highchart-export-btn" mat-button color="primary" (click)="onExportClick()">Export</button>-->

        <app-chart-placeholder [loaded]="loaded"></app-chart-placeholder>
        <highcharts-chart *ngIf="loaded"
                          [Highcharts]="Highcharts"
                          [options]="chartOptions"
                          [constructorType]="chartConstructor"
                          (chartInstance)="setChartInstance($event)"
                          style="width: 100%; height: auto; display: block;"
        ></highcharts-chart>
    </div>

<svg style="height: 0">
    <defs>
        <linearGradient id="gradient-0" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0" />
            <stop offset="1" />
        </linearGradient>
        <linearGradient id="gradient-1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0" />
            <stop offset="1" />
        </linearGradient>
    </defs>
</svg>

</div>
