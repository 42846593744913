import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UsersService} from './users.service';
import {CompanyDialogComponent} from './company-dialog/company-dialog.component';
import {UtilService} from '../../shared/services/util.service';
import {UserDialogComponent} from './user-dialog/user-dialog.component';
import {User} from '../../shared/models/user.model';
import {AuthService} from '../../shared/services/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('100ms ease')),
    ]),
  ]
})
export class UsersComponent implements OnInit {

  allExpanded = false;

  isLoading = true;
  sysUsers: any[] = [];
  companies: any[] = [];
  expandedElement: any | null;
  columnsToDisplay = ['data'];
  dataSource: MatTableDataSource<any>;
  isAdmin = false;

  constructor(
    private dialog: MatDialog,
    private util: UtilService,
    private service: UsersService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe((resp: any) => {
      if (resp && resp.id) {
        this.isAdmin = resp.isAdmin();
      }
      this.fetchGroupedUsers();

      if (this.isAdmin) {
        // Fetch clients only for admin
        // this.fetchCompanies();
      } else {
        // Fetch system users for SuperAdmin
      }
    });
  }

  fetchGroupedUsers() {
    this.isLoading = true;
    this.service.getUsers({type: 'grouped'}).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.companies = resp;

      this.companies.forEach(company => {
        company.users = company.users.map(user => new User(user));
      });

      if (this.companies.length === 1) {
        this.companies[0].expanded = true;
      }

      this.dataSource = new MatTableDataSource(this.companies);
      this.isLoading = false;
    });
  }

  expandAll() {
    this.allExpanded = !this.allExpanded;
    this.companies = this.companies.map(c => {
      c.expanded = this.allExpanded;
      return c;
    });
  }

  newCompany(company?) {
    const dialogRef = this.dialog.open(CompanyDialogComponent, {
      data: {
        indices: company ? company.allIndices : [],
        company,
        isAdmin: this.isAdmin
      },
      maxWidth: '800px',
      autoFocus: false,
      width: '100%'
    });

    dialogRef.afterClosed().subscribe( (resp: any) => {
      if (resp) {
        if (resp.deleted) {
          this.companies = this.util.findAndReplaceItemInArray(company, this.companies, true);
          this.dataSource.data = this.companies;
        } else if (company) {
          this.companies = this.util.findAndReplaceItemInArray(resp, this.companies);
        } else {
          this.companies.push(resp);
        }
        this.dataSource.data = this.companies;
      }
    });
  }

  newUser(user?, indices?) {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      data: {
        companies: this.companies,
        isAdmin: this.isAdmin,
        indices: indices ? indices : [],
        user
      },
      autoFocus: false,
      maxWidth: '800px',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe( (resp: any) => {
      if (resp) {
        if (!resp.company) {
          resp.company = this.companies[0]; // system users
        }

        if (resp.company) {
          const company = this.companies.find(c => c.id === resp.company.id);
          const newUser = new User(resp);

          // Edit user
          if (user) {
            if (!user.company) {
              user.company = this.companies[0]; // system users
            }
            // same company, replace user
            if (company && user.company.id === company.id) {
              company.users = this.util.findAndReplaceItemInArray(newUser, company.users);
            } else {
              // new company move user
              // remove user from old company
              const oldCompany = this.companies.find(c => c.id === user.company.id);
              if (oldCompany) {
                oldCompany.users = this.util.findAndReplaceItemInArray(user, oldCompany.users, true);
              }

              this.addUserToCompany(newUser, company);
            }
          } else {
            // new user
            this.addUserToCompany(newUser, company);
          }
        }
      }
    });
  }

  deleteUser(user) {
    this.service.deleteUser(user).subscribe(resp => {
      this.service.notificationService.open('User deleted');
      const company = user.isSuperAdmin() ?
        this.companies[0] :
        this.companies.find(c => c.id === user.company.id);
      if (company) {
        company.users = this.util.findAndReplaceItemInArray(user, company.users, true);
      }
    });
  }

  confirmDelete(user) {
    this.service.confirmAction().subscribe(confirm => {
      if (confirm) {
        this.deleteUser(user);
      }
    });
  }

  addUserToCompany(user, company?) {
    if (company) {
      if (!company.users) {
        company.users = [];
      }
      company.users.push(user);
    } else {
      // New company created in user dialog
      const newClient = {
        ...user.company,
        users: [user]
      };
      this.companies.push(newClient);
      this.dataSource.data = this.companies;
    }
  }

  onIndexChange(element) {
    const postData = Object.assign({}, element);
    this.service.saveClient(postData).subscribe();
  }

  onCompanyAccessChange(company) {
    company.customization_access = !company.customization_access;
    this.service.toggleClientCustomizationAccess(company.id).subscribe();
  }

  onRowClick(company) {
    company.expanded = !company.expanded;
    /*if (company.expanded === true && (company.id > 0) && !company.allIndices) {
      this.fetchIndicesForCompany(company);
    }*/
  }
}
