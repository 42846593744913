<div class="pb-3">
    <mat-horizontal-stepper class="content-stepper portfolio-container" linear #stepper [selectedIndex]="selectedStep" (selectionChange)="stepChange($event)">
        <mat-step>
            <mat-toolbar>
                <h1>Create portfolio</h1>
                <div class="toolbar-buttons">
                    <button type="button" class="float-right ml-3" mat-stroked-button color="primary" (click)="onNextClick()">Next</button>
                </div>
            </mat-toolbar>
            <div class="card" style="overflow: visible !important;">
                <div class="card-body">
                    <app-existing-portfolio
                            [list]="list"
                            [meta]="meta"
                            [filterLists]="filterLists"
                            [portfolio]="portfolio"
                            [selected]="selected"
                            (nextClick)="onNextClick()"
                            (getFilters)="fetchCompaniesIn($event)"
                            (pageEvent)="onPageEvent($event)"
                            (startDateChange)="onMaxStartDateChange($event)"
                    ></app-existing-portfolio>
                </div>
            </div>
        </mat-step>
        <mat-step>
            <mat-toolbar>
                <h1>Create portfolio</h1>
                <div class="toolbar-buttons">
                    <button mat-stroked-button type="button" color="primary" matStepperPrevious>Back</button>
                    <button mat-flat-button type="button" class="float-right ml-3" color="primary" (click)="onSavePortfolioClick(portfolio)">Save portfolio</button>
                </div>
            </mat-toolbar>
            <div class="card" style="overflow: visible !important;">
                <div class="card-body" style="overflow: visible !important;">
                    <h4>Portfolio</h4>
                    <div class="mb-4">
                        <app-manual-portfolio-form
                                *ngIf="selectedStep == 1"
                                [portfolio]="portfolio"
                                [maxStartDate]="max_start_date"
                                [filterLists]="filterLists"
                                [selectedCurrencies]="selectedCurrencies"
                                (portfolioSave)="onSavePortfolioClick($event)"
                                (weightChange)="onWeightTypeChange()"
                                (startDateSet)="onStartDateSet()"
                        ></app-manual-portfolio-form>
                    </div>

                    <div class="card mb-4">
                        <div class="card-body">
                            <p *ngIf="weightError" class="text-center p-2 text-danger">Weight is over 100%!</p>
                            <table class="table m-0 table-with-input">
                                <thead>
                                <tr>
                                    <td class="bold-header">Name</td>
                                    <td class="bold-header">ISIN</td>
                                    <td class="bold-header text-right">Market Cap (M$)</td>
                                    <td class="bold-header text-right">Market Cap (M$) at Start date</td>
                                    <td >Start date</td>
                                    <td >Asset Class</td>
                                    <td >Currency</td>
                                    <td >Sector</td>
                                    <td >Weight</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of selected">
                                    <td class="font-weight-bold">
                                        <span class="underline-on-hover ml-2 text-truncate" (click)="onCompanyInfoClick(item)">{{item.company_name}}</span>
                                    </td>
                                    <td>{{item.isin}}</td>
                                    <td class="text-right">{{item.market_value_usd|number:'1.0-0'}}</td>
                                    <td class="text-right">{{item.market_value_start ? (item.market_value_start|number:'1.0-0') : '-'}}</td>
                                    <td>{{item.p_first_date}}</td>
                                    <td>{{item.security_type_equity}}</td>
                                    <td>{{item.currency ? item.currency.code : item.currency_id}}</td>
                                    <td>{{item.sector ? item.sector.name : item.sector_id}}</td>
                                    <td>
                                        <mat-form-field class="max-100" appearance="outline">
                                            <input matInput [(ngModel)]="item.weight" type="number" min="0" max="100" [disabled]="portfolio.weighting_type !== 'manual'" (ngModelChange)="onWeightChange(item)">
                                            <span matSuffix>%</span>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>

