import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-idle-dialog',
  templateUrl: './idle-dialog.component.html',
  styleUrls: ['./idle-dialog.component.scss']
})
export class IdleDialogComponent implements OnInit {

  seconds = 10;
  counterDelay = 10000;
  counterFunc = undefined;
  logoutTrigger = undefined;

  constructor(
    public dialogRef: MatDialogRef<IdleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.seconds = 10;
    this.startCounter();
  }

  startCounter() {
    this.logoutTrigger = setTimeout(() => { this.logout(); }, this.counterDelay);
    this.counterFunc = setInterval(() => {
      this.seconds--;
    }, 1000);
  }

  stopCounter() {
    if (typeof this.logoutTrigger !== 'undefined') {
      clearTimeout(this.logoutTrigger);
      clearInterval(this.counterFunc);
      this.logoutTrigger = undefined;
      this.counterFunc = undefined;
      this.seconds = 10;
    }
  }

  stay() {
    this.stopCounter();
    this.dialogRef.close({logout: false});
  }

  logout() {
    this.stopCounter();
    this.dialogRef.close({logout: true});
  }
}
