import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as Highcharts from "highcharts/highstock";
import {high_colors} from "../general-chart/general-chart.component";

@Component({
  selector: 'app-weight-pie-chart',
  templateUrl: './weight-pie-chart.component.html',
  styleUrls: ['./weight-pie-chart.component.scss']
})
export class WeightPieChartComponent implements OnChanges {

  @Input() lists: any = null;
  @Input() subHeader: any = {};
  @Input() isPrint = false;
  loaded = false;

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'chart';
  chartOptions: Highcharts.Options = {};

  types = ['Weights', 'Country', 'Sector', 'Industry'];
  selectedType = 'Weights';
  chartData: any[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lists) {
      this.onTypeChange();
    }
  }

  onTypeChange() {
    this.chartData = this.lists[this.selectedType.toLowerCase()];
    this.initChart();
  }

  initChart() {
    this.loaded = false;

    this.chartOptions = {
      chart: {
        height: '500px'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        valueSuffix: '%'
      },
      legend: {
        enabled: true,
      },
      series: [{
        type: "pie",
        data: this.chartData,
        colors: high_colors
      }],
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.2f} %'
          }
        },
        series: {
          animation: !this.isPrint
        }
      },
    };
    setTimeout(() => this.loaded = true, 10);
  }
}
