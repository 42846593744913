import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DataService} from "../../shared/services/data.service";

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {

  @Input() isUploading: boolean = false;
  @Input() accept = "image/*"
  @Input() acceptLabel = "image"
  @Output() uploadEvent: EventEmitter<any>;

  files: any[] = [];

  constructor(
    private service: DataService
  ) {
    this.uploadEvent = new EventEmitter<any>();
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.uploadEvent.emit(item);
    }
  }
}
