<div class="info-section">

    <div *ngIf="company" class="col-md-12 flex">
        <div class="col-md-6">
            <div class="info-line">
                <span class="info-title">Country</span>
                <span class="info-value">{{company.country ? company.country.name : '-'}}</span>
            </div>
            <div class="info-line">
                <span class="info-title">Sector</span>
                <span class="info-value industry-sector">{{company.sector ? company.sector.name : '-'}}
            </span>
            </div>
            <div class="info-line">
                <span class="info-title">Industry</span>
                <span class="info-value industry-sector">{{company.industry ? company.industry.name : '-'}}</span>
            </div>
            <div class="info-line">
                <span class="info-title">Number of Employees</span>
                <span class="info-value">{{ employeeNum | number: '1.0-0'}}</span>
            </div>
        </div>

        <div class="col-md-6">
            <div class="info-line">
                <span class="info-title">Ticker</span>
                <span class="info-value">{{company.fs_ticker}}</span>
            </div>
            <div class="info-line">
                <span class="info-title">ISIN</span>
                <span class="info-value">{{company.isin}}</span>
            </div>
            <div class="info-line">
                <span class="info-title">Exchange</span>
                <span class="info-value">{{company.exchange ? company.exchange.name : '-'}}</span>
            </div>
            <div class="info-line">
                <span class="info-title">Market Capitalization</span>
                <span *ngIf="isData" class="info-value">{{ company.currency.code }} {{ isData[0].ff_mcap | number: '1.0-0' }}M</span>
            </div>
        </div>
    </div>

    <div class="info-line" *ngFor="let info of list">
        <span class="info-title">{{info.title}}</span>
        <span class="info-value">
                <span *ngIf="info.type == 'string'">{{overview[info.id]||'-'}} <small *ngIf="info.unit" class="text-muted">{{info.unit}}</small></span>
                <span *ngIf="info.type == 'url'"><a [href]="overview[info.id]" target="_blank">{{overview[info.id]}}</a></span>
                <span *ngIf="info.type == 'number'">{{(overview[info.id]|number:'1.0-2')||'-'}} <small *ngIf="info.unit" class="text-muted">{{info.unit}}</small></span>
                <span *ngIf="info.type == 'percent'">{{((overview[info.id]|number:'1.0-2'))||'-'}} <small *ngIf="info.unit" class="text-muted">{{info.unit}}</small></span>
            </span>
    </div>
</div>
