<mat-drawer-container class="sidenav-container" autosize>
    <mat-drawer (openedChange)="onOpenedChange($event)" #drawer mode="side" position="end" autoFocus="false" mode="over" class="sidenav">
        <div [hidden]="!(type === 'filters')">
            <app-asset-table-column-visibility-filter #stockFilters [columnVisibility]="columnVisibility" assetType="stock" [quantList]="quantList" [dynamicColumns]="dynamicColumns" (columnChanged)="onDynamicColumnChange($event)"></app-asset-table-column-visibility-filter>
        </div>
        <div [hidden]="!(type !== 'filters' && drawlerData)" class="p-48 pr-0">
            <mat-form-field class="example-full-width">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search for..." [(ngModel)]="drawlerData.searchText">
            </mat-form-field>
            <div class="selection-container">
                <button type="button" mat-button color="primary" (click)="drawlerData.selectAll()">Select all</button>
                <button type="button" mat-button color="primary" (click)="drawlerData.deselectAll()">Deselect all</button>
            </div>

            <mat-label>Choose an option</mat-label>
            <ul>
                <li 
                    *ngFor="let item of drawlerData.list | search: drawlerData.searchText"
                    [hidden]="item.hidden == true || (drawlerData.hideMarked && item.marked == true)">
                    <mat-checkbox
                        (change)="handleCheckboxUpdate(item.id)"
                        [checked]="filters[drawlerData.name] ? filters[drawlerData.name].includes(item.id.toString()) : false"
                    >
                        {{item.name}}
                    </mat-checkbox>
                </li>
            </ul>
            <div class="selection-container bottom-buttons">
                <button type="button" mat-button color="primary" (click)="filterCompanies();drawer.toggle();">OK</button>
                <button type="button" mat-button color="primary" (click)="drawlerData.onPanelCloseOrCancel();drawer.toggle();">Cancel</button>
            </div>
        </div>
    </mat-drawer>
    <div class="ml-8 mr-8 mt-3">
        <a class="header-back" [routerLink]="['/app']">
            <mat-icon inline=true fontSet="fa" fontIcon="fa-arrow-left" aria-label="back"></mat-icon>
            <span>My portfolios</span>
        </a>
    </div>

    <div class="pb-3">
        <mat-horizontal-stepper class="content-stepper portfolio-container" linear #stepper [selectedIndex]="selectedStep" (selectionChange)="stepChange($event)">
            <mat-step>
                <div class="ml-3 mr-3">
                    <h1>Select assets</h1>
                    <button *ngIf="showUploadBtn" type="button" class="ml-3" mat-flat-button color="primary" (click)="onUploadClick()">Upload</button>
                    <div class="toolbar-buttons">
                    <!--     <app-new-portfolio-search [model]="currentFilters.searchTerm" (fetchCompanies)="onFetchCompanies()" (modelChange)="currentFilters.searchTerm = $event" [meta]="meta"> </app-new-portfolio-search>
                        --> 
                        <button type="button" class="float-right ml-3 next-button" mat-stroked-button color="primary" (click)="onNextClick()">
                            <span>Next</span>
                            <mat-icon inline=true fontSet="fa" fontIcon="fa-chevron-right" aria-label="new portfolio"></mat-icon>
                        </button>
                      <!--   <button [disabled]="!selected.length" mat-flat-button class="portfolio-cart float-right ml-3" (click)="onPortfolioCartClick()">
                            <mat-icon [matBadge]="selected.length">work</mat-icon>
                        </button> --> 
                    </div>
                </div>
                <div class="card" style="overflow: visible !important;">
                    <div class="card-body">
                        <app-new-portfolio
                                [drawer]="drawer"
                                [list]="list"
                                [selected]="selected"
                                [filterLists]="filterLists"
                                [portfolio]="portfolio"
                                [meta]="meta"
                                [priorityCurrencies]="priorityCurrencies"
                                (nextClick)="onNextClick()"
                                (getFilters)="getFiltersIn($event)"
                                (fetchCompanies)="fetchCompaniesIn($event, 1)"
                                (itemSelect)="onCompanySelect($event)"
                                (selectAll)="onSelectAll($event)"
                                (pageEvent)="onPageEvent($event)"
                                (cartClick)="onPortfolioCartClick()"
                                (startDateChange)="onMaxStartDateChange($event)"
                                (fileUploaded)="onFileUploaded($event)"
                                (selectedSortingChanged)="changeSelectedSorting($event)"
                        ></app-new-portfolio>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <div class="ml-3 mr-3">
                    <h1>New portfolio</h1>
                    <div class="toolbar-buttons">
                        <button mat-flat-button type="button" class="loat-right ml-3 next-button float-right ml-3" color="primary" (click)="onSavePortfolioClick(portfolio)"><span class="navigation-button">Save portfolio</span></button>
                        <button class="portfolio-cart float-right ml-3" mat-stroked-button type="button" color="primary" matStepperPrevious>Back</button>
                    </div>
                </div>
                <div class="card" style="overflow: visible !important;">
                    <div class="card-body" style="overflow: visible !important;">
                        <div class="mb-4">
                            <app-manual-portfolio-form
                                    *ngIf="selectedStep == 1"
                                    [portfolio]="portfolio"
                                    [maxStartDate]="max_start_date"
                                    [filterLists]="filterLists"
                                    [selectedCurrencies]="selectedCurrencies"
                                    [hasETF]="hasETF"
                                    (portfolioSave)="onSavePortfolioClick($event)"
                                    (weightChange)="onWeightTypeChange()"
                                    (startDateSet)="onStartDateSet()"
                                    (setAllEqual)="setAllAssetEqual()"
                            ></app-manual-portfolio-form>
                        </div>

                        <div class="card mb-4">
                            <div class="card-body">
                                <app-new-portfolio-select-dialog *ngIf="portfolio" [isStep2]="true" [list]="selected" [portfolio]="portfolio"></app-new-portfolio-select-dialog>
<!--                                <p *ngIf="weightError" class="text-center p-2 text-danger">Weight is over 100%!</p>-->
<!--                                <table class="table m-0 table-with-input table-with-sorting table-header-align-top">-->
<!--                                    <thead>-->
<!--                                    <tr>-->
<!--                                    <td class="bold-header">-->
<!--                                        Name-->
<!--                                        <button mat-icon-button (click)="onSelectedSortClick('company_name');$event.preventDefault()" class="sorting-button" [class.sort-visible]="selectedSorting && selectedSorting.includes('company_name')"><mat-icon>{{ selectedSorting && selectedSorting.includes('company_name') ? ((selectedSorting === 'company_name') ? 'north' : 'south') : 'swap_vert'}}</mat-icon></button>-->
<!--                                    </td>-->
<!--                                    <td class="bold-header">-->
<!--                                        ISIN-->
<!--                                        <button mat-icon-button (click)="onSelectedSortClick('isin');$event.preventDefault()" class="sorting-button" [class.sort-visible]="selectedSorting && selectedSorting.includes('isin')"><mat-icon>{{ selectedSorting && selectedSorting.includes('isin') ? ((selectedSorting === 'isin') ? 'north' : 'south') : 'swap_vert'}}</mat-icon></button>-->
<!--                                    </td>-->
<!--                                    <td class="bold-header">-->
<!--                                        Market Cap-->
<!--                                        <button mat-icon-button (click)="onSelectedSortClick('market_value_usd');$event.preventDefault()" class="sorting-button" [class.sort-visible]="selectedSorting && selectedSorting.includes('market_value_usd')"><mat-icon>{{ selectedSorting && selectedSorting.includes('market_value_usd') ? ((selectedSorting === 'market_value_usd') ? 'north' : 'south') : 'swap_vert'}}</mat-icon></button>-->
<!--                                    </td>-->
<!--                                    <td class="bold-header">-->
<!--                                        Earliest price point-->
<!--                                        <button mat-icon-button (click)="onSelectedSortClick('market_value_start');$event.preventDefault()" class="sorting-button" [class.sort-visible]="selectedSorting && selectedSorting.includes('market_value_start')"><mat-icon>{{ selectedSorting && selectedSorting.includes('market_value_start') ? ((selectedSorting === 'market_value_start') ? 'north' : 'south') : 'swap_vert'}}</mat-icon></button>-->
<!--                                    </td>-->
<!--                                    <td class="bold-header">Asset Class</td>-->
<!--                                    <td class="bold-header">Currency</td>-->
<!--                                    <td class="bold-header">Sector</td>-->
<!--                                    <td class="bold-header">Weight</td>-->
<!--                                    </tr>-->
<!--                                    </thead>-->
<!--                                    <tbody>-->
<!--                                    <tr *ngFor="let item of selected;index as index" [class.etf-border-top]="item.isETF() && selected[index-1] && selected[index-1].isStock()">-->
<!--                                        <td class="font-weight-bold">-->
<!--                                            <span class="underline-on-hover ml-2 text-truncate" (click)="onCompanyInfoClick(item)">{{item.company_name}}</span>-->
<!--                                        </td>-->
<!--                                        <td>{{item.isin}}</td>-->
<!--                                        <td class="number-col">{{item.market_value_usd|number:'1.0-0'}} {{item.fund_reference_table.AverageMarketCapital |number:'1.0-0'}} </td>-->
<!--                                        <td>{{item.p_first_date}} {{item.fund_reference_table.FundInceptionDate}}</td>-->
<!--                                        <td>{{item.security_type_equity}}</td>-->
<!--                                        <td>{{item.currency ? item.currency.code : item.currency_id}} {{item.fund_reference_table.BaseCurrency}}</td>-->
<!--                                        <td>{{item.sector ? item.sector.name : item.sector_id}}</td>-->
<!--                                        <td>-->
<!--                                            <mat-form-field style="max-width: 100px" appearance="outline">-->
<!--                                                <input matInput [(ngModel)]="item.weight" type="number" min="0" max="100" [disabled]="portfolio.weighting_type !== 'manual'" (ngModelChange)="onWeightChange(item)">-->
<!--                                                <span matSuffix>%</span>-->
<!--                                            </mat-form-field>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    </tbody>-->
<!--                                </table>-->
                            </div>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</mat-drawer-container>
