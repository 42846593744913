<div class="card">
    <div class="row">
        <div class="col-md-6">
            <div class="card-header pb-0">{{selectedData.name}}</div>
            <div class="card-sub-header"><span>Portfolio:</span> {{subHeader}}</div>
            <div class="card-sub-header"><span>Type:</span> Baseline Portfolio</div>
        </div>
        <div class="col-md-6" *ngIf="!isPrint">
            <div class="float-right pt-3 pr-3">
                <mat-form-field appearance="outline" class="header-select ">
                    <mat-label>Coloring</mat-label>
                    <mat-select [(ngModel)]="selectedColoring" (ngModelChange)="initChart()">
                        <mat-option [value]="'individual'">Individual</mat-option>
                        <mat-option [value]="'country'">Country</mat-option>
                        <mat-option [value]="'industry'">Industry</mat-option>
                        <mat-option [value]="'sector'">Sector</mat-option>
                        <mat-option *ngIf="hasETF" [value]="'type'">ETF</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="card-body pt-0">

        <highcharts-chart *ngIf="loaded"
                          [Highcharts]="Highcharts"
                          [options]="chartOptions"
                          [constructorType]="chartConstructor"
                          style="width: 100%; height: auto; display: block;"
        ></highcharts-chart>
    </div>
</div>
