<!-- <button [matMenuTriggerFor]="filtermenu" mat-button color="primary" class="px-0 float-right"><mat-icon>filter_list</mat-icon></button> -->
<!-- <mat-menu #filtermenu="matMenu" [overlapTrigger]="false" class="filter-menu"> -->
  <div class="flex mt-4 ml-4 mr-4">
    <button class="flex-1 navigation-button" mat-stroked-button color="primary" (click)="onResetFilters(); $event.stopPropagation()">Reset Filters</button>
    <span class="flex-02 navigation-button"></span>
    <button class="flex-1" mat-stroked-button color="primary" (click)="opendialog(); $event.stopPropagation()">Create Preset</button>
  </div> 
    <mat-form-field class="w-100 px-3">
      <input placeholder="Search" matInput [(ngModel)]="searchText" (click)="$event.stopPropagation()">
    </mat-form-field>
    <mat-accordion class="app-nav-accordion" [multi]="true">

      <mat-expansion-panel *ngIf="favoriteFilters.length > 0" [expanded]="true">
        <mat-expansion-panel-header (click)="$event.stopPropagation()">
          <mat-panel-title>Favorite filters</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <div mat-menu-item *ngFor="let f of favoriteFilters; let i = index" [disableRipple]="true">
            <mat-checkbox [checked]="columnVisibility[f.id] == true"
                          [(ngModel)]="columnVisibility[f.id]"
                          (change)="cacheVisibility(true);"
                          (click)="$event.stopPropagation();"
                          color="primary">
              {{ f.name }}
              <button (click)="toggleFavorite(f); $event.stopPropagation();" mat-button color="primary" class="px-0 float-right"><mat-icon style="margin-right: 0; margin-top: 8px;">star</mat-icon></button>
              <button (click)="scrollTo(f.id); $event.stopPropagation();" mat-button color="primary" class="px-0 float-right"><mat-icon style="margin-right: 0; margin-top: 8px;">search</mat-icon></button>
            </mat-checkbox>
          </div>
        </mat-nav-list>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="savedFilters.length > 0">
        <mat-expansion-panel-header (click)="$event.stopPropagation()">
          <mat-panel-title>Saved filters</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <div mat-menu-item *ngFor="let f of savedFilters; let i = index" [disableRipple]="true">
            <mat-checkbox [(ngModel)]="activeSavedFilter[f.name]"
                          (change)="applyPreset(f)"
                          (click)="$event.stopPropagation();"
                          color="primary">{{ f.name }}
              <button (click)="editSavedPreset(i); $event.stopPropagation();" mat-button color="primary" class="px-0 float-right"><mat-icon style="margin-right: 0;">edit</mat-icon></button>
              <button (click)="removeSavedPreset(i); $event.stopPropagation();" mat-button color="primary" class="px-0 float-right"><mat-icon style="margin-right: 0;">delete</mat-icon></button>
            </mat-checkbox>
          </div>
        </mat-nav-list>
      </mat-expansion-panel>

        <mat-expansion-panel *ngFor="let panel of accordionData|panelsearch:searchText; first as isFirst"
                             [expanded]="(favoriteFilters.length < 0 && isFirst) || expandedPanels[panel.title] === true"
                             [disabled]="panel.hidden"
                             (opened)="expandedPanels[panel.title] = true"
                             (closed)="expandedPanels[panel.title] = false">
            <mat-expansion-panel-header (click)="$event.stopPropagation()" [hidden]="panel.hidden">
                <mat-panel-title [style.color]="activePanelsAmount[panel.title] ? 'black' : null"
                                 [style.font-weight]="activePanelsAmount[panel.title] ? 'bolder' : null">
                  {{panel.title}} ({{ activePanelsAmount[panel.title] || 0}})
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <div mat-menu-item *ngFor="let f of panel.items; let i = index" [disableRipple]="true" [hidden]="f.hidden">
                    <mat-checkbox [checked]="columnVisibility[f.id] == true"
                                  id="{{f.id}}"
                                  [attr.data-panel]="panel.title"
                                  [(ngModel)]="columnVisibility[f.id]"
                                  (change)="cacheVisibility(); toggleFilterCount(panel.title, f.id)"
                                  (click)="$event.stopPropagation();"
                                  color="primary">
                      {{ f.name }}
                      <button (click)="toggleFavorite(f); $event.stopPropagation();" mat-button color="primary" class="px-0 float-right">
                        <mat-icon style="margin-right: 0; margin-top: 8px;">{{ isFavorite(f) ? 'star' : 'star_outlined' }}</mat-icon>
                      </button>
                    </mat-checkbox>
                </div>
            </mat-nav-list>
        </mat-expansion-panel>
    </mat-accordion>
<!-- </mat-menu> -->
