import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {PortfolioModel} from '../../../../shared/models/portfolio.model';
import {PortfolioService} from '../../../portfolio/portfolio.service';
import {PortfolioSelectorDialogComponent} from '../../portfolio-selector-dialog/portfolio-selector-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavListComponent implements OnInit {
  @Input() portfolio = new PortfolioModel({});
  @Input() isAdmin = false;
  @Output() closeSidenav = new EventEmitter();
  @Input() isPortfolio = false;

  active = 'settings-section-optimized';

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private portfolioService: PortfolioService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onPortfolioSelectClick() {
    this.dialog.open(PortfolioSelectorDialogComponent, {
      minWidth: '800px',
    });
  }

  onCloseSidenav() {
    this.closeSidenav.emit();
  }

  onClosePortfolio() {
    console.log('here');
    this.portfolioService.clear();
    this.router.navigate(['/app'])
  }
  onLogout() {
    this.authService.logout(true);
  }

  scroll(el: string) {
    const element = document.getElementById(el);
    this.active = el;
    element.scrollIntoView({behavior: 'smooth'});
  }

}
