import {Component, Injector, OnInit} from '@angular/core';
import {PageSelectionComponent} from "../page-selection/page-selection.component";

@Component({
  selector: 'app-page-upload',
  templateUrl: '../page-selection/page-selection.component.html',
  styleUrls: ['../portfolio-create.component.scss']
})
export class PageUploadComponent extends PageSelectionComponent{

  constructor(injector: Injector) {
    super(injector);
  }

  initPage() {
    this.showUploadBtn = true
    this.selectedPortfolioType = 1;
    this.onUploadClick();
  }


}
