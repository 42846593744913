<button [matMenuTriggerFor]="filtermenu" mat-stroked-button style="min-width: 150px;">
    <span class="bench-btn-label" *ngIf="selectedBenchmark || showLabel">Select benchmark</span>
    {{selectedBenchmark ? selectedBenchmark.index_name_full : benchmarkPlaceholder}}

  <button mat-icon-button color="primary" *ngIf="selectedBenchmark" (click)="removeBenchmark();$event.stopPropagation()" class="remove-benchmark-btn"><mat-icon>close</mat-icon></button>
</button>
<mat-menu #filtermenu="matMenu" [overlapTrigger]="false" class="filter-menu">
  <div mat-menu-item [disableRipple]="true" style="height: 50px">
    <mat-form-field class="w-100" (click)="$event.stopPropagation();">
      <input placeholder="Search" matInput [(ngModel)]="searchText" class="select-input-search" (ngModelChange)="onSearchChange()">
      <button *ngIf="searchText != ''" mat-icon-button matSuffix color="primary" (click)="resetSearch()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <mat-accordion class="app-nav-accordion">
        <mat-expansion-panel [hidden]="benchmarksHidden">
            <mat-expansion-panel-header (click)="$event.stopPropagation()">
                <mat-panel-title><b>Benchmarks</b></mat-panel-title>
            </mat-expansion-panel-header>

            <mat-accordion class="app-nav-accordion">
                <mat-expansion-panel *ngFor="let panel of benchmarks">
                    <mat-expansion-panel-header (click)="$event.stopPropagation()" [hidden]="panel.hidden">
                        <mat-panel-title>{{panel.group}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <div mat-menu-item
                             *ngFor="let f of panel.items | search: searchText"
                             [hidden]="f.hidden == true"
                             (click)="onBenchmarkSelect(f)"
                             [class.active]="f.name === this.selectedBenchmark.name"
                        >{{f.name_full}}</div>
                    </mat-nav-list>
                </mat-expansion-panel>
            </mat-accordion>

        </mat-expansion-panel>

        <mat-expansion-panel [hidden]="portfoliosHidden">
            <mat-expansion-panel-header (click)="$event.stopPropagation()">
                <mat-panel-title><b>Portfolios</b></mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <div mat-menu-item
                     *ngFor="let f of portfolios | search: searchText"
                     [hidden]="f.hidden == true"
                     (click)="onBenchmarkSelect(f)"
                     [class.active]="f.name === this.selectedBenchmark"
                >{{f.name}}</div>
            </mat-nav-list>
        </mat-expansion-panel>
    </mat-accordion>
</mat-menu>
