<div class="row">
    <div class="col-md-2">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Company limit</mat-label>
            <input matInput placeholder="Company limit" type="number" min="1" max="100" required [(ngModel)]="portfolio.limit" (blur)="onLimitChange()">
        </mat-form-field>
    </div>
  <div class="col-md-2">
    <p class="mb-0 pt-3">Company count: {{(meta.total) || 0}}</p>
  </div>
    <div class="col text-right mt-2">
        <button mat-flat-button color="primary" class="ml-3 mr-2" (click)="onFilterChange()"><mat-icon>search</mat-icon> Search</button>
    </div>

</div>
<button *ngIf="redirectToPortfoliosAfterSave" class="mt-4" mat-flat-button color="primary" (click)="onSaveClick()">Save portfolio</button>


<div class="mb-2 clearfix">
  <div class="selected-filter" *ngFor="let s of selectedFilterLabels|keyvalue">
    <span class="selected-label">{{s.key}}</span>:
    <span *ngFor="let l of s.value;let last = last;" [matTooltip]="l.name.length > filter_label_length_limit ? l.name : ''"><span class="text-truncate">{{(l.name.length > filter_label_length_limit) ? ((l.name | slice:0:filter_label_length_limit) + '...') : l.name}}</span> <span *ngIf="!last">| </span></span>
    <mat-icon (click)="onDeleteFilterClick(s)">close</mat-icon>
  </div>
  <div class="selected-filter" *ngFor="let d of currentFilters.dynamic|keyvalue">
    <span class="selected-label">{{sortingNiceName(d.key)}}</span>: {{d.value}}
    <mat-icon (click)="onDeleteDynamicFilterClick(d.key)">close</mat-icon>
  </div>
    <!-- <div class="float-right">
      <app-asset-table-column-visibility-filter #stockFilters [columnVisibility]="columnVisibility" assetType="stock" [quantList]="quantList" [dynamicColumns]="dynamicColumns" (columnChanged)="onDynamicColumnChange($event)"></app-asset-table-column-visibility-filter>
    </div> -->
</div>
<div class="overflow-auto">
    <app-asset-table
            [list]="list"
            [columnVisibility]="columnVisibility"
            [filterLists]="filterLists"
            [sorting]="sorting"
            [currentFilters]="currentFilters"
            [quantList]="dynamicColumns"
            filterProp="quant"
            [hideSelect]="true"
            (fetchCompanies)="onFilterChange()"
            (sortClicked)="onSortClick($event)"
    ></app-asset-table>
</div>
<app-pagination *ngIf="list.length" [meta]="meta" (pageEvent)="onPageEvent($event)"></app-pagination>
