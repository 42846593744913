<div class="container-fluid d-flex align-items-center h-100 login-form-wrapper">
  <div class="form-signin form-registration text-center">

    <form name="form" (ngSubmit)="f.form.valid && onRegistrationFormSubmit()" #f="ngForm" class="mt-3">
      <div class="form-group">
        <input type="text" [(ngModel)]="guest.first_name" class="form-control" placeholder="First name" name="first_name" required/>
      </div>
      <div class="form-group">
        <input type="text" [(ngModel)]="guest.last_name" class="form-control" placeholder="Last name" name="last_name" required/>
      </div>
      <div class="form-group">
        <input type="text" [(ngModel)]="guest.email" class="form-control" placeholder="Email" name="email" required/>
      </div>
      <div class="form-group">
        <input type="text" [(ngModel)]="guest.phone" class="form-control" placeholder="Phone" name="phone" required/>
      </div>
      <button class="btn btn-block btn-primary mb-3">Register</button>
      <a routerLink="/" mat-button color="primary">
        <mat-icon aria-hidden="false">keyboard_arrow_left</mat-icon> <span>Back to home</span>
      </a>
    </form>
  </div>
</div>
