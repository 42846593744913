<span [matMenuTriggerFor]="headerMenu" class="input-filter-header" style="text-transform: capitalize">{{title}} <small *ngIf="unit">({{unit}})</small></span>
<mat-menu #headerMenu="matMenu" [overlapTrigger]="false" class="input-menu" (closed)="onPanelClosed()">
    <div mat-menu-item [disableRipple]="true">
        <div class="row">
            <div class="col divider-right">
                <mat-form-field appearance="outline"
                        (click)="$event.stopPropagation();"
                        (keydown.tab)="$event.stopPropagation()"
                        (keydown.enter)="onEnterHit()">
                    <mat-label>Min</mat-label>
                    <input matInput type="number" name="header_min" [(ngModel)]="minModel" (ngModelChange)="minModelChange()">
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline"
                        (click)="$event.stopPropagation();"
                        (keydown.tab)="$event.stopPropagation()"
                        (keydown.enter)="onEnterHit()">
                    <mat-label>Max</mat-label>
                    <input matInput type="number" name="header_max" [(ngModel)]="maxModel" (ngModelChange)="maxModelChange()">
                </mat-form-field>
            </div>
        </div>
    </div>
  <div class="selection-container bottom-buttons">
    <button type="button" mat-button color="primary" (click)="onOKClick();">OK</button>
    <button type="button" mat-button color="primary" (click)="onPanelCloseOrCancel();">Cancel</button>
  </div>
</mat-menu>
