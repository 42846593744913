<mat-toolbar>
    <h1>Watchlists</h1>
    <button class="ml-auto" type="button" mat-flat-button color="primary" [routerLink]="'/app/watchlist-create'">Create watchlist</button>
</mat-toolbar>

<div class="card">
    <div class="card-body px-0">
        <div class="port-table-container">
            <table mat-table [dataSource]="dataSource" matSort class="port-table table-sticky-first-col" [hidden]="!tableLoaded">
                <ng-container matColumnDef="name" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="cursor-pointer" (click)="onWatchClick(element)" >
                            {{element.name}}
                            <app-info-popover *ngIf="element.description" [company]="element" [title]="'Description - ' + element.name" [content]="element.description" (click)="closeOtherInfo(element);$event.stopPropagation();"></app-info-popover>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef ></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="color-mid-grey">more_vert</mat-icon></button>
                        <mat-menu #menu="matMenu">
                            <button *ngIf="!element.readonly" mat-menu-item [routerLink]="['/app/watchlist-create', element.id]">Update</button>
                            <button *ngIf="!element.readonly" mat-menu-item (click)="deleteItem(element)">Delete</button>
                        </mat-menu></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            </table>
            <p *ngIf="!list || !list.length" class="text-center">No watchlists</p>
        </div>
    </div>
</div>
