import {Component, Injector, OnInit, ViewChild, ViewEncapsulation,Output,EventEmitter,Input} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PortfolioService} from "../portfolio.service";
import {MatDialog} from "@angular/material/dialog";
import {CompanyModel} from "../../../shared/models/company.model";
import {PageMeta} from "../../../shared/models/page.model";
import {NewPortfolioCartDialogComponent} from "./components/new-portfolio-cart-dialog/new-portfolio-cart-dialog.component";
import {PortfolioModel, VISIBILITY_PRIVATE} from "../../../shared/models/portfolio.model";
import {MatStepper} from "@angular/material/stepper";
import {UploadDialogComponent} from "../../../components/upload-dialog/upload-dialog.component";
import * as moment from "moment";
import {UtilService} from "../../../shared/services/util.service";
import { BasePortfolioCreateComponent } from '../base-portfolio-create/base-portfolio-create.component';
import {
  NewPortfolioSelectDialogComponent
} from "./components/new-portfolio-select-dialog/new-portfolio-select-dialog.component";

@Component({
  selector: 'app-portfolio-create',
  templateUrl: './portfolio-create.component.html',
  styleUrls: ['./portfolio-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortfolioCreateComponent extends BasePortfolioCreateComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  @Input() startDateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() startingDate: EventEmitter<any> = new EventEmitter<any>();

  selectLimit = 100;

  selectedPortfolioType = null;

  portfolio = new PortfolioModel({
    description: "Here you can describe the way you have iterated your starting universe for your portfolio, e.g. which filters you have used and in which order or. Add a brief description of your underlying investment idea to support your portfolio overview after you have saved and generated your portfolio. This text will also show in the overview page when you hoover over the portfolios that you have built.",
    currency_id: 42,
    weighting_type: 'equal'

  })
  currentPage = 1;
  searchTerm = ''
  selectedStep = 0;
  meta: PageMeta = {};
  list: CompanyModel[] = [];
  selected: CompanyModel[] = [];

  // Select input lists
  filterLists: any = {
    markets: [],
    regions: [],
    countries: [],
    sectors: [],
    industries: [],
    currencies: [],
    main_currencies: [],
    size_classification: [],
    exchange_code_fs: [],
    country_of_risk: [],
    expectedResults: null,
    flags: [],
    DomicileName: [],
    BrandingCompanyName: [],
    LocalCategoryName: [],
    GlobalCategoryName: [],
    EquityStyleBox:[],
    FeeLevel:[],
    MorningStarRating:[],
    FundOfFunds: [],
    UCITS: [],
    BaseCurrency: []
  }

  max_start_date = null;
  weightError = false;
  hasETF = false;

  route: ActivatedRoute;
  router: Router;
  service: PortfolioService;
  dialog: MatDialog;
  location: any;
  util: UtilService;

  priorityCurrencies = ['USD', 'EUR', 'GBP', 'SEK', 'NOK', 'DKK'];
  selectedCurrencies: any[] = [];

  portfolioLoadedInService: PortfolioModel = null;

  selectedSorting = 'company_name';

  ngOnInit() {
    this.service.portfolio$.subscribe(res => this.portfolioLoadedInService = res);
    this.fetchCompaniesIn();
    this.initPage();
  }

  initPage() {}

  getFiltersIn(filter?) {
    let extraFilters: any = {}
    if (filter && filter.limit) {
      extraFilters.limit = filter.limit;
      delete filter.limit;
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp);
    })
  }

  fetchCompaniesIn(filter?, page?, isPageEvent?) {
    let extraFilters: any = {
      page: page ? page : this.currentPage
    }
    if (filter) {
      if (filter.searchTerm) {
        extraFilters.search_term = filter.searchTerm;
      }
      if (filter.page) {
        extraFilters.page = filter.page;
      }
      // type is the etf/stock type
      if (filter.type) {
        extraFilters.type = filter.type;
        delete filter.type;
      }
      if (filter.limit) {
        extraFilters.limit = filter.limit;
        delete filter.limit;
      }
    }
    this.service.fetchData(filter, extraFilters).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.handleFilterResponse(resp, isPageEvent);
    })
  }

  handleCompaniesResponse(resp, isPageEvent?) {
    if (resp.data) {
      if (isPageEvent) {
        let tempList = resp.data.map(r => {
          const company = new CompanyModel(r)
          company.selected = this.isCompanyInSelected(r) > -1
          return company;
        });
        this.list = this.list.concat(tempList);
      } else {
        this.list = resp.data.map(r => {
          const company = new CompanyModel(r)
          company.selected = this.isCompanyInSelected(r) > -1
          return company;
        });
      }
    }
    // If generated portfolio set every company as the selected list
    if (this.selectedPortfolioType == 2) {
      this.selected = this.list;
      this.sortSelectedList();
    }
    this.meta = new PageMeta(resp);
  }

  isCompanyInSelected(company) {
    return this.selected.findIndex(el => el.security_id === company.security_id);
  }

  onCompanySelect(selectInfo) {
    const selectedIndex = this.isCompanyInSelected(selectInfo.company);
    if (selectInfo.isSelected) {
      // selected, add to list
      if (selectedIndex > -1) {
        return this.service.notificationService.open('Company already selected with security id: ' + selectInfo.company.security_id);
      }
      if (this.selected.length < 100) {
        selectInfo.company.selected = true;
        if (selectInfo.company.isStock()) {
          // Add stock to start of array
          this.selected.unshift(selectInfo.company);
        } else {
          // Add !stock to end of array
          this.selected.push(selectInfo.company);
        }
      } else {
        return this.service.notificationService.open(`You can select only ${this.selectLimit} assets.`);
      }
    } else {
      // deselected
      if (selectedIndex > -1) {
        this.selected.splice(selectedIndex, 1);
        selectInfo.company.selected = false;
      }
    }

    this.checkETF();
    if ((this.portfolio.weighting_type === 'equal') && this.hasETF) {
      this.setAllAssetEqual()
    } else {
      this.recalculateWeight()
    }
    this.updateSelectedCurrencyList();
    this.sortSelectedList();
  }

  sortSelectedList() {
    const sortingProps = ['-security_type_equity'];
    if (this.selectedSorting) {
      sortingProps.push(this.selectedSorting);
    }
    this.selected.sort(this.util.dynamicSortMultiple(sortingProps));
  }

  changeSelectedSorting(sortProperty) {
    this.selectedSorting = sortProperty;
    this.sortSelectedList();
  }

  onSelectedSortClick(type) {
    this.selectedSorting = this.service.checkSelectedSorting(this.selectedSorting, type);
    this.sortSelectedList();
  }

  updateSelectedCurrencyList() {
    this.selectedCurrencies = [];
    this.selected.map((company: CompanyModel) => {
      if (company.currency) {
        let i = this.selectedCurrencies.findIndex(x =>x.id == company.currency.id);
        if (i <= -1) {
          this.selectedCurrencies.push(company.currency);
        }
      }
      return company;
    });
  }

  recalculateWeight() {
    this.checkETF();
    if (this.portfolio.weighting_type === 'market_cap') { // market cap
      this.recalculateMarketCapWeight()
      return;
    }
    // recalculate equal weights
    let selectedStockLength = this.selected.filter(c => c.isStock()).length + this.selected.filter(c => c.isFund()).length;
    let freeStockWeight = 100 - this.portfolio.etfPercent;

    let selectedETFLength = this.selected.filter(c => c.isETF()).length;
    let freeETFWeight = this.portfolio.etfPercent;

    if (selectedStockLength || selectedETFLength) {
      this.selected.map((company: CompanyModel) => {
        if (company.weightManuallyAdjusted) {
          if (company.isStock() || company.isFund()) {
            selectedStockLength -= 1;
            freeStockWeight -= company.weight;
          } else {
            selectedETFLength -= 1;
            freeETFWeight -= company.weight;
          }
        }
      });
      this.selected = this.selected.map((company: CompanyModel) => {

        console.log(company)
        if (!company.weightManuallyAdjusted) {
          if (company.isStock() || company.isFund()) {
            company.weight = +(freeStockWeight / selectedStockLength).toFixed(2);
          } else {
            company.weight = +(freeETFWeight / selectedETFLength).toFixed(2);
          }
        }
        return company;
      });
      this.weightError = freeStockWeight < 0 || freeETFWeight < 0;
    }

  }

  onWeightChange(company) {
    this.portfolio.weighting_type = 'manual';
    company.weightManuallyAdjusted = company.weight;
    this.recalculateWeight();
  }

  recalculateMarketCapWeight() {
    let freeWeight = 100;

    if (this.hasETF) {
      freeWeight = 100 - this.portfolio.etfPercent;
      const selectedETFLength = this.selected.filter(c => c.isETF()).length;
      this.selected = this.selected.map((company: CompanyModel) => {
        if (!company.isStock()) {
          company.weight = +(this.portfolio.etfPercent / selectedETFLength).toFixed(2);
        }
        return company;
      });
    }
    let totalMarketCap = 0;
    this.selected.map((company: CompanyModel) => {
      if (company.isStock()) {
        totalMarketCap += +company.market_value_usd;
      }
      if (company.isFund()) {
        totalMarketCap += +company.fund_reference_table.AverageMarketCapital;
      }
    });
    this.selected = this.selected.map((company: CompanyModel) => {
      if (company.isStock()) {
        company.weight = +(+company.market_value_usd/totalMarketCap*freeWeight).toFixed(2);
      }
      if (company.isFund()) {
        company.weight = +(+company.fund_reference_table.AverageMarketCapital/totalMarketCap*freeWeight).toFixed(2);
      }
      return company;
    });
  }

  setAllAssetEqual() {
    // recalculate equal weights


    const selectedStockLength = this.selected.filter(c => c.isStock()).length + this.selected.filter(c => c.isFund()).length;
    const selectedETFLength = this.selected.filter(c => c.isETF()).length;
    const totalLength = selectedStockLength + selectedETFLength;
    const freeWeight = 100;

    this.selected = this.selected.map((company: CompanyModel) => {
      company.weight = +(freeWeight / totalLength).toFixed(2);
      return company;
    });
    this.portfolio.etfPercent = Math.round((selectedETFLength / totalLength) * 100);
  }

  onWeightTypeChange() {
    if (this.portfolio.weighting_type === 'equal') { // equal
      this.selected = this.selected.map((company: CompanyModel) => {
        company.weightManuallyAdjusted = false;
        return company;
      });
      this.recalculateWeight();
    } else if (this.portfolio.weighting_type === 'market_cap') { // market cap
      this.recalculateMarketCapWeight()
    }
  }

  onSelectAll(event) {
    // Add all company to the selected list
    if (event.selected === true) {
      this.list.map(c => {
        this.onCompanySelect({
          company: c,
          isSelected: true
        });
      });
    } else {
      // Remove all selecteds (by asset type)
      this.list = this.list.map(c => {
        c.selected = false;
        return c;
      });
      if (event.type === 'etf') {
        this.selected = this.selected.filter( (s: CompanyModel) => !s.isETF());
      } else {
        this.selected = this.selected.filter( (s: CompanyModel) => !s.isStock());
      }
    }
    this.sortSelectedList();
  }

  onMaxStartDateChange(date) {
    if (date) {
      this.max_start_date = date;
      if (!this.portfolio.start_date_fixed) {
        this.portfolio.start_date = date;
      }
    }
  }

  onFileUploaded(list) {
    // Only add company to the selected list if not already there
    let maxDate = '1800-01-01';
    list.map((c: any) => {
      if (moment(c.p_first_date) > moment(maxDate)) {
        maxDate = c.p_first_date;
      }
      c.weightManuallyAdjusted = c.weight;
      const selectedIndex = this.isCompanyInSelected(c);
      if (selectedIndex == -1) {
        if (this.selected.length < 100) {
          c.selected = true;
          this.selected.push(c);
        } else {
          return this.service.notificationService.open(`You can select only ${this.selectLimit} assets.`);
        }
      }
    });
    this.portfolio.start_date = maxDate;
    this.portfolio.weighting_type = 'manual';
    this.updateSelectedCurrencyList();
    this.selectedPortfolioType = 1;
  }

  openSelectedType(typeNumber) {
    this.onSelectAll(false); //deselect all selected
    this.selectedPortfolioType = typeNumber;
    this.myStepper.next();

    if (this.selectedStep == 1) {
      if (this.selectedPortfolioType !== 1) {
        // generate portfolio
        // Collect available currencies for "generate" portfolio
        this.selected = this.list.slice(0, this.portfolio.limit);
        this.updateSelectedCurrencyList()
      }
    }
  }

  handleFilterResponse(resp, isPageEvent?) {
    // Do not filter the filtering lists
    // this.filterLists = {...resp};

    Object.entries(resp).map(([label, data]) => {
      // skip the list response
      if (label === "list") {
        return false;
      }
      if (!this.filterLists[label] || !this.filterLists[label].length) {
        this.filterLists[label] = data;
      }
    });

    if (this.filterLists.list) {
      delete this.filterLists.list;
    }
    this.filterLists.main_currencies = resp.currencies ? resp.currencies : this.filterLists.main_currencies;
    this.filterLists.currencies = resp.currencies || resp.BaseCurrency || [];
    this.filterLists.currencies = this.filterLists.currencies.map(c => {
      c.name = `${c.name}`
      return c
    })
    
    this.filterLists.currencies = this.filterLists.currencies.sort((a,b) => {return this.priorityCurrencies.includes(b.code) ? 0 : a.code > b.code ? 1: 0})
    
    if (resp.markets.length) {
      this.filterLists.markets = resp.markets || [];
    }
    if (resp.regions.length) {
      this.filterLists.regions = resp.regions || [];
    }
    if (resp.countries) {
      this.filterLists.countries = resp.countries || [];
    }
    if (resp.sectors) {
      this.filterLists.sectors = resp.sectors || [];
    }
    if (resp.industries) {
      this.filterLists.industries = resp.industries || [];
    }
    if (resp.exchange_code_fs && resp.exchange_code_fs.length) {
      this.filterLists.exchange_code_fs = resp.exchange_code_fs || [];
    }
    if (resp.DomicileName && resp.DomicileName.length) {
      this.filterLists.DomicileName = resp.DomicileName || [];
    }
    if (resp.BrandingCompanyName && resp.BrandingCompanyName.length) {
      this.filterLists.BrandingCompanyName = resp.BrandingCompanyName || [];
    }
    if (resp.BaseCurrency && resp.BaseCurrency.length) {
      this.filterLists.BaseCurrency = resp.BaseCurrency || [];
    }
    if (resp.LocalCategoryName && resp.LocalCategoryName.length) {
      this.filterLists.LocalCategoryName = resp.LocalCategoryName || [];
    }
    if (resp.GlobalCategoryName && resp.GlobalCategoryName.length) {
      this.filterLists.GlobalCategoryName = resp.GlobalCategoryName || [];
    }
    if (resp.FeeLevel && resp.FeeLevel.length) {
      this.filterLists.FeeLevel = resp.FeeLevel || [];
    }
    if (resp.EquityStyleBox && resp.EquityStyleBox.length) {
      this.filterLists.EquityStyleBox = resp.EquityStyleBox || [];
    }
    if (resp.UCITS && resp.UCITS.length) {
      this.filterLists.UCITS = resp.UCITS || [];
    }
    if (resp.MorningStarRating && resp.MorningStarRating.length) {
      this.filterLists.MorningStarRating = resp.MorningStarRating || [];
    }
    this.filterLists.size_classification = [
      {
        "id": "Micro Cap",
        "name": "Micro Cap"
      },
      {
        "id": "Small Cap",
        "name": "Small Cap"
      },
      {
        "id": "Mid Cap",
        "name": "Mid Cap"
      },
      {
        "id": "Large Cap",
        "name": "Large Cap"
      },
      {
        "id": "Mega Cap",
        "name": "Mega Cap"
      }
    ];

    this.filterLists.country_of_risk = [
      {
        "id": "AE",
        "name": "AE"
      },
      {
        "id": "AR",
        "name": "AR"
      },
      {
        "id": "AT",
        "name": "AT"
      },
      {
        "id": "AU",
        "name": "AU"
      },
      {
        "id": "BB",
        "name": "BB"
      },
      {
        "id": "BE",
        "name": "BE"
      },
      {
        "id": "BF",
        "name": "BF"
      },
      {
        "id": "BG",
        "name": "BG"
      },
      {
        "id": "BH",
        "name": "BH"
      },
      {
        "id": "BM",
        "name": "BM"
      },
      {
        "id": "BR",
        "name": "BR"
      },
      {
        "id": "BS",
        "name": "BS"
      },
      {
        "id": "BW",
        "name": "BW"
      },
      {
        "id": "CA",
        "name": "CA"
      },
      {
        "id": "CD",
        "name": "CD"
      },
      {
        "id": "CG",
        "name": "CG"
      },
      {
        "id": "CH",
        "name": "CH"
      },
      {
        "id": "CL",
        "name": "CL"
      },
      {
        "id": "CN",
        "name": "CN"
      },
      {
        "id": "CO",
        "name": "CO"
      },
      {
        "id": "CR",
        "name": "CR"
      },
      {
        "id": "CY",
        "name": "CY"
      },
      {
        "id": "CZ",
        "name": "CZ"
      },
      {
        "id": "DE",
        "name": "DE"
      },
      {
        "id": "DK",
        "name": "DK"
      },
      {
        "id": "EC",
        "name": "EC"
      },
      {
        "id": "EE",
        "name": "EE"
      },
      {
        "id": "EG",
        "name": "EG"
      },
      {
        "id": "ES",
        "name": "ES"
      },
      {
        "id": "FI",
        "name": "FI"
      },
      {
        "id": "FO",
        "name": "FO"
      },
      {
        "id": "FR",
        "name": "FR"
      },
      {
        "id": "GA",
        "name": "GA"
      },
      {
        "id": "GB",
        "name": "GB"
      },
      {
        "id": "GE",
        "name": "GE"
      },
      {
        "id": "GH",
        "name": "GH"
      },
      {
        "id": "GR",
        "name": "GR"
      },
      {
        "id": "HK",
        "name": "HK"
      },
      {
        "id": "HR",
        "name": "HR"
      },
      {
        "id": "HU",
        "name": "HU"
      },
      {
        "id": "ID",
        "name": "ID"
      },
      {
        "id": "IE",
        "name": "IE"
      },
      {
        "id": "IL",
        "name": "IL"
      },
      {
        "id": "IN",
        "name": "IN"
      },
      {
        "id": "IQ",
        "name": "IQ"
      },
      {
        "id": "IS",
        "name": "IS"
      },
      {
        "id": "IT",
        "name": "IT"
      },
      {
        "id": "JE",
        "name": "JE"
      },
      {
        "id": "JO",
        "name": "JO"
      },
      {
        "id": "JP",
        "name": "JP"
      },
      {
        "id": "KE",
        "name": "KE"
      },
      {
        "id": "KH",
        "name": "KH"
      },
      {
        "id": "KR",
        "name": "KR"
      },
      {
        "id": "KW",
        "name": "KW"
      },
      {
        "id": "KY",
        "name": "KY"
      },
      {
        "id": "KZ",
        "name": "KZ"
      },
      {
        "id": "LA",
        "name": "LA"
      },
      {
        "id": "LB",
        "name": "LB"
      },
      {
        "id": "LI",
        "name": "LI"
      },
      {
        "id": "LK",
        "name": "LK"
      },
      {
        "id": "LT",
        "name": "LT"
      },
      {
        "id": "LU",
        "name": "LU"
      },
      {
        "id": "LV",
        "name": "LV"
      },
      {
        "id": "MA",
        "name": "MA"
      },
      {
        "id": "MC",
        "name": "MC"
      },
      {
        "id": "ML",
        "name": "ML"
      },
      {
        "id": "MM",
        "name": "MM"
      },
      {
        "id": "MO",
        "name": "MO"
      },
      {
        "id": "MT",
        "name": "MT"
      },
      {
        "id": "MU",
        "name": "MU"
      },
      {
        "id": "MX",
        "name": "MX"
      },
      {
        "id": "MY",
        "name": "MY"
      },
      {
        "id": "MZ",
        "name": "MZ"
      },
      {
        "id": "NG",
        "name": "NG"
      },
      {
        "id": "NI",
        "name": "NI"
      },
      {
        "id": "NL",
        "name": "NL"
      },
      {
        "id": "NO",
        "name": "NO"
      },
      {
        "id": "NZ",
        "name": "NZ"
      },
      {
        "id": "OM",
        "name": "OM"
      },
      {
        "id": "PA",
        "name": "PA"
      },
      {
        "id": "PE",
        "name": "PE"
      },
      {
        "id": "PG",
        "name": "PG"
      },
      {
        "id": "PH",
        "name": "PH"
      },
      {
        "id": "PL",
        "name": "PL"
      },
      {
        "id": "PR",
        "name": "PR"
      },
      {
        "id": "PS",
        "name": "PS"
      },
      {
        "id": "PT",
        "name": "PT"
      },
      {
        "id": "QA",
        "name": "QA"
      },
      {
        "id": "RO",
        "name": "RO"
      },
      {
        "id": "RU",
        "name": "RU"
      },
      {
        "id": "SA",
        "name": "SA"
      },
      {
        "id": "SE",
        "name": "SE"
      },
      {
        "id": "SG",
        "name": "SG"
      },
      {
        "id": "SI",
        "name": "SI"
      },
      {
        "id": "SK",
        "name": "SK"
      },
      {
        "id": "TH",
        "name": "TH"
      },
      {
        "id": "TJ",
        "name": "TJ"
      },
      {
        "id": "TR",
        "name": "TR"
      },
      {
        "id": "TT",
        "name": "TT"
      },
      {
        "id": "TW",
        "name": "TW"
      },
      {
        "id": "UA",
        "name": "UA"
      },
      {
        "id": "US",
        "name": "US"
      },
      {
        "id": "UY",
        "name": "UY"
      },
      {
        "id": "VG",
        "name": "VG"
      },
      {
        "id": "VN",
        "name": "VN"
      },
      {
        "id": "ZA",
        "name": "ZA"
      },
      {
        "id": "ZW",
        "name": "ZW"
      }
    ];
    this.filterLists.flags = [{
      "id": "0",
      "name": "0"
    }, {
      "id": "1",
      "name": "1"
    }];
    if (resp.list) {
      this.handleCompaniesResponse(resp.list, isPageEvent);
    }
    this.filterLists.expectedResults = resp.list && resp.list.meta && resp.list.meta.total ? resp.list.meta.total : 0;
  }

  onPageEvent(data) {
    this.currentPage = data.page;
    this.fetchCompaniesIn(data.filters, null, data.isPageEvent);
  }

  stepChange(step) {
    this.selectedStep = step.selectedIndex;
  }

  openCartDialog() {
    const dialogRef = this.dialog.open(NewPortfolioSelectDialogComponent, {
      data: {
        list: this.selected,
        portfolio: this.portfolio
      },
      autoFocus: false,
      width: '100%'
    });

    dialogRef.afterClosed().subscribe( (resp: any) => {
      if (resp) {
        if (resp.list) {
          this.selected = [...resp.list];
          this.list = this.list.map(i => {
            i.selected = this.isCompanyInSelected(i) > -1;
            return i;
          })
          this.recalculateWeight();
        }
      }
    });
  }

  onPortfolioCartClick() {
    this.onStartDateSet(true)
  }

  convertSecurityIDsWithWeights() {
    let securityIDs:any = {}
    this.selected.map(s => {
      securityIDs[s.id] = {
        // set weight between 0-1
        weight: s.weight/100,
        type: s.security_type_equity
      }
    });
    return securityIDs;
  }

  getCurrencyIDByCode(code) {
    const currency = this.filterLists.currencies.filter(c => c.code === code)[0]
    return currency ? currency.id : 0;
  }

  extractCurrencyHedging() {
    this.selectedCurrencies = this.portfolio.currency_hedging.map((ch: any) => {
      return {
        id: this.getCurrencyIDByCode(ch.currency_code),
        code: ch.currency_code,
        hedging: ch.hedge_pct
      }
    })
  }

  onSavePortfolioClick(portfolio) {
    if (!portfolio.name || !portfolio.description || !portfolio.currency_id || !portfolio.start_date) {
      return this.service.notificationService.open("Please fill every mandatory field.")
    }
    if (moment(portfolio.start_date).unix() < moment(this.max_start_date).unix()) {
      return this.service.notificationService.open("Start date can not be older than minimum asset start date: " + this.max_start_date)
    }

    if (!portfolio.visibility) {
      portfolio.visibility = VISIBILITY_PRIVATE;
    }
    portfolio.security_ids = this.convertSecurityIDsWithWeights();

    if (this.selectedPortfolioType === 1) {
      // manual portfolio
      delete portfolio.country_ids;
      delete portfolio.industry_ids;
      delete portfolio.market_ids;
      delete portfolio.region_ids;
      delete portfolio.sector_ids;
    }

    // Convert hedging to portfolio property
    let currency_hedging = {};
    this.selectedCurrencies.map((c: any) => {
      if (c.id != portfolio.currency_id) {
        currency_hedging[c.code] = c.hedging;
      }
    })
    portfolio.currency_hedging = currency_hedging;

    this.service.saveItem(portfolio).subscribe((resp: any) => {
      if (resp.id) {

        // Wait for R script to answer
        this.openWebsocket(resp.id);
        this.service.notificationService.open("Portfolio saved");

        // User came from portfolio overview page, redirect him back
        if (this.portfolioLoadedInService && this.portfolioLoadedInService.id && this.portfolioLoadedInService.id == resp.id) {
          this.router.navigate(['/app/portfolio-view/'+resp.id+'/overview'])
        } else {
          this.router.navigate(['/app'])
        }
      }
    })
  }

  openWebsocket(portfolioID) {
    this.service.openPortfolioWebsocket(portfolioID).subscribe(
      msg => {
        console.log('message received: ', msg)
        if (msg.success) {
          if (msg.task == 'portfolio') {
            this.service.portfolioFinished.next({id: portfolioID}) // reload portfolio
            // websocket answered success true, close socket
            this.service.notificationService.open("#"+portfolioID + " portfolio computation finished")
          }
        } else {
          this.service.notificationService.open("There was an error during portfolio computation.")
        }
        this.service.closeWebsocket();
      }, // Called whenever there is a message from the server.
      err => this.handleWebsocketClosure(err, portfolioID), // Called if at any point WebSocket API signals some kind of error.
      () => console.log('complete') // Called when connection is closed (for whatever reason).
    );
  }

  handleWebsocketClosure(resp, portfolioID) {
    console.log("websocket error", resp)
    // Browser closed (or ngnix) after 60sec, reopen socket
    if (resp.code === 1006) {
      this.openWebsocket(portfolioID);
    }
  }

  onNextClick() {
    this.myStepper.next();
    if (this.selectedStep == 1) {
      if (this.selectedPortfolioType !== 1) {
        // generate portfolio
        // Collect available currencies for "generate" portfolio
        this.selected = this.list.slice(0, this.portfolio.limit);
        this.updateSelectedCurrencyList()
      }
    }
    this.recalculateWeight();
  }
  onBackClick() {
    this.myStepper.previous();
  }

  onCompanyInfoClick(company) {
    this.service.openCompanyInfoDialog(company.fs_ticker, company);
  }

  onUploadClick() {

    const dialogRef = this.dialog.open(UploadDialogComponent, {
      minWidth: '400px',
      maxWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((resp: CompanyModel[]) => {
      if (resp && resp.length) {
        this.onFileUploaded(resp);
      }
    })
  }

  // Fetch market values at start date
  onStartDateSet(openCartAfterReceived = false) {
    const securities = this.selected.map(s => s.security_id);
    if (securities.length && this.portfolio.start_date) {
      this.service.getMarketValuesAtStart({
        date: this.portfolio.start_date,
        securities: securities
      }).subscribe((resp: any) => {
        if (resp && resp.success) {
          this.portfolio.start_date_fixed = true;
          const mcapList = resp.success;
          this.selected = this.selected.map((company: CompanyModel) => {
            if (mcapList[company.security_id]) {
              company.market_value_start = `${mcapList[company.security_id].mcap/1000}`; // Fix mcp value
            }
            return company
          });
          // Recalculate weight if weigting type market_cap
          if (this.portfolio.weighting_type == 'market_cap') {
            this.recalculateMarketCapWeight();
          }

          if (openCartAfterReceived) {
            this.openCartDialog()
          }
        }
      })
    }
  }

  checkETF() {
    this.hasETF = this.service.hasETF(this.selected);
    if (!this.hasETF) {
      this.portfolio.etfPercent = 0;
    }
  }

  onHistoryBackClick() {
    this.location.back();
  }
}
