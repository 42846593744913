import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-datawrapper-help-dialog',
  templateUrl: './datawrapper-help-dialog.component.html',
  styleUrls: ['./datawrapper-help-dialog.component.scss']
})
export class DatawrapperHelpDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DatawrapperHelpDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
