<!-- This button triggers the overlay and is it's origin -->
<button  mat-icon-button color="primary" class="popover-info-btn" (click)="company.isOpen = !company.isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <mat-icon>info</mat-icon>
</button>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="company.isOpen"
        [cdkConnectedOverlayOffsetX]="50"
>
    <div class="popover-card">
        <button class="popover-close-btn" mat-icon-button (click)="company.isOpen = false"><mat-icon>close</mat-icon></button>
        <p class="text-semi-bold  mb-2" [innerHTML]="title|highlight:highlight"></p>
        <p *ngIf="content" class="max-500" [innerHTML]="content|highlight:highlight"></p>
        <p *ngIf="isMAR" class="max-500">
            A MAR ratio is a measurement of <a href="https://www.investopedia.com/terms/r/riskadjustedreturn.asp" target="_blank">returns adjusted for risk</a> that can be used to compare the performance of
            commodity trading advisors, hedge funds, and trading strategies. The MAR ratio is calculated by dividing
            the <a href="https://www.investopedia.com/terms/c/cagr.asp" target="_blank">compound annual growth rate</a> (CAGR) of a fund or strategy since its inception by its most significant
            <a href="https://www.investopedia.com/terms/d/drawdown.asp" target="_blank">drawdown</a>. The higher the ratio, the better the risk-adjusted returns.
        </p>
        <p *ngIf="isCAGR" class="max-500">
            The compound annual growth rate (CAGR) is the <a href="https://www.investopedia.com/terms/r/rateofreturn.asp">rate of return</a> (RoR) that would be required for an
            investment to grow from its beginning balance to its ending balance, assuming the profits were reinvested
            at the end of each period of the investment’s life span.
        </p>

        <div class="max-500" *ngIf="portfolioCreateContent && portfolioCreateContent == 1">
            <ul>
                <li>Search and select securities representing the assets you want to use for portfolio construction.</li>
                <li>Refine your desired selection by using the intuitive and comprehensive asset data filter menu.</li>
                <li>Range-bound filtering per asset data point allows you to create a universe of assets representing your investment idea.</li>
                <li>Your final selection takes you seamlessly to the portfolio construction module where you generate your portfolio.</li>
            </ul>
        </div>
        <div class="max-500" *ngIf="portfolioCreateContent && portfolioCreateContent == 2">
            <ul>
                <li>Define the number of securities you want to include in your exposure.</li>
                <li>Create an exposure based on a specific theme, sector, attribute or mix.</li>
                <li>Use the filters to define the intrinsic characteristics of your assets for the exposure.</li>
                <li>Apply your logic as to how you want to sort the selected filters and the system will create the exposure which you can simulate in the portfolio construction module.</li>
            </ul>
        </div>
        <div class="max-500" *ngIf="portfolioCreateContent && portfolioCreateContent == 3">
            <ul>
                <li>Based on an Excel file which I've exported from my trading or asset management platform</li>
            </ul>
        </div>
    </div>
</ng-template>
