<div class="card ">
    <div class="card-header">
      Performance

      <app-simple-select class="ml-2" *ngIf="portfolioHasETF" [list]="typeList" label="Type" [isMultiple]="true" [model]="filter.type" (modelChanged)="filter.type = $event; onFilterChange()"></app-simple-select>
    </div>
    <div class="card-body">
        <table mat-table [dataSource]="dataSource" matSort class="w-100 mat-small-table table-hover">
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name</th>
                <td mat-cell *matCellDef="let element">
                    <span class="underline-on-hover ml-2 text-truncate" (click)="onCompanyInfoClick(element)">{{element.company}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="isin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ISIN </th>
                <td mat-cell *matCellDef="let element">{{element.isin}}</td>
            </ng-container>

            <ng-container matColumnDef="sector">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sector </th>
                <td mat-cell *matCellDef="let element" class="text-truncate">{{element.sector}}</td>
            </ng-container>

            <ng-container matColumnDef="industry">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Industry </th>
                <td mat-cell *matCellDef="let element" class="text-truncate">{{element.industry}} </td>
            </ng-container>

            <ng-container matColumnDef="Weight">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Weight </th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4 number-col">{{element.Weight*100|number:'1.0-2'}}%</td>
            </ng-container>
            <ng-container matColumnDef="CAGR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Return </th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4 number-col">{{element.CAGR*100|number:'1.0-2'}}%</td>
            </ng-container>

            <ng-container matColumnDef="Volatility">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Volatility </th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4 number-col">{{element.Volatility*100|number:'1.0-2'}}%</td>
            </ng-container>

            <ng-container matColumnDef="Max Drawdown">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Max Drawdown</th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4 number-col">{{element['Max Drawdown']*100|number:'1.0-2'}}%</td>
            </ng-container>
            <ng-container matColumnDef="Sharpe">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Sharpe </th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4 number-col">{{element.Sharpe|number:'1.0-2'}}</td>
            </ng-container>
            <ng-container matColumnDef="TrackingError">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Tracking Error </th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4">{{element.TrackingError*100|number:'1.0-2'}}%</td>
            </ng-container>
            <ng-container matColumnDef="Beta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Beta </th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4">{{element.Beta|number:'1.0-2'}}</td>
            </ng-container>
            <ng-container matColumnDef="Alpha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Alpha </th>
                <td mat-cell *matCellDef="let element" class="text-right pr-4">{{element.Alpha*100|number:'1.0-2'}}%</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [hidden]="!needFooter" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    </div>
</div>
