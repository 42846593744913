import {Injectable} from '@angular/core';
import * as moment from "moment";

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMM DD',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

@Injectable()
export class UtilService {

  private smallScreenWidthLimit = 992;

  constructor() {
  }

  /**
   * Find object in array, return modified array if item exits.
   */
  public findAndReplaceIDInArray(id, array, deleteInstead = false) {
    if (!id || !array || array.length === 0) {
      return false;
    }
    const index = array.findIndex(el => id === el);

    if (index > -1) {
      if (deleteInstead === true) {
        array.splice(index, 1);
      } else {
        Object.assign(array[index], id);
      }
    }

    return array;
  }

  /**
   * Find object in array, return modified array if item exits.
   */
  public findAndReplaceItemInArray(item, array, deleteInstead = false) {
    if (!item || !array || array.length === 0) {
      return false;
    }
    const index = array.findIndex(el => el.id === item.id);

    if (index > -1) {
      if (deleteInstead === true) {
        array.splice(index, 1);
      } else {
        Object.assign(array[index], item);
      }
    }

    return array;
  }

  /**
   * Find item in array, return modified array if item exits.
   */
  public findAndReplaceSimpleItemInArray(item, array, deleteInstead = false) {
    if (!item || !array || array.length === 0) {
      return false;
    }
    const index = array.findIndex(el => el === item);

    if (index > -1) {
      if (deleteInstead === true) {
        array.splice(index, 1);
      } else {
        Object.assign(array[index], item);
      }
    }

    return array;
  }

  /**
   * Is item exists in array
   */
  public isItemInArray(item, array) {
    return (array.findIndex(el => el.id === item.id) > -1);
  }

  /**
   * Check if the window is smaller then wihdowLimit
   */
  public isSmallScreen() {
    return window.innerWidth <= this.smallScreenWidthLimit;
  }

  /**
   * Returns the pressed keycode (borwser support)
   */
  public getPressedKeyCode(event) {
    let code;

    if (event.key !== undefined) {
      code = event.key;
    } else if (event.keyIdentifier !== undefined) {
      code = event.keyIdentifier;
    } else if (event.keyCode !== undefined) {
      code = event.keyCode;
    }

    return code;
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  dynamicSortMultiple(props: string[]) {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    return (obj1, obj2) => {
      let i = 0;
      let result = 0;
      const numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while (result === 0 && i < numberOfProperties) {
        result = this.dynamicSort(props[i])(obj1, obj2);
        i++;
      }
      return result;
    }
  }

  /**
   * Material datatable sorting
   */
  public tableSorting(item, property: string) {
    const convertToLowercaseString = (input) => (typeof input === 'string' || input instanceof String) ? input.toLocaleLowerCase() : input;

    if (property.includes('.')) {
      const _prop = property.split('.');
      if (!item[_prop[0]]) {
        return false;
      } else {
        return _prop.reduce((o, i) => (o[i] ? convertToLowercaseString(o[i]) : false), item);
      }
    } else {
      return convertToLowercaseString(item[property]);
    }
  }

  public extractQueryParams(params): any {
    const paramList = {};
    Object.entries(params).map(([key, val]) => {

      const k = `${key}`;
      if (k.includes('[')) {
        const parts = k.split('[');
        const objKey = parts[0];
        const objProp = parts[1].replace(']', '');
        if (!paramList[objKey]) {
          paramList[objKey] = {};
        }

        if (objProp === 'company_ids') {
          const values = `${val}`.split(',');
          paramList[objKey][objProp] = [...values.map(v => +v)];
        } else {
          paramList[objKey][objProp] = val;
        }
      } else {
        paramList[key] = val;
      }
    });
    return paramList;
  }

  public makeQueryParams(filters) {
    const params = {};
    Object.entries(filters).map(([key, val]) => {
      if (typeof val === 'object') {
        const entries = val ? Object.entries(val) : [];
        if (entries.length) {
          entries.map(([k, v]) => {
            params[`${key}[${k}]`] = `${v}`;
          });
        } else {
          params[key] = '';
        }
      } else {
        params[key] = val;
      }
    });
    return params;
  }


  public stringifyParams(params, asURL = false, extraParam = false) {
    const paramQuery = [];
    Object.entries(params).map(([key, val]) => {

      if (key === 'search_term' && val) {
        val = encodeURIComponent(`${val}`);
      }

      if (typeof val === 'object') {
        const entries = val ? Object.entries(val) : [];
        if (entries.length) {
          entries.map(([k, v]) => {
            if (k == 'dynamic') {

              let dynamicList: any = {}
              // convert dynamic list, split at $ char
              Object.entries(v).map(([dkey, dvalue]:[string,any]) => {
                if (dkey.includes('$')) {
                  let keyParts = dkey.split('$');
                  if (!dynamicList[keyParts[0]]) {
                    dynamicList[keyParts[0]] = {}
                  }
                  dynamicList[keyParts[0]][keyParts[1]] = dvalue;
                }
              });

              // build the dynamic params
              Object.entries(dynamicList).map(([dkey, dvalues]:[string,any]) => {
                if (dvalues.lt && dvalues.gt) {
                  paramQuery.push(`filters[dynamic][${dkey}]=between=${encodeURIComponent(dvalues.gt)}=${encodeURIComponent(dvalues.lt)}`);
                } else if (dvalues.in) {
                  paramQuery.push(`filters[dynamic][${dkey}]=in=${encodeURIComponent(dvalues.in.join('|'))}`);
                } else if (dvalues.lt || dvalues.lt === 0) {
                  paramQuery.push(`filters[dynamic][${dkey}]=lt=${encodeURIComponent(dvalues.lt)}`);
                } else if (dvalues.gt || dvalues.gt === 0) {
                  paramQuery.push(`filters[dynamic][${dkey}]=gt=${encodeURIComponent(dvalues.gt)}`);
                } else if (dvalues.isnull) {
                  paramQuery.push(`filters[dynamic][${dkey}]=isnull`);
                }
              })
            } else if (v) {
              paramQuery.push(`${key}[${k}]=${v}`);
            }
          });
        }
      } else {
        if (val) {
          if (extraParam) {
            paramQuery.push(`${key}=${val}`);
          } else {
            if (key != 'page') {
              paramQuery.push(`filters[${key}]=${val}`);
            }
          }
        }
      }
    });
    return asURL ? paramQuery.join('&') : paramQuery;
  }

  public extractFilters(paramFilters) {
    const filters = [];

    Object.entries(paramFilters.filters).map(([key, val]:[string, any]) => {
      if (Array.isArray(val) && val.length === 1) {
        if (val[0] === 0) {
          val = [];
        }
      }

      if (
        (Array.isArray(val) && val.length) || (!Array.isArray(val) && val)
      ) {
        filters[key] = val;
      }
    });

    return filters;
  }

  public initSeries(index, prop, isAreaType = false, isPercent = false) {
    if (index && index[prop]) {
      const list = this.copyArrayOfObjects(index[prop]);
      return this.initSeriesFromSimpleList(index, list, isAreaType, isPercent);
    }
    return null;
  }

  public copyArrayOfObjects(list) {
    return list.map(x => Object.assign({}, x));
  }

  public initSeriesFromSimpleList(index, list, isAreaType = false, isPercent = false) {
      let result = null;
      if (index && list) {
        result = {
          type: isAreaType ? 'area' : 'line',
          name: index.name,
          data: list.map((data: any) => {
            return [new Date(data.date), isPercent ? data.value * 100 : data.value];
          })
        };
      }
      return result;
    }

    public arrayToString(list, hasTimestamp = false, titleX = 'Date', titleY = 'Value') {
      let listString = `${titleX};${titleY}\n`;
      list.map(d => {
        const date = hasTimestamp ? moment(d[0]).format("YYYY-MM-DD") : d[0];
        listString += `${date};${d[1]}\n`;
      });
      return listString;
    }

  public seriesToCSVInput(list, hasTimestamp = false, titleX = 'Date', titleY = 'Value') {
    let header = 'Categories;';
    let data = [];
    let hasBenchmark = false;
    if (list[0]) {
      const portfolio = list[0]
      header += portfolio.name;
      portfolio.data.map(d => {
        let date = hasTimestamp ? moment(d[0]).format("YYYY-MM-DD") : d[0];
        data[date] = {portfolio: d[1]};
      })

    }
    if (list[1]) {
      hasBenchmark = true;
      const benchmark = list[1];
      header += ';' +benchmark.name;

      benchmark.data.map(d => {
        let date = hasTimestamp ? moment(d[0]).format("YYYY-MM-DD") : d[0];
        if (data[date]) {
          data[date].benchmark = d[1];
        } else {
          data[date] = {
            benchmark: d[1],
            portfolio: ''
          }
        }
      })
    }

    let listString = `${header}\n`;
    Object.entries(data).map(([key, d]:[any,any]) => {
      if (hasBenchmark) {
        if (d.portfolio && d.benchmark) {
          listString += `${key};${d.portfolio};${d.benchmark}\n`;
        }
      } else {
        listString += `${key};${d.portfolio}\n`;
      }
    });
    return listString;
  }

    public keepUniqueObjectOfArray(array) {
      const result = [];
      const map = new Map();
      for (const item of array) {
        if(!map.has(item.id)){
          map.set(item.id, true);    // set any value to Map
          result.push({
            id: item.id,
            name: item.name
          });
        }
      }
      result.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
      return result;
    }

  public extractPlotData(list, start?) {
    const result = [];
    Object.entries(list).map(([name, data]:[string,any]) => {
      // Convert data to new start date if exists
      if (start) {
        data = this.normalizeDataToStartDate(start, data);
      }
      result.push({
        name,
        data,
        type: 'line'
      });
    });
    return result;
  }

  public normalizeDataToStartDate(start, data) {
    const ref = this.getSeriesRefElement(start, data);
    if (ref) {
      data.map(d => {
        d[1] = +(d[1] / ref * 100).toFixed(2);
        return d;
      });
    }
    return data;
  }

  /* Find the portfolio value at the closest date benchmark date */
  getClosestDate(benchdata, portfolioData){
    const firstBenchDate = benchdata[0][0]; // The first benchmark date
    for (let i = 0; i < portfolioData.length; i++){
      /* If there is a common date then return the portfolio value at that date */
      if (portfolioData[i][0] >= firstBenchDate){
        // Return the Value at the first common date
        return portfolioData[i][1];
      }
    }
  }

  public getSeriesRefElement(timestamp, list) {
      let time = null;
      for (const item of list) {
        if (item[1] > 0) { // only consider dates with valid values
          time = item[0];
          if (time >= timestamp) {
            return item[1];
          }
        }
      }
      return null;
    }

    public calculateDrawdown(returns) {
      const drawdown = [];
      //Input
      // x: The cumulative return series array (un-scaled)
      // dates: The date array

      // Inital varialbes
      let maxCumulativeReturn = 1;

      // Iterate over all datapoints
      for (const item of returns) {
        // Keep the maximum cumulative return
        if (item[1] > maxCumulativeReturn)  {
          maxCumulativeReturn = item[1];
        }
        // Compute the drawdown percentage
        drawdown.push([
          item[0],
          +(((item[1] / maxCumulativeReturn) - 1) * 100).toFixed(2)
        ]);
      }
      return drawdown;
    }

  shadeColor(color: string, percent: number = 1) {
    if (!color) {
      color = '#666666';
    }
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.round((R * (100 + percent)) / 100);
    G = Math.round((G * (100 + percent)) / 100);
    B = Math.round((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }

  sortingNiceName(key) {
    let name = key;
    switch (key) {
      case 'country_hq_name':
        name = 'Country';
        break;
      case 'p_first_date':
        name = 'Start date';
        break;
      case 'market_value_usd':
        name = 'Market Cap (M$)';
        break;
      case 'region_list_name':
        name = 'Region';
        break;
      default:
        name = key;
    }
    return name.replaceAll('_', ' ').replace('$gt', ' >').replace('$lt', ' <');
  }
}
