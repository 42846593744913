import {Component, Inject, Input, OnInit} from '@angular/core';
import {StorageService} from '../../../../shared/services/storage.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
export interface DialogData {
  filtersList: any[];
  name: string;
  columns: any;
  index: number;
  assetType: string;
}

@Component({
  selector: 'app-asset-table-column-preset',
  templateUrl: './asset-table-column-preset.component.html',
  styleUrls: ['./asset-table-column-preset.component.scss']
})
export class AssetTableColumnPresetComponent implements OnInit {
  @Input() columnPreset: any = {};

  accordionData: any[] = [];
  searchPanel: string = null;
  name: string = null;
  saved: any = [];
  index: number = null;
  assetType: string = null;

  constructor(
    private dialogRef: MatDialogRef<AssetTableColumnPresetComponent>,
    private storage: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.accordionData.push(...data.filtersList);
    this.assetType = data.assetType;

    if (data.name) {
      this.name = data.name;
      this.columnPreset = data.columns;
      this.index = data.index;
    }
  }

  ngOnInit(): void {
    this.initPresets();
  }

  initPresets(): void{
    const saved = this.storage.get(`${this.storage.columnPresetStorageKey}${this.assetType}`);
    if (saved) {
      this.saved = saved;
    }
  }

  onSaveClick(): void {
    const key = `${this.storage.columnPresetStorageKey}${this.assetType}`;
    const presetVal = {name: this.name, columns: this.columnPreset};
    if (this.index !== null) {
      this.saved[this.index] = presetVal;
    } else {
      this.saved.push(presetVal);
    }
    this.storage.set(key, this.saved);
    this.dialogRef.close();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
