import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {PortfolioService} from "../portfolio.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {StorageService} from "../../../shared/services/storage.service";

@Component({
  selector: 'app-asset-table',
  templateUrl: './asset-table.component.html',
  styleUrls: ['./asset-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetTableComponent implements AfterViewInit, OnChanges {

  @Input() list: any[] = [];
  @Input() selected: any[] = [];
  @Input() filterLists: any = {};
  @Input() columnVisibility: any = {};
  @Input() currentFilters: any = {
    filters: {},
    dynamic: {}
  };
  @Input() sorting: any = {};
  @Input() hideSorting = false;
  @Input() hideSelect = false;
  @Input() quantList: any[] = [];
  @Input() filterProp = '';
  @Input() widerFirstCol: boolean = false;

  @Output() itemSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() fetchCompanies: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnChanged: EventEmitter<any> = new EventEmitter<any>();

  // TODO: handle !f.default
  columnsEnum = {
    fs_ticker: {
      title: 'Ticker',
      model: 'filters.ticker',
      value: 'ticker',
      colValue: 'ticker'
    },
    isin: {
      title: 'ISIN',
    },
    marketCap: {
      title: 'MCap',
      unit: 'M$',
      textAlign: 'right',
      model: 'dynamic.market_value_usd',
      value: 'market_value_usd',
      headerFilter: true,
      sorting: true,
      colType: 'number',
      colValue: 'market_value_usd'
    },
    startDate: {
      title: 'Hist. Date',
      textRight: true,
      model: 'dynamic.p_first_date',
      value: 'p_first_date',
      menuFilter: true,
      sorting: true,
      colValue: 'p_first_date'
    },
    assetClass: {
      title: 'Asset Class',
      colValue: 'security_type_equity',
    },
    market: {
      title: 'Market',
      isMultiple: true,
      headerSelect: true,
      list: 'markets',
      model: 'filters.market_ids',
      value: 'market_ids'
    },
    country: {
      title: 'Country',
      isMultiple: true,
      headerSelect: true,
      list: 'countries',
      model: 'filters.country_ids',
      value: 'country_ids',
      colDeep: 'country.name'
    },
    exchange_code_fs: {
      title: 'Exchange',
      isMultiple: true,
      headerSelect: true,
      list: 'exchange_code_fs',
      model: 'filters.exchange_code_fs',
      value: 'exchange_code_fs',
      colValue: 'exchange_code_fs'
    },
    currency: {
      title: 'Currency',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      list: 'currencies',
      model: 'filters.currency_ids',
      value: 'currency_ids',
      colValue: 'currency_code'
    },
    sector: {
      title: 'Sector',
      isMultiple: true,
      headerSelect: true,
      double: true,
      list: 'sectors',
      model: 'filters.sector_ids',
      value: 'sector_ids',
      colDeep: 'sector.name'
    },
    region: {
      title: 'Region',
      isMultiple: true,
      headerSelect: true,
      double: true,
      list: 'regions',
      model: 'filters.region_ids',
      value: 'region_ids',
      colDeep: 'region.name'
    },
    industry: {
      title: 'Industry',
      isMultiple: true,
      headerSelect: true,
      double: true,
      list: 'industries',
      model: 'filters.industry_ids',
      value: 'industry_ids',
      colDeep: 'industry.name'
    },
    BaseCurrency: {
      title: 'Currency',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      list: 'BaseCurrency',
      model: 'filters.currencies',
      value: 'BaseCurrency',
      sorting: true,
      colValue: 'name'
    },
    DomicileName: {
      title: 'Domicile',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      list: 'DomicileName',
      model: 'filters.DomicileName',
      value: 'DomicileName',
      colValue: 'name'
    },
    BrandingCompanyName: {
      title: 'Brand Name',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      list: 'BrandingCompanyName',
      model: 'filters.BrandingCompanyName',
      value: 'BrandingCompanyName',
      colValue: 'name'
    },
    LocalCategoryName: {
      title: 'Local Category',
      isMultiple: true,
      headerSelect: true,
      sortable: true,
      textRight: false,
      double: true,
      list: 'LocalCategoryName',
      model: 'filters.LocalCategoryName',
      value: 'LocalCategoryName',
      colValue: 'name'
    },
    EquityStyleBox: {
      title: 'StyleBox',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      list: 'EquityStyleBox',
      model: 'filters.EquityStyleBox',
      value: 'EquityStyleBox',
      colValue: 'name'
    },
    MorningStarRating: {
      title: 'Rating',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      list: 'MorningStarRating',
      model: 'filters.MorningStarRating',
      value: 'MorningStarRating',
     // colType: 'number',
      colValue: 'name'
    },
    FeeLevel: {
      title: 'FeeLevel',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      list: 'FeeLevel',
      model: 'filters.FeeLevel',
      value: 'FeeLevel',
      colValue: 'name'
    },
    GlobalCategoryName: {
      title: 'Global Category',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      double: true,
      list: 'GlobalCategoryName',
      model: 'filters.GlobalCategoryName',
      value: 'GlobalCategoryName',
      colValue: 'name'
    },
    LegalStructure: {
      title: 'Legal Structure',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      double: true,
      list: 'LegalStructure',
      model: 'filters.LegalStructure',
      value: 'LegalStructure',
      colValue: 'name'
    },
    UCITS: {
      title: 'UCITS',
      isMultiple: true,
      headerSelect: true,
      textRight: false,
      list: 'UCITS',
      model: 'filters.UCITS',
      value: 'UCITS',
      colValue: 'name'
    },
    FundOfFunds: {
      title: 'FundOfFunds',
      isMultiple: true,
      headerSelect: true,
      textRight: true,
      list: 'FundOfFunds',
      model: 'filters.FundOfFunds',
      value: 'FundOfFunds',
      colValue: 'name'
    },
    FundInceptionDate: {
      title: 'Inception date',
      textRight: true,
      model: 'dynamic.FundInceptionDate',
      value: 'FundInceptionDate',
      menuFilter: true,
      sorting: true,
      colValue: 'FundInceptionDate'
    }
  };
  rightAlignedProps = ['country_of_risk', 'currency', 'marketCap', 'report_date', 'startDate', 'FundOfFunds', 'FundInceptionDate', 'BaseCurrency'];
  assetType: string;

  headersFixed = false;
  interval = null;
  headerWidth: any = 0;
  timeoutHandler;
  quantListLength = 0;

  constructor(
    private cdRef: ChangeDetectorRef,
    private service: PortfolioService,
    private storage: StorageService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes.list || (changes.quantList)) {
    //   if(changes.quantList) {
    //     this.quantListLength = changes.quantList.currentValue.length;
    //   }
    //   clearInterval(this.interval);
    //   // this.interval = setInterval(() => {
    //   //   const firstTdRow: any = (document.getElementsByClassName('headers')[0].parentNode.childNodes[1] as any).children[0];
    //   //   if(firstTdRow) {
    //   //     clearInterval(this.interval);
    //   //     this.setScroll();
    //   //   }
    //   // }, 500);
    //   if(this.quantList.length > (window.innerWidth / 450)) {
    //     this.headerWidth = Math.max(this.quantList.length * 220, window.innerWidth);
    //   } else {
    //     this.headerWidth = 'calc(100vw - 350px)';
    //   }
    //   try {
    //     this.setHeaderSizes();
    //   } catch(e) {}
    //   this.cdRef.detectChanges();
    // }
  }

  ngOnInit(): void {
    if (this.filterProp === 'quant') {
      this.assetType = 'stock';
    } else if(this.filterProp === 'etf_reference_table'){

      this.assetType = 'etf';
    }else {
      this.assetType = 'fund';
    }
    
  }

  // mousedown(event) {
  //   setTimeout(() => {
  //     this.setScroll();
  //   }, 200)
  // }

  // mouseup(event) {
  //   this.timeoutHandler = null
  //   setTimeout(() => {
  //     this.setScroll();
  //   }, 200)
  // }


  setScroll() {
    // document.onscroll = () => {
    //   if(window.scrollY > 260) {
    //     (document.getElementsByClassName('headers')[0] as any).style.top = (window.scrollY - 230) + 'px';
    //   } else {
    //     (document.getElementsByClassName('headers')[0] as any).style.top = '0px';
    //   }
    // };
    // if(this.headerWidth < 100) {
    //   this.headerWidth = (document.getElementsByClassName('headers')[0] as any).offsetWidth;
    // }
    // this.setHeaderSizes();
    // const tcontainer: any = document.getElementsByClassName('outer-table-container')[0];
    // const scrolable: any = document.getElementsByClassName('flexible');
    // tcontainer.onscroll = () => {
    //   for(let counter = 0; counter < scrolable.length; counter++) {
    //     if(tcontainer.scrollLeft > 120) {
    //       scrolable[counter].style.marginLeft = (tcontainer.scrollLeft - 35) + 'px';
    //     } else {
    //       scrolable[counter].style.marginLeft = '0px';
    //     }
    //   }
    // };
  }

  ngAfterViewInit(): void {}

  setHeaderSizes() {
    const headerChildren: any = document.getElementsByClassName('headers')[0].children;
    const firstTdRow: any = document.getElementsByClassName('headers')[0].parentNode.childNodes[1].childNodes[0];
    const bodyChildren = firstTdRow.children;
    if(!bodyChildren) {
      return;
    }
    for(let i = 0; i < bodyChildren.length; i++) {
      headerChildren[i].style.width = (bodyChildren[i].offsetWidth > 220 ? 220 : bodyChildren[i].offsetWidth)  + 'px';
    }
    (document.getElementsByClassName('headers')[0] as any).style.width = this.quantList.length > (window.innerWidth / 450) ? (this.headerWidth + 'px') : 'calc(100vw - 350px)';
    (document.getElementsByClassName('body')[0] as any).style.width = this.quantList.length > (window.innerWidth / 450) ? (this.headerWidth + 'px') : 'calc(100vw - 350px)';
  }

  onCompanyInfoClick(company) {
    if (company.isStock()) {
      this.service.openCompanyInfoDialog(company.fs_ticker, company)
    } else if (company.isFund()) {
      this.service.openFundInfoDialog(company)
    } else {
      this.service.openETFInfoDialog(company);
    }
  }

  onToggle(event) {
    // console.log('hit me : ', event);
  }

  onSortClick(label) {
    this.sortClicked.emit(label)
  }

  onFetchCompanies() {
    this.fetchCompanies.emit();
  }

  onDynamicSelectFilterChange(id, value) {
    if (value && value.length) {
      this.currentFilters.dynamic[id + '$in'] = value;
    } else {
      delete this.currentFilters.dynamic[id + '$in'];
    }
  }

  onSelectFilterChange(id, value) {
    if (value && value.length) {
      this.currentFilters.filters[id] = value;
    } else {
      delete this.currentFilters.filters[id];
    }
    this.onFetchCompanies();
  }

  onNumberFilterChange(id, type, value, fetch = false) {
    if (value) {
      this.currentFilters.dynamic[id + type] = value;
    } else {
      delete this.currentFilters.dynamic[id + type];
    }
    if (fetch) {
      this.onFetchCompanies();
    }
  }

  onSelect(value, item) {
    this.itemSelect.emit({
      company: item,
      isSelected: value.checked
    })
  }

  closeOtherCompanies(company) {
    this.list = this.list.map((c: any) => {
      if (c.id !== company.id) {
        c.isOpen = false;
      }
      return c;
    });
  }

  onNullFilter(id: string, $event: any) {
    this.currentFilters.dynamic[id + '$isnull'] = true;
    this.onFetchCompanies();
  }

  onDynamicFilterChange(prop, $event) {
    if ($event && !$event.length) {
      delete this.currentFilters.dynamic[prop + '$in'];
    } else {
      this.currentFilters.dynamic[prop + '$in'] = $event;
    }
  }

  isRightAligned(item, property) {
    if (item && item[this.filterProp]) {
      const value = item[this.filterProp][property];
      if (typeof value === 'number') {
        return true;
      }
    }
    return this.rightAlignedProps.includes(property);
  }

  cacheVisibility(obj) {
    const key = `${this.storage.visibilityStorageKey}${this.assetType}`;
    this.storage.set(key, obj);
  }

  drop(event: CdkDragDrop<any>) {
    const colVisibility = [];
    moveItemInArray(this.quantList, event.previousIndex, event.currentIndex);
    for (const quantListElement of this.quantList) {
      colVisibility[quantListElement.id] = true;
    }
    this.cacheVisibility(Object.assign({}, colVisibility));
    // this.columnChanged.emit(this.quantList)
    this.setScroll();
  }

  flatten(obj) {
    var toReturn = {};
    // debugger;
    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;

        if ((typeof obj[i]) == 'object' && obj[i] !== null) {
            var flatObject = this.flatten(obj[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = obj[i];
        }
    }
    return toReturn;
  }

  get tableWidth() {
    if(this.quantList.length > (window.innerWidth / 450) && (this.quantList.length * 220 > window.innerWidth)) {
      return (220 * this.quantList.length) + 'px';
    }
    return 'calc(100vw - 350px)';
  }

  get self() {
    return this;
  }

  get getCurrentFilters() {
    return this.flatten(this.currentFilters);
  }

  get getFilterLists() {
    return this.filterLists;
  }



    
}

