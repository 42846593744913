<button *ngIf="data" mat-icon-button class="dialog-close-btn" (click)="onNoClick()"><mat-icon>close</mat-icon></button>
<!-- <h1 mat-dialog-title>
    Assets chosen

    <div style="position:absolute;top: 20px;right: 260px; " *ngIf="hasETF">
        <small class="text-muted">% of ETFs in portfolio:</small>
        <mat-slider
                class="w-100"
                color="primary"
                name="etfPercent"
                [max]="100"
                [min]="0"
                [step]="1"
                [thumbLabel]="true"
                [value]="portfolio.etfPercent"
                [(ngModel)]="portfolio.etfPercent"
                (ngModelChange)="onWeightTypeChange()"
        >
        </mat-slider>
    </div>

    <mat-form-field *ngIf="!isStep2" appearance="outline" class="pt-2" style="float:right;">
        <mat-label >Weighting </mat-label>
        <mat-select
                name="weighting"
                placeholder="Weighting"
                panelClass="cim-select"
                [(value)]="portfolio.weighting_type"
                (valueChange)="onWeightTypeChange()"
        >
            <mat-option [value]="'manual'">Manual</mat-option>
            <mat-option [value]="'equal'">Equal Weighted</mat-option>
            <mat-option [value]="'market_cap'">Market Cap Weighted</mat-option>
        </mat-select>
    </mat-form-field>
</h1> -->
<p *ngIf="weightError" class="text-center p-2 text-danger">Weight is over 100%!</p>
<div mat-dialog-content style="margin-top: 3em">
    <table class="table m-0">
        <thead>
        <tr>
            <th class="bold-header">Name</th>
            <th >Weight</th>
            <th class="bold-header">ISIN</th>
            <th class="bold-header">MCap(M$)</th>
            <!--             <td class="bold-header mw-240 text-right">Market Cap (M$) at Earliest price data point or AUM at Earliest price data point</td>
             -->            <th class="bold-header">Earliest price data point</th>
            <th class="bold-header">Asset Class</th>
            <th class="bold-header">Currency</th>
            <th class="bold-header">Sector</th>
            <!--             <td >Industry</td>
             -->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of list;index as index" [class.selected-row]="item.markedForDelete" [class.etf-border-top]="list[index-1] && list[index-1].isStock() && item.isFund()">
            <td class=" font-weight-bold">
                <mat-checkbox [checked]="item.markedForDelete" color="primary" (change)="onSelect($event, item)">{{item.company_name}} {{item.fund_reference_table.name}}</mat-checkbox>
            </td>
            <td>
                <mat-form-field style="max-width: 100px; margin-top:0.75em;" appearance="outline">
                    <input matInput [(ngModel)]="item.weight" type="number" min="0" max="100" [disabled]="portfolio.weighting_type !== 'manual'" (ngModelChange)="onWeightChange(item)">
                    <span matSuffix>%</span>
                </mat-form-field>
            </td>
            <td>{{item.isin}}</td>
            <td class="text-right">{{item.market_value_usd|number:'1.0-0'}} {{item.fund_reference_table.AverageMarketCapital| number:'1.0-0'}}</td>
            <!--             <td class="text-right">{{item.market_value_start ? (item.market_value_start|number:'1.0-0') : '-'}}</td>
             -->            <td>{{item.p_first_date}} {{item.fund_reference_table.FundInceptionDate}}</td>
            <td>{{item.security_type_equity}}</td>
            <td>{{item.currency ? item.currency.code : item.currency_id}} {{item.fund_reference_table.BaseCurrency}}</td>
            <td>{{item.sector ? item.sector.name : "FUND"}} </td>
            <!--             <td>{{item.industry ? item.industry.name : item.industry_id}}</td>
             -->
        </tr>
        </tbody>
    </table>
</div>
<div mat-dialog-actions class="d-block" *ngIf="data">
    <div>
        <button mat-button color="primary" (click)="onRemoveClick()" class="border-primary rounded-pill">Remove</button>
        <button mat-flat-button color="primary" (click)="onSaveClick()" class="border-primary rounded-pill float-right"><span class="navigation-button">Save changes</span> </button>
        <button mat-stroked-button color="primary" (click)="onNoClick()" class="border-primary rounded-pill float-right">Discard changes</button>
    </div>
</div>
