import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PortfolioService} from "../../pages/portfolio/portfolio.service";
import {PortfolioModel} from "../../shared/models/portfolio.model";

@Component({
  selector: 'app-benchmark-selector',
  templateUrl: './benchmark-selector.component.html',
  styleUrls: ['./benchmark-selector.component.scss']
})
export class BenchmarkSelectorComponent implements OnInit {
  @Input() showLabel = false;
  @Input() benchmarkPlaceholder = 'Select benchmark';
  @Output() benchmarkSelect: EventEmitter<any> = new EventEmitter<any>();

  benchmarks: any[] = [];
  portfolios: any[] = [];

  portfolio = new PortfolioModel({});
  @Input() selectedBenchmark: any;
  searchText = '';
  benchmarksHidden = false;
  portfoliosHidden = false;
  constructor(
    private service: PortfolioService
  ) { }

  ngOnInit(): void {
    this.fetchBenchmarks();
    this.getPortfolioList();
//   this.checkSelectedBenchmark();

  }

  async fetchBenchmarks() {
    
    var resp = await this.service.getBenchmarks();
    resp.subscribe((res: any) => {
      if (res) {
        this.benchmarks = res;
      }
    });
    //this.benchmarks = resp;
  }

  checkSelectedBenchmark() {
    this.service.benchmark$.subscribe((res: any) => {
      if (res && res.id) {
        this.selectedBenchmark = res.name;
      }
    });
  }

  getPortfolioList() {
    if (this.service.portfolioLists) {
      return this.skipSelectedPortfolioFromList()
    }
    this.service.getItems().subscribe((resp: any) => {
      if (resp.error) {
        this.service.notificationService.open(resp.error)
      }
      resp = this.service.extractPortfolioData(resp);
      let lists = resp;

      // merge private and shared by me lists
      lists.private = [
        ...lists.private,
        ...lists.shared_by_me
      ]
      this.service.portfolioLists = lists;
      return this.skipSelectedPortfolioFromList();
    })
  }

  skipSelectedPortfolioFromList() {
    let portfolios = [];
    Object.entries(this.service.portfolioLists).map(([key, values]:[string, any]) => {
      values.map((p: any) => {
        if (p.id != this.portfolio.id) {
          portfolios.push(p);
        }
      })
    })
    portfolios.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));

    this.portfolios = portfolios;
  }
  onBenchmarkSelect(selected) {
    this.selectedBenchmark = selected.name
    this.benchmarkSelect.emit(selected);
  }

  onSearchChange() {
    this.benchmarks = this.benchmarks.map(p => {
      p.hidden = !(p.items.filter(f => !f.hidden).length);
      return p;
    });
    this.benchmarksHidden = !(this.benchmarks.filter(p => !p.hidden).length);
    this.portfoliosHidden = !(this.portfolios.filter(p => !p.hidden).length);
  }

  resetSearch() {
    this.searchText = '';
    this.benchmarksHidden = false;
    this.portfoliosHidden = false;
    this.benchmarks = this.benchmarks.map(p => {
      p.hidden = false;
      p.items = p.items.map(b => {
        b.hidden = false;
        return b;
      });
      return p;
    });
    this.portfolios = this.portfolios.map(p => {
      p.hidden = false;
      return p;
    });
  }

  removeBenchmark() {
    this.selectedBenchmark = '';
    this.benchmarkSelect.emit(this.selectedBenchmark);
  }
}
