import {Injectable, Injector} from '@angular/core';
import {ApiEndpoints} from '../../ApiEndpoints';
import {DataService} from '../../shared/services/data.service';
import {tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ImageHandlerService} from "../../shared/services/image-handler.service";


@Injectable({
  providedIn: 'root'
})
export class UsersService extends DataService {

  constructor(
    private imageHandler: ImageHandlerService,
    injector: Injector,
  ) {
    super(injector);
  }

  getUsers(params = {}) {
    return this.getRequest(ApiEndpoints.users, params);
  }

  getClients() {
    return this.getRequest(ApiEndpoints.clients);
  }

  getCompanies() {
    return this.getRequest(ApiEndpoints.companies);
  }

  saveCompany(data) {
    delete data.expanded;
    delete data.users;

    if (data.id) {
      return this.putRequest(ApiEndpoints.company, data);
    } else {
      return this.postRequest(ApiEndpoints.company, data);
    }
  }

  deleteCompany(data) {
    return this.deleteRequest(ApiEndpoints.company, data.id);
  }

  getSysUsers() {
    return this.getRequest(ApiEndpoints.users);
  }

  saveClient(data) {
    // clean up client
    if (data.indices) {
      data.indices = data.indices.map(i => i.id);
    }
    delete data.expanded;
    delete data.users;

    if (data.id) {
      return this.putRequest(ApiEndpoints.client, data);
    } else {
      return this.postRequest(ApiEndpoints.client, data);
    }
  }

  getClientIndeces(id) {
    return this.getRequest(`${ApiEndpoints.client}/${id}/indices`);
  }

  deleteClient(data) {
    return this.deleteRequest(ApiEndpoints.client, data.id);
  }

  toggleClientCustomizationAccess(id) {
    if (!id) {
      return of({error: 'Missing client id'});
    }
    return this.postRequest(`${ApiEndpoints.client}/${id}/toggle-customization`, {});
  }

  saveUser(data) {
    if (!data.photo) {
      delete data.photo;
    }
    if (!this.imageHandler.isDataUrl(data.photo)) {
      delete data.photo;
    }
    if (data.id) {
      return this.putRequest(ApiEndpoints.user, data);
    } else {
      return this.postRequest(ApiEndpoints.user, data);
    }
  }

  deleteUser(user) {
    return this.deleteRequest(ApiEndpoints.user, user.id);
  }

  getIndices() {
    return this.getRequest(ApiEndpoints.clientIndices);
  }

  getNotifications() {
    return this.getRequest(ApiEndpoints.notifications);
  }

  markAllAsRead() {
    return this.postRequest(ApiEndpoints.notifications + '/mark-all-as-read', {});
  }

  markAsRead(item) {
    if (!item && !item.id) {
      return of({error: 'Missing notification ID'});
    }
    return this.postRequest(`${ApiEndpoints.notification}/${item.id}/mark-as-read`, {});
  }



}
