import { Component, OnInit } from '@angular/core';
import {CountryService} from '../../shared/services/country.service';
import {User} from '../../shared/models/user.model';
import {KeyValuePair} from '../../shared/models/key-value.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class RegistrationComponent implements OnInit {

  guest: User = new User();

  countries: KeyValuePair[] = [];
  cities: KeyValuePair[] = [];

  constructor(
    private countryService: CountryService
  ) { }

  ngOnInit() {
  }

  onRegistrationFormSubmit() {

  }
}
