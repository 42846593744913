<!-- This button triggers the overlay and is it's origin -->
<button mat-icon-button color="primary" (click)="company.isOpen = !company.isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <mat-icon>info</mat-icon>
</button>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="company.isOpen"
        [cdkConnectedOverlayOffsetX]="50"
>
    <div class="popover-card">
        <button class="popover-close-btn" mat-icon-button (click)="company.isOpen = false"><mat-icon>close</mat-icon></button>
        <p class="text-semi-bold  mb-2">{{company.name}}</p>
        <table class="table table-borderless table-sm p-0 m-0">
            <tbody>
            <tr>
                <th>Current Market Cap</th>
                <td class="text-right text-semi-bold">{{company.current_market_cap|number:'1.0-2' || '0'}} M{{currency}}</td>
            </tr>
            <tr>
                <th>Dividend Indicated Yield (Net)</th>
                <td class="text-right text-semi-bold">{{company.dividend_indicated_yield_net|number:'1.0-2' || '0'}}$</td>
            </tr>
            <tr>
                <th>Enterprise Value</th>
                <td class="text-right text-semi-bold">{{company.enterprise_value|number:'1.0-2' || '0'}} M{{currency}}</td>
            </tr>
            <tr>
                <th>Enterprise Value / EBITDA</th>
                <td class="text-right text-semi-bold">{{company.enterprise_value_per_ebitda|number:'1.0-2' || '0'}}</td>
            </tr>
            <tr *ngIf="!hideFreq">
                <th>Frequency</th>
                <td class="text-right text-semi-bold">{{company.frequency ? ((company.frequency*100)|number:'1.0') : '0'}}%</td>
            </tr>
            <tr>
                <th>Last Close</th>
                <td class="text-right text-semi-bold">{{company.last_price|number:'1.0-2' || '0'}} $</td>
            </tr>
            <tr *ngIf="company.date_last_price">
                <th>Last Close Date</th>
                <td class="text-right text-semi-bold">{{company.date_last_price}}</td>
            </tr>
            <tr>
                <th>PE Ratio</th>
                <td class="text-right text-semi-bold">{{company.price_earnings_ratio|number:'1.0-2' || '0'}}</td>
            </tr>
            <tr>
                <th>Price/Book Ratio</th>
                <td class="text-right text-semi-bold">{{company.price_to_book_ratio|number:'1.0-2' || '0'}}</td>
            </tr>
            <tr>
                <th>Price/Sales Ratio</th>
                <td class="text-right text-semi-bold">{{company.price_to_sales_ratio|number:'1.0-2' || '0'}}</td>
            </tr>
            <tr>
                <th>Volatility (30 days)</th>
                <td class="text-right text-semi-bold">{{company.volatility_30_days|number:'1.0-2' || '0'}}%</td>
            </tr>
            <tr>
                <th>Volatility (90 days)</th>
                <td class="text-right text-semi-bold">{{company.volatility_90_days|number:'1.0-2' || '0'}}%</td>
            </tr>
            </tbody>
        </table>
    </div>
</ng-template>
