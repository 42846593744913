const imageAssetsPath = '/assets/images';
export const END_USER_GROUP_ID = 3;
export const ADMIN_GROUP_ID = 5;
export const SUPERADMIN_GROUP_ID = 6;
export class User {

  public id?: number;
  public first_name = '';
  public last_name = '';
  public email?: string;
  public phone?: string;
  public job_description?: string;
  public activated?: boolean;
  public custom_access?: boolean;
  public two_fa_enabled?: boolean;
  public two_fa_type?: string;
  public public_share?: string;
  public is_admin?: boolean;
  public deleted?: boolean;
  public password?: string;
  public password_confirm?: string;
  public photo?: string;
  public company?: any;
  public company_id?: number;
  public group?: any;
  public group_id?: number;
  public indices?: any[];
  public auth_logs_30_days?: number;
  public auth_logs_1_year?: number;
  public last_login?: string;
  public has_custom_portfolio?: string;
  public datawrapper_api_key?: string;
  public has_data_wrapper_api_key?: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.email = data.email;
      this.phone = data.phone;
      this.activated = data.activated;
      this.deleted = data.deleted;
      this.job_description = data.job_description;
      this.is_admin = data.is_admin;
      this.custom_access = data.custom_access;
      this.two_fa_enabled = data.two_fa_enabled;
      this.two_fa_type = data.two_fa_type;
      this.public_share = data.public_share;
      this.company = data.company;
      this.company_id = data.company_id;
      if (data.company && data.company.id) {
        this.company_id = data.company.id;
      }
      this.group = data.group;
      this.group_id = data.group_id;
      if (data.group) {
        this.is_admin = this.isAdmin();
      }
      this.photo = data.photo;
      this.indices = data.indices;
      this.auth_logs_30_days = data.auth_logs_30_days;
      this.auth_logs_1_year = data.auth_logs_1_year;
      this.last_login = data.last_login;
      this.has_custom_portfolio = data.has_custom_portfolio;
      this.datawrapper_api_key = data.datawrapper_api_key;
      this.has_data_wrapper_api_key = !!data.has_data_wrapper_api_key;
    }
  }

  public get fullName() {
    return this.first_name + ' ' + this.last_name;
  }

  getPhoto() {
    return this.photo ? `${this.photo}` : `${imageAssetsPath}/icons/placeholder-user.jpg`;
  }

  isValid() {
    const basic = this.first_name && this.last_name && this.email;
    if (this.id) {
      return basic;
    }
    return  basic && this.password;
  }

  isSuperAdmin() {
    return this.group && +this.group.id === SUPERADMIN_GROUP_ID;
  }

  isAdmin() {
    return this.group && +this.group.id === ADMIN_GROUP_ID;
  }

  hasCustomAccess() {
    return false;
    // Disable custom access if client does not have the access
    /*if (this.client && this.client.customization_access === false) {
      return false;
    }
    return this.custom_access;*/
  }

  isValidCompany() {
    return !!this.first_name && !!this.last_name && this.email && (this.company_id || (this.company && this.company.name));
  }

  canSharePublicly() {
    return +this.public_share === 1;
  }
}
