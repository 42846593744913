import {Injectable, Injector} from '@angular/core';
import {ApiEndpoints} from '../../ApiEndpoints';
import {map} from 'rxjs/operators';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
export class DatawrapperService extends DataService {

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  createChart(title: string) {
    return this.postRequest(ApiEndpoints.datawrapperCreateChart, {title});
  }

  uploadChartData(chartId: string, data: any) {
    return this.postRequest(ApiEndpoints.datawrapperUploadChartData.replace(':id', chartId), data, {}, true, true);
  }
}
