import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ObjectSelectComponent} from "../object-select/object-select.component";
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-table-header-select',
  templateUrl: './table-header-select.component.html',
  styleUrls: ['../filter-select/filter-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableHeaderSelectComponent extends ObjectSelectComponent{
  @Input() name: string = '';

  constructor(
    private storage: StorageService
  ) {
    super();
  }

  trigger() {
    this.storage.sideMenuObjectChange.emit(this);
  }
}
