import {AfterContentInit, AfterViewInit, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PortfolioService} from "../../pages/portfolio/portfolio.service";
import * as moment from "moment";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ColumnType} from "../cim-datatable/cim-datatable.component";

@Component({
  selector: 'app-company-info-dialog',
  templateUrl: './company-info-dialog.component.html',
  styleUrls: ['./company-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('100ms ease')),
    ]),
  ]
})
export class CompanyInfoDialogComponent implements OnInit {
  closeAdjData: any = [];

  introExpanded = true;
  sankeyExpanded = true;
  valuationExpanded = true;
  incomeExpanded = true;
  balanceExpanded = true;
  employeesNum = '-';

  incomeStatementColumns: any[] = [];
  incomeStatementData: any[] = [];
  incomeDataLoaded = false;

  balanceColumns: any[] = [];
  balanceData: any[] = [];

  introSection = [
    // {
    //   id: 'address',
    //   title: 'Address',
    //   unit: '',
    //   type: 'string',
    // },
    // {
    //   id: 'web_site',
    //   title: 'URL',
    //   unit: '',
    //   type: 'url',
    // },
    // {
    //   id: 'pct_above_52w_low',
    //   title: '% above 52w low',
    //   unit: '%',
    //   type: 'percent',
    // },
    // {
    //   id: 'pct_below_52w_high',
    //   title: '% below 52w high',
    //   unit: '%',
    //   type: 'percent',
    // },
    // {
    //   id: 'peg_ratio',
    //   title: '52w position',
    //   unit: '%',
    //   type: 'percent',
    // },
  ];

  sections: any[] = [
    // {
    //   name: 'Key Data',
    //   list: [
    //     {
    //       id: 'enterprise_value',
    //       title: 'Enterprise value',
    //       unit: 'M USD',
    //       type: 'number',
    //     },
    //     {
    //       id: 'ebitda_usd',
    //       title: 'EBITDA',
    //       unit: 'M USD',
    //       type: 'number',
    //     },
    //     {
    //       id: 'price_to_earnings',
    //       title: 'P/E',
    //       unit: '',
    //       type: 'number',
    //     },
    //     {
    //       id: 'price_to_book',
    //       title: 'P/B',
    //       unit: '',
    //       type: 'number',
    //     },
    //     {
    //       id: 'price_to_sales',
    //       title: 'P/S',
    //       unit: '',
    //       type: 'number',
    //     },
    //     {
    //       id: 'price_target_estimate_revisions_45d',
    //       title: 'Price target estimate revisions 45d',
    //       unit: '% [-100, +100]',
    //       type: 'number',
    //     },
    //     {
    //       id: 'price_target_estimate_revisions_75d',
    //       title: 'Price target estimate revisions 75d',
    //       unit: '% [-100, +100]',
    //       type: 'number',
    //     },
    //     {
    //       id: 'price_target_estimate_revisions_100d',
    //       title: 'Price target estimate revisions 100d',
    //       unit: '% [-100, +100]',
    //       type: 'number',
    //     },
    //     {
    //       id: 'price_target_estimate_revisions_180d',
    //       title: 'Price target estimate revisions 180d',
    //       unit: '% [-100, +100]',
    //       type: 'number',
    //     },
    //     {
    //       id: 'days_since_first_trade',
    //       title: 'Days since first trade',
    //       unit: 'Days',
    //       type: 'number',
    //     },
    //     {
    //       id: 'days_since_report',
    //       title: 'Days since report',
    //       unit: 'Days',
    //       type: 'number',
    //     },
    //     {
    //       id: 'days_to_report',
    //       title: 'Days to report',
    //       unit: 'Days',
    //       type: 'number',
    //     },{
    //       id: 'local_index',
    //       title: 'Local index',
    //       unit: '',
    //       type: 'string',
    //     },{
    //       id: 'minimum_lot_size',
    //       title: 'Minimum Lot Size',
    //       unit: '',
    //       type: 'number',
    //     },{
    //       id: 'number_of_employees',
    //       title: 'Number of Employees',
    //       unit: '',
    //       type: 'number',
    //     },{
    //       id: 'years_since_founded',
    //       title: 'Years Since Founded',
    //       unit: 'Years',
    //       type: 'number',
    //     },{
    //       id: 'business_description_length',
    //       title: 'Business Description Length',
    //       unit: '',
    //       type: 'number',
    //     },
    //   ],
    //   expanded: false
    // },

  ];

  companyTicker = null;
  companyName = '';
  overview: any = {};
  isData: any = null;

  extraOptions = {
    tooltip: {
      xDateFormat: '%Y-%m-%d',
      valueSuffix: ' USD'
    },
    rangeSelector: {
      buttons: [{
        type: 'month',
        count: 1,
        text: '1m',
        title: 'View 1 month'
      },
        {
          type: 'month',
          count: 3,
          text: '3m',
          title: 'View 3 months'
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
          title: 'View 6 months'
        },
        {
          type: 'ytd',
          text: 'YTD',
          title: 'View year to date'
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
          title: 'View 1 year'
        },
        {
          type: 'year',
          count: 3,
          text: '3y',
          title: 'View 3 years'
        },
        {
          type: 'year',
          count: 5,
          text: '5y',
          title: 'View 5 years'
        },
        {
          type: 'year',
          count: 10,
          text: '10y',
          title: 'View 10 years'
        },
        {
          type: 'all',
          text: 'All',
          title: 'View all'
        }]
    },
  };

  constructor(
    public dialogRef: MatDialogRef<CompanyInfoDialogComponent>,
    private service: PortfolioService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.dialogRef.afterOpened().subscribe(() => {
      this.initDialog();
    });
  }

  initDialog() {
    if (this.data.company) {
      const currency = this.data.company.currency ? this.data.company.currency.code : 'USD';
      this.extraOptions.tooltip.valueSuffix = ` ${currency}`;
      this.fetchAdjChart();
      this.incomeStatementColumns.push({id: 'msek', label: `(M${currency})`, type: ColumnType.Simple});
      this.balanceColumns.push({id: 'msek', label: `(M${currency})`, type: ColumnType.Simple});
      this.fetchIs();
    }
    if (this.data.company.fs_ticker) {
      this.companyTicker = this.data.company.fs_ticker.split('-')[0];
    } else {
      this.service.notificationService.open('Missing company ticker');
    }
  }

  fetchAdjChart() {
    this.service.getCloseAdj(this.data.company.id).subscribe((resp: any) => {
      if (resp.overview) {
        this.companyName = resp.overview.security_name_full;
        this.overview = {
          ...this.data.company.quant,
          ...resp.overview
        };
      }
      if (resp.items) {
        const closeAdj = [];
        resp.items.map((r: any) => {
          closeAdj.push([moment(`${r.date} 12:00:00`).unix() * 1000, r.close_adj]);
        });
        this.closeAdjData = [{
          name: this.companyName,
          data: closeAdj,
          tooltip: {
            valueDecimals: 2
          }
        }];
      }
    })
  }

  fetchIs() {
    this.service.getIs(this.data.company.security_id).subscribe((resp: any) => {
      if (resp.success) {
        this.isData = resp.success;
        let incomeData = {};
        let balanceData = {};
        let firstYear = null;
        let epsFormatted = `EPS (${this.extraOptions.tooltip.valueSuffix.trim()})`
        let dpsFormatted = `DPS (${this.extraOptions.tooltip.valueSuffix.trim()})`
        resp.success.map((e: any, index: number) => {
          let date = new Date(e.date);
          let year = date.getFullYear().toString();
          if (index === 0) {
            firstYear = year;
            this.employeesNum = e.ff_emp_num;
          }
          this.incomeStatementColumns.push({
            id: year,
            label: year,
            type: ColumnType.Number2Decimal
          });
          this.balanceColumns.push({
            id: year,
            label: year,
            type: ColumnType.Number2Decimal
          });

          incomeData[year] = {
            'Revenue': e.ff_sales,
            'Gross Income': e.ff_gross_inc,
            'EBITDA': e.ff_ebitda_oper,
            'EBIT': e.ff_ebit_oper,
            'Pretax profit': e.ff_ptx_inc,
            'Net income': e.ff_net_income,
            [epsFormatted]: e.ff_eps,
            [dpsFormatted]: e.ff_dps,
          };

          balanceData[year] = {
            'Total Assets': e.ff_assets,
            'Total Liabilities': e.ff_liabs,
            'Total Equity': e.ff_eq_tot,
          };
        })

        this.incomeStatementData = Object.keys(incomeData[firstYear]).map((msek) => ({
          msek,
          ...Object.keys(incomeData).reduce((acc, year) => {
            acc[year] = incomeData[year][msek];
            return acc;
          }, {}),
        }));

        this.balanceData = Object.keys(balanceData[firstYear]).map((msek) => ({
          msek,
          ...Object.keys(balanceData).reduce((acc, year) => {
            acc[year] = balanceData[year][msek];
            return acc;
          }, {}),
        }));

        this.incomeDataLoaded = true;
      }
    })
  }

  initIncomeEmptyData() {
    this.incomeStatementColumns.map((e: any) => {

    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onToggleAll(expand = true) {
    this.introExpanded = expand;
    this.valuationExpanded = expand;
    this.sankeyExpanded = expand;
    this.sections = this.sections.map(s => {
      s.expanded = expand;
      return s;
    });
  }
}
