import {Component, Injector, OnInit} from '@angular/core';
import {GeneralChartComponent, high_colors} from "../general-chart/general-chart.component";

@Component({
  selector: 'app-stress-risk-chart',
  templateUrl: '../general-chart/general-chart.component.html'
})
export class StressRiskChartComponent extends GeneralChartComponent {

  constructor(injector: Injector) {
    super(injector);
    this.chartConstructor = 'chart';
  }

  initChart() {
    this.chartOptions = {
      chart: {
        height: '620px'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      legend: {
        maxHeight: 80,
        itemDistance: 5,
        margin: 0,
        padding: 0,
        itemWidth: 100,
        backgroundColor: '#ffffff'
      },
      colors: high_colors,
      xAxis: {
        minorGridLineWidth: 0,
        //min: 0,
        //max: 0.5,
        title: {
          text: 'Volatility',
          style: {
            textTransform: 'capitalize'
          }
        },
        labels: {
          format: '{value}%'
        },
      },
      yAxis: {
        minorGridLineWidth: 0,
        title: {
          text: 'Return',
          style: {
            textTransform: 'capitalize'
          }
        },
        labels: {
          format: '{value}%'
        },
      },
      series: this.series,
      plotOptions: {
        series: {
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y}%</b>',
          },
        },
        scatter: {
          dataLabels: {
            enabled: true,
            format: "{point.name}",
          }
        }
      },
    };
    if (this.fullHeight) {
      this.chartOptions.chart.height = '88%';
    }
    setTimeout(() => this.loaded = true, 10);
  }

}
