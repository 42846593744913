<button mat-icon-button class="dialog-close-btn" (click)="onNoClick()"><mat-icon>close</mat-icon></button>
<h1 mat-dialog-title>{{user.id ? 'Edit' : 'Create new'}} user</h1>
<div mat-dialog-content>
    <div class="row mb-30">
        <div class="col-md-6">
            <div class="user-image-uploader">
                <div class="user-photo" [style.backgroundImage]="'url('+user.getPhoto()+')'"></div>
                <button mat-button color="primary" (click)="fileInput.click()">Change profile picture</button>
                <input hidden type="file" #fileInput accept="image/*" name="photo" (change)="handleInputChange($event)">
            </div>
        </div>
        <div class="col-md-6 text-right">
            <div class="d-flex justify-content-center align-items-end flex-column h-100">
                <div *ngIf="!isSuperAdmin">
                    <mat-slide-toggle color="primary" [(ngModel)]="user.is_admin" [checked]="user.is_admin" labelPosition="before">
                        Admin rights
                    </mat-slide-toggle>
                </div>
                <div>
                    <mat-slide-toggle color="primary" [(ngModel)]="user.activated" [checked]="user.activated" class="pt-1" labelPosition="before">
                        Activated
                    </mat-slide-toggle>
                </div>
                <div>
                    <mat-slide-toggle color="primary" [(ngModel)]="user.two_fa_enabled" class="pt-1" labelPosition="before">
                        2-Factor Authentication
                    </mat-slide-toggle>
                </div>
                <div>
                    <mat-slide-toggle color="primary" [(ngModel)]="user.public_share" class="pt-1" labelPosition="before">
                       Public share
                    </mat-slide-toggle>
                </div>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label >First name </mat-label>
                <input matInput placeholder="First name *" name="fname" [(ngModel)]="user.first_name" required>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label >Last name </mat-label>
                <input matInput placeholder="Last name *" name="lname" [(ngModel)]="user.last_name" required>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label >Email </mat-label>
                <input matInput placeholder="Email *" name="email" email [(ngModel)]="user.email" required>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label >Phone</mat-label>
                <input matInput placeholder="Phone" name="phone" [(ngModel)]="user.phone">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label >Job role</mat-label>
                <input matInput placeholder="Job role" name="job" [(ngModel)]="user.job_description">
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="!isSuperAdmin">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label >Add to company </mat-label>
            <mat-select [(ngModel)]="user.company_id" placeholder="Add to company *" required>
                <mat-option *ngFor="let company of data.companies | sysfilter" [value]="company.id">{{company.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions class="justify-content-between">
    <div>
        <button mat-flat-button color="primary" (click)="onSaveClick()" class="border-primary rounded-pill">Save</button>
    </div>
</div>
