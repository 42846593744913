import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'panelsearch'
})
export class ColumnVisibilitySearchPipe implements PipeTransform {

  transform(category: any, args?: any): any {
    if (!category) {return category; }
    if (!args) {
      return category.map(i => {
      i.hidden = false;
      i.items.map(x => {
        x.hidden = false;
        return x;
      })
      return i});
    }

    return category.map((cat) => {
      const text = args.toLowerCase();

      let hiddenItems = 0;
      cat.items.map((item) => {
        if (!item.name.toLowerCase().includes(text)) {
          item.hidden = true;
          hiddenItems += 1;
        } else {
          item.hidden = false;
          hiddenItems -= 1;
        }
      });
      cat.hidden = hiddenItems == cat.items.length;
      return cat;
    });
  }

}
