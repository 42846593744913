<button mat-icon-button class="dialog-close-btn" (click)="onNoClick()"><mat-icon>close</mat-icon></button>
<h1 mat-dialog-title>
    Share portfolio
</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col">
            <p >Select the users that you want to share the <span class="font-weight-bold">{{portfolio.name}}</span> named portfolio</p>

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Users</mat-label>
                <input type="text"
                       placeholder="User"
                       matInput
                       name="filterUser"
                       [(ngModel)]="filterUser"
                       [matAutocomplete]="auto"
                       #filterInput
                >
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let user of users" [value]="user.name" (click)="filterInput.blur();onUserSelect(user)">
                        {{user.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <table *ngIf="selected.length || (portfolio.shared_with_users && portfolio.shared_with_users.length)" class="table">
                <thead>
                <tr>
                    <th>User</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let u of portfolio.shared_with_users">
                    <td>{{u.name}}</td>
                    <td><button mat-button color="warn" (click)="onUserRemove(u)">remove</button></td>
                </tr>
                <tr *ngFor="let u of selected">
                    <td>{{u.name}}</td>
                    <td><button mat-button color="warn" (click)="onUserRemove(u)">remove</button></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-center">
    <button mat-flat-button (click)="onNoClick()" class="border-primary rounded-pill">Cancel</button>
    <button mat-flat-button color="primary" (click)="onShareClicked()" class="border-primary rounded-pill">Share</button>
</div>
