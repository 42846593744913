<button mat-icon-button class="dialog-close-btn" (click)="onFinish2FAClicked()"><mat-icon>close</mat-icon></button>
<h1 mat-dialog-title>
    2FA Reset
</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col">
            <p >Scan the QR code with <b>Google Authenticator</b> app.</p>

            <p class="mt-4 text-center">
                <img src="{{data.qrCode}}" alt="" class="mw-100">
            </p>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-center">
    <button mat-flat-button color="primary" (click)="onFinish2FAClicked()" class="border-primary rounded-pill">Finish</button>
</div>
