<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-6">
                        <h1>{{title}}</h1>
                    </div>
                    <div class="col-6">
                        <mat-form-field *ngIf="isPortfolioPerformance" appearance="outline" class="header-select">
                            <mat-label>Type</mat-label>
                            <mat-select [(ngModel)]="chartType" (ngModelChange)="changeData()">
                                <mat-option [value]="'volatility'">Volatility</mat-option>
                                <mat-option [value]="'return'">Return</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="card-body">
        <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="chartOptions"
                style="width: 100%; height: auto; display: block;"
        >
        </highcharts-chart>
            </div>
        </div>
     </div>
</div>
