import {AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Location} from "@angular/common";
import {UtilService} from "../../../shared/services/util.service";
import {StorageService} from "../../../shared/services/storage.service";
import {PortfolioService} from "../portfolio.service";
import {CompanyModel} from "../../../shared/models/company.model";
import * as moment from "moment";
import {ActivatedRoute, Router} from "@angular/router";
import { AssetTableColumnVisibilityFilterComponent } from '../asset-table-column-visibility-filter/asset-table-column-visibility-filter.component';

@Component({
  selector: 'app-base-portfolio-create',
  templateUrl: './base-portfolio-create.component.html',
  styleUrls: ['./base-portfolio-create.component.scss']
})
export class BasePortfolioCreateComponent implements AfterViewInit, OnChanges {
  @Input() list: CompanyModel[] = [];
  @Input() filterLists: any = {
    markets: [],
    regions: [],
    countries: [],
    sectors: [],
    industries: [],
    expectedResults: null,
    flags: []
  }

  @Output() getFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() fetchCompanies: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() startDateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() startingDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedSortingChanged: EventEmitter<any> = new EventEmitter<any>();

  assetType = 'stock';
  loading = true;

  filter_label_length_limit = 6;
  max_start_date: any = null;
  columnVisibility: any = {};
  quantList: any[] = [];
  dynamicColumns: any[] = [];

  currentFilters: any = {
    filters: {},
    dynamic: {},
    searchTerm: null
  }

  selectedFilterLabels: any = [];

  filterListDescriptor = [
    'market_ids',
    'region_ids',
    'country_ids',
    'sector_ids',
    'industry_ids',
    'currency_ids',
    'exchange_code_fs',
      'DomicileName',
      'BrandingCompanyName',
      'LocalCategoryName',
      'GlobalCategoryName',
      'FeeLevel',
      'EquityStyleBox',
      'MorningStarRating',
      'UCITS',
      'FundOfFunds',
      'LegalStructure',
      'BaseCurrency'
  ];

  sorting: any = {
  }

  selectedSorting = 'company_name';

  dialog: MatDialog;
  util: UtilService;
  storage: StorageService;
  service: PortfolioService;
  router: Router;
  route: ActivatedRoute;
  location: Location;

  constructor(
    private injector: Injector,
  ) {
    this.route = injector.get(ActivatedRoute);
    this.location = injector.get(Location);
    this.dialog = injector.get(MatDialog);
    this.util = injector.get(UtilService);
    this.storage = injector.get(StorageService);
    this.service = injector.get(PortfolioService);
    this.router = injector.get(Router);
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list) {
      this.checkColumnLists(changes.list);
    }
    
  }

  sortingNiceName(key) {
    return this.util.sortingNiceName(key);
  }

  checkColumnLists(listState) {
    if (!this.quantList.length && listState.currentValue.length) {
      const company = listState.currentValue[0];

      console.log(company);
      let quantList: any[] = [];

      const skipIDList = ['id', 'fs_ticker', 'security_id', 'isin', 'date']
      const creditRatingType = [];
      let filterType = null;

      let filterItems = {};

      if (company.quant ) {
        filterItems = company.quant;
      } else if (company.etf_reference_table) {
        filterItems = company.etf_reference_table;
      } else if (company.fund_reference_table) {
        filterItems = company.fund_reference_table;
      }

      if (filterItems) {
        Object.entries(filterItems).map(([key, value]) => {
          filterType = null;
          if (!skipIDList.includes(key)) {
            let name = key.split('_').join(' ');
            if (creditRatingType.includes(key)) {
              filterType = 'credit'
            }
            quantList.push({
              id: key,
              name: name,
              visible: false,
              type: filterType
            })
          }
        })
      }
      this.quantList = quantList;
    }
    this.loading = false;
  }

  restoreVisibility() {
    const key = `${this.storage.visibilityStorageKey}${this.assetType}`;
    const visibility = this.storage.get(key);
    this.columnVisibility = visibility ? visibility : {};
    this.getDynamicColumns();
  }
  getDynamicColumns() {}

  checkMaxStartDate(list) {
    const startingDate = '1800-01-01'
    let maxDate = startingDate;
    list.map((l: CompanyModel) => {
      if (moment(l.p_first_date) > moment(maxDate)) {
        maxDate = l.p_first_date
      }
      
    })
    this.max_start_date = maxDate === startingDate ? null : maxDate;
    if (this.max_start_date) {
      this.startDateChange.emit(this.max_start_date)
      this.startingDate.emit(this.max_start_date)
    }
  }
  

  onCompanyInfoClick(company) {
    this.service.openCompanyInfoDialog(company.fs_ticker, company)
  }

  resetFilters() {
    this.currentFilters = {
      filters: {},
      dynamic: {},
      searchTerm: null
    }
    this.selectedFilterLabels = [];
    this.sorting = {};
  }

  collectFilters() {
    const params:any = {
      filters: {
      }
    }
    this.filterListDescriptor.map(label => {
      if (this.currentFilters.filters[label] && this.currentFilters.filters[label].length) {
        params.filters[label] = this.currentFilters.filters[label];
      }
    })
    if (this.currentFilters.dynamic) {
      params.filters['dynamic'] = this.currentFilters.dynamic;
    }

    if (this.currentFilters.searchTerm) {
      params.searchTerm = this.currentFilters.searchTerm;
    }
    if (this.currentFilters.filters.start_date) {
      params.filters.start_date = this.currentFilters.filters.start_date
    }
    if (this.currentFilters.filters.end_date) {
      params.filters.end_date = this.currentFilters.filters.end_date
    }

    if (this.sorting) {
      params.orders = {}
      Object.entries(this.sorting).map(([key, value]:[string,string]) => {
        params.orders[key] = value;
      })
    }

    params.page = 1;
    params.type = this.assetType;
    return params;
  }

  getItemByIdFromList(id, label) {
    let list = this.filterLists[this.getListNameByFilterListLabel(label)];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == id) {
        return list[i];
      }
    }
  }

  getLabelByFilterListLabel(label) {
    switch (label) {
      case 'market_ids':
        return 'Market';
      case 'region_ids':
        return 'Region';
      case 'country_ids':
        return 'Country';
      case 'sector_ids':
        return 'Sector';
      case 'industry_ids':
        return 'Industry';
      case 'currency_ids':
        return 'Currency';
      case 'exchange_code_fs':
        return 'Exchange';
    }
    return label;
  }

  getListNameByFilterListLabel(label) {
    switch (label) {
      case 'market_ids':
        return 'markets';
      case 'region_ids':
        return 'regions';
      case 'country_ids':
        return 'countries';
      case 'sector_ids':
        return 'sectors';
      case 'industry_ids':
        return 'industries';
      case 'currency_ids':
        return 'currencies';
    }
    return label;
  }

  updateFilterLabels() {
    this.selectedFilterLabels = [];
    this.filterListDescriptor.map(label => {
      if (this.currentFilters.filters[label] && this.currentFilters.filters[label].length) {
        this.currentFilters.filters[label].map(id => {
          let item = this.getItemByIdFromList(id, label);
          if (item) {
            const niceLabel = this.getLabelByFilterListLabel(label);
            if (!this.selectedFilterLabels[niceLabel]) {
              this.selectedFilterLabels[niceLabel] = []
            }
            this.selectedFilterLabels[niceLabel].push(
              {
                id: item.id,
                name: item.name,
                label: niceLabel,
                label_raw: label
              }
            )
          }
        })
      }
    })

    if (this.currentFilters.searchTerm) {
      this.selectedFilterLabels['Text'] = [{
        id: this.currentFilters.searchTerm,
        name: this.currentFilters.searchTerm,
        label: 'Text'
      }]
    }
  }

  onDeleteFilterClick(filter) {
    if (filter.key == "Text") {
      this.currentFilters.searchTerm = null;
    } else {
      const label = filter.value[0].label_raw;
      this.currentFilters.filters[label] = [];
    }
    delete this.selectedFilterLabels[filter.key];
    this.onFetchCompanies();
  }

  onDeleteDynamicFilterClick(dynamic) {
    delete this.currentFilters.dynamic[dynamic];
    this.onFetchCompanies();
  }

  onPageEvent(event) {
    const filters = this.collectFilters();
    filters.page = event;
    this.pageEvent.emit({
      filters: filters,
      page: event,
      isPageEvent: true
    });
  }

  onSortClick(label) {
    // Reset sorting if not the clicked label is used (only 1 can active)
    const sortingKeys = Object.keys(this.sorting);
    if (sortingKeys[0] !== label) {
      this.sorting = {};
    }

    if (!this.sorting[label]) {
      this.sorting[label] = 'asc';
    } else if (this.sorting[label] === 'asc'){
      this.sorting[label] = 'desc';
    } else {
      delete this.sorting[label]
    }
    this.onFetchCompanies();
  }

  onDeleteSortClick(sort) {
    delete this.sorting[sort.key];
    this.onFetchCompanies();
  }

  onFetchCompanies() {
    const currentFilters = this.collectFilters();
    this.fetchCompanies.emit(currentFilters);
    this.updateFilterLabels();
    return currentFilters;
  }

  onNextClick() {
    this.nextClick.emit()
  }

  onDynamicColumnChange(list) {
    this.dynamicColumns = list;
  }

  onDateChange() {
    if (this.currentFilters.filters.start instanceof moment) {
      this.currentFilters.filters.start = `${this.currentFilters.filters.start.format('YYYY-MM-DD')} 00:00:00`
    }
    if (this.currentFilters.filters.end instanceof moment) {
      this.currentFilters.filters.end = `${this.currentFilters.filters.end.format('YYYY-MM-DD')} 23:59:59`
    }
  }

  onSelectedSortClick(type) {
    this.selectedSorting = this.service.checkSelectedSorting(this.selectedSorting, type);
    this.selectedSortingChanged.emit(this.selectedSorting);
  }
}
