import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {high_colors} from "../general-chart/general-chart.component";

@Component({
    selector: 'app-risk-contribution-column-chart',
    templateUrl: './risk-contribution-column-chart.component.html',
    styleUrls: ['./risk-contribution-column-chart.component.scss']
})
export class RiskContributionColumnChartComponent implements OnInit {
    loaded = false;
    Highcharts: typeof Highcharts = Highcharts;

    @Input() riskContributionRaw: any = {};
    @Input() subHeader: any = {};

    chartOptions: Highcharts.Options = {};
    chartType: string = 'risk';
    series: any[] = [];
    name: string = '';
    ngOnInit(): void {
        this.setRiskData();
    }

    ngOnChanges(): void {
        this.setRiskData()
    }

    changeDataRC() {
        if (this.chartType === 'risk') {
            this.setRiskData();
            
        } else {
            this.setReturnData();
            
        }
    }

    private setRiskData() {
        this.series = [];
        this.riskContributionRaw.sort((a, b) => {
            return b.riskContribution - a.riskContribution;
        })
        this.name = 'Risk';
        this.series.push(
            {
                type: 'column',
                name: 'Weight',
                color: '#40c6ba',
                data: this.riskContributionRaw.map((e) => e.Weight),
                pointPadding: 0.3,
            },
            {
                type: 'column',
                name: 'Risk',
                color: '#424CCD',
                data: this.riskContributionRaw.map((e) => e.riskContribution),
                pointPadding: 0.4,
            });
        this.initChart();
    }

    private setReturnData() {
        this.series = [];
        this.riskContributionRaw.sort((a, b) => {
            return b.Return - a.Return;
        })
        this.name = 'Return';
        this.series.push(
            {
                type: 'column',
                name: 'Weight',
                color: '#40c6ba',
                data: this.riskContributionRaw.map((e) => e.Weight),
                pointPadding: 0.3,
            },
            {
                type: 'column',
                name: 'Return',
                color: '#424CCD',
                data: this.riskContributionRaw.map((e) => e.Return),
                pointPadding: 0.4,
            }
        );
        this.initChart();
    }

    private initChart() {
        this.loaded = false;

        this.chartOptions = {
            chart: {
                height: '300px',
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },

            legend: {
                enabled: true,
            },
            yAxis: {
                title: {
                    text: this.name
                },
                gridLineColor: '#ffff',
                labels: {
                    format: '{value}%'
                },
                minorGridLineWidth: 0,
            },
            plotOptions: {
                column: {
                    grouping: false,
                    shadow: false,
                    borderWidth: 0
                }
            },
            xAxis: {
                categories: this.riskContributionRaw.map((e) => e.Ticker),
                minorGridLineWidth: 0,
                gridLineWidth: 0,
                gridLineColor: '#ffff',

            },
            tooltip: {
                valueSuffix: '%',
                valueDecimals: 2,
            },
            series: this.series
        };


        setTimeout(() => this.loaded = true, 10);
    }
}
